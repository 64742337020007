<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h5 class="sub-title">Marital History</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p-divider class="d-block mb-2"></p-divider>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h5 class="sub-title">Current Marital Status</h5>
    </div>
  </div>
  <form [formGroup]="maritalHistoryHeaderForm">
    <div class="row">
      <div class="col-12 mb-2 selectionContainer">
        <label class="label-cls d-block">What is your current marital status?</label>
        <label class="text-bold">{{ currentMaritalStatus ?
          currentMaritalStatus.currentMaritalStatus.maritalStatus.name : 'Unknown Status' }}</label>
      </div>
    </div>
    <ng-template
      [ngIf]="currentMaritalStatus && currentMaritalStatus.currentMaritalStatus.maritalStatus.code !== 'SNGL'">
      <div class="row">
        <div class="col-12">
          <label class="label-cls required">If you are married, is your spouse a current member of the U.S. armed forces
            or
            U.S. Coastal guard</label>
          <br />
          <div class="row mt-2">
            <div class="col-4">
              <p-radioButton class="float-left p-checkbox ml-2 mr-2" formControlName="isSpouseInArmedSrvc" [value]="2"
                label="N/A">
              </p-radioButton>
            </div>
            <div class="col-4">
              <p-radioButton class="float-left p-checkbox mr-2" formControlName="isSpouseInArmedSrvc" [value]="1"
                label="Yes">
              </p-radioButton>
            </div>
            <div class="col-4">
              <p-radioButton class="float-left p-checkbox ml-2 mr-2" formControlName="isSpouseInArmedSrvc" [value]="0"
                label="No">
              </p-radioButton>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 my-3">
          <label class="label-cls required"> How many times have you been married </label>
          <p-inputNumber formControlName="numOfMarriage" placeholder="Enter" [min]="1" [useGrouping]="false">
          </p-inputNumber>
        </div>
        <span class="invalid-message" *ngIf="
                    (maritalHistoryHeaderForm.get('numOfMarriage').touched) &&
                    maritalHistoryHeaderForm.get('numOfMarriage').errors?.required
                  ">
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <button pButton type="button" label="Save" title="Save" class="save-btn" icon="pi pi-save"
            [ngClass]="{'disabled': disabledControls || maritalHistoryHeaderForm.invalid }"
            [disabled]="maritalHistoryHeaderForm.invalid || disabledControls" (click)="saveMaritalStatus()"></button>
        </div>
      </div>
    </ng-template>
  </form>
  <form [formGroup]="maritalHistoryForm"
    *ngIf="currentMaritalStatus && currentMaritalStatus.currentMaritalStatus.maritalStatus.code !== 'SNGL'">
    <div class="row">
      <div class="col-12">
        <p-divider class="mb-3"></p-divider>
      </div>
    </div>
    <div class="row col-md-12">
      <h5 class="sub-title">Marital History</h5>
    </div>
    <div class="row col-md-12">
      <label class="text-bold">Spouse's Legal Name</label>
    </div>
    <div class="row mt-2">
      <div class="col-4">
        <p class="label-cls required">Title</p>
        <select class="im-input col-md" formControlName="title">
          <option *ngFor="let title of titleListArr" [value]="title.name">
            {{ title.name }}
          </option>
        </select>
        <span class="invalid-message" *ngIf="
                    (maritalHistoryForm.get('title').touched) &&
                    maritalHistoryForm.get('title').errors?.required
                  ">
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
      </div>
      <div class="col-4">
        <p class="label-cls required">First Name</p>
        <input type="text" class="im-input" [ngClass]="{
                    'im-input-error':
                      (maritalHistoryForm.get('firstName').touched) &&
                      maritalHistoryForm.get('firstName').errors?.required
                  }" formControlName="firstName" placeholder="Enter your First Name" />
        <span class="invalid-message" *ngIf="
                    (maritalHistoryForm.get('firstName').touched) &&
                    maritalHistoryForm.get('firstName').errors?.required
                  ">
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
        <span class="invalid-message" *ngIf="
                    (maritalHistoryForm.get('firstName').touched) &&
                    maritalHistoryForm.get('firstName').errors?.pattern
                  ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
      </div>
      <div class="col-4">
        <p class="label-cls">Middle Name</p>
        <input type="text" class="im-input" [ngClass]="{
                    'im-input-error':
                      (maritalHistoryForm.get('middleName').touched) &&
                      maritalHistoryForm.get('middleName').errors?.pattern
                  }" formControlName="middleName" placeholder="Enter your Middle Name" />
        <span class="invalid-message" *ngIf="
                    (maritalHistoryForm.get('middleName').touched) &&
                    maritalHistoryForm.get('middleName').errors?.pattern
                  ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
      </div>
      <div class="col-4 mt-2">
        <p class="label-cls required">Last Name</p>
        <input type="text" class="im-input" [ngClass]="{
                    'im-input-error':
                      (maritalHistoryForm.get('lastName').touched) &&
                      maritalHistoryForm.get('lastName').errors?.required
                  }" formControlName="lastName" placeholder="Enter your Last Name" />
        <span class="invalid-message" *ngIf="
                    (maritalHistoryForm.get('lastName').touched) &&
                    maritalHistoryForm.get('lastName').errors?.required
                  ">
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
        <span class="invalid-message" *ngIf="
                    (maritalHistoryForm.get('lastName').touched) &&
                    maritalHistoryForm.get('lastName').errors?.pattern
                  ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
      </div>
      <div class="col-4 mt-2">
        <p class="label-cls">A-Number (if any)</p>
        <input type="text" class="im-input" formControlName="alienRegNo" placeholder="Enter your A-Number (if any)" />
      </div>
    </div>
    <div class="row col-12 form-group mt-3">
      <div class="p-field-checkbox m-0">
        <p-checkbox id="hasAliasName" formControlName="hasAliasName" [disabled]="disabledControls" binary="true">
        </p-checkbox>
        <label class="label-cls">Does your spouse have any birth name?</label>
      </div>
    </div>
    <div *ngIf="maritalHistoryForm.get('hasAliasName').value" formArrayName="aliasName">
      <div *ngFor="let item of aliasNameForm; let i= index">
        <div [formGroupName]="i">
          <div class="row mt-2">
            <div class="col-4">
              <p class="label-cls required">Title</p>
              <select class="im-input col-md" formControlName="title">
                <option *ngFor="let title of titleListArr" [value]="title.name">
                  {{ title.name }}
                </option>
              </select>
              <span class="invalid-message" *ngIf="
                      (item.get('title').touched) &&
                      item.errors?.aliasNameTitleFieldIsRequired
                    ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
            </div>
            <div class="col-4">
              <p class="label-cls required">First Name</p>
              <input type="text" class="im-input" [ngClass]="{
                      'im-input-error':
                        (item.get('fName').touched) &&
                        (item.errors?.aliasNameFNameFieldIsRequired || item.errors?.aliasNameFNameFieldIsPattern)
                    }" formControlName="fName" placeholder="Enter your First Name" />
              <span class="invalid-message" *ngIf="
                      (item.get('fName').touched) &&
                      item.errors?.aliasNameFNameFieldIsRequired
                    ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
              <span class="invalid-message" *ngIf="
                      (item.get('fName').touched) &&
                      item.errors?.aliasNameFNameFieldIsPattern
                    ">
                {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
              </span>
            </div>
            <div class="col-4">
              <p class="label-cls">Middle Name</p>
              <input type="text" class="im-input" [ngClass]="{
                      'im-input-error':
                        (item.get('mName').touched) &&
                        item.errors?.aliasNameMNameFieldPattern
                    }" formControlName="mName" placeholder="Enter your Middle Name" />
              <span class="invalid-message" *ngIf="
                      (item.get('mName').touched) &&
                      item.errors?.aliasNameMNameFieldPattern
                    ">
                {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
              </span>
            </div>
            <div class="col-4">
              <p class="label-cls required">Last Name</p>
              <input type="text" class="im-input" [ngClass]="{
                      'im-input-error':
                        (item.get('lName').touched) &&
                        (item.errors?.aliasNameLNameFieldIsRequired || item.errors?.aliasNameLNameFieldIsPattern)
                    }" formControlName="lName" placeholder="Enter your Last Name" />
              <span class="invalid-message" *ngIf="
                            (item.get('lName').touched) &&
                            item.errors?.aliasNameLNameFieldIsRequired
                          ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
              <span class="invalid-message" *ngIf="
                            (item.get('lName').touched) &&
                            item.errors?.aliasNameLNameFieldIsPattern
                          ">
                {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4 form-group input-disabled" formGroupName="gender">
        <p class="label-cls required">Gender</p>
        <select class="im-input col-md" formControlName="id">
          <option *ngFor="let gender of genderListArr" [value]="gender.id">
            {{ gender.name }}
          </option>
        </select>
        <span class="invalid-message" *ngIf="
                      (maritalHistoryForm.get('gender').get('id').touched) &&
                      maritalHistoryForm.get('gender').get('id').errors?.required
                    ">
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
      </div>
      <div class="col-md-4 form-group input-disabled">
        <label class="label-cls required">Date of Birth </label>
        <p-calendar class="im-input-calendar mw-100" styleClass="p-form-datepicker" appendTo="body"
          dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true" [disabled]="disabledControls" formControlName="dob" [monthNavigator]="true" [yearNavigator]="true"
          yearRange="1910:2050" [showButtonBar]="true">
        </p-calendar>
        <div class="invalid-message"
          *ngIf="maritalHistoryForm.get('dob').touched && maritalHistoryForm.get('dob').errors?.required">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-md-4 form-group input-disabled">
        <label class="label-cls">Date Marriage Ended</label>
        <p-calendar class="im-input-calendar mw-100" appendTo="body" styleClass="p-form-datepicker"
          dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true"
          [minDate]="maritalHistoryForm.get('dob').value" [disabled]="disabledControls"
          formControlName="marriageDate" [showButtonBar]="true" [monthNavigator]="true" [yearNavigator]="true"
          yearRange="1910:2050">
        </p-calendar>
        <div class="invalid-message"
          *ngIf="maritalHistoryForm.get('marriageDate').touched && maritalHistoryForm.get('marriageDate').errors?.required">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-md-4 form-group input-disabled" *ngIf="primaryData?.visatype !== 'I192U'">
        <label class="label-cls">Place where Marriage Ended</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
          'im-input-error':
            (maritalHistoryForm.get('marriageEndPlace').touched) &&
            maritalHistoryForm.get('marriageEndPlace').errors?.pattern
        }" formControlName="marriageEndPlace" />
      
        <div class="invalid-message"
          *ngIf="maritalHistoryForm.get('marriageEndPlace').touched && maritalHistoryForm.get('marriageEndPlace').errors?.required">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="text-bold">Place of Birth</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 form-group processing-dropdown" formGroupName="birthCountryCode">
        <label class="label-cls"> Country of Birth</label>
        <p-dropdown appendTo="body" [options]="countryList" [filter]="false" id="countryCode"
          [disabled]="disabledControls" formControlName="countryCode" optionLabel="countryName"
          optionValue="countryCode" (onChange)="handleCountryChange($event.value,'Birth')" placeholder="select">
        </p-dropdown>
      </div>
      <div class="col-md-4 form-group processing-dropdown">
        <label class="label-cls"> State of Birth</label>
        <p-dropdown appendTo="body" [options]="listBirthStates" *ngIf="listBirthStates.length > 0"
          id="stateProvinceCode" formControlName="birthStateProvinceCode" name="country" optionLabel="stateProvinceName"
          optionValue="stateProvinceCode" placeholder="select">
        </p-dropdown>
        <input class="im-input mw-100" [readonly]="disabledControls" *ngIf="listBirthStates.length === 0"
          id="clientName" formControlName="birthStateProvinceName" type="text" pInputText placeholder="Enter" />
      </div>
      <div class="col-4">
        <label class="label-cls">City of Birth</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                              'im-input-error':
                                (maritalHistoryForm.get('birthCity').touched) &&
                                maritalHistoryForm.get('birthCity').errors?.pattern
                            }" formControlName="birthCity" />
        <span class="invalid-message" *ngIf="
                              (maritalHistoryForm.get('birthCity').touched) &&
                              maritalHistoryForm.get('birthCity').errors?.pattern
                            ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="text-bold">Place of Marriage</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 form-group processing-dropdown" formGroupName="marriageCountryCode">
        <label class="label-cls"> Country of Marriage</label>
        <p-dropdown appendTo="body" [options]="countryList" [filter]="false" id="countryCode"
          [disabled]="disabledControls" formControlName="countryCode" optionLabel="countryName"
          optionValue="countryCode" (onChange)="handleCountryChange($event.value,'Marriage')" placeholder="select">
        </p-dropdown>
      </div>
      <div class="col-md-4 form-group processing-dropdown">
        <label class="label-cls"> State of Marriage </label>
        <p-dropdown appendTo="body" [options]="listMarriageStates" *ngIf="listMarriageStates.length > 0"
          id="stateProvinceCode" formControlName="marriageStateProvinceCode" name="country"
          optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="select">
        </p-dropdown>
        <input class="im-input mw-100" [readonly]="disabledControls" *ngIf="listMarriageStates.length === 0"
          id="clientName" formControlName="marriageStateProvinceName" type="text" pInputText placeholder="Enter" />
      </div>
      <div class="col-4">
        <label class="label-cls">City of Marriage</label>
        <input type="text" pInputText class="im-input" [ngClass]="{
                              'im-input-error':
                                (maritalHistoryForm.get('marriageCity').touched) &&
                                maritalHistoryForm.get('marriageCity').errors?.pattern
                            }" formControlName="marriageCity" />
        <span class="invalid-message" *ngIf="
                              (maritalHistoryForm.get('marriageCity').touched) &&
                              maritalHistoryForm.get('marriageCity').errors?.pattern
                            ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <button type="button" *ngIf="!disabledControls" class="p-btn btn btn-outline-primary mr-3"
          [ngClass]="{'disabled': disabledControls || !maritalHistoryForm.valid }"
          [disabled]="disabledControls || !maritalHistoryForm.valid" (click)="saveMaritalHistory()">ADD</button>
        <!-- <button type="button" class="p-btn btn btn-outline-primary mr-3" (click)="saveMaritalHistory()">ADD</button> -->
        <button type="button" *ngIf="!disabledControls" class="p-btn btn btn-light"
          (click)="cancelForm()">CANCEL</button>
      </div>
    </div>
    <div class="row mt-4 mb-5">
      <div class="col-12">
        <h5 class="sub-title text-primary">Added Details</h5>
        <p-table tableStyleClass="i130-address-table" *ngIf="maritalHistoryList && maritalHistoryList.length > 0"
          [value]="maritalHistoryList">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" class="col-2 pr-0">Name</th>
              <th scope="col" class="col-2 pr-0">Date Marriage Ended</th>
              <th scope="col" class="col-2">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-doc>
            <tr>
              <td class="col-2 pr-0">{{doc.firstName}}</td>
              <td class="col-2 pr-0" *ngIf="doc.isActionable">{{doc.marriageDate | date: 'MM/dd/yyyy'}}</td>
              <td class="col-2 pr-0" *ngIf="!doc.isActionable">Present</td>
              <td class="col-2">
                <div class="row action-btn" *ngIf="doc.isActionable">
                  <div class="p-0">
                    <button pButton type="button" [disabled]="disabledControls" title="Edit" icon="pi pi-pencil"
                      (click)="editDocument(doc)" class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                    </button>
                  </div>
                  <div class="p-0">
                    <button pButton type="button" [disabled]="disabledControls" title="Delete" icon="pi pi-trash"
                      (click)="deleteDocument(doc.id)"
                      class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="no-data-box" *ngIf="!maritalHistoryList || (maritalHistoryList && maritalHistoryList.length == 0)">
          <p>Currently No Details Added</p>
        </div>
      </div>
    </div>
  </form>
</div>
