import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(public http: HttpClient) {
  }

  public Subject = new BehaviorSubject<boolean>(false);

  getEamilTemplate(companyId: any) {

    return this.http.get(`/emailTemplate/company/${companyId}/list`).pipe(
      map((response: ImagilityBaseResponse) => response.data));

  }

  genaratelink(companyId: any) {

    const body = {}
    return this.http.put(`/marketing/lead/collectedlead/genetatelink/company/${companyId}`, body).pipe(map((response: any) => response.data));
  }

  sendInvite(payload: any) {

    return this.http.put(`/marketing/lead/inviteLeads`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  sendEmailtoclient(payload: any, companyId: any) {

    return this.http.post(`/marketing/lead/${companyId}/sendEmailToClient`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );

  }

  getSendInvitelist(emailsetupId: any) {

    return this.http.get(`/marketing/${emailsetupId}/fetch`).pipe(
      map((response: ImagilityBaseResponse) => response.data));

  }


}
