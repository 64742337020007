import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/data/models/app-state';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { CountryApiService } from '../../petitioner/services/country.service';
import * as I9SectionOneSelector  from 'src/app/app-state/selectors/i9-section-one.selector';
import * as errorUtils from 'src/app/modules/utility/global-utils';
import { MatDialog } from '@angular/material/dialog';
import { SignatureUploadComponent } from '../signature-upload/signature-upload.component';
import { UserRoleService } from 'src/app/services/user-role.service';
import { BooleanLiteral } from 'typescript';
import * as I9Actions from 'src/app/app-state/actions/i9-section-one.actions';
import { I9Service } from 'src/app/services/i9-service';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { MarkDirtyFormAction, MarkCleanFormAction } from 'src/app/app-state/actions/dirty-form.actions';

@Component({
  selector: 'app-i9-preparer-info',
  templateUrl: './i9-preparer-info.component.html',
  styleUrls: ['./i9-preparer-info.component.scss']
})
export class I9PreparerInfoComponent implements OnInit, AfterViewInit {

  listCountry: [];
  listStates: [];
  preparerForm: FormGroup;
  submitted:boolean = false;
  signatureImage: string = ''; //`data:image/jpeg;base64,
  showCropperSection: boolean = true;
  companyId: number;
  i9FormId: number;
  signatureImageForm = new FormData();
  preparerInfo = null;
  isPreparer = '0';
  preparerSignDate = moment().utc().format('MM-DD-YYYY'); 
  formChangesSubscription: Subscription;
  destroy$ = new Subject<void>();
  signImgName = '';
  @Input() isEdit: boolean;

  constructor(private cacheCountryService: CacheCountryService,
    private countryApiService: CountryApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private referenceLibraryService: ReferenceLibraryService,
    private toastr: ToastrService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private i9Service: I9Service
  ) {
    this.listStates = [];
    this.listCountry = [];
    this.preparerForm = this.fb.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        middleName: [''],
        prepAddress: this.fb.array([
          this.fb.group({
            addressLine1: ['', Validators.required],
            addressLine2: [''],
            countryCode: this.fb.group({
              countryCode: ['', Validators.required]
            }),
            city: ['', Validators.required],
            stateProvinceCode: [''],
            stateProvinceName: [''],
            type: this.fb.group({
              code: ['CURR']
            }),
            locality: [''],
            postCode: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)]]
          })
        ]),
        prepSignImage: [''],
        prepSmallSignImage: [''],
        preparerSignDate: [this.preparerSignDate, [Validators.required]]
      }
    );
  }
  ngOnInit() {     
    this.cacheCountryService.getCachedCountryList().subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries;
      }
    });
    this.store.pipe(select(I9SectionOneSelector.selectI9SectionOne)).pipe(
      //take(1)
    ).subscribe( state =>{                                                                                                                                                                                                                                                                                                                                             
      if(state){
        this.i9FormId = state?.i9FormDtlId;
        this.companyId = state?.companyId;
        this.preparerInfo = state?.i9WorkAuthPreparerInfo?.preparerInfo || null;              
        if(this.preparerInfo){
          this.preparerForm.patchValue(this.preparerInfo);
        }  
        this.signatureImage = (this.preparerInfo && this.preparerInfo?.prepSignImage) ? `data:image/jpeg;base64,${this.preparerInfo?.prepSignImage}` : '';
        this.preparerSignDate = (this.preparerInfo && this.preparerInfo?.preparerSignDate)? moment(this.preparerInfo.preparerSignDate).format('MM-DD-YYYY') : moment().format('MM-DD-YYYY');
        this.preparerForm.get('preparerSignDate').setValue(this.preparerSignDate);
        let selectedCountry = this.preparerInfo?.prepAddress[0]?.countryCode?.countryCode || null;
        if(selectedCountry){
          this.handleCountryChange(selectedCountry);
        } 
        this.preparerForm.updateValueAndValidity();
      }
     });
     this.preparerForm.get('preparerSignDate').valueChanges.subscribe(val =>{
      this.preparerSignDate = moment(val).format('MM-DD-YYYY'); 
     });
  }
  ngAfterViewInit() {    
    this.formChangesSubscription =  this.preparerForm.valueChanges.pipe(
      map(() => this.preparerForm.dirty && this.preparerForm.enabled),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
      ).subscribe(dirty => {
        // if(dirty){
        //   this.store.dispatch(new MarkDirtyFormAction({            
        //     dirty: true
        //   }));
        // }else{
        //   this.store.dispatch(new MarkCleanFormAction({            
        //     dirty: false
        //   }));
        // }  
              
      });
  }

  
  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/png',
    });
  }

  selectSignatureImage(){
    this.showCropperSection = false;
    const dialogRef = this.dialog.open(SignatureUploadComponent, {
      panelClass: 'custom-dialog'
    });
  
    dialogRef.afterClosed().subscribe( result => {
      if(result){
        const file = this.dataURItoBlob(result);  
        this.signatureImageForm = new FormData();
        this.signatureImageForm.append("file",file , "mysignature.png");  
        this.signatureImage = result; 
        if(this.preparerInfo){
          this.uploadSignature();
        }
      }     
    });
  }

  uploadSignature(){
    this.i9Service.uploadPreparerSignature(this.companyId, this.i9FormId,this.signatureImageForm).subscribe((response) => {
      if (response['status'] === 200) {
           this.toastr.success('Image Uploaded successfully');
           this.signatureImage = `data:image/jpeg;base64,${response['data']['signImage']}`;                     
       }
   }); 
  }

  handleCountryChange(countryCode: string) {
    this.listStates = [];
    this.countryApiService.getStates(countryCode).subscribe((states: []) => {
      this.listStates = states;           
      this.applyStateValidation(states);
    });
  }

  applyStateValidation(states) {
    if (states.length > 0) {
      this.preparerForm.get('address')['controls'][0].get('stateProvinceCode').setValidators([Validators.required]);
    } else {
      this.preparerForm.get('address')['controls'][0].get('stateProvinceName').clearValidators();      
    }
    this.preparerForm.get('address')['controls'][0].get('stateProvinceCode').updateValueAndValidity();
    this.preparerForm.get('address')['controls'][0].get('stateProvinceName').updateValueAndValidity();
  }

  handleStateChange(statecode, stateIndex) {
    this.preparerForm.get('address')['controls'][stateIndex].get('stateProvinceCode').clearValidators();
    if (statecode === "null") {
      this.preparerForm.get('address')['controls'][stateIndex].get('stateProvinceCode').setValidators(null);
      this.preparerForm.get('address')['controls'][stateIndex].get('stateProvinceCode').value = null;
      this.preparerForm.get('address')['controls'][stateIndex].get('stateProvinceName').value = '';
      this.preparerForm.updateValueAndValidity();
    }
  }
  ngOnDestroy() {
    this.formChangesSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    this.store.dispatch(new MarkCleanFormAction({dirty: false}));
  }
  
}
