<div class="row py-3 border-bottom-dashed">
  <div class="col-4">
    <label class="label-light">Name</label>
    <label class="label-dark">{{ employmentDetails?.beneficiaryName ? employmentDetails?.beneficiaryName : '-'
      }}</label>
  </div>
  <div class="col-4">
    <label class="label-light">Company</label>
    <label class="label-dark">{{ employmentDetails?.companyName ? employmentDetails?.companyName : '-' }}</label>
  </div>
  <div class="col-4" *ngIf="!employmentDetails?.isOutsideImagility && taskDetails?.subTaskTypeCode !== 'DS160'">
    <label class="label-light">Position Details</label>
    <label class="label-dark">{{ employmentDetails?.positionDetails ? employmentDetails?.positionDetails : '-'
      }}</label>
  </div>
</div>
<form (ngSubmit)="onSubmit(form)" #form="ngForm" novalidate>
  <div class="row py-3 border-bottom-dashed">
    <div class="col-4" *ngIf="employmentDetails?.isOutsideImagility || taskDetails?.subTaskTypeCode === 'DS160' ">
      <label class="label-light mb-2 required">position Details</label>
      <input type="text" name="positionDetails" [(ngModel)]="beneficiary.positionDetails" #positionDetails="ngModel" />

      <span class="invalid-message"
        *ngIf="(positionDetails?.touched || form.submitted) && positionDetails?.errors?.required">
        Position Details is required
      </span>
    </div>
    <div class="col-4">
      <label class="label-light mb-2 required">Employment Start Date</label>
      <p-calendar [required]="true" name="employmentStartDate" class="filter-datepicker" dateFormat="mm/dd/yy"
        [styleClass]="employmentDetails?.taskId === 0 ? 'onboard-employment' : ''"
        [(ngModel)]="beneficiary.employmentStartDate" #employmentStartDate="ngModel" [monthNavigator]="true"
        (onSelect)="setMaxDateForEndDate()" [yearNavigator]="true" yearRange="{{fromYear}}:{{toYear}}">
      </p-calendar>
      <span class="invalid-message"
        *ngIf="(employmentStartDate?.touched || form.submitted) && employmentStartDate?.errors?.required">
        Employment Start is required
      </span>
    </div>
    <div class="col-4">
      <label class="label-light mb-2">Previous Employment End Date</label>
      <p-calendar class="filter-datepicker" name="employmentEndDate" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
        (onSelect)="setMinDateForStartDate()" [showIcon]="true" [maxDate]="maxDateForEndDate"
        [(ngModel)]="beneficiary.employmentEndDate" #employmentEndDate="ngModel"
        [styleClass]="employmentDetails?.taskId === 0 ? 'onboard-employment' : ''" [monthNavigator]="true"
        [yearNavigator]="true" yearRange="{{fromYear}}:{{toYear}}">
      </p-calendar>
    </div>
  </div>
  <div class="row py-3">
    <div class="col-12">
      <label class="label-light mb-2">Comments</label>
      <textarea class="w-100" name="comments" [rows]="3" pInputTextarea [autoResize]="true"
        [(ngModel)]="beneficiary.comments" #comments="ngModel"></textarea>
    </div>
  </div>
  <div class="row py-3 border-bottom-dashed">
    <div class="col-12" *ngIf="employmentDetails?.visaType === 'H1-B'">
      <span class="pr-2"> Notify Beneficiary about LCA linked to the Petition </span>
      <p-inputSwitch styleClass="lcaWorkNotifySwitch" name="isLcaBenAcknwldgMt" #isLcaBenAcknwldgMt="ngModel"
        [(ngModel)]="beneficiary.isBenNotifyLcaLinkPet"></p-inputSwitch>
    </div>
  </div>
  <div class="row py-3 border-bottom-dashed">
    <div class="col-12">
      <span class="pr-2"> Do you want to initiate the I-9 form? </span>
      <p-inputSwitch styleClass="lcaWorkNotifySwitch" [disabled]="i9FormTrue" name="initiateI9Form"
        #initiateI9Form="ngModel" [(ngModel)]="beneficiary.initiateI9Form"></p-inputSwitch>
    </div>
  </div>
  <div class="row py-3">
    <div class="col-12 text-right">
      <button type="button" (click)="onCancel()" class="btn btn-secondary mr-3">Cancel</button>
      <button type="submit" *ngIf="beneficiary.initiateI9Form" class="btn btn-outline-primary mr-3">Save & Next</button>
      <button type="submit" *ngIf="!beneficiary.initiateI9Form" class="btn btn-outline-primary mr-3">Save</button>
      <button type="button" (click)="migrateBeneficiary()" *ngIf="!beneficiary.initiateI9Form" class="btn btn-primary">Onboard</button>
    </div>
  </div>
</form>
