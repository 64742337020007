import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, pluck, catchError } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../../../data/models/response.model';
import { environment } from 'src/environments/environment';
import { EMPTY, Observable, throwError } from 'rxjs';
import { BeneficiaryImmigrationInterface } from './beneficiary-immigration.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiServicesService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  // IM-3066 - remove deleted file.
  public deletedfile$ = new BehaviorSubject<any>({});

  constructor(private http: HttpClient) { }

  markComplete(payload, benenficiaryId, companyId) {
    return this.http.post(`/attor/markComplete?benenficiaryId=${benenficiaryId}&companyId=${companyId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  saveTrainingDetails(beneficiaryId: number, type: string, payload: any, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/beneficiary/${beneficiaryId}/profile/professional/${type}${profileFamilyString}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getTrainingsDetails(beneficiaryId: number, type: string, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/professional/${type}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  deleteTrainingsDetails(beneficiaryId: number, type: string, professionalId: number, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.delete(`/beneficiary/${beneficiaryId}/profile/professional/${professionalId}/${type}${profileFamilyString}`).pipe(
      map((response) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return response;
      })
    );
  }

  // Immigration Fmaily

  saveImmgFamilyDetails(beneficiaryId: number, payload: any) {
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/family`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getImmgFamilyDetails(beneficiaryId: number) {
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/family`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  deleteImmgFamilyDetails(beneficiaryId: number, familyId: number) {
    return this.http.delete(`/beneficiary/${beneficiaryId}/immigration/family/${familyId}`).pipe(
      map((data) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return data;
      })
    );
  }


  saveBeneficiaryDetails(beneficiaryId: number, type: string, payload: any, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/beneficiary/${beneficiaryId}/profile/${type}${profileFamilyString}`, payload).pipe(
      map((response) => response)
    );
  }

  getBeneficiaryDetails(beneficiaryId: number, type: string, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/${type}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getBeneficiaryFamilyDetails(beneficiaryId: number, type: string) {
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/${type}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  saveBeneficiaryFamilyDetails(beneficiaryId: number, type: string, payload: any) {
    return this.http.post(`/beneficiary/${beneficiaryId}/profile/${type}`, payload).pipe(
      map((response) => response)
    );
  }
  getFamilyDocumentsList(beneficiaryId: number, familyId: number) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/documents${profileFamilyString}`).pipe(
      map((response) => response));
  }

  getBeneficiaries(companyId) {
    return this.http.get('/company/beneficiaries/company/' + companyId).pipe(
      pluck('data'),
      map((benes: Array<any>) => {
        benes.forEach(item => {
          item.name = item.fName + ' ' + item.lName,
            item.address = (item.address.length > 0) ? item.address[0].addressLine1 : '',
            item.email = (item.emailContacts.length > 0) ? item.emailContacts[0].email : '',
            item.phone = (item.phoneContacts.length > 0) ? item.phoneContacts[0].phoneNo : '';
        });
        return benes;
      })
    );
  }

  generateUserid(payload) {
    return this.http.post('/user/generate/userId', payload).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  sendExternalBeneficiaryInvite(payload) {
    return this.http.post('/petitioner/dashboard/external/beneficiaries/create', payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  updateCommunicationEmail(payload) {
    return this.http.post('/updateCommunicationEmail', payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  updateUserId(payload) {
    return this.http.post('/updateUserId', payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  updateModeOfCreation(payload) {
    return this.http.post('/updateModeOfCreation', payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  sendInviteToAttorneyCreatedBeneficiary(beneficiaryId, companyId) {
    return this.http.post(`/attorneySendInviteToBeneficiary/${beneficiaryId}/${companyId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  sendBeneficiaryInvite(payload) {
    return this.http.post('/petitioner/dashboard/beneficiaries/create/', payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }
  // IM-3352 (IM-3335) user story
  resendBenificaryInvite(linkRequestId) {
    return this.http.post(`/linkRequest/${linkRequestId}/resend`, {}).pipe(
      map((response) => response)
    );
  }

  sendInviteToClient(companyId, petitionerId) {
    return this.http.post(`/attorneySendInviteToPetitioner/${companyId}/${petitionerId}`, {}).pipe(
      map((response) => response)
    );
  }

  withdrawanBenificaryInvite(linkRequestId) {
    return this.http.post(`/linkRequest/${linkRequestId}/withdraw`, {}).pipe(
      map((response) => response)
    );
  }

  getBeneficiariesListForPetitioner(companyId, payload) {
    return this.http.post('/petitioner/dashboard/beneficiaries/company/' + companyId, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }



  getBeneficiariesByCompanyId(companyId, payload) {
    return this.http.get(`/petitioner/dashboard/beneficiaries/company/associated/${companyId}/list`).pipe(
      // pluck('data'),
      // pluck('beneficiaryList'),
      // map((benes: Array<any>) => {
      //   benes.forEach(item => {
      //     item.firstname = item.beneficiaryEmployeeFName ? item.beneficiaryEmployeeFName : '',
      //       item.lastname = item.beneficiaryEmployeeLName ? item.beneficiaryEmployeeLName : '',
      //       item.middlename = item.beneficiaryEmployeeMName ? item.beneficiaryEmployeeMName : '-',
      //       item.company = (item.companyName) ? item.companyName : '';
      //   });
      //   return benes;
      // })
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 && response.data) {
          return response.data;
        }else {
          return []
        }
      })
    );
  }

  saveBeneficiaryEducationByPetitioner(petitionId: number, type: string, payload: any) {
    return this.http.post(`/petitionBuilder/qualification/profile/${type}/petition/${petitionId}`, payload).pipe(
      map((response) => response)
    );
  }

  saveBeneficiaryTrainingLicenseByPetitioner(petitionId: number, type: string, payload: any) {
    return this.http.post(`/petitionBuilder/qualification/profile/${petitionId}/professional/${type}/`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  saveBeneficiaryExperienceByPetitioner(petitionId: number, payload: any) {
    return this.http.post(`/petitionBuilder/workexperience/petition/${petitionId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  updateProfilePic(beneficiaryId, payload) {
    return this.http.post(`/beneficiary/${beneficiaryId}/profile/self/profileimage`, payload).pipe(
      map((response) => response)
    );
  }
  getBeneAvatar(beneficiaryId) {
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/self`).pipe(
      map((response) => response)
    );
  }

  updateFamilyProfilePic(beneficiaryId, familyId, payload) {
    return this.http.post(`/beneficiary/${beneficiaryId}/profile/family/${familyId}/profileimage`, payload).pipe(
      map((response) => response)
    );
  }

  getStates(countryCode: string) {
    return this.http.get(`/countries/states?countryCode=${countryCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  saveBeneficiaryCurrentImmigration(beneficiaryId, payload) {
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self/current`, payload).pipe(
      map((response) => response)
    );
  }

  getBeneficiaryCurrentImmigration(beneficiaryId) {
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/self/current`).pipe(
      map((response) => response)
    );
  }

  getBeneficiarysTasks(beneficiaryId: number) {
    return this.http.get(`/beneficiary/${beneficiaryId}/task`).pipe(
      map((response) => response));

  }

  getPastImmigrationDetails(beneficiaryId: number) {
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/self/past`).pipe(
      map((response) => response));
  }

  savePastImmigrationDetails(beneficiaryId, payload) {
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self/past`, payload).pipe(
      map((response) => response)
    );
  }

  deleteBeneExperience(beneficiaryId: number, experienceId: number, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.delete(`/beneficiary/${beneficiaryId}/profile/experience/${experienceId}${profileFamilyString}`).pipe(
      map((data) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return data;
      })
    );
  }

  saveGroundsOfExclusion(beneficiaryId, payload) {
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self/exclusion`, payload).pipe(
      map((data) => data)
    );
  }

  getQuestionsForBeneficiary(groupCode: string, beneficiaryId: number) {
    return this.http.get(`/question/category/${groupCode}/beneficiary/${beneficiaryId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  getGroundsOfExclusion(beneficiaryId) {
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/self/exclusion`).pipe(
      map((data) => data)
    );
  }

  // Beneficiary education detials

  getEducationDetails(beneficiaryId: number, familyId?: number) {
    const url = familyId ? `/beneficiary/${beneficiaryId}/profile/education?familyId=${familyId}` : `/beneficiary/${beneficiaryId}/profile/education`;
    // console.log('url', url);
    return this.http.get(url).pipe(
      map((data) => data)
    );
  }
  getDocumentsList(beneficiaryId: number) {
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/documents`).pipe(
      map((response) => response));
  }
  saveEducatinDegreeDetails(beneficiaryId: number, payload: any, familyId?: number) {
    const url = familyId ? `/beneficiary/${beneficiaryId}/profile/education?familyId=${familyId}` : `/beneficiary/${beneficiaryId}/profile/education`;
    return this.http.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }
  deleteEducatinDegreeDetails(beneficiaryId: number, educationId: number, familyId?: number) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.delete(`/beneficiary/${beneficiaryId}/profile/education/${educationId}${profileFamilyString}`).pipe(
      map((data) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return data;
      })
    );
  }

  addCurrentImmigrationDetails(beneficiaryId: number, payload: any) {
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self/current`, payload).pipe(
      map((response: ImagilityBaseResponse) => response, catchError(this.errorHandler))
    );
  }

  addPastImmigrationDetails(beneficiaryId: number, payload: any) {
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self/past`, payload).pipe(
      map((response: ImagilityBaseResponse) => response, catchError(this.errorHandler))
    );
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  saveVisaMetaInfo(beneficiaryId: number, payload, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/meta/self${profileFamilyString}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response;
        }
      })
    );
  }

  saveVisaDetailsInfo(beneficiaryId: number, payload, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self${profileFamilyString}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response;
        }
      })
    );
  }

  getVisaDetails(beneficiaryId: number, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/self${profileFamilyString}`).pipe(
      map((response) => response));
  }

  getVisaMetaInfo(beneficiaryId: number, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/meta/self${profileFamilyString}`).pipe(
      map((response) => response));
  }


  saveTravelHistory(beneficiaryId: number, immigrationId: number, payload, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}/travelInfo${profileFamilyString}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response, catchError(this.errorHandler))
    );
  }

  getTravelHistory(beneficiaryId: number, immigrationId: number, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}/travelInfo`).pipe(
      map((response) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return response;
      }));

  }

  deleteImmigration(beneficiaryId: number, immigrationId: number, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.delete(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}${profileFamilyString}`).pipe(
      map((response) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return response;
      }));
  }

  deleteTravelInfo(beneficiaryId: number, immigrationId: number, travelInfoId: number) {
    return this.http.delete(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}/travelInfo/${travelInfoId}`).pipe(
      map((response) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return response;
      }));

  }

  // IM-3066 - remove deleted file.
  deletefile(beneficiaryId: number) {
    this.deletedfile$.next(true);
  }

  getSignatureImageBen(beneficiaryId) {
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/signpic`).pipe(
      map((data: Array<any>) => {
        // console.log('from servpicget', data);
        return data;
      }));
  }

  getFamilySignatureImageBen(beneficiaryId, familyId) {
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/family/${familyId}/signpic`).pipe(
      map((data: Array<any>) => {
        // console.log('from servpicget', data);
        return data;
      }));
  }

  // for benificiary sign Upload
  postSignatureImageBen(beneficiaryId, payload) {
    // console.log('post data', payload);
    return this.http.post(`/beneficiary/${beneficiaryId}/profile/signpic`, payload).pipe(
      map((data: Array<any>) => {
        // console.log('from servpicpost', data);
        return data;
      })
    );
  }

  // for benificiary sign Upload for family
  postFamilySignatureImageBen(beneficiaryId, familyId, payload) {
    // console.log('post data', payload);
    return this.http.post(`/beneficiary/${beneficiaryId}/profile/family/${familyId}/signPic`, payload).pipe(
      map((data: Array<any>) => {
        // console.log('from servpicpost', data);
        return data;
      })
    );
  }

  getBenficiaryLists(payload) {
    return this.http.post(`/beneficiaries/list`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      })
    );
  }

  // deleteFileFromDocumentList(){
  // }

  deleteFileFromDocumentList(beneficiaryId: number, catagoryGroup, catagoryCode, fileId) {
    return this.http.delete(`/document/beneficiary/${beneficiaryId}/category/${catagoryGroup}/fileCategory/${catagoryCode}/${fileId}`).pipe(
      map((data) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return data;
      })
    );
  }

  // Inviting beneficiary through attorney login
  sendBeneficiaryConnectionInvite(attorneyId: number, beneficiaryId: number, payload) {
    return this.http.post(`/beneficiary/link/${attorneyId}/${beneficiaryId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response;
        } else {
          return null;
        }
      })
    );
  }

  // Attorney Beneficiary Connection Status
  getAttorneyBeneficiaryConnectionStatus(attorneyId: number, beneficiaryId: number) {
    return this.http.get(`/beneficiary/link/${attorneyId}/${beneficiaryId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response;
        }
      })
    );
  }

  attorneyCreateBeneficiary(payload) {
    return this.http.post(`/attorney/beneficiary/create`, payload).pipe(
      map((response: ImagilityBaseResponse) => response, catchError(this.errorHandler))
    );
  }

  getBeneficiariesListForAttorney(companyId, payload) {
    return this.http.post(`/attorney/${companyId}/beneficiaries/list`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }
  getUndocImmigrantListForPetitioner(companyId, payload) {
    return this.http.post(`/lp/undocImmigrants/${companyId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response['data'];
        }
      })
    );
  }

  sendNotification(payload) {
    return this.http.post(`/mail/beneficiary`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getBeneExperienceInformation(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, isUsedForBenEdit: boolean) {
    return this.http.get(`/${visatype}/${caseType}/${caseId}/${synctype}?superTaskId=${superTaskId}&taskId=${subTaskId}&isUsedForBenEdit=${isUsedForBenEdit}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  savePetitionWorkExperience(petitionId: number, payload: any) {
    return this.http.post(`/petition/saveExperience/${petitionId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getPetitionEducationDetails(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number) {
    const url = `/${visatype}/${caseType}/${caseId}/${synctype}?superTaskId=${superTaskId}&taskId=${subTaskId}&isUsedForBenEdit=true`;
    return this.http.get(url).pipe(
      map((data) => data)
    );
  }

  savePetitionEducatinDegreeDetails(petitionId: number, payload: any, familyId?: number) {
    const url = `/petition/saveEducation/${petitionId}`;
    return this.http.post(url, payload).pipe(
          map((response: ImagilityBaseResponse) => response)
    );
  }

  deletBeneWorkExperience(visaType: string, caseType: string, caseId: number, type: string, superTaskId: number, subTaskId: number, experienceId: number) {
    return this.http.delete(`/${visaType}/${caseType}/${caseId}/experience/${experienceId}?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }

  deleteBenEducationDegreeDetails(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, educationId: number) {
    return this.http.delete(`/${visatype}/${caseType}/${caseId}/${synctype}/${educationId}?superTaskId=${superTaskId}&taskId=${subTaskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }
}
