import {
  Component, ComponentFactoryResolver, ElementRef, ChangeDetectorRef, OnInit,
  ViewChild, ViewContainerRef, AfterViewInit, AfterContentInit, AfterContentChecked, AfterViewChecked, Output, EventEmitter, SimpleChanges, Input, OnDestroy,
} from "@angular/core";
import { PlaceHolderDirective } from "place-holder-library";
import { AuthenticationService } from "../../../services/authentication.service";
import { select, Store } from "@ngrx/store";
import { first, takeUntil, take, delay, timeout } from "rxjs/operators";
import { async, of, Subject, Subscription } from "rxjs";
import { DirtyFormGuard, MarkCleanFormAction } from "dirty-check-store";
import { ToastrService } from "ngx-toastr";
import { ApiServicesService } from "../services/api-services.service";
import { ActivatedRoute, Router } from "@angular/router";
import { I } from "@angular/cdk/keycodes";
import { BeneficiaryQuestionnaireService } from "../../beneficiary-questionnaire/beneficiary-questionnaire.service";
import { DependentProfileDataService } from "../services/dependent-profile-data.service";
import { ReferenceLibraryService } from "../../../services/referenceLibrary.service";
// import { I140FileUploadConfiguration, ReferenceLibrary } from "app-models";
import { layout_code } from "../../l1-visa/utility/constants/layout-code";
import { LcaComponent } from "../../compliance/lca/lca.component";
import { DatePipe } from '@angular/common'

import {
  getSubTaskDetails, getSubTaskStepList, loadSubTaskDetails, SetVisaTypeCode,
  getStepStatusUpdate, getSuperTask,
} from "visa-store";
import { loadSubTaskDetails as i140loadSubTaskDetails } from "src/app/modules/green-card-i140/store/actions/sub-task-details.actions";
import { ReferenceLibrary, TaskStep, SubTaskDetails, SubTask, ImagilityBaseResponse, PrimaryData, SuperTask, stepStatus, acceptedFileTypes } from "app-models";
import { AwaitLotterySelectionComponent } from "../../l1-visa/components/await-lottery-selection/await-lottery-selection.component";
import { CaptureExtraordinaryAbilityDetailsComponent } from "../../l1-visa/components/capture-extraordinary-ability-details/capture-extraordinary-ability-details.component";
import { CustomStepsFormComponent } from "../../l1-visa/components/custom-steps-form/custom-steps-form.component";
import { EnterEntitiesRelationshipDetailsComponent } from "../../l1-visa/components/enter-entities-relationship-details/enter-entities-relationship-details.component";
import { EnterProcessingInformationComponent } from "../../l1-visa/components/enter-processing-information/enter-processing-information.component";
import { H1bJobDescriptionComponent } from "../../l1-visa/components/h1b-job-description/h1b-job-description.component";
import { H1bPetitionerDetailsComponent } from "../../l1-visa/components/h1b-petitioner-details/h1b-petitioner-details.component";
import { ItineraryOfServicesComponent } from "../../l1-visa/components/itinerary-of-services/itinerary-of-services.component";
import { PastJobDescriptionComponent } from "../../l1-visa/components/past-job-description/past-job-description.component";
import { PetitionerDetailsForeignCompanyComponent } from "../../l1-visa/components/petitioner-details-foreign-company/petitioner-details-foreign-company.component";
import { ProvideEERComponent } from "../../l1-visa/components/provide-eer/provide-eer.component";
import { ProvideInformationContainerComponent } from "../../l1-visa/components/provide-information-container/provide-information-container.component";
import { SpecialityOccupationComponent } from "../../l1-visa/components/speciality-occupation/speciality-occupation.component";
import { USCISH1BLotteryComponent } from "../../l1-visa/components/uscis-h1-b-lottery/uscis-h1-b-lottery.component";
import { VdApplicantDetailsComponent } from "../../l1-visa/components/vd-applicant-details/vd-applicant-details.component";
import { VdJobDescriptionComponent } from "../../l1-visa/components/vd-job-description/vd-job-description.component";
import { VdProvidePetitionerDetailsComponent } from "../../l1-visa/components/vd-provide-petitioner-details/vd-provide-petitioner-details.component";
import { UpdateEmploymentComponent } from "../../update-employment/update-employment.component";
import { SendNotificationComponent } from "../profile/send-notification/send-notification.component";
import * as Task from "../../superTask/task-state/actions/task.actions";
import { getSubTaskList } from "../../superTask/task-state/reducers/task.reducer";
import { MainContainerComponentService } from "../../l1-visa/components/main-container/main-container.component.service";
import { TaskNotesService } from "task-notes-lib";
import { CacheCountryListService } from "cache-country-list";
import { APIDocumentChecklist } from "base-document-checklist-library";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { VisaExtendedState } from "../../visa-base/utility/interfaces/visa-extended-state";
import { JobDescriptionComponent } from "../../l1-visa/components/job-description/job-description.component";
import { BeneficiaryPersonalDetailsComponent } from "../../l1-visa/components/beneficiary-personal-details/beneficiary-personal-details.component";
import { EducationDetailsComponent as L1VisaEducationDetailsComponent } from "../../l1-visa/components/education-details/education-details.component";
import { EducationDetailsComponent as GreencardEducationDetailsComponent } from "../../green-card-i140/components/education-details/education-details.component";
import { WorkExperienceDetailsComponent as L1VisaWorkExperienceDetailsComponent } from "../../l1-visa/components/work-experience-details/work-experience-details.component";
import { WorkExperienceDetailsComponent as GreencardWorkExperienceDetailsComponent } from "../../green-card-i140/components/work-experience-details/work-experience-details.component";
import { BeneficiaryDocumentsComponent as L1VisaBeneficiaryDocumentsComponent } from "../../l1-visa/components/beneficiary-documents/beneficiary-documents.component";
import { BeneficiaryDocumentsComponent as GreencardBeneficiaryDocumentsComponent } from "../../green-card-i140/components/beneficiary-documents/beneficiary-documents.component";
import { PetitionerDetailsComponent } from "../../l1-visa/components/petitioner-details/petitioner-details.component";
import { UscisContentComponent } from "../../l1-visa/components/uscis-content/uscis-content.component";
import { ReviewI140PetitionComponent } from "../../l1-visa/components/review-i140-petition/review-i140-petition.component";
import { PrintPetitionComponent } from "../../l1-visa/components/print-petition/print-petition.component";
import { SubmitPetitionComponent } from "../../l1-visa/components/submit-petition/submit-petition.component";
import { GetReceiptsComponent } from "../../l1-visa/components/get-receipts/get-receipts.component";
import { LetterGenerationComponent } from "../../l1-visa/components/letter-generation/letter-generation.component";
import { UpdateDecisionComponent } from "../../l1-visa/components/update-decision/update-decision.component";
import { MatDialog } from '@angular/material/dialog';
// import { setTimeout } from "timers";
import { FormControl } from "@angular/forms";
import { BeneficiaryMyformService } from "../../../services/beneficiary-myform.service";
import { AddDegreeComponent } from "../profile/education/add-degree/add-degree.component";
import { PersonalDetailsComponent } from "../profile/personal-details/personal-details.component";
import { WorkExperienceComponent } from "../profile/work-experience/work-experience.component";
import { BeneficiaryImmigrationComponent } from "../profile/beneficiary-immigration/beneficiary-immigration.component";
import { StepStatusUpdatePayloadService } from "step-status-update-payload-service";
import { GC_I140_Layout } from "src/app/data/constants/layout-code";
import { UploadDocumentService } from "src/app/services/upload-document.service";
import { DependentDetailsComponent as L1VisaDependentDetailsComponent } from "../../l1-visa/components/dependent-details/dependent-details.component";
import { StatusUpdateComponent } from "../../green-card-i140/components/status-update/status-update.component";
import { DependentDetailsComponent as GreencardDependentDetailsComponent } from "../../green-card-i140/components/dependent-details/dependent-details.component";
import { getStepDetails as i140getStepDetails } from "../../green-card-i140/store/selectors/sub-task-details.selectors";
import { CustomStepsComponent } from "../../l1-visa/components/custom-steps/custom-steps.component";
import { CaseRequestService } from "../../case-request/services/case-request.service";


@Component({
  selector: "app-beneficiary-information",
  templateUrl: "./beneficiary-information.component.html",
  styleUrls: ["./beneficiary-information.component.scss"],
  providers: [DialogService],
})
export class BeneficiaryInformationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("mainBody", { static: true }) mainBody: ElementRef;
  @ViewChild("personalDetail", { static: true }) personalDetail: ElementRef;
  @ViewChild(PersonalDetailsComponent) personalDetails;
  @ViewChild(AddDegreeComponent) degree;
  @ViewChild(WorkExperienceComponent) workExp;
  @ViewChild(BeneficiaryImmigrationComponent) benImmi;
  @ViewChild(StatusUpdateComponent) getStepStatsus;
  isEditMode: boolean = true;
  activeIndex: number = 0;
  selectedItem: any;
  beneficiaryId: number;
  dynamicDialogRef: DynamicDialogRef;
  companyId: number;
  isLastTab = false;
  stepDetails: TaskStep;
  typeOfButton: string;
  sectionCode: string;
  observableSubscription$ = new Subject();
  visibleSection = "ED";
  visibleSubSection = "DEG";
  educationDetails = [];
  showFileUploadSection = false;
  showLifeStoryTimeLineSection = false;
  beneficiaryType: any;
  familyId: number;
  accordianActivedIndex: any = -1;
  presentRightTabIndex = 0;
  isSelf: boolean = true;
  isFamily: boolean = false;
  isDependent: boolean = false;
  isDocuments: boolean = false;
  tabList: any;
  getTaskData: any;
  selectedSectionData: any;
  selectedSectionIndex: any;
  dependentItems: any[];
  showOtherDependentSection: boolean = false;

  stepList: TaskStep[];
  primaryData: PrimaryData;
  subTaskDetails: SubTaskDetails;
  receiptETADateRange: string;
  decisionETADateRange: string;
  caseId: number;
  taskTypeCode: string;
  paramTaskTypeCode: string;
  paramPresent: string;
  superTask: SuperTask;
  userType: string;
  superTaskTypeCode: string;
  toggleTabText: string = "TIMELINE";
  selectedTaskType: string;
  destroy$ = new Subject<void>();
  selectedChild = 0;
  editClicked: boolean = false;
  isShowSingleDiv: boolean = true;
  isHideNext: any;
  hideSpecificDiv: boolean = true;

  fileUploadConfigurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENPASSDOC',
    entityId: 0,
    fileCategory: 'PASSCOPY',
    listUploadedFiles: [],
    useCase: 'beneficiary',
    useCaseId: 0,
    documentTypeRequired: true,
    docummentTypeCode: 'BENPASSDOC',
    useEntityId: false,
    isDeleteEnabled: sessionStorage.getItem('bene-profile-viewMode') == 'true' ? false : true,
    isUploadEnabled: sessionStorage.getItem('bene-profile-viewMode') == 'true' ? false : true,
    familyId: null
  };

  items: any[] = [
    {
      header: "Fiance",
      content: "content1",
      code: "FIANCE",
      desc: "Fiance",
      group: "FAMRELTYP",
      id: 1110,
      name: "Fiance",
      subTabsList: null,
    },
    {
      header: "Father",
      content: "content1",
      code: "FATHER",
      desc: "Father",
      group: "FAMRELTYP",
      id: 15,
      name: "Father",
      subTabsList: null,
    },
    {
      header: "Mother",
      content: "content1",
      code: "MOTHER",
      desc: "Mother",
      group: "FAMRELTYP",
      id: 16,
      name: "Mother",
      subTabsList: null,
    },
    {
      header: "Sister",
      content: "content1",
      code: "SISTER",
      desc: "Sister",
      group: "FAMRELTYP",
      id: 889,
      name: "Sister",
      subTabsList: [],
    },
    {
      header: "Brother",
      content: "content1",
      code: "BROTHER",
      desc: "Brother",
      group: "FAMRELTYP",
      id: 888,
      name: "Brother",
      subTabsList: [],
    },
    {
      header: "Spouse",
      content: "content1",
      code: "SPOUSE",
      desc: "Spouse",
      group: "FAMRELTYP",
      id: 14,
      name: "Spouse",
      subTabsList: null,
    },
    {
      header: "Children",
      content: "content1",
      code: "CHILD",
      desc: "Child",
      group: "FAMRELTYP",
      id: 13,
      name: "Child",
      subTabsList: [],
    },
  ];
  selectedDetailAbout: any;
  dynamicValue = [];
  beneficiaryFamilyInfo: any;
  beneficiaryFamilyInfoGrouped: any;
  beneficiarySelfInfo: any;
  maritalStatus: string = "";
  familyRelTypeArr: ReferenceLibrary[];

  @ViewChild(PlaceHolderDirective, { static: true })
  placeHolder: PlaceHolderDirective;
  // @ViewChild('mainBody', { static: true }) mainBody: ElementRef;
  lastTabObj: { name: string; code: string; isLastTab: boolean };
  public inputData: PrimaryData;
  activeRightTabIndex = 0;

  profileSectionsOrder = [
    "Personal Details",
    "Brother Details",
    "SISTER Details",
    "FATHER Details",
    "MOTHER Details",
    "FIANCE Details",
    "CHILD Details",
    "SPOUSE Details",
    "Education Details",
    "Work Experience Details",
    "Immigration Details",
  ];
  index: number = 0;
  dependentIdSubscription: Subscription;
  additionalPetitionInfo: any[] = [];
  selectedStepLayoutCode: any;
  superTaskDetails: any;
  hasFamily: boolean = false;
  checker = new FormControl(false);

  selectedSubTask: any;
  additionalInfo: PrimaryData;
  accessDate: any;
  CurrentDate = new Date();
  disableBtn: boolean = true;
  completedSteps: any;
  additionalCompletedSteps: any;
  isExpired: boolean;
  selectedDependentIndex: number;
  extraFamMemDynamicValue: any;
  selectedSectionFamInfo: any;
  customQuestionnaieDisplay: any;
  valChangeSubScription: Subscription;
  getStepStatusUpdateSubscription: Subscription;
  isExternalEmployeeId: string;
  caseRequestResponseData: any;
  isCaseRequestStatus: boolean = true;
  isBenQuestionElemStatus: boolean = false;
  @Output() caseRequestBack = new EventEmitter<any>();
  @Output() onCaseRequestNext = new EventEmitter<any>();
  @Input() isViewMode:boolean = false;
  currentItemDisplay: any = [];
  @Input() isFromCaseRequest: boolean = false;
  isBenQuestionStatus: boolean = false;

  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    private authenticationService: AuthenticationService,
    private guard: DirtyFormGuard,
    private store: Store<VisaExtendedState>,
    private dependentProfileDataService: DependentProfileDataService,
    private toastr: ToastrService,
    private apiService: ApiServicesService,
    private apiDocumentChecklist: APIDocumentChecklist,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private mainContainerComponentService: MainContainerComponentService,
    private taskNotesService: TaskNotesService,
    private cacheCountyService: CacheCountryListService,
    public route: ActivatedRoute,
    public dialogService: DialogService,
    private benServe: BeneficiaryQuestionnaireService,
    private beneficiaryMyformService: BeneficiaryMyformService,
    private referenceLibraryService: ReferenceLibraryService,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private uploadDocumentService: UploadDocumentService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private caseRequestService: CaseRequestService,
  ) {
    this.selectedItem = 0;
    this.isExternalEmployeeId = sessionStorage.getItem('_isExternalEmployeeId');
    // this.sectionCode = this.inputData.layoutCode;
    if (this.isExternalEmployeeId) {
      this.isEditMode = false;
    }
    // console.log("this.beneficiaryId", this.beneficiaryId);
    this.referenceLibraryService.getReferenceData("FAMRELTYP").subscribe((data) => {
      this.familyRelTypeArr = data;
      this.setDependentTabItems();

    });
  }

  async ngOnInit() {
    if(sessionStorage.getItem('dataLogId') || !!(sessionStorage.getItem('isViewEditQuestionnaire'))){
      this.isBenQuestionStatus = true;
    } else {
      this.isBenQuestionStatus = false;
    }
    // console.log("Beneficiary information Component Before" ,this.isViewMode)
    // console.log("Beneficiary information Component Before :: this.isCaseRequestStatus" ,this.isFromCaseRequest)
    // console.log("Before :: isCaseRequestStatus" ,this.isCaseRequestStatus)
    if (sessionStorage.getItem('caseRequestResponse')) {
      this.isCaseRequestStatus = false;
      // console.log("After :: isCaseRequestStatus", this.isCaseRequestStatus)
      sessionStorage.setItem('isCaseRequest', 'true');
      // JSON.parse(sessionStorage.getItem('caseRequestResponse'));
      // await this.caseRequestService.getCaseRequest(JSON.parse(sessionStorage.getItem('caseRequestData'))).subscribe(async (res: any) => {

      this.store.dispatch(
        new MarkCleanFormAction({
          dirty: false,
        })
      );

      this.caseRequestResponseData = JSON.parse(sessionStorage.getItem('caseRequestResponse'));
      // let data = res.data;
      this.customQuestionnaieDisplay = this.caseRequestResponseData.customAttorneyJson;
      localStorage.setItem('custCompanyId', JSON.stringify(this.caseRequestResponseData.attorney.id));
      localStorage.setItem('custBeneficiaryId', JSON.stringify(this.caseRequestResponseData.beneficiary.id));
      localStorage.setItem('customQuestions', JSON.stringify(this.customQuestionnaieDisplay));
      // if (data === null) {
      //   this.router.navigate(["beneficiary-profile"]);
      // }

      // this.isExpired = false;

      // let assignedAccessTime: any = new Date(this.caseRequestResponseData.attorneyJson.accessTillDate);
      // let todayDate: any = new Date();

      // if (Math.round((assignedAccessTime - todayDate) / (24 * 60 * 60 * 1000)) >= 0) {
      //   this.isExpired = false;
      //   this.caseRequestResponseData.attorneyJson.requestValidity = Math.round((assignedAccessTime - todayDate) / (24 * 60 * 60 * 1000));
      // } else {
      //   this.isExpired = true;
      // }

      if (this.caseRequestResponseData.attorneyJson.additionalPetitionInfo.length > 0) {
        this.caseRequestResponseData.attorneyJson.additionalPetitionInfo[0]["clicked"] = true;
      }
      this.caseRequestResponseData.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList = this.caseRequestResponseData.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList.filter(x => x.selected);
      this.caseRequestResponseData.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList =
        this.mapOrder(
          this.caseRequestResponseData.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList,
          this.profileSectionsOrder, "name"
        );
      this.caseRequestResponseData.attorneyJson.qfamilyDependents.forEach((element) => {
        element.qfamilySubSectionList = element.qfamilySubSectionList.filter(x => x.selected);
        if (element.qfamilySubSectionList.length > 0) {
          element.qfamilySubSectionList = this.mapOrder(
            element.qfamilySubSectionList, this.profileSectionsOrder, "name");

          // if (element.qfamilySubSectionList.filter(x => x.markComplete).length === element.qfamilySubSectionList.length) {
          //   data.attorneyJson['is' + (element.qfamilySubSectionList[0].referenceLibrarysubSection.name).toLowerCase().replace(/\s/g, '') + 'MarkComplete'] = true;
          // }
        }
      });
      this.caseRequestResponseData.attorneyJson.qfamilyOtherDependents.forEach((element) => {
        if (element.qfamilySubSectionList.length > 0) {
          element.qfamilySubSectionList = element.qfamilySubSectionList.filter(x => x.selected);
          element.qfamilySubSectionList = this.mapOrder(
            element.qfamilySubSectionList, this.profileSectionsOrder, "name"
          );
          // if (element.qfamilySubSectionList.filter(x => x.markComplete).length === element.qfamilySubSectionList.length) {
          //   data.attorneyJson['is' + (element.qfamilySubSectionList[0].referenceLibrarysubSection.name).toLowerCase().replace(/\s/g, '') + 'MarkComplete'] = true;
          // }
        };
      });
      this.caseRequestResponseData.attorneyJson.additionalPetitionInfo = this.caseRequestResponseData.attorneyJson.additionalPetitionInfo.filter(a => a.selected).sort((a, b) => a.sequenceNo - b.sequenceNo)
      this.tabList = this.caseRequestResponseData;
      this.beneficiaryId = this.tabList.beneficiary.id;
      this.companyId = this.tabList.attorney.id;
      // let accessTimeAssigned: any = new Date(
      //   this.caseRequestResponseData.attorneyJson.accessTillDate
      // );
      // accessTimeAssigned = this.datepipe.transform(accessTimeAssigned, 'yyyy-MM-dd');
      // let today: any = new Date();
      // today = this.datepipe.transform(today, 'yyyy-MM-dd');

      // if (accessTimeAssigned < today) {
      //   this.isEditMode = false;
      //   this.benServe.addValidity(true);
      // }
      // else{
      //   this.isEditMode = true;
      //   this.benServe.addValidity(true)
      // }
      setTimeout(() => {
        if (sessionStorage.getItem('showDivSingle')) {
          if (sessionStorage.getItem('showDivSingle') == 'true') {
            this.isShowSingleDiv = true;
          } else {
            this.isShowSingleDiv = false;
          }
        }
        if (!this.isShowSingleDiv) {
          this.isShowSingleDiv = true;
          this.isHideNext = sessionStorage.getItem('hideNext');
          if (this.isHideNext) {
            if (this.route.snapshot.queryParams.prop.toLowerCase() == this.isHideNext) {
              this.hideSpecificDiv = true;
            } else {
              this.hideSpecificDiv = false;
            }
          }
        }

      }, 1000);
      // console.log('this.tabList11', this.tabList);
      if (this.tabList.superTaskId !== null && this.tabList.superTaskId !== '' && this.tabList.superTaskId != undefined) {
        // console.log('this.tabList22', this.tabList);
        await this.apiDocumentChecklist.getSuperTask(this.tabList.superTaskId)
          .pipe(takeUntil(this.destroy$)).subscribe((response) => {
            // console.log('responssss', response);
            this.superTaskDetails = response.superTask;
            this.selectedSubTask = response.subTaskList[0];
            this.caseId = this.selectedSubTask.caseId;
            this.taskTypeCode = this.selectedSubTask.taskTypeCode;
            if (this.route.snapshot.paramMap.has('visaType')) {
              this.paramTaskTypeCode = 'PETITION';
              this.paramPresent = 'visaType';
            } else if (this.route.snapshot.paramMap.has('taskType')) {
              this.paramTaskTypeCode = this.route.snapshot.paramMap.get('taskType');
              this.paramPresent = 'taskType';
            }
            // this.postNgOnInit()
            let typeCode;
            if (response.superTask.visaType && response.superTask.visaType.code) {
              typeCode = response.superTask.visaType.code;
            } else {
              typeCode = response.subTaskList[0].taskTypeCode;
            }
            // this.paramTaskTypeCode = (this.selectedSubTask.taskTypeCode === 'I485' ? 'I485') : 'I485';
            this.superTaskTypeCode =
              typeCode;
            this.store.dispatch(
              new SetVisaTypeCode(
                this.superTaskTypeCode === "NONIMMPET" ? this.superTaskTypeCode : this.taskTypeCode
              )
            );
            this.store.dispatch(loadSubTaskDetails({
              taskId: parseInt(this.selectedSubTask.id.toString()), visaTypeCode:
                this.superTaskTypeCode,
            })
            );

            // let stepOverviewList = response.subTaskList[0].stepOverviewList.filter(s => s.isBeneficiaryAction == true);
            // this.additionalPetitionInfo = [
            //   ...new Map(
            //     stepOverviewList
            //       .filter((o1) => this.tabList.attorneyJson.additionalPetitionInfo.some(
            //         (o2) => o1.stepName === o2.stepName))
            //       .map((item) => [item["stepName"], item])).values(),
            // ];
            // this.additionalCompletedSteps = this.additionalPetitionInfo.filter(pet => pet.stepStatusName === "Submitted").length;

            this.apiService.getTask(typeCode, response.subTaskList[0].id)
              .subscribe((data: any) => {
                this.getTaskData = data.data;
                this.additionalPetitionInfo = [];

                let additionalPetitionInfo = [];

                let stepOverviewList = data.data.taskStepDetails.filter(s => s.isPetitionerAction == true);
                let companyId: any = data.data.createdByUserType === "Attorney" ? data.data.attorneyId : data.data.companyId;
                this.tabList.attorneyJson.additionalPetitionInfo = this.tabList.attorneyJson.additionalPetitionInfo.filter((elem) => {
                  return this.selectedSubTask.stepOverviewList.some((ele) => {
                    return ele.stepName === elem.stepName && ele.isPetitionerAction === elem.isPetitionerAction;
                  });
                });

                this.tabList.attorneyJson.additionalPetitionInfo.forEach(petition => {
                  let stepDetailsPetition = stepOverviewList.find(taskPetition => taskPetition.stepName === petition.stepName);
                  if (stepDetailsPetition.stepStatusDetails.stepStatusCode === "COMPLETE") {
                    petition.markComplete = true;
                  }
                  additionalPetitionInfo.push({ ...petition, ...stepDetailsPetition });
                });
                try {
                  let customSteps = [];
                  this.customQuestionnaieDisplay.customStepList.forEach(element => {
                    if (element.isPetitionerAction && element.isPetitionerEditable && element.isPetitionerVisible && element.selected) customSteps.push(element)
                  });
                  let customStepList = customSteps.reverse();
                  for (var i = 0; i < customStepList.length; i++) {
                    let index = additionalPetitionInfo.findIndex(x => x.stepName === customStepList[i].previousStepName);
                    customStepList[i]['isCustomStep'] = true;
                    additionalPetitionInfo.splice(index, 0, customStepList[i]);
                  }
                  this.additionalPetitionInfo = additionalPetitionInfo;
                  // this.apiService.getQuestionListSteps(companyId, data.data.taskTypeId)
                  //   .pipe(takeUntil(this.observableSubscription$)).subscribe((cqData: any) => {
                  //     let customSteps = [];
                  //     cqData.customStepList.forEach(element => {
                  //       if (element.isPetitionerAction && element.isPetitionerEditable && element.isPetitionerVisible) customSteps.push(element)
                  //     });
                  //     let customStepList = customSteps.reverse();
                  //     for (var i = 0; i < customStepList.length; i++) {
                  //       let index = additionalPetitionInfo.findIndex(x => x.stepName === customStepList[i].previousStepName);
                  //       customStepList[i]['isCustomStep'] = true;
                  //       additionalPetitionInfo.splice(index + 2, 0, customStepList[i]);
                  //     }
                  //     this.additionalPetitionInfo = additionalPetitionInfo;
                  //   });
                } catch (error) {
                  this.additionalPetitionInfo = additionalPetitionInfo;
                }
                this.additionalCompletedSteps = additionalPetitionInfo.filter(step => step.stepStatusDetails?.stepStatusCode === "COMPLETE").length;
              });
          });
      }

      if (this.route.snapshot.queryParams.prop !== 'additional') {
        let getBenificiaryDetails = this.benServe.getBenificiaryDetails()?.qfamilySubSectionList;
        getBenificiaryDetails = getBenificiaryDetails ? getBenificiaryDetails : JSON.parse(localStorage.getItem('selectedBeneData')).qfamilySubSectionList
        this.dynamicValue = this.mapOrder(
          await getBenificiaryDetails, this.profileSectionsOrder, "name"
        );
        this.dynamicValue = this.dynamicValue.filter((x)=>x.selected);
        this.completedSteps = this.dynamicValue?.filter((x) => x.markComplete).length;
      } else {
        this.dynamicValue = [];
      }

      this.dependentIdSubscription =
        this.dependentProfileDataService.dependentProfileSubject.subscribe(selectedBeneObj => {
          this.familyId = selectedBeneObj.dependentId;
          this.beneficiaryType = selectedBeneObj.beneficiaryType;
          this.showFileUploadSection = this.beneficiaryType === "family" && this.familyId ? true
            : this.beneficiaryType === "self" ? true : false;
        });
      this.dependentProfileDataService.selfBeneficiaryDetailChange.subscribe(() => {
        this.getBeneficiarySelfDetails();
        // this.getBeneficiaryFamilyDetails();
        //this.setDependentDynamicTabs(this.beneficiaryFamilyInfo);
      }
      );
      this.dependentProfileDataService.newSubDependentCreated.subscribe(
        (response) => {
          this.setDependentDynamicTabs(response.beneficiaryFamilyInfo);
          this.modifyItemsArr();
          const item = this.dependentItems.find(
            (x) => x.subTabsList && x.subTabsList.length > 0 &&
              x.subTabsList.some((y) => y.id == response.selectedSubTabId)
          );
          this.setSelectedDetailAbout(item, response.selectedSubTabId);
        }
      );
      this.fileUploadConfigurations.entityId = this.beneficiaryId;
      this.fileUploadConfigurations.useCaseId = this.beneficiaryId;
      this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);

      this.showFileUploadSection = this.beneficiaryType === "family" && this.familyId
        ? true : this.beneficiaryType === "self" ? true : false;
      this.getBeneficiarySelfDetails();
      this.getBeneficiaryFamilyDetails();
      if (this.route.snapshot.queryParams.prop !== 'additional') {
        this.valChangeSubScription = this.checker.valueChanges.subscribe((result) => {
          let selectedDynamicVal = this.dynamicValue[parseInt(this.route.snapshot.queryParams.subsection ? this.route.snapshot.queryParams.subsection : 0)]
          this.handleLeftPanelSelection(selectedDynamicVal ? selectedDynamicVal : this.dynamicValue?.find((element) => element.selected == true), false,
            this.route.snapshot.queryParams.prop && this.route.snapshot.queryParams.prop != 'BENE' && this.beneficiaryFamilyInfoGrouped[this.route.snapshot.queryParams.prop] ? this.beneficiaryFamilyInfoGrouped[this.route.snapshot.queryParams.prop][0] : null, parseInt(this.route.snapshot.queryParams.subsection ? this.route.snapshot.queryParams.subsection : 0), 0
          );
        });
      }

      this.benServe.isExperienceChanged.subscribe((res: any) => {
        if (res.length === 0) {
          this.markcomplete();
        }
      })
      this.benServe.isEducationDetailsChanged.subscribe((res: any) => {
        if (res.length === 0) {
          this.markcomplete();
        }
      })
      this.onScrollTop();

      let sub = this.benServe.selfDetailsSaved.subscribe(() => {
        this.ngOnInit();
      })
    } else {
      this.isCaseRequestStatus = true;
      let accessCode = localStorage.getItem("accessCode");
      await this.beneficiaryMyformService.getBeneficiary(parseInt(accessCode)).subscribe(async (res: any) => {
        let data = res.data;
        this.customQuestionnaieDisplay = res.data.customAttorneyJson;
        localStorage.setItem('custCompanyId', JSON.stringify(res.data.attorney.id));
        localStorage.setItem('custBeneficiaryId', JSON.stringify(res.data.beneficiary.id));
        localStorage.setItem('customQuestions', JSON.stringify(this.customQuestionnaieDisplay));
        if (data === null) {
          this.router.navigate(["beneficiary-profile"]);
        }

        if(!res?.data?.attorneyJson?.accessTillDate){
          this.isExpired = false;
        } else{
          let assignedAccessTime: any = new Date(res.data.attorneyJson.accessTillDate);
          let todayDate: any = new Date();

          if (Math.round((assignedAccessTime - todayDate) / (24 * 60 * 60 * 1000)) >= 0) {
            this.isExpired = false;
            res.data.attorneyJson.requestValidity = Math.round((assignedAccessTime - todayDate) / (24 * 60 * 60 * 1000));
          } else {
            this.isExpired = true;
          }
        }

        if (data.attorneyJson.additionalPetitionInfo.length > 0) {
          data.attorneyJson.additionalPetitionInfo[0]["clicked"] = true;
        }
        data.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList = data.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList.filter(x => x.selected);
        data.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList =
          this.mapOrder(
            data.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList,
            this.profileSectionsOrder, "name"
          );
        data.attorneyJson.qfamilyDependents.forEach((element) => {
          element.qfamilySubSectionList = element.qfamilySubSectionList.filter(x => x.selected);
          if (element.qfamilySubSectionList.length > 0) {
            element.qfamilySubSectionList = this.mapOrder(
              element.qfamilySubSectionList, this.profileSectionsOrder, "name");

            // if (element.qfamilySubSectionList.filter(x => x.markComplete).length === element.qfamilySubSectionList.length) {
            //   data.attorneyJson['is' + (element.qfamilySubSectionList[0].referenceLibrarysubSection.name).toLowerCase().replace(/\s/g, '') + 'MarkComplete'] = true;
            // }
          }
        });
        data.attorneyJson.qfamilyOtherDependents.forEach((element) => {
          if (element.qfamilySubSectionList.length > 0) {
            element.qfamilySubSectionList = element.qfamilySubSectionList.filter(x => x.selected);
            element.qfamilySubSectionList = this.mapOrder(
              element.qfamilySubSectionList, this.profileSectionsOrder, "name"
            );
            // if (element.qfamilySubSectionList.filter(x => x.markComplete).length === element.qfamilySubSectionList.length) {
            //   data.attorneyJson['is' + (element.qfamilySubSectionList[0].referenceLibrarysubSection.name).toLowerCase().replace(/\s/g, '') + 'MarkComplete'] = true;
            // }
          };
        });
        data.attorneyJson.additionalPetitionInfo = data.attorneyJson.additionalPetitionInfo.filter(a => a.selected).sort((a, b) => a.sequenceNo - b.sequenceNo)
        this.tabList = data;
        this.beneficiaryId = this.tabList.beneficiary.id;
        this.companyId = this.tabList.attorney.id;
        if(!res?.data?.attorneyJson?.accessTillDate){
          this.isEditMode = true;
          this.benServe.addValidity(false);
        } else{
          let accessTimeAssigned: any = new Date(
            data.attorneyJson.accessTillDate
          );
          accessTimeAssigned = this.datepipe.transform(accessTimeAssigned, 'yyyy-MM-dd');
          let today: any = new Date();
          today = this.datepipe.transform(today, 'yyyy-MM-dd');

          if (accessTimeAssigned < today) {
            this.isEditMode = false;
            this.benServe.addValidity(true);
          }
        }
        // else{
        //   this.isEditMode = true;
        //   this.benServe.addValidity(true)
        // }
        if (data.superTaskId !== null && data.superTaskId !== '' && data.superTaskId != undefined) {
          await this.apiDocumentChecklist.getSuperTask(data.superTaskId)
            .pipe(takeUntil(this.destroy$), take(1)).subscribe((response) => {
              this.superTaskDetails = response.superTask;
              this.selectedSubTask = response.subTaskList[0];
              this.caseId = this.selectedSubTask.caseId;
              this.taskTypeCode = this.selectedSubTask.taskTypeCode;
              if (this.route.snapshot.paramMap.has('visaType')) {
                this.paramTaskTypeCode = 'PETITION';
                this.paramPresent = 'visaType';
              } else if (this.route.snapshot.paramMap.has('taskType')) {
                this.paramTaskTypeCode = this.route.snapshot.paramMap.get('taskType');
                this.paramPresent = 'taskType';
              }
              // this.postNgOnInit()
              let typeCode;
              if (response.superTask.visaType && response.superTask.visaType.code) {
                typeCode = response.superTask.visaType.code;
              } else {
                typeCode = response.subTaskList[0].taskTypeCode;
              }
              // this.paramTaskTypeCode = (this.selectedSubTask.taskTypeCode === 'I485' ? 'I485') : 'I485';
              this.superTaskTypeCode =
                typeCode;
              this.store.dispatch(
                new SetVisaTypeCode(
                  this.superTaskTypeCode === "NONIMMPET" ? this.superTaskTypeCode : this.taskTypeCode
                )
              );
              this.store.dispatch(loadSubTaskDetails({
                taskId: parseInt(this.selectedSubTask.id.toString()), visaTypeCode:
                  this.superTaskTypeCode,
              })
              );

              // let stepOverviewList = response.subTaskList[0].stepOverviewList.filter(s => s.isBeneficiaryAction == true);
              // this.additionalPetitionInfo = [
              //   ...new Map(
              //     stepOverviewList
              //       .filter((o1) => this.tabList.attorneyJson.additionalPetitionInfo.some(
              //         (o2) => o1.stepName === o2.stepName))
              //       .map((item) => [item["stepName"], item])).values(),
              // ];
              // this.additionalCompletedSteps = this.additionalPetitionInfo.filter(pet => pet.stepStatusName === "Submitted").length;

              this.apiService.getTask(typeCode, response.subTaskList[0].id)
                .subscribe((data: any) => {
                  this.getTaskData = data.data;
                  this.additionalPetitionInfo = [];

                  let additionalPetitionInfo = [];

                  let stepOverviewList = data.data.taskStepDetails.filter(s => s.isBeneficiaryAction == true);
                  let companyId: any = data.data.createdByUserType === "Attorney" ? data.data.attorneyId : data.data.companyId;
                  this.tabList.attorneyJson.additionalPetitionInfo = this.tabList.attorneyJson.additionalPetitionInfo.filter((elem) => {
                    return this.selectedSubTask.stepOverviewList.some((ele) => {
                      return ele.stepName === elem.stepName && ele.isBeneficiaryAction === elem.isBeneficiaryAction;
                    });
                  });

                  this.tabList.attorneyJson.additionalPetitionInfo.forEach(petition => {
                    let stepDetailsPetition = stepOverviewList.find(taskPetition => taskPetition.stepName === petition.stepName);
                    if (stepDetailsPetition.stepStatusDetails.stepStatusCode === "SUBMIT") {
                      petition.markComplete = true;
                    }
                    additionalPetitionInfo.push({ ...petition, ...stepDetailsPetition });
                  });
                  try {
                    this.apiService.getQuestionListSteps(companyId, data.data.taskTypeId)
                      .pipe(takeUntil(this.observableSubscription$)).subscribe((cqData: any) => {
                        let customSteps = [];
                        cqData.customStepList.forEach(element => {
                          if (element.isBeneficiaryAction && element.isBeneficiaryEditable && element.isBeneficiaryVisible) customSteps.push(element)
                        });
                        let customStepList = customSteps.reverse();
                        for (var i = 0; i < customStepList.length; i++) {
                          let index = additionalPetitionInfo.findIndex(x => x.stepName === customStepList[i].previousStepName);
                          customStepList[i]['isCustomStep'] = true;
                          additionalPetitionInfo.splice(index + 2, 0, customStepList[i]);
                        }
                        this.additionalPetitionInfo = additionalPetitionInfo;
                      });
                  } catch (error) {
                    this.additionalPetitionInfo = additionalPetitionInfo;
                  }
                  this.additionalCompletedSteps = additionalPetitionInfo.filter(step => step.stepStatusDetails?.stepStatusCode === "SUBMIT").length;
                });
            });
        }

        if (this.route.snapshot.queryParams.prop !== 'additional') {
          let getBenificiaryDetails = this.benServe.getBenificiaryDetails()?.qfamilySubSectionList;
          getBenificiaryDetails = getBenificiaryDetails ? getBenificiaryDetails : JSON.parse(localStorage.getItem('selectedBeneData')).qfamilySubSectionList
          this.dynamicValue = this.mapOrder(
            await getBenificiaryDetails, this.profileSectionsOrder, "name"
          );
          this.dynamicValue = this.dynamicValue.filter((x)=>x.selected);
          this.completedSteps = this.dynamicValue?.filter((x) => x.markComplete).length;
        } else {
          this.dynamicValue = [];
        }

        this.dependentIdSubscription =
          this.dependentProfileDataService.dependentProfileSubject.subscribe(selectedBeneObj => {
            this.familyId = selectedBeneObj.dependentId;
            this.beneficiaryType = selectedBeneObj.beneficiaryType;
            this.showFileUploadSection = this.beneficiaryType === "family" && this.familyId ? true
              : this.beneficiaryType === "self" ? true : false;
          });
        this.dependentProfileDataService.selfBeneficiaryDetailChange.subscribe(() => {
          this.getBeneficiarySelfDetails();
          // this.getBeneficiaryFamilyDetails();
          //this.setDependentDynamicTabs(this.beneficiaryFamilyInfo);
        }
        );
        this.dependentProfileDataService.newSubDependentCreated.subscribe(
          (response) => {
            this.setDependentDynamicTabs(response.beneficiaryFamilyInfo);
            this.modifyItemsArr();
            const item = this.dependentItems.find(
              (x) => x.subTabsList && x.subTabsList.length > 0 &&
                x.subTabsList.some((y) => y.id == response.selectedSubTabId)
            );
            this.setSelectedDetailAbout(item, response.selectedSubTabId);
          }
        );
        this.fileUploadConfigurations.entityId = this.beneficiaryId;
        this.fileUploadConfigurations.useCaseId = this.beneficiaryId;
        this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);

        this.showFileUploadSection = this.beneficiaryType === "family" && this.familyId
          ? true : this.beneficiaryType === "self" ? true : false;
        this.getBeneficiarySelfDetails();
        this.getBeneficiaryFamilyDetails();
        if (this.route.snapshot.queryParams.prop !== 'additional') {
          this.valChangeSubScription = this.checker.valueChanges.subscribe((result) => {
            let selectedDynamicVal = this.dynamicValue[parseInt(this.route.snapshot.queryParams.subsection ? this.route.snapshot.queryParams.subsection : 0)]
            this.handleLeftPanelSelection(selectedDynamicVal ? selectedDynamicVal : this.dynamicValue?.find((element) => element.selected == true), false,
              this.route.snapshot.queryParams.prop && this.route.snapshot.queryParams.prop != 'BENE' && this.beneficiaryFamilyInfoGrouped[this.route.snapshot.queryParams.prop] ? this.beneficiaryFamilyInfoGrouped[this.route.snapshot.queryParams.prop][0] : null, parseInt(this.route.snapshot.queryParams.subsection ? this.route.snapshot.queryParams.subsection : 0), 0
            );
          });
        }

        this.benServe.isExperienceChanged.subscribe((res: any) => {
          if (res?.length === 0) {
            this.markcomplete();
          }
        })
        this.benServe.isEducationDetailsChanged.subscribe((res: any) => {
          if (res?.length === 0) {
            this.markcomplete();
          }
        })
        this.onScrollTop();

        let sub = this.benServe.selfDetailsSaved.subscribe(() => {
          this.ngOnInit();
        })

      });
      
    }

    if(localStorage.getItem('selectedBeneData')){
      this.isBenQuestionElemStatus = true;
      // console.log('selectedSectionData11', JSON.parse(localStorage.getItem('selectedBeneData')));
      this.selectedSectionData = JSON.parse(localStorage.getItem('selectedBeneData'));
      // console.log('selectedSectionData22', this.selectedSectionData);
    } else {
      this.isBenQuestionElemStatus = false;
    }

  }

  ngAfterViewInit(): void {
    // console.log("ddds", this.personalDetail);
  }

  ngOnDestroy() {
    if (this.dependentIdSubscription) {
      this.dependentIdSubscription.unsubscribe();
    }
    this.getStepStatusUpdateSubscription?.unsubscribe();
    this.valChangeSubScription?.unsubscribe();
    sessionStorage.removeItem("bene-profile-viewMode");
    sessionStorage.removeItem("listBeneficiaryId");
    if ('beneQuestIsCaseRequest' in sessionStorage) {
      sessionStorage.removeItem('beneQuestIsCaseRequest');
    }
    if ('isFromBenQuestionairre' in sessionStorage) {
      sessionStorage.removeItem('isFromBenQuestionairre');
    }
    if ('isViewEditQuestionnaire' in sessionStorage) {
      sessionStorage.removeItem('isViewEditQuestionnaire');
    }
  }

  checkSub(e) {
    if (e) {
      this.disableBtn = false;
      this.familyId = e;
      // console.log(e);
    }
  }

  onTabOpen(event) {
    if (event.index > 0 && (this.route.snapshot.queryParams.prop === 'CHILD' || this.route.snapshot.queryParams.prop === 'BROTHER' || this.route.snapshot.queryParams.prop === 'SISTER')) {
      this.handleLeftPanelSelection(this.extraFamMemDynamicValue[event.index - 1].qfamilySubSectionList[0], false,
        this.route.snapshot.queryParams.prop === 'BENE' ? null : this.beneficiaryFamilyInfoGrouped[
          this.route.snapshot.queryParams.prop][event.index], 0, event.index
      );
    } else {
      this.handleLeftPanelSelection(this.dynamicValue?.find((element) => element.selected == true), false,
        this.route.snapshot.queryParams.prop === 'BENE' ? null : this.beneficiaryFamilyInfoGrouped[
          this.route.snapshot.queryParams.prop][event.index], 0, event.index
      );
    }
  }

  groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) acc[key] = [];
      acc[key].push(obj);
      return acc;
    }, {});
  }

  getBeneficiarySelfDetails() {
    this.apiService.getBeneficiaryDetails(this.beneficiaryId, "self")
      .subscribe((beneficiarySelfInfo: any) => {
        // console.log("$$$$ beneficiaryFamilyInfo", beneficiarySelfInfo);
        this.beneficiarySelfInfo = beneficiarySelfInfo;
        this.maritalStatus = this.beneficiarySelfInfo.maritalStatus &&
          this.beneficiarySelfInfo.maritalStatus.code
          ? this.beneficiarySelfInfo.maritalStatus.code : "SNGL";
        this.showOtherDependentSection = (this.beneficiarySelfInfo.ctznshipCountryCode &&
          this.beneficiarySelfInfo.ctznshipCountryCode.countryCode &&
          this.beneficiarySelfInfo.ctznshipCountryCode.countryCode ===
          "USA") || this.beneficiarySelfInfo.isLpr ? true : false;
      });
  }

  updateAttrJson(attorneydata) {
    this.apiService.markComplete(
      attorneydata,
      this.beneficiaryId,
      this.tabList.attorney.id
    ).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {

      });;
  }

  async getBeneficiaryFamilyDetails() {
    this.apiService.getBeneficiaryDetails(this.beneficiaryId, "family")
      .subscribe((beneficiaryFamilyInfo: any) => {
        beneficiaryFamilyInfo.forEach((element) => {
          element["relationShipCode"] = element.relationShipType.code;
        });
        this.beneficiaryFamilyInfo = beneficiaryFamilyInfo;
        of(this.groupBy(beneficiaryFamilyInfo, "relationShipCode"))
          .pipe(delay(100))
          .subscribe((result) => {
            if (!result['BROTHER']) {
              result['BROTHER'] = [{
                relationShipType: {
                  code: "BROTHER",
                  name: "Brother"
                }
              }]
            }
            if (!result['SISTER']) {
              result['SISTER'] = [{
                relationShipType: {
                  code: "SISTER",
                  name: "Sister"
                }
              }]
            }
            if (!result['CHILD']) {
              result['CHILD'] = [{
                relationShipType: {
                  code: "CHILD",
                  name: "Child"
                }
              }]
            }
            if (this.route.snapshot.queryParams.prop === 'CHILD' || this.route.snapshot.queryParams.prop === 'BROTHER' || this.route.snapshot.queryParams.prop === 'SISTER') {
              let extraFamInfo;
              if (this.tabList.attorneyJson.extraFamilyMember.filter(b => b.refLibFamilySection.code === this.route.snapshot.queryParams.prop).length !== result[this.route.snapshot.queryParams.prop].length - 1) {
                if (this.route.snapshot.queryParams.prop === 'CHILD' && result[this.route.snapshot.queryParams.prop].length > 1) {
                  extraFamInfo = [];
                  let onlyDependData = this.tabList.attorneyJson.qfamilyDependents.filter(q => q.refLibFamilySection.code === this.route.snapshot.queryParams.prop)[0];
                  for (var i = 1; i < result[this.route.snapshot.queryParams.prop].length; i++) {
                    onlyDependData.refLibFamilySection.id = onlyDependData.refLibFamilySection.id + i;
                    let extraFamilyMemberData = JSON.parse(JSON.stringify(onlyDependData));
                    extraFamInfo.push(extraFamilyMemberData)
                  }
                  // extraFamInfo = new Array(result[this.route.snapshot.queryParams.prop].length - 1).fill(this.tabList.attorneyJson.qfamilyDependents.find(q => q.refLibFamilySection.code === this.route.snapshot.queryParams.prop));

                  extraFamInfo.forEach(famSubSec => {
                    famSubSec.qfamilySubSectionList.map(s => s.markComplete = false);
                  });
                  let otherDeps = this.tabList.attorneyJson.extraFamilyMember.filter(x => x.refLibFamilySection.code !== this.route.snapshot.queryParams.prop);
                  this.tabList.attorneyJson.extraFamilyMember = [...otherDeps, ...extraFamInfo];
                } else if ((this.route.snapshot.queryParams.prop === 'BROTHER' || this.route.snapshot.queryParams.prop === 'SISTER') && result[this.route.snapshot.queryParams.prop].length > 1) {
                  extraFamInfo = [];
                  let onlyDependData = this.tabList.attorneyJson.qfamilyOtherDependents.filter(q => q.refLibFamilySection.code === this.route.snapshot.queryParams.prop)[0];
                  for (var i = 1; i < result[this.route.snapshot.queryParams.prop].length; i++) {
                    onlyDependData.refLibFamilySection.id = onlyDependData.refLibFamilySection.id + i;
                    let extraFamilyMemberData = JSON.parse(JSON.stringify(onlyDependData));
                    extraFamInfo.push(extraFamilyMemberData)
                  }
                  // extraFamInfo = new Array(result[this.route.snapshot.queryParams.prop].length - 1).fill(this.tabList.attorneyJson.qfamilyOtherDependents.find(q => q.refLibFamilySection.code === this.route.snapshot.queryParams.prop));
                  extraFamInfo.forEach(famSubSec => {
                    famSubSec.qfamilySubSectionList.map(s => s.markComplete = false);
                  });
                  let otherDeps = this.tabList.attorneyJson.extraFamilyMember.filter(x => x.refLibFamilySection.code !== this.route.snapshot.queryParams.prop);
                  this.tabList.attorneyJson.extraFamilyMember = [...otherDeps, ...extraFamInfo];
                }
                this.updateAttrJson(this.tabList.attorneyJson);
              }
              this.extraFamMemDynamicValue = this.tabList.attorneyJson.extraFamilyMember.filter(c => c.refLibFamilySection.code === this.route.snapshot.queryParams.prop);

              this.extraFamMemDynamicValue.map((val) => {
                this.completedSteps += val.qfamilySubSectionList.filter(x => x.markComplete).length;
              });
            }

            this.beneficiaryFamilyInfoGrouped = result;
            this.checker.setValue(true);
          });
        this.setDependentDynamicTabs(beneficiaryFamilyInfo);
      });
  }

  resetExtraFam() {
    this.tabList.attorneyJson.extraFamilyMember = [];
    this.updateAttrJson(this.tabList.attorneyJson);
  }

  selfAllDetails_MC(isTrue) {
    if (isTrue) {
      this.tabList.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList[
        this.selectedSectionIndex].markComplete = true;
    } else {
      this.tabList.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList[
        this.selectedSectionIndex].markComplete = false;
    }
  }
  famOtherAllDetails_MC() {
    if (this.selectedDependentIndex > 0) {
      this.tabList.attorneyJson.extraFamilyMember.filter(fam => fam.refLibFamilySection.code === this.route.snapshot.queryParams.prop)[this.selectedChild - 1].qfamilySubSectionList[this.selectedSectionIndex].markComplete = true;
    } else {
      this.tabList.attorneyJson.qfamilyOtherDependents[
        this.tabList.attorneyJson.qfamilyOtherDependents.indexOf(
          this.tabList.attorneyJson.qfamilyOtherDependents.find(
            (o, i) =>
              o.refLibFamilySection.code === this.route.snapshot.queryParams.prop
          )
        )
      ].qfamilySubSectionList[this.selectedSectionIndex].markComplete = true;
    }
  }
  famAllDetails_MC() {
    if (this.selectedDependentIndex > 0) {
      this.tabList.attorneyJson.extraFamilyMember[
        this.tabList.attorneyJson.extraFamilyMember.indexOf(
          this.tabList.attorneyJson.extraFamilyMember.find(
            (o, i) =>
              o.refLibFamilySection.code === this.route.snapshot.queryParams.prop
          )
        )
      ].qfamilySubSectionList[this.selectedSectionIndex].markComplete = true;
    } else {
      this.tabList.attorneyJson.qfamilyDependents[
        this.tabList.attorneyJson.qfamilyDependents.indexOf(
          this.tabList.attorneyJson.qfamilyDependents.find(
            (o, i) =>
              o.refLibFamilySection.code === this.route.snapshot.queryParams.prop
          )
        )
      ].qfamilySubSectionList[this.selectedSectionIndex].markComplete = true;
    }
  }

  invaidMarkComplete(sectionitem) {
    this.toastr.warning(sectionitem + " are incomplete.");
  }

  markcomplete() {
    let isAdditionalInfoCompleted = this.additionalCompletedSteps === this.additionalPetitionInfo?.length ? true : false;
    switch (this.route.snapshot.queryParams.prop) {
      case "BENE":
        switch (this.selectedSectionData.referenceLibrarysubSection.name) {
          case "Personal Details":
            if (this.personalDetails.beneSelfDetailsForm.valid) {
              this.personalDetails.savePersonalData();
              this.selfAllDetails_MC(true);
            } else {
              this.selfAllDetails_MC(false);
              this.invaidMarkComplete("Personal Details");
              return;
            }
            break;
          case "Education Details":
            this.selfAllDetails_MC(true);
            // if (this.degree.educationDetailsList?.length > 0) {
            // } else {
            //   this.selfAllDetails_MC(false);
            //   this.invaidMarkComplete("Education Details");
            //   return;
            // }
            break;
          case "Work Experience Details":
            this.selfAllDetails_MC(true);
            // if (this.workExp.dataSource?.data?.length > 0) {
            // } else {
            //   this.selfAllDetails_MC(false);
            //   this.invaidMarkComplete("Work Experience Details");
            //   return;
            // }
            break;
          case "Immigration Details":
            this.selfAllDetails_MC(true);
            // if (
            //   this.benImmi.neverAppliedFlag === "Yes" ||
            //   this.benImmi.dataSource.data.length > 0
            // ) {
            // } else {
            //   this.selfAllDetails_MC(false);
            //   this.invaidMarkComplete("Immigration Details");
            //   return;
            // }
            break;
          default:
            break;
        }
        break;
      case "BROTHER":
      case "SISTER":
      case "FATHER":
      case "MOTHER":
      case "FIANCE":
        switch (this.selectedSectionData.referenceLibrarysubSection.name) {
          case "Brother Details":
          case "Sister Details":
          case "Father Details":
          case "Mother Details":
          case "Fiance Details":
            if (this.personalDetails.beneSelfDetailsForm.valid) {
              this.personalDetails.savePersonalData();
              this.famOtherAllDetails_MC();
            } else {
              this.invaidMarkComplete(this.selectedSectionData.referenceLibrarysubSection.name);
              return;
            }
            break;
          case "Education Details":
            this.famOtherAllDetails_MC();
            // if (this.degree.educationDetailsList?.length > 0) {
            //   this.famOtherAllDetails_MC();
            // } else {
            //   this.invaidMarkComplete("Education Details");
            //   return;
            // }
            break;
          case "Work Experience Details":
            this.famOtherAllDetails_MC();
            // if (this.workExp.dataSource?.data?.length > 0) {
            //   this.famOtherAllDetails_MC();
            // } else {
            //   this.invaidMarkComplete("Work Experience Details");
            //   return;
            // }
            break;
          case "Immigration Details":
            this.famOtherAllDetails_MC();
            // if (
            //   this.benImmi.neverAppliedFlag === "Yes" ||
            //   this.benImmi.dataSource?.data?.length > 0
            // ) {
            //   this.famOtherAllDetails_MC();
            // } else {
            //   this.invaidMarkComplete("Immigration Details");
            //   return;
            // }
            break;
          default:
            break;
        }
        break;
      case "CHILD":
      case "SPOUSE":
        switch (this.selectedSectionData.referenceLibrarysubSection.name) {
          case "Child Details":
          case "Spouse Details":
            if (this.personalDetails.beneSelfDetailsForm.valid) {
              this.personalDetails.savePersonalData();
              this.famAllDetails_MC();
            } else {
              this.invaidMarkComplete(this.selectedSectionData.referenceLibrarysubSection.name);
              return;
            }
            break;
          case "Education Details":
            this.famAllDetails_MC();
            // if (this.degree.educationDetailsList?.length > 0) {
            //   this.famAllDetails_MC();
            // } else {
            //   this.invaidMarkComplete(this.selectedSectionData.referenceLibrarysubSection.name);
            //   return;
            // }
            break;
          case "Work Experience Details":
            this.famAllDetails_MC();
            // if (this.workExp.dataSource?.data?.length > 0) {
            //   this.famAllDetails_MC();
            // } else {
            //   this.invaidMarkComplete(this.selectedSectionData.referenceLibrarysubSection.name);
            //   return;
            // }
            break;
          case "Immigration Details":
            this.famAllDetails_MC();
            // if (
            //   this.benImmi.neverAppliedFlag === "Yes" ||
            //   this.benImmi.dataSource?.data?.length > 0
            // ) {
            //   this.famAllDetails_MC();
            // } else {
            //   this.invaidMarkComplete(this.selectedSectionData.referenceLibrarysubSection.name);
            //   return;
            // }
            break;
          default:
            break;
        }

        break;
      case "additional":
        if (isAdditionalInfoCompleted) {
          this.tabList.attorneyJson.additionalPetitionInfoCompleted = true;
        } else {
          this.invaidMarkComplete("Petitioner Information Details");
          return;
        }
        break;
      case "document":
        if (isAdditionalInfoCompleted) {
          this.tabList.attorneyJson.additionalPetitionInfoCompleted = true;
        } else {
          this.invaidMarkComplete("Document Checklists");
          return;
        }
        break;
      default:
        break;
    }
    if (
      this.tabList.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList.filter((x) => x.markComplete
      ).length === this.tabList.attorneyJson.beneficiaryProfileInfo.qfamilySubSectionList.length
    ) {
      this.tabList.attorneyJson.beneficiaryProfileInfoDetailsCompleted = true;
    }

    this.tabList.attorneyJson.qfamilyDependents.forEach((element) => {
      if (element.qfamilySubSectionList.length > 0) {
        if (element.qfamilySubSectionList.filter(x => x.markComplete).length === element.qfamilySubSectionList.length) {
          this.tabList.attorneyJson['is' + (element.qfamilySubSectionList[0].referenceLibrarysubSection.name).toLowerCase().replace(/\s/g, '') + 'MarkComplete'] = true;
        }
      }
    });
    this.tabList.attorneyJson.qfamilyOtherDependents.forEach((element) => {
      if (element.qfamilySubSectionList.length > 0) {
        if (element.qfamilySubSectionList.filter(x => x.markComplete).length === element.qfamilySubSectionList.length) {
          this.tabList.attorneyJson['is' + (element.qfamilySubSectionList[0].referenceLibrarysubSection.name).toLowerCase().replace(/\s/g, '') + 'MarkComplete'] = true;
        }
      };
    });


    let dependents = {
      dependentsCount: 0,
      dependentsMarkedCompleteCount: 0,
      otherDependentsCount: 0,
      otherDependentsMarkedCompleteCount: 0,
    };
    this.tabList.attorneyJson.qfamilyDependents.filter(x => x.selected).forEach(dependent => {
      dependents['dependentsCount'] += dependent.qfamilySubSectionList.length;
      dependents['dependentsMarkedCompleteCount'] += dependent.qfamilySubSectionList.filter(d => d.markComplete).length;
    });

    this.tabList.attorneyJson.qfamilyOtherDependents.filter(x => x.selected).forEach(dependent => {
      dependents['otherDependentsCount'] += dependent.qfamilySubSectionList.length;
      dependents['otherDependentsMarkedCompleteCount'] += dependent.qfamilySubSectionList.filter(d => d.markComplete).length;
    });

    this.tabList.attorneyJson.extraFamilyMember.forEach(dependent => {
      if (dependent.refLibFamilySection.code === 'CHILD' || dependent.refLibFamilySection.code === 'SPOUSE') {
        dependents['dependentsCount'] += dependent.qfamilySubSectionList.length;
        dependents['dependentsMarkedCompleteCount'] += dependent.qfamilySubSectionList.filter(d => d.markComplete).length;
      } else {
        dependents['otherDependentsCount'] += dependent.qfamilySubSectionList.length;
        dependents['otherDependentsMarkedCompleteCount'] += dependent.qfamilySubSectionList.filter(d => d.markComplete).length;
      }
    });

    this.tabList.attorneyJson.qfamilyDependentsCompleted = dependents.dependentsCount === dependents.dependentsMarkedCompleteCount;

    this.tabList.attorneyJson.qfamilyOtherDependentsCompleted = dependents.otherDependentsCount === dependents.otherDependentsMarkedCompleteCount;

    this.tabList.attorneyJson.extraFamilyMember = this.tabList.attorneyJson.extraFamilyMember.filter(tempDeps => !tempDeps.tempId)

    this.apiService.markComplete(this.tabList.attorneyJson, this.beneficiaryId, this.companyId)
      .pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          if (this.route.snapshot.queryParams.prop !== 'additional' && this.route.snapshot.queryParams.prop !== 'document') {
            let selectedBenData = this.benServe.getBenificiaryDetails()
            if (!selectedBenData) {
              selectedBenData = JSON.parse(localStorage.getItem("selectedBeneData"));
            }
            selectedBenData.qfamilySubSectionList[this.selectedSectionIndex].markComplete = true
            this.benServe.addBenificiary(selectedBenData)
            localStorage.setItem("selectedBeneData", JSON.stringify(selectedBenData));
          }
          this.completedSteps = this.dynamicValue?.filter((x) => x.markComplete).length;
          // this.ngOnInit();
          // this.router.navigate(["beneficiary-profile"]);
          this.toastr.success(response.message);
        }
      });
  }

  mapOrder(array, order, key) {
    if (array) {
      array.sort(function (sa, sb) {
        var sectionA = sa.referenceLibrarysubSection[key],
          sectionB = sb.referenceLibrarysubSection[key];
        if (order.indexOf(sectionA) > order.indexOf(sectionB)) {
          return 1;
        } else {
          return -1;
        }
      });
      return array;
    }
  }

  setSelectedDetailAbout(item, selectedSubTabId) {
    this.selectedDetailAbout = {
      showDetailFor: "dependent",
      dependentType: item,
      selectedSubTabId,
    };
  }

  setDependentDynamicTabs(beneficiaryFamilyInfo) {
    this.beneficiaryFamilyInfo = beneficiaryFamilyInfo;
    this.items.forEach((item) => {
      if (item && item.subTabsList) {
        item.subTabsList = [];
        if (this.beneficiaryFamilyInfo && this.beneficiaryFamilyInfo.length > 0
        ) {
          this.beneficiaryFamilyInfo.forEach((familyItem) => {
            if (familyItem.relationShipType.code === item.code) {
              item.subTabsList.splice(item.subTabsList.length, 0, {
                header: `${item.name} ${item.subTabsList.length + 1}`,
                content: "", id: `${familyItem.id}`, showButton: false,
              });
            }
          });
        }
        if (item.subTabsList.length === 0) {
          item.subTabsList.splice(item.subTabsList.length, 0, {
            header: `${item.name} ${item.subTabsList.length + 1}`,
            content: "", id: 0, showButton: false,
          });
        }
      }
    });
    // }
    // console.log("this.items", this.items);
  }

  deleteDocumentHandler(item) {
    this.apiService.deleteFileFromDocumentList(
      this.beneficiaryId, item.fileCategory.group, item.fileCategory.code, item.id)
      .pipe(takeUntil(this.observableSubscription$)).subscribe((data: any) => {
        this.toastr.success(data.message);
      });
  }

  onScrollTop() {
    // this.mainBody.nativeElement.scroll(0, 0);
    window.scroll(0, 0);
  }

  handleChange(e) {
    this.accordianActivedIndex = -1;
  }

  onEditClicked(status) {
    this.editClicked = status;
    this.getUpolodedFiles();
  }

  getUpolodedFiles() {
    this.fileUploadConfigurations.familyId = this.familyId ? this.familyId : null;
    this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
    //this.editClicked =  (sessionStorage.getItem('dependentId') == '0') ? false : true
    this.uploadDocumentService.genericGetUploadDocument(this.fileUploadConfigurations.useCase, this.fileUploadConfigurations.useCaseId, this.fileUploadConfigurations.categoryName, this.fileUploadConfigurations.entityId, this.fileUploadConfigurations.useEntityId, this.fileUploadConfigurations.familyId).subscribe((response) => {
      if (response['status'] === 200) {
        this.fileUploadConfigurations.listUploadedFiles = response['data'];
        this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
      }
      else {
        this.fileUploadConfigurations.listUploadedFiles = [];
        this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
      }
    });
  }

  getEducationDetails() {
    this.apiService.getEducationDetails(this.beneficiaryId).subscribe((data) => {
      if (data["status"] === 200) {
        this.educationDetails = data["data"]["education"];
      }
    });
  }

  onAddButton(mainTab) {
    let newData;
    if (this.extraFamMemDynamicValue.length > 0) {
      newData = JSON.parse(JSON.stringify(this.extraFamMemDynamicValue[this.extraFamMemDynamicValue.length - 1]));
    } else {
      if (mainTab === 'CHILD') {
        let childDepData = this.tabList.attorneyJson.qfamilyDependents.filter(x => x.refLibFamilySection.code === 'CHILD');
        newData = JSON.parse(JSON.stringify(childDepData[childDepData.length - 1]));
      } else {
        let DepData = this.tabList.attorneyJson.qfamilyOtherDependents.filter(x => x.refLibFamilySection.code === this.route.snapshot.queryParams.prop);
        newData = JSON.parse(JSON.stringify(DepData[DepData.length - 1]));
      }
    }
    newData.tempId = this.extraFamMemDynamicValue.length + 1;
    newData.refLibFamilySection.id = newData.refLibFamilySection.id + 1;
    newData.qfamilySubSectionList.forEach(famdata => {
      famdata.markComplete = false;
      famdata.referenceLibrarysubSection.id = famdata.referenceLibrarysubSection.id + 1
    });
    this.tabList.attorneyJson.extraFamilyMember.push(newData)
    this.extraFamMemDynamicValue.push(newData);
  }

  removeEtraFammember(tempId) {
    this.extraFamMemDynamicValue = this.extraFamMemDynamicValue.filter(m => m.tempId !== tempId)
  }

  setDependentTabItems() {
    this.items = [
      {
        header: "Fiance",
        content: "content1",
        code: "FIANCE",
        desc: "Fiance",
        group: "FAMRELTYP",
        id: 1110,
        name: "Fiance",
        subTabsList: null,
      },
      {
        header: "Father",
        content: "content1",
        code: "FATHER",
        desc: "Father",
        group: "FAMRELTYP",
        id: 15,
        name: "Father",
        subTabsList: null,
      },
      {
        header: "Mother",
        content: "content1",
        code: "MOTHER",
        desc: "Mother",
        group: "FAMRELTYP",
        id: 16,
        name: "Mother",
        subTabsList: null,
      },
      {
        header: "Sister",
        content: "content1",
        code: "SISTER",
        desc: "Sister",
        group: "FAMRELTYP",
        id: 889,
        name: "Sister",
        subTabsList: [],
      },
      {
        header: "Brother",
        content: "content1",
        code: "BROTHER",
        desc: "Brother",
        group: "FAMRELTYP",
        id: 888,
        name: "Brother",
        subTabsList: [],
      },
      {
        header: "Spouse",
        content: "content1",
        code: "SPOUSE",
        desc: "Spouse",
        group: "FAMRELTYP",
        id: 14,
        name: "Spouse",
        subTabsList: null,
      },
      {
        header: "Children",
        content: "content1",
        code: "CHILD",
        desc: "Child",
        group: "FAMRELTYP",
        id: 13,
        name: "Child",
        subTabsList: [],
      },
    ];
  }

  modifyItemsArr() {
    this.dependentItems = [];
    if (this.isFamily) {
      this.dependentItems = this.items.filter(
        (item) => item.code === "SPOUSE" || item.code === "CHILD"
      );
    } else {
      if (this.showOtherDependentSection) {
        this.dependentItems = this.items.filter((item) =>
          ["FIANCE", "FATHER", "MOTHER", "SISTER", "BROTHER"].includes(
            item.code
          )
        );
      } else {
        this.dependentItems = this.items.filter((item) =>
          ["FATHER", "MOTHER"].includes(item.code)
        );
      }
    }
  }
  goBack() {
    // console.log('>>> back clicked');
    this.router.navigate(['beneficiary-profile']);
  }

  goBackCase() {
    this.caseRequestBack.emit(true);
  }

  goNextCase() {
    this.onCaseRequestNext.emit({
      item: this.route.snapshot.queryParams.prop
    });
  }

  handleLeftPanelSelection(selectedData, isLastTab, famInfo: any, i?: number, j?: number
  ) {
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: { subsection: i, familyId: famInfo?.id }, queryParamsHandling: 'merge', skipLocationChange: true });

    let selectedTabData = selectedData.referenceLibrarysubSection?.code;
    this.selectedSectionData = selectedData;
    this.selectedSectionData.subLevel.forEach(element => {
      this.currentItemDisplay.push(element.code);
    });
    this.selectedSectionIndex = i;
    this.selectedSectionFamInfo = famInfo;
    this.selectedDependentIndex = j;
    this.index = i;
    this.selectedChild = j;
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(
          new MarkCleanFormAction({
            dirty: false,
          })
        );
        sessionStorage.setItem("listBeneficiaryId", this.beneficiaryId.toString());
        // if (selectedTab === 'QTPROFILESSPERS') {
        //   this.isLastTab = isLastTab;
        //   this.selectedItem = selectedTab;
        //   this.selectedDetailAbout = {
        //     showDetailFor: 'dependent',
        //     dependentType: this.dependentItems[0],
        //   selectedSubTabId: null
        //   };
        // }
        if (selectedTabData === "QTPROFILESSPERS") {
          this.isLastTab = isLastTab;
          this.selectedItem = selectedTabData;
          this.isSelf = true;
          this.isDocuments = false;
          this.isFamily = true;
          this.isDependent = true;
          this.modifyItemsArr();
          this.checkDependent(famInfo);
        } else if (selectedTabData === "QTPROFILESSEDUC") {
          this.isLastTab = isLastTab;
          this.selectedItem = selectedTabData;
          this.isSelf = true;
          this.isDocuments = false;
          this.isFamily = true;
          this.isDependent = true;
          this.modifyItemsArr();
          this.checkDependent(famInfo);
          // this.selectedDetailAbout = {
          //   showDetailFor: 'self',
          // };
        } else if (selectedTabData === "QTPROFILESSWRKEXP") {
          this.isLastTab = isLastTab;
          this.selectedItem = selectedTabData;
          this.checkDependent(famInfo);
          // this.selectedDetailAbout = {
          //   showDetailFor: 'self',
          // };
        } else if (selectedTabData === "QTPROFILESSIMM") {
          this.isLastTab = isLastTab;
          this.selectedItem = selectedTabData;
          this.dependentProfileDataService.changeDependentProfileID(famInfo?.id, 'family');
          this.checkDependent(famInfo);
          // this.selectedDetailAbout = {
          //   showDetailFor: 'self',
          // };
        } else if (selectedTabData === "DOCUMENTS_CHECKLIST") {
          this.isLastTab = isLastTab;
          this.selectedItem = selectedTabData;
          this.checkDependent(famInfo);
          // this.selectedDetailAbout = {
          //   showDetailFor: 'self',
          // };
        }
      } else {
        return false;
      }
    });
  }

  checkDependent(famInfo) {
    let a = "";
    this.route.queryParams.subscribe((param) => {
      a = param["prop"];
    });
    if (a && a !== 'BENE') {
      if (famInfo != null) {
        this.dependentProfileDataService.changeDependentProfileID(famInfo.id, 'family');
      } else {
        this.dependentProfileDataService.changeDependentProfileID(null, 'family');
      }
      this.selectedDetailAbout = {
        showDetailFor: "dependent",
        dependentType: this.items.filter((item) => item.code == a)[0],
        selectedSubTabId: famInfo ? famInfo.id : null,
      };
    } else {
      this.dependentProfileDataService.changeDependentProfileID(null, 'self');
      this.selectedDetailAbout = {
        showDetailFor: "self",
      };
    }
  }

  selectedAdditionalStep(stepData, index) {
    this.tabList.attorneyJson.additionalPetitionInfo.map((step) => (step.clicked = false));
    stepData.clicked = true;
  }


  postNgOnInit() {
    // Get Specific Task Details
    this.store.select(getSubTaskDetails).pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: SubTaskDetails) => {
        if (data.id && this.taskTypeCode) {
          this.subTaskDetails = data;
          this.receiptETADateRange = data.receiptETADateRange;
          this.decisionETADateRange = data.decisionETADateRange;
          this.store.dispatch(
            new Task.SetActiveSubTaskIndex(this.subTaskDetails.id)
          );
          let familyId;
          if (["K1", "K3"].includes(
            this.subTaskDetails.taskTypeDetails.visaType) && this.subTaskDetails.jsonInput
          ) {
            const jsonInput = JSON.parse(this.subTaskDetails.jsonInput);
            familyId = jsonInput && jsonInput.primaryApplicant &&
              jsonInput.primaryApplicant.id ? jsonInput.primaryApplicant.id : null;
          } else {
            familyId = this.subTaskDetails.familyId ? this.subTaskDetails.familyId : null;
          }
          this.primaryData = {
            superTaskId: this.subTaskDetails.superTaskId,
            subTaskId: this.subTaskDetails.id,
            caseType: this.taskTypeCode,
            caseId: this.caseId || this.subTaskDetails.petitionId,
            petitionId: this.subTaskDetails.petitionId,
            foreignCompanyId: this.subTaskDetails.foreignCompanyId,
            visatype: this.paramTaskTypeCode === "I130" ? "GC" : this.subTaskDetails.taskTypeDetails.visaType
              ? this.subTaskDetails.taskTypeDetails.visaType : this.subTaskDetails.taskTypeDetails.immCategoryCode,
            visaName: this.subTaskDetails.taskTypeDetails.visaName,
            petitionType: this.subTaskDetails.taskTypeDetails.petitionType,
            immSpecialistId: this.subTaskDetails.immSpecialistId,
            attorneyId: this.subTaskDetails.attorneyId,
            sponsorId: ["K1", "K3"].includes(
              this.subTaskDetails.taskTypeDetails.visaType)
              ? this.subTaskDetails.beneficiaryId : this.subTaskDetails.sponsorId
                ? this.subTaskDetails.sponsorId : null, familyId,
            immigrationCategory:
              this.paramTaskTypeCode === "I130" ? "GC"
                : this.superTask && this.superTask.immigrationCategory
                  ? this.superTask.immigrationCategory.code : null,
            immigrationCategoryName:
              this.superTask && this.superTask.immigrationCategory
                ? this.superTask.immigrationCategory.name : null,
            immigrationClassification:
              this.superTask && this.superTask.immigrationClassification
                ? this.superTask.immigrationClassification.code : null,
            immigrationClassificationName:
              this.superTask && this.superTask.immigrationClassification
                ? this.superTask.immigrationClassification.name : null,
            superTaskTypeCode: this.superTaskTypeCode,
            getTaskType:
              this.superTaskTypeCode === "NONIMMPET"
                ? this.subTaskDetails.taskTypeDetails.visaType : this.taskTypeCode,
            jsonInput: this.subTaskDetails.jsonInput
              ? JSON.parse(this.subTaskDetails.jsonInput) : null,
            beneficiaryId: this.subTaskDetails.beneficiaryId
              ? this.subTaskDetails.beneficiaryId : null,
            relationshipCode: this.subTaskDetails.relationshipCode
              ? this.subTaskDetails.relationshipCode : null,
          };
        }
      });
    // Get sub task list
    this.store.pipe(select(getSubTaskList)).pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: SubTask[]) => {
        if (data && data.length > 0) {
          // console.log('this.paramTaskTypeCodedata', data);
          // console.log('this.paramTaskTypeCode', this.paramTaskTypeCode);
          const subTask = data.find(
            (x) => x.taskTypeCode === this.paramTaskTypeCode
          );
          this.caseId = subTask.caseId;
          this.taskTypeCode = subTask.taskTypeCode;
          this.superTaskTypeCode = subTask.superTaskTypeCode;
          this.store.dispatch(
            new SetVisaTypeCode(
              this.superTaskTypeCode === "NONIMMPET" ? this.superTask.visaType.code : this.taskTypeCode
            )
          );
          this.store.dispatch(loadSubTaskDetails({
            taskId: parseInt(subTask.id.toString()),
            visaTypeCode: this.superTaskTypeCode === "NONIMMPET"
              ? this.superTask.visaType.code : this.taskTypeCode,
          })
          );
        }
      });
    // Get Step list
    this.store.pipe(select(getSubTaskStepList)).pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep[]) => {
        if (data) {
          this.stepList = data;
        }
      });

    this.getStepStatusUpdateSubscription = this.store.pipe(select(getStepStatusUpdate)).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.toastr.success(response.message);
          this.mainContainerComponentService.onStepStatusUpdate(
            this.subTaskDetails.superTaskId
          );
          this.ngOnInit();
        }
      });

    this.taskNotesService.onNotesUpdated.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      this.mainContainerComponentService.onStepStatusUpdate(
        this.subTaskDetails.superTaskId
      );
    });

    this.cacheCountyService.getCountry().subscribe();
    // This is to load countries in a cache service
  }

  i140getStepDetails(step: any) {
    this.store.pipe(select(i140getStepDetails, { id: step.id }))
      .pipe(take(1))
      .subscribe((dataTaskStep: TaskStep) => {
        if (dataTaskStep) {
          if (dataTaskStep.stepActionList && dataTaskStep.stepActionList.length > 0) {
            this.dynamicDialogRef = this.dialogService.open(this.getStepComponent(step.stepActionList[0].layoutCode), {
              width: '70%',
              contentStyle: { 'max-height': '90vh', 'z-index': '1000', 'padding-bottom': '15px', 'overflow-y': 'hidden' },
              closable: false,
              showHeader: false,
              closeOnEscape: false,
              modal: true,
              dismissableMask: true,
              // data: this.primaryData,
              data: {
                superTaskId: this.subTaskDetails.superTaskId,
                subTaskId: this.subTaskDetails.id,
                i140CaseId: this.caseId,
                stepId: step.id,
                companyId: step.companyId,
                petitionerId: step.petitionerId,
                stepName: step.stepName,
                beneficiaryId: this.beneficiaryId,
                layoutCode: dataTaskStep.stepActionList[0].layoutCode,
                isViewMode: this.isViewMode,
                isCaseRequest: this.isFromCaseRequest
              },
            });
          }
        } else {
          setTimeout(() => {
            this.i140getStepDetails(step);
          }, 500);
        }
      });
  }

  handleStepClick(step: any) {
    // console.log('handleStepClick :: step ::', step)
    if (step.isCustomStep && !this.isCaseRequestStatus) {
      sessionStorage.setItem('taskID', this.tabList.superTaskId);
    }else if(!step.isCustomStep && this.isBenQuestionStatus){
      sessionStorage.setItem('taskID', this.tabList.superTaskId);
      sessionStorage.setItem('isFromBenQuestionairre','true');
    }
    if (this.isExternalEmployeeId) { return }
    this.postNgOnInit();
    this.ngOnInit();
    this.apiDocumentChecklist.getSuperTask(this.tabList.superTaskId)
      .pipe(takeUntil(this.destroy$), take(1)).subscribe((response) => {
        if (response) {
          if (response.subTaskList && response.subTaskList.length > 0) {
            let typeCode;
            if (response.superTask.visaType && response.superTask.visaType.code && !step.isCustomStep) {
              typeCode = response.superTask.visaType.code;
            } else {
              typeCode = response.subTaskList[0].taskTypeCode;
            }
            if (typeCode === 'I140' && !step.isCustomStep) {
              this.store.dispatch(i140loadSubTaskDetails({ taskId: response.subTaskList[0].id }));
              this.i140getStepDetails(step);
            } else {
              this.apiService.getTask(typeCode, response.subTaskList[0].id).subscribe((data: any) => {
                if (data) {
                  let familyId;
                  if (["K1", "K3"].includes(this.subTaskDetails.taskTypeDetails.visaType) &&
                    this.subTaskDetails.jsonInput) {
                    const jsonInput = JSON.parse(this.subTaskDetails.jsonInput);
                    familyId = jsonInput && jsonInput.primaryApplicant && jsonInput.primaryApplicant.id
                      ? jsonInput.primaryApplicant.id : null;
                  } else {
                    familyId = this.subTaskDetails.familyId ? this.subTaskDetails.familyId : null;
                  }
                  this.primaryData = {
                    superTaskId: this.subTaskDetails.superTaskId,
                    subTaskId: this.subTaskDetails.id,
                    caseType: this.taskTypeCode,
                    caseId: this.caseId || this.subTaskDetails.petitionId,
                    petitionId: this.subTaskDetails.petitionId,
                    foreignCompanyId: this.subTaskDetails.foreignCompanyId,
                    visatype:
                      this.paramTaskTypeCode === "I130" ? "GC" : this.subTaskDetails.taskTypeDetails.visaType
                        ? this.subTaskDetails.taskTypeDetails.visaType : this.subTaskDetails.taskTypeDetails.immCategoryCode,
                    visaName: this.subTaskDetails.taskTypeDetails.visaName,
                    petitionType: this.subTaskDetails.taskTypeDetails.petitionType,
                    immSpecialistId: this.subTaskDetails.immSpecialistId,
                    attorneyId: this.subTaskDetails.attorneyId,
                    sponsorId: ["K1", "K3"].includes(this.subTaskDetails.taskTypeDetails.visaType)
                      ? this.subTaskDetails.beneficiaryId : this.subTaskDetails.sponsorId
                        ? this.subTaskDetails.sponsorId : null,
                    familyId, immigrationCategory: this.paramTaskTypeCode === "I130" ? "GC"
                      : this.superTask && this.superTask.immigrationCategory
                        ? this.superTask.immigrationCategory.code : null,
                    immigrationCategoryName: this.superTask && this.superTask.immigrationCategory
                      ? this.superTask.immigrationCategory.name : null,
                    immigrationClassification:
                      this.superTask && this.superTask.immigrationClassification
                        ? this.superTask.immigrationClassification.code : null,
                    immigrationClassificationName:
                      this.superTask && this.superTask.immigrationClassification
                        ? this.superTask.immigrationClassification.name : null,
                    superTaskTypeCode: this.superTaskTypeCode,
                    getTaskType: this.superTaskTypeCode === "NONIMMPET"
                      ? this.subTaskDetails.taskTypeDetails.visaType : this.taskTypeCode,
                    jsonInput: this.subTaskDetails.jsonInput
                      ? JSON.parse(this.subTaskDetails.jsonInput) : null,
                    beneficiaryId: this.subTaskDetails.beneficiaryId
                      ? this.subTaskDetails.beneficiaryId : null,
                    relationshipCode: this.subTaskDetails.relationshipCode
                      ? this.subTaskDetails.relationshipCode : null,
                    isViewMode: this.isViewMode,
                  };
                  let companyId: any = this.subTaskDetails.createdByUserType === "Attorney" ? this.subTaskDetails.attorneyId : this.subTaskDetails.companyId;
                  sessionStorage.setItem("companyId", companyId);
                  let taskTypeId: any = this.subTaskDetails.taskTypeId;
                  sessionStorage.setItem("taskTypeId", taskTypeId);
                  // console.log('step', step);
                  if (step.previousStepName != null) {
                    const dialogRef = this.dialog.open(CustomStepsComponent, {
                      disableClose: true,
                      width: '900px',
                      height: '70vh',
                      panelClass: 'custom-questionnaire-dialog-container',
                      data: { stepName: step.stepName, taskId: step.taskId ? step.taskId : this.subTaskDetails.id, subTaskDetails: this.subTaskDetails, stepId: step.id, beneficiaryId: this.superTaskDetails.beneficiaryId, isCustomStep: true, isViewMode: this.isViewMode, isCaseRequest: this.isFromCaseRequest }
                    },
                    );
                    dialogRef.afterClosed().subscribe(result => {
                      this.ngOnInit();
                    });
                  } else {
                    this.ngOnInit();
                    this.primaryData = {
                      ...this.primaryData,
                      stepId: step.id,
                      companyId: response.superTask.companyId,
                      petitionerId: data.data.taskStepDetails[0].petitionerId,
                      stepName: step.stepName,
                      layoutCode: step.layoutCode ? step.layoutCode : step.stepActionList[0].layoutCode,
                    };
                    // if(this.isViewMode == true && this.userType == 'Attorney')
                    this.primaryData['isViewMode'] = this.isViewMode;
                    this.primaryData['isCaseRequest'] = this.isFromCaseRequest
                    // this.primaryData.isViewMode =  this.isViewMode;
                    this.dialogService.open(
                      this.getStepComponent(step.layoutCode ? step.layoutCode : step.stepActionList[0].layoutCode),
                      {
                        width: "96%",
                        height: "100%",
                        contentStyle: {
                          "z-index": "1000",
                          "overflow-y": "hidden",
                        },
                        closable: false,
                        showHeader: false,
                        closeOnEscape: false,
                        modal: true,
                        dismissableMask: true,
                        data: this.primaryData,
                      }
                    );
                  }
                }

              });
            }
          }
        }
      });
  }

  getStepComponent(stepName) {
    switch (stepName) {
      case layout_code.REGISTER_USCIS:
        return USCISH1BLotteryComponent;
      case layout_code.AWAIT_LOTTERY:
        return AwaitLotterySelectionComponent;
      case layout_code.OCCUPATION_DETAIL:
        return SpecialityOccupationComponent;
      case layout_code.BEN_OCCUPATION_DETAIL:
        return SpecialityOccupationComponent;
      case this.superTaskDetails?.visaType?.code === "H1-B" &&
        layout_code.H1_B_POSITION_DETAIL:
        return H1bJobDescriptionComponent;
      case this.superTaskDetails?.visaType?.code === "H1-B" &&
        layout_code.PETITIONER_DOCS_H1B:
        return H1bPetitionerDetailsComponent;
      case layout_code.H1B_POSITION_DETAIL:
      case layout_code.POSITION_DETAIL:
      case layout_code.BEN_POSITION_DETAIL:
      case layout_code.BEN_H1_B_POSITION_DETAIL:
        return JobDescriptionComponent;
      case layout_code.PAST_POSITION_DETAIL:
        return PastJobDescriptionComponent;
      case layout_code.BEN_PAST_POSITION_DETAIL:
        return PastJobDescriptionComponent;
      case layout_code.PERSONAL_DETAIL:
      case layout_code.BEN_PERSONAL_DETAIL:
        if (["I130", "I485"].includes(this.primaryData.caseType) ||
          ["K1", "K3"].includes(this.superTaskDetails?.visaType?.code) ||
          ["ASYLRELATIVE"].includes(this.subTaskDetails.taskTypeDetails.immClassificationCode)
        ) {
          return ProvideInformationContainerComponent;
        } else {
          return BeneficiaryPersonalDetailsComponent;
        }
      case layout_code.EDUCATION_DETAIL:
        return L1VisaEducationDetailsComponent
      case layout_code.BEN_EDU_DETAIL:
        if (this.primaryData.caseType == 'I140') {
          return GreencardEducationDetailsComponent;
        } else {
          return L1VisaEducationDetailsComponent
        }
      case layout_code.BEN_EDUCATION_DETAIL:
        return L1VisaEducationDetailsComponent
      case layout_code.EXPERIENCE_DETAIL:
        return L1VisaWorkExperienceDetailsComponent;
      case layout_code.BEN_EXP_DETAIL:
        if (this.primaryData.caseType == 'I140') {
          return GreencardWorkExperienceDetailsComponent;
        } else {
          return L1VisaWorkExperienceDetailsComponent
        }
      // case layout_code.BEN_EXP_DETAIL:
      //   return WorkExperienceDetailsComponent;
      case layout_code.BEN_EXPERIENCE_DETAIL:
        return L1VisaWorkExperienceDetailsComponent;
      case layout_code.IMM_OTH_DOCS:
        return L1VisaBeneficiaryDocumentsComponent;
      case layout_code.BEN_IMM_OTH_DOCS:
        return L1VisaBeneficiaryDocumentsComponent;
      case layout_code.EMPR_EMP_REL_DETAIL:
      case layout_code.BEN_EMPR_EMP_REL_DETAIL:
        return ProvideEERComponent;
      case layout_code.PETITIONER_DOCS_FORGN:
        return PetitionerDetailsForeignCompanyComponent;
      case layout_code.PETITIONER_DOCS:
        return PetitionerDetailsComponent;
      case layout_code.BEN_PETITIONER_DOCS:
        return PetitionerDetailsComponent;
      case layout_code.PETITIONER_DOCS_H1B:
        return PetitionerDetailsComponent;
      case layout_code.PETITIONER_DOCS_US:
        return PetitionerDetailsComponent;
      // case layout_code.BEN_PROVIDE_BEN_DOCS:
      //   return L1VisaBeneficiaryDocumentsComponent;
      case layout_code.USCIS_CONTENT:
      case layout_code.BEN_USCIS_CONTENT:
        return UscisContentComponent;
      case layout_code.REVIEW_PETITION:
      case layout_code.BEN_REVIEW_PETITION:
        return ReviewI140PetitionComponent;
      case layout_code.PRINT_PETITION:
      case layout_code.BEN_PRINT_PETITION:
        return PrintPetitionComponent;
      case layout_code.SUBMIT_FEES:
      case layout_code.BEN_SUBMIT_FEES:
        return SubmitPetitionComponent;
      case layout_code.GET_RECEIPT:
      case layout_code.BEN_GET_RECEIPT:
        return GetReceiptsComponent;
      case layout_code.FILL_FORM:
      case layout_code.BEN_FILL_FORM:
        return LetterGenerationComponent;

      // case layout_code.BEN_EXP_DETAIL:
      //   return WorkExperienceDetailsComponent;
      case layout_code.BEN_DEPENDENT_INFO:
        return GreencardDependentDetailsComponent;
      case layout_code.BEN_PROVIDE_BEN_DOCS:
        return GreencardBeneficiaryDocumentsComponent;
      case layout_code.UPDATE_DECISION:
        return UpdateDecisionComponent;
      case layout_code.SEND_NOTIFICATION:
        return SendNotificationComponent;
      case layout_code.UPDATE_EMPLOYMENT:
        return UpdateEmploymentComponent;
      case layout_code.IOS_DETAIL:
      case layout_code.BEN_IOS_DETAIL:
        return ItineraryOfServicesComponent;
      case layout_code.LCA:
        return LcaComponent;
      case layout_code.EXTR_ABL_DETAIL:
        return CaptureExtraordinaryAbilityDetailsComponent;
      case layout_code.BEN_EXTR_ABL_DETAIL:
        return CaptureExtraordinaryAbilityDetailsComponent;
      case layout_code.ENT_REL_DETAIL:
      case layout_code.BEN_ENT_REL_DETAIL:
        return EnterEntitiesRelationshipDetailsComponent;
      case layout_code.O_POSITION_DETAIL:
      case layout_code.BEN_O_POSITION_DETAIL:
      case layout_code.TN_POSITION_DETAIL:
      case layout_code.E_POSITION_DETAIL:
        return VdJobDescriptionComponent;
      case layout_code.O_PETITIONER_DOCS:
      case layout_code.BEN_O_PETITIONER_DOCS:
        return VdProvidePetitionerDetailsComponent;
      case layout_code.PROC_INFO:
      case layout_code.BEN_PROC_INFO:
        return EnterProcessingInformationComponent;
      case layout_code.BENEFICIARY_DETAIL:
      case layout_code.BEN_BENEFICIARY_DETAIL:
        return ProvideInformationContainerComponent;
      case layout_code.PRIMARY_APPLICANT_DETAILS:
      case layout_code.SECONDARY_APPLICANT_DETAILS:
      case layout_code.BEN_PRIMARY_APPLICANT_DETAILS:
      case layout_code.BEN_SECONDARY_APPLICANT_DETAILS:
      case layout_code.SPOUSE_DETAIL:
      case layout_code.CHILD_DETAIL:
      case layout_code.BEN_SPOUSE_DETAIL:
      case layout_code.BEN_CHILD_DETAIL:
        return VdApplicantDetailsComponent;
      default:
        return CustomStepsFormComponent;
    }
  }

  onRefreshPage(event) {
    if (event) {
      this.ngOnInit();
    }
  }
}
