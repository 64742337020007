import { Component, OnInit, ViewChild, ViewChildren, QueryList, ViewEncapsulation, OnDestroy, AfterContentInit, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { VisaDetails, VisaMeta, TravelHistory } from '../../../../data/models/immigration.model';
import { NgForm, Validators, FormControl } from '@angular/forms';
import { ReferenceLibrary } from '../../../../data/models/referenceLibrary.model';
import { ReferenceLibraryService } from '../../../../services/referenceLibrary.service';
import { ApiServicesService } from '../../services/api-services.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CustomValidationService } from 'src/app/services/custom-validation.service';
import { UtilityServiceService } from '../../services/utility-service.service';
import * as Roles from '../../../../data/constants/roles';
import * as errorUtils from 'src/app/modules/utility/global-utils';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { InfoModalComponent } from 'src/app/modules/shared/modal/info-modal/info-modal.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';

import { UploadDocumentService } from '../../../../services/upload-document.service';
import { FileDownloadComponent } from 'src/app/modules/shared/file-download/file-download.component';
import { NgModel } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DependentProfileDataService } from '../../services/dependent-profile-data.service';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { BeneficiaryQuestionnaireService } from 'src/app/modules/beneficiary-questionnaire/beneficiary-questionnaire.service';
import { CustomQuestionsComponent } from '../../../shared/custom-questions/custom-questions.component';
import { acceptedFileTypes } from 'app-models';
@Component({
  selector: 'beneficiary-immigration',
  templateUrl: './beneficiary-immigration.component.html',
  styleUrls: ['./beneficiary-immigration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class BeneficiaryImmigrationComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() mode :string;
  @Input()  showLifeStoryTimeLineSection: boolean;
  @Input() caseRequestShowDivs: any;
  @Input() benQuestShowDivs: any;
  @Input() isViewMode :boolean;  

  modeVal: boolean = false;
  iscaseRequest: boolean = false;
  currentItemDisplay: any = [];
  showEditButton: boolean = true;
  [x: string]: any;
  visaDetailsForm: NgForm;
  visa: VisaDetails;
  visaMeta: VisaMeta;
  updateBenProfile = true;  // Edit permission
  beneficiaryId = 0;

  petitionTypeList: ReferenceLibrary[];
  visaTypeList: ReferenceLibrary[];
  outcomeTypeList: ReferenceLibrary[];
  visaPetitionTypeList: ReferenceLibrary[];

  neverAppliedFlag: string;

  expandPanel = false;

  immiexpandPanel = false;  // need for expanding on add / Edit button

  isTimeLineView = true;
  isListView = false;
  immigrationData = [];
  hasID = false;
  isLinear = true;
  formerrors = { arivalDateError: false, exitDateError: false, combination: false, errors: [], combinations: false, validityStartDateError: false, validityEndDateError: false };
  visaDateDetails = { startDate: '', expDate: '' };
  today: Date;
  arrivalDate: any;
  exitDate: any;
  validityStartDate: any;
  validityEndDate: any;
  min: Date;
  max: Date;
  validityConfirmationDialog:boolean;
  minVisaValidityEndDate: Date;
  minVisaExpirationDate: Date;
  // showPetitionTypeDropdown: boolean = true;
  isCurrentlyUSChecked = false;
  indicatingMandatoryField = false;
  isDepatureChecked = false;
  listUploadedFiles = [];
  viewMode = false;
  @ViewChild('visaDetailsForm', { static: true }) immvisaDetailsForm: NgForm;
  @ViewChildren(NgModel) immgFields: QueryList<NgModel>;

  /* IM-4320 : Current immigration details overrides the past immigration info */
  private isEditVisaDetails = false;
  destroy$ = new Subject<void>();
  formChangesSubscriptions: any[];

  /* IM-5015 Sevis No */
  isVisaTypeF1 = false;
  isBenQuestShowDivs: boolean = false;


  // Travel History
  isTravelHistoryValid = true;

  travelHistoryForm: NgForm;

  displayedColumnsForTravelInfo: string[] = [];

  columnsTravelInfo = [
    { columnDef: 'meansOfTravel', header: 'Mode Of Travel', columnType: 'text', customClass: '' },
    { columnDef: 'portOfEntry', header: 'Port', columnType: 'text', customClass: '' },
    { columnDef: 'arrivalDate', header: 'Arrival Date', columnType: 'text', customClass: '' },
    { columnDef: 'i94Number', header: 'I94 Number', columnType: 'text', customClass: '' },
    { columnDef: 'i94ExpiryDate', header: 'I94 Expiry Date', columnType: 'text', customClass: '' },
    { columnDef: 'exitDate', header: 'Exit Date', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }
  ];
  travelInfoDataSource: MatTableDataSource<any>;

  travelHistory: TravelHistory = new TravelHistory({
    arrivalDate: '',
    arrivalCountryCode:'',
    arrivalStateProvinceCode:'',
    arrivalStateProvinceName:'',
    arrivalCity: '',
    exitDate: '',
    effectiveStartDate: '',
    effectiveEndDate: '',
    i94ExpiryDate: '',
    i94Number: null,
    durationOfStatus: null,
    id: 0,
    isCurrentlyInUS: false,
    meansOfTravel: '',
    portOfEntry: ''
  });

  // Main Data Grid
  envPath: any;
  expandedElement: null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [];
  columns = [
    { columnDef: 'toggle', header: '', columnType: 'toggleicon', customClass: '' },
    { columnDef: 'immigrationStatusCode', header: 'Visa', columnType: 'text', customClass: '' },
    { columnDef: 'petitionTypeCode', header: 'Petition', columnType: 'text', customClass: '' },
    { columnDef: 'companyName', header: 'Petitioner Name', columnType: 'text', customClass: '' },
    { columnDef: 'visaStatus', header: 'Visa Status', columnType: 'text', customClass: '' },
    { columnDef: 'noticeNo', header: 'Receipt Number', columnType: 'text', customClass: '' },
    { columnDef: 'receiptDate', header: 'Receipt Date', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }
  ];
  dataSource: MatTableDataSource<any>;

  // Documents Upload
  configurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENIMMDOC',
    entityId: 0,
    fileCategory: '',
    listUploadedFiles: [],
    documentTypeRequired: true,
    docummentTypeCode: 'BENIMMDOC',
    useCase: 'beneficiary',
    useCaseId: 0,
    useEntityId: false,
    familyId: null
  };
  observableSubscription$ = new Subject();
  dependentIdSubscription: Subscription;
  familyId: any;
  beneficiaryType: any;
  dependentId: any;
  // showLifeStoryTimeLineSection: boolean;
  listCountry = [];
  listStates = [];
  selectedUser: any;
  customQuestions: any;
  // customTriggeredStatus: boolean = false;
  @ViewChild(CustomQuestionsComponent) customQuestionsComponent;

  isSaveAsDraftDisabled: boolean = true;
  isExternalEmployeeId:string;
  isLocked:boolean=false;
  listArrivalCountry: any[] = [];
  constructor(
    private cacheCountryService:CacheCountryService,
    private changeDetectorRef: ChangeDetectorRef,
    private referenceLibraryService: ReferenceLibraryService,
    private apiService: ApiServicesService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private customValidationService: CustomValidationService,
    private utilityService: UtilityServiceService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private uploadDocumentService: UploadDocumentService,
    protected store: Store<any>,
    private dependentProfileDataService: DependentProfileDataService,
    private benServ: BeneficiaryQuestionnaireService
  ) {
    this.isExternalEmployeeId = sessionStorage.getItem('_isExternalEmployeeId');
    if(this.isExternalEmployeeId){
      this.isLocked=true;
    }
    this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : this.authenticationService.currentUserValue['beneficiaryId'];
    this.resetImmigration();
    this.visaMeta = new VisaMeta({
      alienRegNo: null,
      hasAlienRegnNo: false,
      hasNonImmVisa: null
    });
    const request1 = this.referenceLibraryService.getReferenceDataWithoutAuthentication('PETTYP');
    const request2 = this.referenceLibraryService.getReferenceDataWithoutAuthentication('IMMSTAT');
    const request3 = this.referenceLibraryService.getReferenceDataWithoutAuthentication('VSAOUT');
    forkJoin([request1, request2, request3]).subscribe(([response1, response2, response3]) => {
      this.petitionTypeList = response1 as ReferenceLibrary[];
      this.visaTypeList = response2 as ReferenceLibrary[];
      this.outcomeTypeList = response3 as ReferenceLibrary[];
      this.getVisaDetails();
      this.getVisaMetaInfo();
    });
    this.today = new Date();
    this.formChangesSubscriptions = [];
  }

  ngOnInit() {
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;
    this.authenticationService.currentUser.pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY || sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
        this.updateBenProfile = this.utilityService.checkBeneEditPermission();
        this.viewMode = !this.updateBenProfile;
      }
      this.envPath = environment.docs;
    });

    this.dependentIdSubscription = this.dependentProfileDataService.dependentProfileSubject.subscribe(selectedBeneObj => {
      // console.log("$$$$ dependentId", selectedBeneObj);
      this.familyId = selectedBeneObj.dependentId;
      this.beneficiaryType = selectedBeneObj.beneficiaryType;
      this.showLifeStoryTimeLineSection = (this.beneficiaryType === 'family' && this.familyId) ? true : (this.beneficiaryType === 'self') ? true : false;
      if(this.isBenQuestShowDivs && sessionStorage.getItem('isCaseRequest')){
        if(this.beneficiaryType === 'self'){
          this.iscaseRequest = true;
        } else {
          this.iscaseRequest = false;
        }
       }
      this.getVisaDetails();
      this.getVisaMetaInfo();
      //this.getLicense();
    });
    this.showEditButton = !this.benServ.checkValidity();
    this.dependentProfileDataService.onLifeStorySubTabs.next();

    // this.familyId= sessionStorage.getItem('dependentId') ? parseInt(sessionStorage.getItem('dependentId')):null;

    // IM-3066 - remove deleted file.
    this.apiService.deletedfile$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getUpolodedFiles();
      }
    });
    this.cacheCountryService.getCachedCountryList().subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries;
        this.listArrivalCountry = [{countryCode:'',countryName:'Select',labelWithCode:'Select',phoneCode:''},...this.listCountry];
      }
    });

    this.selectedUser = JSON.parse(localStorage.getItem('currentUserSelection'));
    this.customQuestions = JSON.parse(localStorage.getItem('customQuestions'));
  }

  visaFormCheck(visaDetailsForm: NgForm) {
    this.visaDetailsForm = visaDetailsForm;
    if (!this.formChangesSubscriptions[0]) {
      this.formChangesSubscriptions[0] = visaDetailsForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
        if (visaDetailsForm.dirty) {
          this.store.dispatch(new MarkDirtyFormAction({
            dirty: true,
            message: 'If you leave before saving, all changes made on the \'Visa Details\' section of the \'Beneficiary Immigration\' form will be lost. Do you wish to continue?'
          }));
        }
        else {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false,
            message: ''
          }));
        }
      });
    }
    if (!this.formChangesSubscriptions[1]) {
      this.formChangesSubscriptions[1] = this.visaDetailsForm.valueChanges?.pipe(takeUntil(this.observableSubscription$)).subscribe((formValue)=>{
        this.isSaveAsDraftDisabled = !Object.values(formValue).some((val) => !!val || val === 0);
      });
    }
  }

  trackTravelHisFormChange(travelHistoryForm: NgForm) {
    // window.alert("in ,travelHistoryForm")
    if (!this.formChangesSubscriptions[1]) {
      this.formChangesSubscriptions[1] = travelHistoryForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
        // window.alert("subs ,travelHistoryForm")
        if (travelHistoryForm.dirty) {
          this.store.dispatch(new MarkDirtyFormAction({
            dirty: true,
            message: 'If you leave before saving, all changes made on the \'U.S travel history\' section of the \'Beneficiary Immigration\'form will be lost. Do you wish to continue?'
          }));
        }
        else {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false,
            message: ''
          }));
        }
      });
    }
  }

  trackNonImmigrantVisaForm(nonImmigrantVisForm: NgForm) {
    // window.alert("in ,travelHistoryForm")
    if (!this.formChangesSubscriptions[3]) {
      this.formChangesSubscriptions[3] = nonImmigrantVisForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
        // window.alert("subs ,travelHistoryForm")
        if (nonImmigrantVisForm.dirty) {
          this.store.dispatch(new MarkDirtyFormAction({
            dirty: true,
            message: 'If you leave before saving, all changes made on the \'Non-Immigrant Visa Info\' section of the \'Beneficiary Immigration\'form will be lost. Do you wish to continue?'
          }));
        }
        else {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false,
            message: ''
          }));
        }
      });
    }
  }
  // IM-3066 - remove deleted file.
  getUpolodedFiles() {
    this.uploadDocumentService.genericGetUploadDocument(this.configurations.useCase, this.configurations.useCaseId, this.categoryName, this.configurations.entityId, this.configurations.useEntityId, this.configurations.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response) => {
      if (response['status'] === 200) {
        this.listUploadedFiles = response['data'];
        this.configurations.listUploadedFiles = response['data'];
        this.configurations = Object.assign({}, this.configurations);
      }
      else {
        this.listUploadedFiles = [];
        this.configurations.listUploadedFiles = [];
      }
    });
  }

  visaValidityStartChange() {
    const minVisaEndDate = new Date(this.visa.validityStartDate);
    this.minVisaValidityEndDate = new Date(
      minVisaEndDate.setDate(minVisaEndDate.getDate() + 1)
    );
    if (this.visa.validityStartDate < this.visa.receiptDate) {
      this.validityConfirmationDialog = true;
    }
  }
  
  visaIssueDateChange() {
    const minVisaExpirationDate = new Date(this.visa.visaIssueDate);
    this.minVisaExpirationDate = new Date(
      minVisaExpirationDate.setDate(minVisaExpirationDate.getDate() + 1)
    );
  }
  
  onValidtion(param) {
    if (param) {
      this.validityConfirmationDialog = false;
    } else {
      this.visa.validityStartDate = this.visa.receiptDate;
      this.validityConfirmationDialog = false;
    }
  }
  
  getVisaDetails() {
    this.apiService.getVisaDetails(this.beneficiaryId, this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        // Update visa status from ref library
        ((response.data) as VisaDetails[]).map((item) => {
          item.visaStatus = this.outcomeTypeList.find(x => x.code === item.outcomeIdCode) ? this.outcomeTypeList.find(x => x.code === item.outcomeIdCode).name : '';
          item.receiptDate = item.receiptDate ? moment(item.receiptDate).format('DD-MMM-YYYY') : '';
        });
        this.immigrationData = (response.data as []) ? (response.data as []) : [];
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(response.data as []);
      }
      if (response.status === 204) {
        // Assign the data to the data source for the table to render
        // IM-2570
        this.immigrationData = [];
        this.dataSource = new MatTableDataSource([]);
      }
      this.dataSource.paginator = this.paginator;
      if (!this.isListView && this.route.snapshot.paramMap.get('listview')) {
        this.toggleListView(true);
      }
    });
  }

  getVisaMetaInfo() {
    this.apiService.getVisaMetaInfo(this.beneficiaryId, this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        this.visaMeta.alienRegNo = response.data['alienRegNo'];
        if (response.data['hasAlienRegnNo'] !== null) {
          // Uncheck chkbox if the user dont have alien Reg Num
          this.visaMeta.hasAlienRegnNo = response.data['hasAlienRegnNo'] === 0 ? true : false;
        }
        else {
          this.visaMeta.hasAlienRegnNo = null;
        }
        if (response.data['hasNonImmVisa'] !== null) {
          this.visaMeta.hasNonImmVisa = response.data['hasNonImmVisa'] === 1 ? true : false;
          this.neverAppliedFlag = response.data['hasNonImmVisa'] === 1 ? 'Yes' : 'No';
        }
        else {
          this.visaMeta.hasNonImmVisa = null;
          this.neverAppliedFlag = null;
        }
        this.handleToggle();
      }
    });
  }

  toggleExpansion() {
    this.immiexpandPanel = !this.immiexpandPanel;
    if (this.immiexpandPanel) {
      this.resetImmigration();
      this.hasID = false;
      this.isLinear = true;
    }
  }

  saveVisaDetails(visaDetailsForm, isDraft:boolean = false) {
    const payload: any = Object.assign({}, this.visa);
    payload.beneficiaryId = this.beneficiaryId;
    payload.companyId = 0;
    payload['isSaveAsDraft'] = isDraft;
    /* IM-4320 : Current immigration details overrides the past immigration info */
    if (this.isEditVisaDetails === false) {
      payload.id = 0;
    }

    if (this.isEditVisaDetails === false && this.visa.id) { // IM 4736
      payload.id = this.visa.id;
    }

    payload.isEadNo = this.visa.isEadNo ? 1 : 0;
    payload.isSevisNo = this.visa.isSevisNo ? 1 : 0;
    payload.outcomeDate = this.visa.outcomeDate ? moment(this.visa.outcomeDate).format('YYYY-MM-DD') : null;
    payload.receiptDate = this.visa.receiptDate ? moment(this.visa.receiptDate).format('YYYY-MM-DD') : null;
    payload.validityStartDate = this.visa.validityStartDate ? moment(this.visa.validityStartDate).format('YYYY-MM-DD') : null;
    payload.validityEndDate = this.visa.validityEndDate ? moment(this.visa.validityEndDate).format('YYYY-MM-DD') : null;
    payload.hasTravelToUs = this.visa.hasTravelToUs ? true : false;
    // IM-3831 : Add  'Not Applicable'
    payload.petitionTypeCode = (this.visa.petitionTypeCode == 'null') ? null : this.visa.petitionTypeCode;
    payload.visaStamping = this.visa.visaStamping ? true : false;
    payload.visaIssueDate = this.visa.visaIssueDate ? moment(this.visa.visaIssueDate).format('YYYY-MM-DD') : null;
    payload.visaExpirationDate = this.visa.visaExpirationDate ? moment(this.visa.visaExpirationDate).format('YYYY-MM-DD') : null;
    this.apiService.saveVisaDetailsInfo(this.beneficiaryId, payload, this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      this.toastr.success(response.message);
      this.visa.id = response.data.id || 0; // IM 4736
      visaDetailsForm.reset(visaDetailsForm.value); // fix reset
      this.getVisaDetails(); // Relaod main table
      this.loadVisaDetails(response.data);
      this.configurations.useCaseId = this.beneficiaryId;
      this.configurations.entityId = this.visa ? this.visa.id : null;
      this.configurations.familyId = this.familyId ? this.familyId : null;
      this.configurations.listUploadedFiles = [];
      this.configurations = Object.assign({}, this.configurations);
      this.hasID = true;
      this.isLinear = false;
      // this.isCurrentlyUSChecked = this.visa.currentlyStayInUS ? true : false;
      this.loadTravelInfo(this.travelInfoDataSource.data as []);
    });
  }


  loadVisaDetails(input) {
    input.receiptDate = input.receiptDate ? moment(input.receiptDate).toISOString() : '';
    input.outcomeDate = input.outcomeDate ? moment(input.outcomeDate).toISOString() : '';
    input.validityEndDate = input.validityEndDate ? moment(input.validityEndDate).toISOString() : '';
    input.validityStartDate = input.validityStartDate ? moment(input.validityStartDate).toISOString() : '';
    input.outcomeDate = input.outcomeDate ? moment(input.outcomeDate).toISOString() : '';
    input.visaStamping = input.visaStamping ? input.visaStamping : false;
    input.visaIssueDate = input.visaIssueDate ? moment(input.visaIssueDate).toISOString() : '';
    input.visaExpirationDate = input.visaExpirationDate ? moment(input.visaExpirationDate).toISOString() : '';
    this.visa = input;
    this.visa.isEadNo = input.isEadNo === 1 ? true : false;
    this.visa.isSevisNo = input.isSevisNo === 1 ? true : false;
    this.visaDateDetails.startDate = input.validityStartDate;
    this.visaDateDetails.expDate = input.validityEndDate;
    this.min = new Date(this.visaDateDetails.startDate);
    this.max = new Date(this.visaDateDetails.expDate);

    // this.isCurrentlyUSChecked = this.visa.currentlyStayInUS ? true : false;

    if (this.visa.immigrationStatusCode) {
      const visaType = this.visa.immigrationStatusCode;
      this.handleVisaTypeChange(visaType, this.immvisaDetailsForm);

      // this.showPetitionTypeDropdown = (visaType === 'H4' || visaType === 'F1' || visaType === 'B1' || visaType === 'B2' || visaType === 'L2') ? false : true;
    }
  }


  handleToggle() {
    if (this.neverAppliedFlag !== null) {
      this.expandPanel = this.neverAppliedFlag === 'Yes' ? true : false;
      this.visaMeta.hasNonImmVisa = this.neverAppliedFlag === 'Yes' ? true : false;
    } else {
      this.expandPanel = false;
      this.visaMeta.hasNonImmVisa = null;
    }
  }

  handleToggleHasTravelToUs() {
    if (this.visa.id && !this.visa.hasTravelToUs) {
      const message = 'Changing the answer from \'Yes\' to \'No\' will result in removal of Stay history details, if available. Are you sure you want to proceed with this? ';
      this.confirmationPopup(this.visa, message, 'hasTravelToUs');

    }
  }
  handleToggleArrivalDate(val) {
    if (val) {
      this.travelHistory.arrivalDate = '';
      this.travelHistory.meansOfTravel = '';
      this.travelHistory.portOfEntry = '';
    } else {
      this.travelHistory.effectiveStartDate = '';

    }

  }

  handleToggleDepartureDate(val) {
    if (val) {
      this.travelHistory.effectiveEndDate = '';
    } else {
      this.travelHistory.exitDate = '';


    }

  }

  updateCurrentlyUS() {
    // console.log('this.visa.currentlyStayInUS == ' + this.visa.currentlyStayInUS);
    this.isCurrentlyUSChecked = this.visa.currentlyStayInUS ? true : false;

  }

  eadUpdate() {
    this.visa.eadNo = '';
  }

  sevisUpdate() {
    this.visa.sevisNo = '';
  }

  updateAlienNo() {
    this.visaMeta.alienRegNo = '';
  }

  saveVisaMetaInfo(nonImmigrantVisForm) {
    const payload: any = Object.assign({}, this.visaMeta);
    payload.hasAlienRegnNo = payload.hasAlienRegnNo ? 0 : 1;
    payload.hasNonImmVisa = payload.hasNonImmVisa ? 1 : 0;
    this.apiService.saveVisaMetaInfo(this.beneficiaryId, payload, this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (this.beneficiaryType === 'self') {
        if(this.customQuestionsComponent){
          this.customQuestionsComponent.onClickSave(null);
        }
      } else {
        if(this.customQuestionsComponent){
          this.customQuestionsComponent.onClickSave(this.familyId);
        }
      }
      this.getVisaDetails();
      nonImmigrantVisForm.reset(nonImmigrantVisForm.value);
      this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
      this.toastr.success(response.message);
    });
    // this.customTriggeredStatus == false ? this.customTriggeredStatus = true: this.customTriggeredStatus = false;

  }

  editImmigration(input) {
    /* IM-4320 : Current immigration details overrides the past immigration info */
    this.isEditVisaDetails = true;

    this.immiexpandPanel = true;
    this.expandPanel = true;
    this.resetValidation();
    this.loadVisaDetails(Object.assign({}, input));
    this.loadTravelInfo(Object.assign([], input.travelInfo));
    this.loadDocuments(Object.assign([], input.documentList));
    this.hasID = true;
    this.isLinear = false;
    // IM-4040-need visa validity start date to resctict min of end date on edit
    this.visaValidityStartChange();
  }

  deleteImmigration(input) {
    const title = 'Confirm';
    const message = 'Do you wish to remove this item?';
    const buttons = ['Cancel', 'Delete'];

    this.dialogueInitializer(event, title, message, buttons).afterClosed().subscribe(result => {
      if (result) {
        this.apiService.deleteImmigration(this.beneficiaryId, input.id, this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            if (this.visa.id === input.id) {
              this.resetImmigration();
            }
            this.toastr.success(response.message);
            this.getVisaDetails(); // Relaod main table
          }
        });
      }
    });
  }

  onRefreshPage(event){
    if(event){
      this.ngOnInit();
    }
  }

  confirmationPopup(input, message, field) {
    const title = 'Confirm';

    const buttons = ['No', 'Yes'];
    this.dialogueInitializer(event, title, message, buttons).afterClosed().subscribe(result => {
      if (result) {
        //  IM-3831 : For able to save visa details
        this.immvisaDetailsForm.resetForm();
        this.visa.currentlyStayInUS = false;
        this.resetOutcomeIdField();
      } else {
        //console.log('$$$$ popup conetent else part', result);
        if (field === 'hasTravelToUs') {
          this.visa.hasTravelToUs = true;
        } else {
          this.visa.outcomeIdCode = 'VSAAPP';
        }

      }
    });
  }



  outcomeCodeIdChange() {
    if (this.visa.id && this.visa.outcomeIdCode !== 'VSAAPP') {
      const message = 'Changing the outcome will result in removal of Stay history details, if available. Are you sure you want to proceed with this?';
      this.confirmationPopup(this.visa, message, 'outcomeIdCode');
    } else {
      //  IM-3831 : For able to save visa details
      if (this.visaDetailsForm) {
        this.visaDetailsForm.resetForm({
          companyName: this.visaDetailsForm.value?.companyName,
          immigrationStatusCode: this.visaDetailsForm.value?.immigrationStatusCode,
          petitionTypeCode: this.visaDetailsForm.value?.petitionTypeCode,
          outcome: this.visaDetailsForm.value?.outcome
         });
      }
      this.visa.currentlyStayInUS = false;
      // Refresh the form
      if (this.travelHistoryForm) {
        this.travelHistoryForm.resetForm({});
      }
      this.travelHistory = new TravelHistory({
        arrivalDate: '',
        arrivalCountryCode:'',
        arrivalStateProvinceCode:'',
        arrivalStateProvinceName:'',
        arrivalCity: '',
        exitDate: '',
        effectiveStartDate: '',
        effectiveEndDate: '',
        i94ExpiryDate: '',
        durationOfStatus: '',
        i94Number: null,
        id: 0,
        isCurrentlyInUS: false,
        meansOfTravel: '',
        portOfEntry: ''
      });
      this.resetOutcomeIdField();
    }

  }

  resetOutcomeIdField() {
    this.visa.currentlyStayInUS = false;
    this.visa.hasTravelToUs = false;
    this.visa.receiptDate = '';
    this.visa.noticeNo = '';
    this.visa.isEadNo = false;
    this.visa.eadNo = '';
    this.visa.isSevisNo = false;
    this.visa.sevisNo = '';
    this.visa.outcomeReason = '';
    this.visa.outcomeDate = '';
    this.visa.statusId = 0;
    this.visa.outcomeDate = '';
    this.visa.receiptDate = '';
    this.visa.validityStartDate = '';
    this.visa.validityEndDate = '';
    this.visa.visaStamping = false;
    this.visa.visaIssueDate = '';
    this.visa.visaExpirationDate = '';
  }

  resetImmigration() {
    /* IM-4320 : Current immigration details overrides the past immigration info */
    this.isEditVisaDetails = false;

    this.visa = new VisaDetails({
      id: 0,
      immigrationStatusCode: '',
      currentlyStayInUS: false,
      hasTravelToUs: false,
      receiptDate: '',
      noticeNo: '',
      companyId: null,
      companyName: null,
      isEadNo: false,
      eadNo: '',
      isSevisNo: false,
      sevisNo: '',
      validityEndDate: '',
      validityStartDate: '',
      outcomeIdCode: '',
      outcomeReason: '',
      outcomeDate: '',
      petitionTypeCode: '',
      visaTypeCode: '',
      statusId: 0,
      visaStamping: false,
      visaIssueDate: '',
      visaExpirationDate: ''
    });
    this.travelHistory = new TravelHistory({
      arrivalDate: '',
      arrivalCountryCode:'',
      arrivalStateProvinceCode:'',
      arrivalStateProvinceName:'',
      arrivalCity: '',
      exitDate: '',
      effectiveStartDate: '',
      effectiveEndDate: '',
      i94ExpiryDate: '',
      i94Number: null,
      durationOfStatus: '',
      id: 0,
      isCurrentlyInUS: false,
      meansOfTravel: '',
      portOfEntry: ''
    });
    this.configurations.listUploadedFiles = [];
    this.configurations = Object.assign({}, this.configurations);
    this.hasID = false;
    this.isLinear = true;
  }

  resetAllForms(visaDetailsForm: NgForm, travelHistoryForm: NgForm) {
    this.loadTravelInfo([]);
    this.configurations.listUploadedFiles = [];
    this.configurations.familyId = this.familyId ? this.familyId : null;
    this.configurations = Object.assign({}, this.configurations);
  }

  saveTravelHistory(travelHistoryForm: NgForm) {
    const payload: any = Object.assign({}, this.travelHistory);
    payload.isCurrentlyInUS = false;
    payload.effectiveStartDate = this.travelHistory.effectiveStartDate ? moment(this.travelHistory.effectiveStartDate).format('YYYY-MM-DD') : null;
    payload.arrivalDate = this.travelHistory.arrivalDate ? moment(this.travelHistory.arrivalDate).format('YYYY-MM-DD') : null;
    
    payload.arrivalCountryCode = this.travelHistory.arrivalCountryCode ? this.travelHistory.arrivalCountryCode : null;
    if(this.listStates.length > 0)
    payload.arrivalStateProvinceName = null;
    payload.arrivalStateProvinceCode = this.travelHistory.arrivalStateProvinceCode ? this.travelHistory.arrivalStateProvinceCode : null;
    if(this.listStates.length <= 0 ) {
    payload.arrivalStateProvinceCode = null;
    payload.arrivalStateProvinceName = this.travelHistory.arrivalStateProvinceName ? this.travelHistory.arrivalStateProvinceName : null;
    }
    payload.arrivalCity = this.travelHistory.arrivalCity ? this.travelHistory.arrivalCity : null;
    
    payload.exitDate = this.travelHistory.exitDate ? moment(this.travelHistory.exitDate).format('YYYY-MM-DD') : null;
    payload.effectiveEndDate = this.travelHistory.effectiveEndDate ? moment(this.travelHistory.effectiveEndDate).format('YYYY-MM-DD') : null;
    if(this.isVisaTypeF1 === false) {
      payload.i94ExpiryDate = this.travelHistory.i94ExpiryDate ? moment(this.travelHistory.i94ExpiryDate).format('YYYY-MM-DD') : null;
    } else {
      payload.i94ExpiryDate = null;
    }

    this.apiService.saveTravelHistory(this.beneficiaryId, this.visa.id, payload, this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        travelHistoryForm.reset(travelHistoryForm); // fix reset
        this.toastr.success(response.message);
        // Reload entire immigration
        this.getVisaDetails();
        // Reload travel info table
        this.getImmigrationTravelInfo();
        // Refresh the form
        travelHistoryForm.resetForm({});
        // travelHistoryForm.form.reset();  //I94 Expiry Date field validation remove
        this.travelHistory = new TravelHistory({
          arrivalDate: '',
          arrivalCountryCode: '',
          arrivalStateProvinceCode: '',
          arrivalStateProvinceName:'',
          arrivalCity: '',
          exitDate: '',
          effectiveStartDate: '',
          effectiveEndDate: '',
          i94ExpiryDate: '',
          i94Number: null,
          id: 0,
          isCurrentlyInUS: false,
          meansOfTravel: '',
          portOfEntry: ''
        });
      }
    });
  }

  loadTravelInfo(listTravelInfo: []) {
    this.isTravelHistoryValid = true;
    if (listTravelInfo.length > 0 && this.visa.currentlyStayInUS) {
      if (listTravelInfo.filter((x: TravelHistory) => x.exitDate === null || x.exitDate === '').length === 1) {
        this.isTravelHistoryValid = true;
      }
      else {
        this.isTravelHistoryValid = false;
      }
    }
    this.travelInfoDataSource = new MatTableDataSource(listTravelInfo);
  }

  getImmigrationTravelInfo() {
    this.apiService.getTravelHistory(this.beneficiaryId, this.visa.id, this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        this.loadTravelInfo(response.data as []);
      }
      if (response.status === 204) {
        this.loadTravelInfo([]);
      }
    });
  }

  editTravelInfo(row) {
    this.travelHistory = Object.assign({}, row); 
    this.isDepatureChecked = this.travelHistory.exitDate ? true : false;
    this.isCurrentlyUSChecked = this.travelHistory.effectiveStartDate ? true : false;
    // ISO Stringfy is required for date correction in different timezone
    this.travelHistory.arrivalDate = this.travelHistory.arrivalDate ? moment(this.travelHistory.arrivalDate).toISOString() : null;

    if(this.travelHistory.arrivalCountryCode && this.travelHistory.arrivalCountryCode != null) {
      // this.handleCountryChange(this.travelHistory.arrivalCountryCode, true);
      // console.log(' editTravelInfo this.travelHistory.arrivalCountryCode',this.travelHistory.arrivalCountryCode)
      if(this.travelHistory.arrivalStateProvinceCode && this.travelHistory.arrivalStateProvinceCode != null) {
        this.handleCountryChange(this.travelHistory.arrivalCountryCode,this.travelHistory.arrivalStateProvinceCode, true);
        this.travelHistory.arrivalStateProvinceCode = this.travelHistory.arrivalStateProvinceCode;
      //  this.handleStateChange(this.travelHistory.arrivalStateProvinceCode)
      }

      if(this.travelHistory.arrivalStateProvinceName && this.travelHistory.arrivalStateProvinceName != null) {
      this.listStates.length = 0;
    this.travelHistory.arrivalStateProvinceName = this.travelHistory.arrivalStateProvinceName ? this.travelHistory.arrivalStateProvinceName : null;
    }
      // if(this.travelHistory.arrivalStateProvinceName && this.travelHistory.arrivalStateProvinceName != null) {
      //   this.travelHistory.arrivalStateProvinceName = this.travelHistory.arrivalStateProvinceName
      //  }


      //  this.travelHistory.arrivalCountryCode = this.travelHistory.arrivalCountryCode ? this.travelHistory.arrivalCountryCode : null;
    }
    // this.travelHistory.arrivalCountryCode = this.travelHistory.arrivalCountryCode ? this.travelHistory.arrivalCountryCode : null;
    // if(this.travelHistory.arrivalStateProvinceCode && this.travelHistory.arrivalStateProvinceCode != null) {
    //   this.listStates.length = 1;
      
    // this.travelHistory.arrivalStateProvinceCode = this.travelHistory.arrivalStateProvinceCode ? this.travelHistory.arrivalStateProvinceCode : null;
    // }
    // if(this.travelHistory.arrivalStateProvinceName && this.travelHistory.arrivalStateProvinceName != null) {
    //   this.listStates.length = 0;
    // this.travelHistory.arrivalStateProvinceName = this.travelHistory.arrivalStateProvinceName ? this.travelHistory.arrivalStateProvinceCode : null;
    // }

    
    // this.travelHistory.arrivalStateProvinceCode = this.travelHistory.arrivalStateProvinceCode ? this.travelHistory.arrivalStateProvinceCode : null;
    // this.travelHistory.arrivalStateProvinceName = this.travelHistory.arrivalStateProvinceName ? this.travelHistory.arrivalStateProvinceName : null;
    this.travelHistory.arrivalCity = this.travelHistory.arrivalCity ? this.travelHistory.arrivalCity : null;

    this.travelHistory.exitDate = this.travelHistory.exitDate ? moment(this.travelHistory.exitDate).toISOString() : null;
    this.travelHistory.effectiveStartDate = this.travelHistory.effectiveStartDate ? moment(this.travelHistory.effectiveStartDate).toISOString() : null;
    this.travelHistory.effectiveEndDate = this.travelHistory.effectiveEndDate ? moment(this.travelHistory.effectiveEndDate).toISOString() : null;
    if(this.isVisaTypeF1 === false) {
      this.travelHistory.i94ExpiryDate = this.travelHistory.i94ExpiryDate ? moment(this.travelHistory.i94ExpiryDate).toISOString() : null;
    } else {
      this.travelHistory.i94ExpiryDate = null;
    }
  }

  deleteTravelInfo(row) {
    // visa.id ===> ImmigrationId
    this.apiService.deleteTravelInfo(this.beneficiaryId, this.visa.id, row.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        // Load entire immigration
        this.getVisaDetails();
        // Reload travel info table
        this.getImmigrationTravelInfo();
      }
    });
  }

  ngAfterContentInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
    this.displayedColumnsForTravelInfo = this.columnsTravelInfo.map(c => c.columnDef);
  }

  loadDocuments(documentList: []) {
    this.configurations.useCaseId = this.beneficiaryId;
    this.configurations.entityId = this.visa ? this.visa.id : null;
    this.configurations.familyId = this.familyId ? this.familyId : null;
    this.configurations.listUploadedFiles = documentList;
    this.configurations = Object.assign({}, this.configurations);
  }

  fileUploadSuccess() {
    this.getVisaDetails(); // Relaod main table
  }

  toggleListView(val) {
    if (!val) {
      this.isTimeLineView = true;
      this.isListView = false;
    } else {
      this.isTimeLineView = false;
      this.isListView = true;
    }

  }

  resetValidation() {
    this.formerrors = { arivalDateError: false, exitDateError: false, combination: false, errors: [], combinations: false, validityStartDateError: false, validityEndDateError: false };
    this.visaDateDetails = { startDate: '', expDate: '' };
    this.arrivalDate = '';
    this.exitDate = '';
    this.validityStartDate = '';
    this.validityEndDate = '';
  }

  handleVisaTypeChange(visaType, formName?: NgForm) {
    // formName.form.controls.petitionTypeCode = null;

    /* IM-5015 Sevis No */
    if (visaType === 'F1') {
      this.isVisaTypeF1 = true;
      this.visa.isSevisNo = false;
    } else {
      this.isVisaTypeF1 = false;
    }

    this.visaPetitionTypeList = [];
    if (visaType === 'H1B') {
      this.visaPetitionTypeList = Object.assign([], this.petitionTypeList);
      this.indicatingMandatoryField = true;
    } else {
      this.indicatingMandatoryField = false;
      const obj = {
        code: null,
        desc: null,
        group: null,
        id: null,
        name: 'Not Applicable'
      };
      this.visaPetitionTypeList = Object.assign([], this.petitionTypeList);
      this.visaPetitionTypeList.push(obj);

    }
    // this.showPetitionTypeDropdown = (visaType === 'H4' || visaType === 'F1' || visaType === 'B1' || visaType === 'B2' || visaType === 'L2') ? false : true;
    if (formName) {
      const formFields = {
        addRequiredValidator: [],
        removeAllValidator: []
      };
      switch (visaType) {
        case 'H1B':
          formFields.addRequiredValidator = [formName.form.controls.petitionTypeCode,
          formName.form.controls.noticeNo, formName.form.controls.receiptDate, formName.form.controls.sevisNo];
          formFields.removeAllValidator = [];
          break;
        case 'F1':
          formFields.addRequiredValidator = [formName.form.controls.noticeNo, formName.form.controls.receiptDate,
          formName.form.controls.sevisNo];
          formFields.removeAllValidator = [formName.form.controls.petitionTypeCode];
          break;
        default:
          formFields.addRequiredValidator = [];
          formFields.removeAllValidator = [formName.form.controls.noticeNo,
          formName.form.controls.receiptDate, formName.form.controls.sevisNo, formName.form.controls.petitionTypeCode];
          break;
      }
      this.customValidationService.dynamicRequiredValidator(formName, visaType, formFields);
    }
  }
  resetPetitionTypeDropDown() {
    this.visa.petitionTypeCode = '';
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
  dialogueInitializer(event, title: string, message: string, buttons: any[]) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: buttons
      }
    });
    return dialogRef;
  }
  openInfoModal() {
    const pageName = 'BeneficiaryImmigration';
    this.dialog.open(InfoModalComponent, {
      width: '450px',
      panelClass: 'custom-info-modalbox',
      data: {
        update: { pageName }

      }
    });
  }

  //  IM-3831 : For able to save visa details
  checkPetitionName() {
    if (this.visa.immigrationStatusCode === 'H4' || this.visa.immigrationStatusCode === 'F1' || this.visa.immigrationStatusCode === 'B1' || this.visa.immigrationStatusCode === 'B2' || this.visa.immigrationStatusCode === 'L2') {
      return true;
    } else { return false; }
  }

  // IM-3956/IM-4058 Alien Registration Number*
  checkAlienRegistration() {
    if (this.neverAppliedFlag === 'Yes') {
      return false;
    } else {
      if (this.neverAppliedFlag === null) {
        return true;
      } else {
        if (this.visaMeta.alienRegNo === '' || this.visaMeta.alienRegNo === null) {
          if (this.visaMeta.hasAlienRegnNo === true) { return false; }
          else { return true; }
        } else {
          return false;
        }
      }
    }
  }

  // IM-3933 : Beneficiary Profile- Immigration section- Click on Add/Edit immigration button
  closeForm(f) {
    this.resetForm(f);
    this.toggleExpansion();
    if (this.visaDetailsForm) {
      this.visaDetailsForm.reset({});
    }
    if (this.travelHistoryForm) {
      this.travelHistoryForm.reset({});
    }
    this.toggleExpansion();
    this.resetAllForms(this.visaDetailsForm, this.travelHistoryForm);
    if (this.stepper) {
      this.stepper.reset();
    }
    this.resetImmigration();
  }

  resetForm(visaDetailsForm: NgForm) {
    visaDetailsForm.resetForm();
    this.isLinear = true;
  }

  markAsUntouched() {
    this.immgFields.forEach(model => {
      if (model.name === 'i94ExpiryDate') {
        model.control.markAsUntouched();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.mode.currentValue) {
      if(changes.mode.currentValue === 'list') {
        this.modeVal = true;
      }
    }
    if((changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue) || (changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue)){
      // this.visaDetailsForm = this.fb.group({
      //   id: [],        
      // });
      if(changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue){
        this.iscaseRequest = true;
        changes.caseRequestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }
      if(changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue){
        this.isBenQuestShowDivs = true;
        changes.benQuestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }
    } else {
      this.iscaseRequest = false;
    }
  }

  ngAfterViewInit(): void {
    if(this.modeVal) {
      this.showLifeStoryTimeLineSection = true;
      this.toggleListView(true);
      this.toggleExpansion();
    }
  }

  ngOnDestroy() {
    if (this.formChangesSubscriptions) {
      this.formChangesSubscriptions.forEach(subscription => subscription.unsubscribe());
    }
    this.destroy$.next();
    this.destroy$.complete();
    if (this.dependentIdSubscription) {
      this.dependentIdSubscription.unsubscribe();
    }
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }

  handleCountryChange(countryCode: string, sc?:string, editMode?: boolean) {
     this.travelHistory.arrivalCountryCode = countryCode;
      this.apiService.getStates(countryCode).subscribe((states: []) => {
        this.listStates = states;
        if(editMode) {
          this.travelHistory.arrivalStateProvinceCode = sc;
        }
        this.changeDetectorRef.markForCheck();
      });
    }

    handleStateChange(stateCode:string){
      this.travelHistory.arrivalStateProvinceCode = stateCode;
    }
  resetTravalHistoryForm(){
    if(this.travelHistoryForm){
      this.travelHistoryForm.reset();
    }
  }

  saveAsDraft(event){
    this.saveVisaDetails(this.visaDetailsForm, event);
  }
}
