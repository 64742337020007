export class RequestData {
    constructor(obj: Partial<RequestData>) {
        Object.assign(this, obj);   
    }
    companyId?: number;
    companyName?: string;
    taskType?:string;
    taskTypeRefId?:string;
    visaType?: string;
    petitionType?: string;
    immCategoryType?: string;
    immClassificationType?:string;
    superTaskTypeId?:string;
    taskTypeId?: number;
    visaId?:number; 
    beneficiaryId?:number; 
    userId?:number; 
    version?: any;
}
