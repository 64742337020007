<div class="row m-4 pb-4 border-bottom-dashed">
    <div class="col-md-12">
    <div>
        <div>
           <h5 class="rowHeading py-3">I-9 Form Details <span class="subHeading">( Update or Enter data fetched from your profile )</span>
               <!-- <p-button class="btn-accordion" title="Edit" (onClick)="Edit()"><i class="fa fa-pencil">Edit</i></p-button>-->
           </h5>   
        </div>
    </div>
<div>
     <h6 class="rowHeading pb-3">Select <span>One</span> of the below options</h6>
 </div> 
 <form [formGroup]="i9WorkAuthPreparerForm">
 <div class="row ">
    
    <div class="col-md-5 pl-0">
        <div style="width: 100%;">
                <mat-radio-group required (change)="handleToggle('sectionSwitch')"
                aria-label="Select an option" name="switchSection" formControlName="workAuthorizationType">
                <mat-radio-button [disabled] = "!isEdit"
                    (change)="resetParent($event);"
                  class="d-block mt-3"
                  *ngFor="let option of workAuthTypes"
                  [value]="option.code"
                >
                  {{ option.desc }}
                </mat-radio-button>
              </mat-radio-group>
                  
            </div> 
   </div>

 <div class="col-md-7" style="border-left:1px dashed #e4e4e4">
<div class="row ml-3" *ngIf="showSection == 'CITZN' ">
     <div class="w-100">
         <b class="mb-3 d-inline-block">Select documents related to your United States Citizenship</b>
         
         <p-table [ngClass]="{'table-disabled': !isEdit === true }"
         [value]="uploadedDocumentList" dataKey="code" selectionMode="single" [(selection)]="selectedAuthorizationProof" >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th class="col-5 pl-3">Document Type</th>
                    <th  class="col-5">Document Name</th>
                    <th class="col-2 pl-4 text-center">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-doc>
                <tr *ngIf="doc.group === 'CITZN'" (click)="isEdit ? handleRowSelect(doc,$event) : ''" [pSelectableRowDisabled]='!isEdit' [pSelectableRow]="doc" >
                        <td>
                            <p-tableRadioButton [disabled] = "!isEdit" [value]="doc"></p-tableRadioButton>
                        </td>
                        <td>
                            {{ doc.desc }} 
                        </td>
                    <td>
                        <label *ngIf="doc.exist">
                            {{doc.data.fileName}}.{{doc.data.fileType}}
                        </label>
                    </td>
                    <td class="px-0">
                        <div *ngIf="doc.exist" class="border-left pl-1">
                            <button pButton type="button" [disabled] = "!isEdit"
                            (click)="viewDocument(doc.data.fileLocation)"
                            class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                <mat-icon class="mr-1 icn" matTooltip="View Document"
                                class="material-icons">visibility</mat-icon>
                            </button>
                            <button pButton type="button" [disabled] = "!isEdit"
                                (click)="confirmDelete(doc.data.id)"
                                class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                <mat-icon class="mr-1 icn" matTooltip="Delete Document"
                                class="material-icons">delete</mat-icon>
                                </button>
                        </div>
                                <div *ngIf="!doc.exist">
                            <p-fileUpload  [disabled] = "!isEdit" #createUploader name="demo[]" mode="basic" chooseLabel="Upload" [auto]="true"
                                customUpload="true" (uploadHandler)="onUpload($event, createUploader, doc)"
                                [accept]="acceptedTypes" showUploadButton="false" showCancelButton="false"
                                maxFileSize="10485760">
                            </p-fileUpload>
                        </div>
                    </td>
                </tr>
                </ng-template>
                </p-table>
     </div> 
 </div> 
 
<div class="row ml-3" *ngIf="showSection == 'NONCITZNNATIONAL' ">
    <div class="w-100">
        <b class="mb-3 d-inline-block">Select documents related to non-citizen national</b>

        <p-table [ngClass]="{'table-disabled': !isEdit === true }" [value]="uploadedDocumentList" dataKey="code" selectionMode="single" [(selection)]="selectedAuthorizationProof" >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th class="col-5 pl-3">Document Type</th>
                    <th  class="col-5">Document Name</th>
                    <th class="col-2 pl-4 text-center">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-doc>
                <tr *ngIf="doc.group === 'NONCITZNNATIONAL'" (click)="isEdit ? handleRowSelect(doc,$event) : ''" [pSelectableRowDisabled]='!isEdit' [pSelectableRow]="doc">
                        <td>
                            <p-tableRadioButton [disabled] = "!isEdit" [value]="doc"></p-tableRadioButton>
                        </td>
                        <td>
                            {{ doc.desc }} 
                            <mat-icon *ngIf="doc.code === 'FORMI766'"
                                class="mr-1 align-bottom"
                                matTooltip="Foreign Passport that contains a temporary I-551 stamp or temporary I-551 primted notation on a machine readable immigrant visa">
                                info
                            </mat-icon>
                        </td>
                    <td>
                        <label *ngIf="doc.exist">
                            {{doc.data.fileName}}.{{doc.data.fileType}}
                        </label>
                    </td>
                    <td class="px-0">
                        <div *ngIf="doc.exist" class="border-left pl-1">
                            <button pButton type="button" [disabled] = "!isEdit"
                            (click)="viewDocument(doc.data.fileLocation)"
                            class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                <mat-icon class="mr-1 icn" matTooltip="View Document"
                                class="material-icons">visibility</mat-icon>
                            </button>
                            <button pButton type="button" [disabled] = "!isEdit"
                                (click)="confirmDelete(doc.data.id)"
                                class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                <mat-icon class="mr-1 icn" matTooltip="Delete Document"
                                class="material-icons">delete</mat-icon>
                                </button>
                        </div>
                                <div *ngIf="!doc.exist">
                            <p-fileUpload  [disabled] = "!isEdit" #createUploader name="demo[]" mode="basic" chooseLabel="Upload" [auto]="true"
                                customUpload="true" (uploadHandler)="onUpload($event, createUploader, doc)"
                                [accept]="acceptedTypes" showUploadButton="false" showCancelButton="false"
                                maxFileSize="10485760">
                            </p-fileUpload>
                        </div>
                    </td>
                </tr>
                </ng-template>
         </p-table>
    </div> 
</div> 


<div class="col-md-12" *ngIf="showSection == 'PERMRESIDENT' ">
    <b class="mb-3 d-inline-block">Details related to your permanent residentship</b>
    <div class="row pl-0 pr-0 mb-1">
        <div class="col-12 px-0">
       <mat-radio-group required 
       aria-label="Select an option" name="permResident" formControlName="workAuthorizationProof">
           <mat-radio-button (change)="resetValue($event);" [disabled] = "!isEdit"
           [disabled] = "!isEdit"
           (click)="$event.stopPropagation()"
           class="d-block mt-3"
           *ngFor="let option of permDocumentTypes"
           [value]="option.code">
               <div *ngIf="option.code == 'ALIENREGNUM'">
                    <div class="d-block">
                        <label class="mb-0 d-block">{{option.desc}}</label>
                        <input *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'ALIENREGNUM'" type="text" placeholder="Enter Registration Number" formControlName="idNumber"> 
                        <input *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'ALIENREGNUM'" type="text" placeholder="Enter Registration Number" formControlName="newVal">
                        <span class="invalid-message" *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'ALIENREGNUM' && i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                            {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                    </div>
               </div>

               <div *ngIf="option.code == 'USCISNUM'">
                    <div class="d-block">
                        <label class="mb-0 d-block">{{option.desc}}</label>
                        <input *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'USCISNUM'" type="text" placeholder="Enter USCIS Number" formControlName="idNumber"> 
                        <input *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'USCISNUM'" type="text" placeholder="Enter USCIS Number" formControlName="newVal"> 
                        <span class="invalid-message" *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'USCISNUM' && i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                            {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                    </div>
                </div>    
           </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="col-12 px-0">
        <span class="invalid-message" *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').errors?.required">
            {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
    </div>
</div>
   </div> 
   <div class="col-md-12" *ngIf="showSection == 'WORKAUTHALIEN' ">
       <b class="mb-3 d-inline-block">Details related to authorisation to work until expiration</b>
       <div class="row pl-0 pr-0 mb-1">
         <div class="col-md-12 pl-0">
           <label>Expiration Date</label> 
           <p-checkbox [disabled] = "!isEdit" #chkEnable name="alienExpiryCheckbox" binary="true" inputId="alienExpiryCheckbox" 
           formControlName="isExpirationDtNa" class="align-middle ml-4 mr-1"></p-checkbox>
           <label for="alienExpiryCheckbox">Not Applicable</label>
        </div>
        <div class="row">
            <p-calendar  [disabled] = "!isEdit || chkEnable.checked" formControlName="expirationDate"
             [showIcon]="true" dateFormat="dd-mm-yy"
                inputId="expiryPick1" [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2100"></p-calendar>
            </div>
       </div>
       <div class="row pl-0 pr-0 mb-1">
           <div class="col-12 px-0">
                <mat-radio-group required class="row"
                aria-label="Select an option" name="alientSection" formControlName="workAuthorizationProof">
                    <mat-radio-button [disabled] = "!isEdit"
                    (change)="resetValue($event)" 
                    (click)="$event.stopPropagation()"
                    class="d-block mt-3 col-6 px-0 line-height-inherit"
                    *ngFor="let option of alienDocumentTypes"
                    [value]="option.code" [ngClass]="{'uscis-number-outer': option.code == 'USCISNUM'}">
                        <div *ngIf="option.code == 'ALIENREGNUM'">
                            <div class="d-block">
                                <label class="mb-0 d-block">{{option.desc}}</label>
                                <input *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'ALIENREGNUM'" type="text" placeholder="Enter Registration Number" formControlName="idNumber"> 
                                <input *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'ALIENREGNUM'" type="text" placeholder="Enter Registration Number" formControlName="newVal">
                                <span class="invalid-message" *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'ALIENREGNUM' && i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                </span>
                            </div>
                        </div>

                        <div *ngIf="option.code == 'USCISNUM'">
                            <div class="d-block">
                                <label class="mb-0 d-block">{{option.desc}}</label>
                                <input *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'USCISNUM'" type="text" placeholder="Enter USCIS Number" formControlName="idNumber">
                                <input *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'USCISNUM'" type="text" placeholder="Enter USCIS Number" formControlName="newVal"> 
                                <span class="invalid-message" *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'USCISNUM' && i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                </span>
                            </div>
                        </div>

                        <div *ngIf="option.code == 'I94NUM'">
                            <div class="d-block">
                                <label class="mb-0 d-block">{{option.desc}}</label>
                                <input *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'I94NUM'" type="text" placeholder="Enter Admission Number" formControlName="idNumber">
                                <input *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'I94NUM'" type="text" placeholder="Enter Admission Number" formControlName="newVal"> 
                                <span class="invalid-message" *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'I94NUM' && i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                </span>
                            </div>
                        </div>

                        <div *ngIf="option.code == 'FORGNPASSPORTNUM'">
                            <div class="d-block">
                                <label class="mb-0 d-block">{{option.desc}}</label>
                                <div *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'FORGNPASSPORTNUM'" class="row">
                                    <div class="col-6 px-0 pr-2">
                                        <p-dropdown [options]="listCountry" 
                                        name="passportIssueCountry" formControlName="countryCode" optionLabel="countryName" optionValue="countryCode" placeholder="Country"></p-dropdown>
                                        <!-- <select class="form-control ml-2" name="passportIssueCountry"
                                            formControlName="countryCode">
                                                <option *ngFor="let code of listCountry" [value]="code.countryCode">
                                                    {{code.countryName}}</option>
                                        </select> -->
                                        <span class="invalid-message" *ngIf="i9WorkAuthPreparerForm.get('countryCode').errors?.required">
                                            {{ getGlobalErrorMessages("REQUIRED") }}
                                        </span>
                                    </div>
                                    <div class="col-6 px-0">
                                        <input type="text" placeholder="Enter Passport Number" formControlName="idNumber"> 
                                        <span class="invalid-message" *ngIf=" i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                                            {{ getGlobalErrorMessages("REQUIRED") }}
                                        </span>
                                    </div>
                                </div>
                                <div *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'FORGNPASSPORTNUM'" class="row">
                                    <div class="col-6 px-0 pr-2">
                                        <p-dropdown [options]="listCountry" 
                                        name="passportIssueCountry" formControlName="newCountryVal" optionLabel="countryName" optionValue="countryCode" placeholder="Country"></p-dropdown>
                                    </div>
                                    <div class="col-6 px-0">
                                        <input class="w-100"  type="text" placeholder="Enter Passport Number" formControlName="newVal">
                                    </div>
                                    <!-- <input class="w-50"  type="text" placeholder="Enter Passport Number" formControlName="newVal"> 
                                    <select class="form-control w-50 d-inline ml-2" name="passportIssueCountry" formControlName="newCountryVal">
                                            <option *ngFor="let code of listCountry" [value]="code.countryCode">
                                                {{code.countryName}}</option>
                                    </select> -->
                                </div>
                            </div>
                        </div>
                    </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-12 px-0">
            <span class="invalid-message" *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').errors?.required">
                {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
        </div>
       </div>
   </div>   
 </div> 

</div>
</form>
</div>
</div>

   <p-confirmDialog header="Delete"></p-confirmDialog>
