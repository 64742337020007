import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DialogService } from 'primeng/dynamicdialog';
import { getStepDetails, updateStepStatus, updateStepStatusToInitialState, getStepStatusUpdate, loadSubTaskDetails, getVisaTypeCode } from 'visa-store';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { map } from 'rxjs/internal/operators/map';
import { PrimaryData, ReferenceLibrary, ImagilityBaseResponse, TaskStep, SubTaskDetails, WorkExperienceDetailsResponse } from 'app-models';
import { ReferenceLibraryService } from 'reference-library';
import { CacheCountryListService } from 'cache-country-list';
import { DirtyFormGuard, MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import * as errorUtils from 'error-message-utility';
import { environment } from 'environment-configurations';
import { combineLatest } from 'rxjs';
import { EmploymentHistoryService } from './employment-history.service';
import { Router } from '@angular/router';
import * as RolesRouteMap from 'app-models';
import { BENEFICIARY } from 'app-models';

export const DeleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { 'z-index': '1001' }
};

export const NoteDialogConfigurations = {
  width: '40%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { 'z-index': '1001' },
  data: {}
};

@Component({
  selector: 'app-employment-history',
  templateUrl: './employment-history.component.html',
  styleUrls: ['./employment-history.component.scss']
})
export class EmploymentHistoryComponent implements OnInit, OnDestroy, OnChanges {

  private _primaryData: any;

  @Input() set primaryData(val: any) {
    this._primaryData = val;
    if(!!val) {
      this.isI130 = this._primaryData.caseType == 'I130' ? true : false;
    }
  }

  get primaryData() {
    return this._primaryData;
  }

  @Input() type: string;
  disabledControls: boolean;
  experienceCols: any[];
  experienceSubCols: any[];
  stepDetails: TaskStep;
  gcWorkExperienceDetailsResponseList: WorkExperienceDetailsResponse[];
  observableSubscription$ = new Subject();
  userType: string;
  @Input() userId: number;
  experienceTypeFamilyOrSelf: string;
  dependentFamilyId: number = null;
  questionnaire :boolean;
  notifyDestinationUser: any;
  isEditWorkExperience: boolean = false;
  isI130: boolean = false;

  constructor(
    public store: Store<any>,
    public apiService: EmploymentHistoryService,
    public toastr: ToastrService,
    public stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    public dialogService: DialogService,
    public guard: DirtyFormGuard,
    public cacheCountyService: CacheCountryListService,
    public referenceLibraryService: ReferenceLibraryService,
    public router: Router
  ) {
    this.questionnaire = false;
    this.userType = sessionStorage.getItem('userTypeRole');
    this.disabledControls = false;
    this.gcWorkExperienceDetailsResponseList = [];
    this.questionnaire = window.location.pathname === "/beneficiary-profile/profile" ?  true : false;
    this.notifyDestinationUser = BENEFICIARY;
  }

  ngOnInit(): void {
    this.experienceCols = [
      { field: 'designation', header: 'Job Title' },
      { field: 'companyName', header: 'Employer' },
      { field: 'startDate', header: 'Employed from' },
      { field: 'endDate', header: 'Employed to' },
      { field: 'action', header: 'Action' }
    ];
    this.experienceSubCols = [
      { field: 'addressLine1', header: 'Address 1' },
      { field: 'addressLine2', header: 'Address 2' },
      { field: 'countryName', header: 'Country' },
      { field: 'stateProvinceName', header: 'State' },
      { field: 'city', header: 'City' },
      { field: 'zipCode', header: 'ZIP / Postal Code' },
    ];
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.stepDetails = data;
        this.toggleControlsStatus();
      });
  }

  ngOnChanges() {
    if (this.primaryData && this.userId) {
      this.experienceTypeFamilyOrSelf = this.primaryData.familyId === this.userId ? 'fexperience' : 'experience';
      this.dependentFamilyId = this.primaryData.familyId === this.userId ? this.primaryData.familyId : this.type === 'family' ? this.userId : null;
      this.getExperienceList();
    }
    if (this.type && this.type === 'family') {
      this.primaryData.familyId = this.primaryData.familyId || this.userId;
    }
  }

  getExperienceList() {
    this.apiService.getBeneExperienceInformation(
      this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId,
      this.experienceTypeFamilyOrSelf, this.primaryData.superTaskId,
      this.primaryData.subTaskId, this.dependentFamilyId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data) => {        
        this.gcWorkExperienceDetailsResponseList = data ? data : [];
      });
  }


  handleAllExperienceSync() {
    this.apiService.syncBeneWorkExperienceAll(
      this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId,
      this.experienceTypeFamilyOrSelf, this.primaryData.superTaskId,
      this.primaryData.subTaskId, this.dependentFamilyId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response) => {
        if (response) {
          this.toastr.success(response.message);
          this.getExperienceList();
        }
      });
  }

  handleExperienceSync(input) {
    this.apiService.syncBeneWorkExperience(
      this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId,
      input.id, this.primaryData.superTaskId, this.primaryData.subTaskId,
      this.experienceTypeFamilyOrSelf, this.dependentFamilyId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response) => {
        if (response) {
          this.toastr.success(response.message);
          this.getExperienceList();
        }
      });
  }

  deleteExperienceList(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: boolean) => {
        if (response) {
          this.apiService.deletBeneWorkExperience(
            this.primaryData.visatype, this.primaryData.caseType,
            this.primaryData.caseId, this.experienceTypeFamilyOrSelf,
            this.primaryData.superTaskId, this.primaryData.subTaskId, input.id)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((deleteResponse: ImagilityBaseResponse) => {
              this.toastr.success(deleteResponse.message);
              this.getExperienceList();
            });
        }
      });
  }

  dutiesDelete(item, rowData: WorkExperienceDetailsResponse) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: boolean) => {
        if (response) {
          this.apiService.deletBeneWorkExperienceDuties(
            this.primaryData.visatype, this.primaryData.caseType,
            this.primaryData.caseId, this.experienceTypeFamilyOrSelf,
            this.primaryData.superTaskId, this.primaryData.subTaskId, rowData.id,
            item.dutyId)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((deleteResponse: ImagilityBaseResponse) => {
              this.toastr.success(deleteResponse.message);
              this.getExperienceList();
            });
        }
      });
  }

  subDutiesDelete(input, rowData: WorkExperienceDetailsResponse) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.apiService.deletBeneWorkExperienceDuties(
          this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId,
          this.experienceTypeFamilyOrSelf, this.primaryData.superTaskId,
          this.primaryData.subTaskId, rowData.id, input.subDutyId)
          .pipe(takeUntil(this.observableSubscription$))
          .subscribe((deleteResponse: ImagilityBaseResponse) => {
            this.toastr.success(deleteResponse.message);
            this.getExperienceList();
          });
      }
    });
  }

  // handleToolsDelete(input) {
  //   const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
  //   deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
  //     if (response) {
  //       this.apiService.deletBeneWorkExperienceTools(
  //         this.primaryData.superTaskId, this.primaryData.subTaskId, input.id)
  //         .pipe(takeUntil(this.observableSubscription$))
  //         .subscribe((deleteResponse: ImagilityBaseResponse) => {
  //           this.toastr.success(deleteResponse.message);
  //           this.getExperienceList();
  //         });
  //     }
  //   });
  // }

  deleteDocuments(subexperiencelist, item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: boolean) => {
        if (response) {
          this.apiService.deleteBeneWorkExperienceDocuments(
            this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId, item.id)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((deleteResponse: ImagilityBaseResponse) => {
              this.toastr.success(deleteResponse.message);
              this.getExperienceList();
            });
        }
      });
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls =
      this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
  }

  updateExperience() {
    this.isEditWorkExperience = !this.isEditWorkExperience;
    if(!this.isEditWorkExperience){
      this.getExperienceList();
    }
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

  sync() {
    this.apiService.syncI130(this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId,
      this.experienceTypeFamilyOrSelf, this.primaryData.superTaskId,
      this.primaryData.subTaskId, this.dependentFamilyId).subscribe((response: any) => {
      if(response.status === 200) {
        this.toastr.success(response.message);
        this.getExperienceList();
      }
    });
  }

  merge() {
    this.apiService.mergeI130(
      this.primaryData.visaType || this.primaryData.visatype,
      this.primaryData.caseId,
      this.primaryData.caseType,
      this.primaryData.superTaskId,
      this.primaryData.taskId,
      this.primaryData.familyId,
      this.experienceTypeFamilyOrSelf)
    .subscribe((response: any) => {
      if(response.status === 200) {
        this.toastr.success(response.message);
        this.getExperienceList();
      }
    });
  }

  reset() {    
    this.apiService.resetI130(
      this.primaryData.visaType || this.primaryData.visatype,
      this.primaryData.caseId,
      this.primaryData.caseType,
      this.primaryData.superTaskId,
      this.primaryData.taskId,
      this.primaryData.familyId,
      this.experienceTypeFamilyOrSelf)
    .subscribe((response: any) => {
      if(response.status === 200) {
        this.toastr.success(response.message);
        this.getExperienceList();
      }
    });
  }
  
}

