import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.scss']
})
export class InternalServerErrorComponent implements OnInit {
  url :string;
  constructor( private router: Router) {
    this.url = "";
   }

  ngOnInit() {
    this.router.events.subscribe(( data ) => {
      if(event instanceof NavigationEnd){
       // data.error.Error
        this.url = event.url
      }
    });
  }
  
  redirectToTheUrl(){
    this.router.navigate([this.url]);
  }
}//class end