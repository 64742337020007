import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormControl} from '@angular/forms';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-default-company-users',
  templateUrl: './add-default-company-users.component.html',
  styleUrls: ['./add-default-company-users.component.scss']
})
export class AddDefaultCompanyUsersComponent implements OnInit, OnDestroy {
  @Input() companyType: string;
  @Input() parentForm: FormGroup;
  @Input() maxUsersLimit: any = 1;
  creatingUserForm: FormGroup;
  destroy$ = new Subject<void>();
  disableFields: boolean = false;
  listCountry: [];
  confirmPwdValidity:boolean = false;
  confirm_password_hide: boolean = false;
  new_password_hide: boolean = false;
  userDetails:any[] = [];
  editIndex: number;
  constructor(
    public dialogRef: MatDialogRef<AddDefaultCompanyUsersComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private cacheCountryService: CacheCountryService,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.parentForm.addControl('userList', new FormControl([]));
    this.creatingUserForm = this.formBuilder.group({
      id:[0],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      middleName: [''],
      email: ['', Validators.required],
      countryCode:['', Validators.required],
      phoneNumber: ['', Validators.required],
      designation: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      isSendInvite:[true]
    });

    this.cacheCountryService.getCountry().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.length > 0) {
        this.listCountry = data.map(x => {
          return {
            ...x,
            countryPhoneCode: `${x.countryCode} - ${x.phoneCode}`
          };
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxUsersLimit.currentValue && parseInt(changes.maxUsersLimit.currentValue) <= this.userDetails.length) {
      this.creatingUserForm?.disable();
      this.disableFields = true;
    }else{
      this.creatingUserForm?.enable();
      this.disableFields = false;
    }
  }

  validateInput(type, newpwd):boolean{
    let regex:RegExp;
    if(type == 'totalCount'){
      regex = /.{8,}$/;
    }
    else if(type == 'aA3'){
      regex = /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\S]{3,}$/g
    }
    else if(type == 'number'){
      regex = /^(?=.*\d)/g;
    }
    else if(type == 'specChar'){
      regex = /^(?=.*\W)(?!.*\s)/g;
    }

    //this.setScore(newpwd.value);

    let str = newpwd.value;
    let res = str.match(regex);
    if(res){
      return true;
    }
    else {
      return false;
    }   
  }

  confirmPasswordMatch() {
    if (this.creatingUserForm.value.password === this.creatingUserForm.value.confirmPassword) {
       this.confirmPwdValidity = true;
    }else {
       this.confirmPwdValidity = false;
    }
  }

  addUser(){
    //check if user already in the list
    const userExists = this.userDetails.find((u)=> u.email === this.creatingUserForm.value.email);
    if(userExists && this.editIndex === undefined){
      this.toastr.error('User already exist');
    }else{
      if(this.userDetails.length && this.editIndex !== undefined){
        this.userDetails.splice(this.editIndex , 0, this.creatingUserForm.value);
        this.editIndex = undefined;
        //this.toastr.success('User Updated successfully');
      }else{
        this.userDetails.push(this.creatingUserForm.value);
      }
      this.creatingUserForm.reset();
    }
    this.parentForm.patchValue({userList: this.userDetails});
    if(parseInt(this.maxUsersLimit) === this.userDetails.length){
      this.disableFields = true;
      this.creatingUserForm.disable();
    }
  }

  editUser(data: any){
    this.creatingUserForm.enable();
    this.disableFields = false;
    this.creatingUserForm.patchValue({
      id: 0,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,  
      email: data.email,
      designation: data?.designation,
      countryCode: data.countryCode,
      phoneNumber: data.phoneNumber,
      password: data.password,
      confirmPassword: data.password,
    });
    this.confirmPwdValidity = true;
    this.editIndex = this.userDetails.indexOf(data);
    this.userDetails.splice(this.editIndex, 1);
  }

  confirmDelete(inputDataUser){
    const title = 'Confirm';
    const message = 'Do you wish to remove this item?';
    const buttons = ['Cancel', 'Delete'];
    this.dialogueInitializer(event, title, message, buttons).afterClosed().subscribe(result => {
      if (result) {
        //this.toastr.success('User deleted successfully');
        this.disableFields = false;
        this.creatingUserForm.enable();
        let index = this.userDetails.indexOf(inputDataUser);
        this.userDetails.splice(index, 1);
        this.parentForm.patchValue({userList: this.userDetails});
      }
    });

  }

  dialogueInitializer(event, title: string, message: string, buttons: any[]) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: buttons
      }
    });
    return dialogRef;
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
