import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormDesignerService {
  private dashboardLinkEnabled: boolean = false;
  redirectId: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  //redirectId: string | null;
  activateMenuAndOpenPage: Subject<number> = new Subject<number>();

  constructor(private http: HttpClient) { }

  removePortFromUrl(url: string): string {
    const urlObject = new URL(url);
    urlObject.port = '';
    return urlObject.toString();
  }

  onFormPublish(pageFormId) {
    return this.http.post(`/vd/formBuilder/pageForm/publish?pageFormId=${pageFormId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      })
    );
  }

  getPageFormById(pageBuilderId) {
    // return this.http.get(`/vd/formBuilder/${pageBuilderId}/createdPage`).pipe(
    return this.http.get(`/vd/formBuilder/${pageBuilderId}/createdPageForm`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }

  getCustomHeaders(pageBuilderId) {
    return this.http.get(`/vd/formBuilder/fetch/customHeaders/${pageBuilderId}`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }

  getBuildFormatTypeList(buildFormatId) {
    return this.http.get(`/vd/formBuilder/buildFormat/${buildFormatId}/buildFormatTypelist`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }

  setRedirectId(redirectId: string | null): void {
    this.redirectId.next(redirectId);
  }
  getRedirectId(): Observable<string | null> {
    return this.redirectId.asObservable();
  }
  clearRedirectId(): void {
    this.setRedirectId(null);
  }

  setDashboardLink(value: boolean): void {
    this.dashboardLinkEnabled = value;
  }

  getDashboardLink(): boolean {
    return this.dashboardLinkEnabled;
  }

}
