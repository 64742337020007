<div [formGroup]="parentForm">
    <div formGroupName="countryDetails">
        <div class="form-row">
            <div class="form-group col-md-4">
                <mat-form-field>
                    <mat-label>Country</mat-label>                    
                    <select class="im-input" formControlName="country"
                        required (change)="handleCountryChange($event.value)">
                        <option *ngFor="let item of listCountry" [value]="item.countryCode">{{item.countryName}}
                        </option>
                    </select>
                </mat-form-field>
            </div>
            <div class="form-group col-md-4" *ngIf="listStates.length>0">
                <mat-form-field>
                    <mat-label>State</mat-label>
                    <mat-select class="im-input" typeaheadDebounceInterval="300" formControlName="stateProvinceCode">
                        <mat-option *ngFor="let item of listStates" [value]="item.stateProvinceCode">
                            {{item.stateProvinceName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group col-md-4" *ngIf="listStates.length <=0">
                <label>State</label>
                <input class="im-input mb-4" formControlName="stateProvinceName" type="text" placeholder="State" />
            </div>
            <div class="form-group col-md-4">
                <label>City</label>
                <input
                    [ngClass]="{ 'is-invalid': parentForm.get('countryDetails').get('city').errors && (parentForm.get('countryDetails').get('city').touched || parentForm.get('countryDetails').get('city').dirty)}"
                    class="im-input mb-4" formControlName="city" type="text" placeholder="City" />
                <span *ngIf="parentForm.get('countryDetails').get('city').errors && parentForm.get('countryDetails').get('city').errors.required"
                    class="invalid-feedback">City Name is required</span>
            </div>
        </div>
    </div>
</div>