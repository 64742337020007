import { Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Dimensions, CropperPosition,ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';


@Component({
  selector: 'app-profile-picture-upload',
  templateUrl: './profile-picture-upload.component.html',
  styleUrls: ['./profile-picture-upload.component.scss']
})
export class ProfilePictureUploadComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  showCropperSection: boolean = true;
  showFileUploadSection: boolean = true;
  zoomVal: any = 1;
  imageSizeLimit:boolean;
  original_width;
  original_height;
  cropper:CropperPosition; 
  constructor( public dialogRef: MatDialogRef<ProfilePictureUploadComponent>, @Inject(MAT_DIALOG_DATA) public data: any ) {
    this.original_width = 0;
    this.original_height=0;
    this.cropper = {
        x1: 0,
        y1: 0,
        x2: 100,
        y2: 100
      }; //initial
  }

  ngOnInit() {  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  uploadProfileImage(){
    this.showCropperSection = false;
  }
  
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showFileUploadSection = false;
    //IM-3616
    this.imageSizeLimit = false;
    this.showFileUploadSection = false;

    /* dimension of the image*/

        let imageDimensionPromise =   new Promise((resolve, reject) => {
        let reader = new FileReader();
        let result = {width:0, height:0};
        reader.readAsDataURL(event.target.files[0]);

        reader.onload = function (e) {
            let image:any = new Image();
            image.src = e.target['result'];
            image.onload = function () {
                 result.height = this.height;
                 result.width = this.width;
                 resolve(result);
            };
         }
      });
      imageDimensionPromise.then(result => {
        this.original_height =result['height'];
        this.original_width  = result['width'];
      })
    /**/
    let filesize = (event.target.files[0].size/1024)/1024;
    if( filesize > 1){
        this.imageSizeLimit = true;
        this.showFileUploadSection = true;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageSizeLimit?this.croppedImage ='':this.croppedImage = event.base64
  }

  imageLoaded() {
      // show cropper
      //console.log("image ready");
  }

  cropperReady() { }

  loadImageFailed() {
      // show message
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
 }

rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
}

private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
}


flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
}

flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
}

resetImage() {
    this.scale = 1;
    this.zoomVal = 0;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
    this.cropper = {
        x1: 0,
        y1: 0,
        x2: this.original_width,
        y2: this.original_height 
      };
}

zoom(event){
    if(this.zoomVal > event ){
        this.zoomOut();
    }
    else {
        this.zoomIn();
    }
    this.zoomVal = event; //IM-4239
}

zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

zoomIn() {
    this.scale += .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
}

updateRotation() {
    this.transform = {
        ...this.transform,
        rotate: this.rotation
    };
}

imageDimensionDetecion(file){
    
}


}
