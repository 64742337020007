import { Component, Input, ChangeDetectionStrategy, OnChanges, DoCheck , SimpleChanges, ChangeDetectorRef , ViewChild} from '@angular/core';
import { FormGroup  } from '@angular/forms';
import { CountryApiService } from './country.service';
import { MatSelect } from  '@angular/material/select';

// tslint:disable-next-line: no-conflicting-lifecycle
@Component({
  selector: 'app-country-details',
  templateUrl: './countryDetails.component.html',
  styleUrls: ['./countryDetails.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryDetailsComponent implements OnChanges , DoCheck {

  @Input()
  parentForm: FormGroup;

  @Input()
  listCountry: [];

  listStates: [];

  iscountrySelected = true ;

  @ViewChild('matSelect', {static: false}) matSelect: MatSelect;
  constructor(
    private apiService: CountryApiService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.listCountry = [];
    this.listStates = [];
  }
  ngOnChanges(e: SimpleChanges) {
      if (e.listCountry && e.listCountry.currentValue) {
      this.listCountry = e.listCountry.currentValue;
    }
  }
  ngDoCheck() {
    if (this.matSelect) {
    const countrySeelctedVal: any = this.matSelect.ngControl.control;
    if (this.listCountry.length > 0 && countrySeelctedVal.value && this.iscountrySelected) {
      this.handleCountryChange(countrySeelctedVal.value);
      this.iscountrySelected = false;
    }
  }
  }
  handleCountryChange(countryCode: string) {
    this.apiService.getStates(countryCode).subscribe((states: []) => {
      this.listStates = states;
      this.changeDetector.markForCheck();
    });
  }
}
