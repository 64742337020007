import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PetitionerDetailsForeignCompanyService {

  constructor(private http: HttpClient) { }

    // api/v2/{type}/company/{companyId}/petitionerDetails? superTaskId = 123 & taskId = 34
    getPetitionerDetailsForeignCompany(visatype, companyId, superTaskId, subTaskId) {
      return this.http.get(`/${visatype}/company/${companyId}/petitionerDetails?superTaskId=${superTaskId}&taskId=${subTaskId}`).pipe(
       map((response: ImagilityBaseResponse) => {
           if (response.status === 200) {
             return response;
           }
           else {
             return [];
           }
         })
       );
     }


    // /api/v1/L1A/PETITION/351/company/277/COMPGENDOC
     getPetitionerUploadDetails(visatype, caseType, caseId, companyId, synccode) {
      return this.http.get(`/${visatype}/${caseType}/${caseId}/company/${companyId}/${synccode}`, {}).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }else
          {
            return null;
          }
        })
      );
     }

     // api/v1/L1A/PETITION/351/company/277/category/COMPGENDOC/sync

    //  /api/v2/L1A/PETITION/351/petitioner/category/COMPGENDOC/sync?superTaskId=257&taskId=741
     syncProfileData(visatype, caseType, caseId, groupId, synccode, superTaskId, subTaskId) {
       return this.http.post(`/${visatype}/${caseType}/${caseId}/company/${groupId}/category/${synccode}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
         map((response: ImagilityBaseResponse) => {
           if (response.status === 200) {
             return response;
           }else
           {
             return null;
           }
         })
       );
     }

}