import { Component, Input, OnInit, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { combineLatest, Subject } from 'rxjs';
import { groupBy, take, takeUntil } from 'rxjs/operators';

import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { ReferenceLibrary } from 'src/app/data/models/referenceLibrary.model';
import { CreateTaskService } from 'src/app/modules/superTask/components/create-task/create-task.service';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import * as errorUtils from 'src/app/modules/utility/global-utils';
import * as RolesRouteMap from '../../../data/constants/roles';

import { RequestData } from '../../../data/models/requestdata.model';
import { DynamicService } from './dynamic.service';
import { ApiServicesService } from '../../beneficiary/services/api-services.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { AttorneyService } from 'src/app/services/attorney.service';

import moment from 'moment';
import { APIDocumentChecklist } from 'base-document-checklist-library';
import { ConfirmationService } from 'primeng/api';
import { standardImmClassification, standardSubtasks, standardVisas } from 'src/app/data/constants/standard-visas';
import { Store } from '@ngrx/store';
import * as Task from 'src/app/modules/superTask/task-state/actions/task.actions';

@Component({
  selector: 'app-request-data-documents',
  templateUrl: './request-data-documents.component.html',
  styleUrls: ['./request-data-documents.component.scss']
})

export class RequestDataDocumentsComponent implements OnInit, OnDestroy {

  beneficiaryDetails: any;
  dynamicData: any = {};
  form: FormGroup;
  immCategoryCode: any;
  immClassificationCode: any;
  immigrantType = '';
  lastIndex: number;
  orderToDisplay: any = [];
  primaryCompanyId: number;
  taskTypeList: ReferenceLibrary[];
  uiData: any = [];
  userType = '';
  visaTypes: ReferenceLibrary[] = [];
  importanceList: any = [{ id: "1", name: 'High' }, { id: "2", name: 'Medium' }, { id: "3", name: 'Low' }];
  newDate = new Date();
  disableFields = false;
  // maxDate: Date;
  establishedDate: '';
  requestData = new RequestData({
    superTaskTypeId: 'NONIMMPET'
  });
  visaTypeList: ReferenceLibrary[];
  immigrationTypeList: ReferenceLibrary[];
  immigrationClassificationList: ReferenceLibrary[];
  petitionTypeList: ReferenceLibrary[];
  subTaskTypeList: any[];
  displayResult: boolean = false;
  showreqLink: boolean = false;
  observableSubscription$ = new Subject();
  isTasktypeId: boolean = false;
  petitioncaseId: number;
  taskId: any;
  visaType: any;
  minimumDate: any;
  visaTemplates = [];
  taskLevelStatus: boolean = false;

  @Input() beneficiaryData: any;
  @Input() isCaseRequest: boolean;
  @Input() caseRequestEdit: any;

  @Input() isBeneficiaryQuestionnaire: boolean;
  @Input() beneficiaryQuestionnaireEdit: any;
  @Input() viewDataLog: boolean;
  @Input() taskLevelData: any;
  @Input() isWizard: boolean = false;
  @Input() wizardData: any;
  @Output() onSaveCancel: EventEmitter<any> = new EventEmitter();

  isViewDataLogData: boolean = false;
  isCaseRequestSatus: boolean = false;
  isCaseRequestEditSatus: boolean = false;
  isCaseRequestEditSatusValue: any;
  beneReqLinkDetails: any;

  isBeneficiaryQuestionnaireStatus: boolean = false;
  isBeneficiaryQuestionnaireEditSatus: boolean = false;
  isBeneficiaryQuestionnaireStatusValue: any;

  confirmDropDatabaseDialogVisible = false;
  categoryType: any;
  customListOptions: any;
  tempCustomListOptions: any;
  selectedCompanyId: any;
  // tempQuesData: any = [];
  deletedAdditionalcustomStepList: any = [];
  deletedAdditionalStepList: any = [];
  tempDeletedAdditionalStepList: any = [];
  tempDeletedAdditionalCustomStepList: any = [];

  isRequestDataDocument: boolean = false;
  taskLevelStatusData: any;
  beneficiaryTempDetails: any;
  isViewEditDisabled: boolean = true;
  viewEditTooltipText: any;

  constructor(private formBuilder: FormBuilder, private referenceLibraryService: ReferenceLibraryService, private createTaskService: CreateTaskService, private dynamicService: DynamicService, private dashboardWidgetsService: DashboardWidgetsService, private apiService: ApiServicesService, private toastr: ToastrService, private router: Router, private apiDocService: APIDocumentChecklist, private confirmationService: ConfirmationService, private petitionerService: PetitionerService, private attorneyService: AttorneyService, private route: ActivatedRoute, protected store: Store<any>) {
    this.viewEditTooltipText = `
    <p><span>This button will be enabled in these scenarios:</span></p>
    <ul>
      <li>1. For a visa/petition/sub-task type that is not yet created, only AFTER you click Submit.</li>
      <li>2. For a request that has already been created before, and needs more data/documents.</li>
    </ul>
    `;
  }

  ngOnChanges(event: SimpleChanges) {
    if (this.route.snapshot.params['createTask'] == 'true') {
      this.isCaseRequestSatus = false;
      this.isBeneficiaryQuestionnaireStatus = false;
      this.beneficiaryDetails = JSON.parse(sessionStorage.getItem('requestDataDocument'));
      this.primaryCompanyId = parseInt(sessionStorage.getItem('companyId'));
      this.selectedCompanyId = sessionStorage.getItem('selectedCompanyId');
    } else {
      if (event.isCaseRequest && event.isCaseRequest.currentValue) {
        this.isCaseRequestSatus = true;
        this.isBeneficiaryQuestionnaireStatus = false;
        this.primaryCompanyId = parseInt(sessionStorage.getItem('companyId'));
        if(event.caseRequestEdit && event.caseRequestEdit.currentValue){
          this.isCaseRequestEditSatus = true;
          this.isCaseRequestEditSatusValue = event.caseRequestEdit.currentValue;
        } else {
          this.isCaseRequestEditSatus = false;
        }
      } else {
        this.isCaseRequestSatus = false;
        this.isBeneficiaryQuestionnaireStatus = false;
        if (event.beneficiaryData && event.beneficiaryData.currentValue) {
          if (event.taskLevelData && event.taskLevelData.currentValue) {
            this.taskLevelStatus = true;
            this.taskLevelStatusData = event.taskLevelData.currentValue;
            this.beneficiaryTempDetails = event.taskLevelData.currentValue.id;
            this.primaryCompanyId = parseInt(sessionStorage.getItem('companyId'));
            this.selectedCompanyId = event.taskLevelData.currentValue.companyId;
          } else {
            this.taskLevelStatus = false;
            this.beneficiaryDetails = event.beneficiaryData.currentValue;
            this.primaryCompanyId = parseInt(sessionStorage.getItem('companyId'));
            this.selectedCompanyId = sessionStorage.getItem('selectedCompanyId');
          }
        }
      }
 
      if (event.isBeneficiaryQuestionnaire && event.isBeneficiaryQuestionnaire.currentValue) {
        this.isBeneficiaryQuestionnaireStatus = true;
        this.isCaseRequestSatus = false;
        this.primaryCompanyId = parseInt(sessionStorage.getItem('companyId'));
        if(event.beneficiaryQuestionnaireEdit && event.beneficiaryQuestionnaireEdit.currentValue){
          this.isBeneficiaryQuestionnaireEditSatus = true;
          this.isBeneficiaryQuestionnaireStatusValue = event.beneficiaryQuestionnaireEdit.currentValue;
        }
        if(event.viewDataLog && event.viewDataLog.currentValue){
          this.isViewDataLogData = true;
        } else {
          this.isViewDataLogData = false;
        }
      } else {
        this.isRequestDataDocument = true;
        this.isBeneficiaryQuestionnaireStatus = false;
        if (event.beneficiaryData && event.beneficiaryData.currentValue) {
          if (event.taskLevelData && event.taskLevelData.currentValue) {
            this.taskLevelStatus = true;
            this.taskLevelStatusData = event.taskLevelData.currentValue;
            this.beneficiaryTempDetails = event.taskLevelData.currentValue.id;
            this.primaryCompanyId = parseInt(sessionStorage.getItem('companyId'));
            this.selectedCompanyId = event.taskLevelData.currentValue.companyId;
          } else {
            this.taskLevelStatus = false;
            this.beneficiaryDetails = event.beneficiaryData.currentValue;
            this.primaryCompanyId = parseInt(sessionStorage.getItem('companyId'));
            this.selectedCompanyId = sessionStorage.getItem('selectedCompanyId');
          }

          if(event.wizardData && event.wizardData.currentValue){
            const data = event.wizardData.currentValue;
            this.requestData = {...this.requestData, visaType: data?.visatype, petitionType: data?.petitionType, taskTypeRefId: data?.caseType, taskTypeId: data?.taskTypeId, version: 'WWF' };
          }
        }
      }

      // Remove this portion testing purpose start
        // this.isCaseRequestSatus = true;
        // this.isCaseRequestEditSatus = true;
        // this.isCaseRequestEditSatusValue = this.isBeneficiaryQuestionnaireStatusValue;
      // Remove this portion testing purpose end
    }
  }

  reqLink() {
    this.showreqLink = true;
  }

  ngOnInit(): void {
    this.minimumDate = new Date();
    this.userType = sessionStorage.getItem('userTypeRole');
    combineLatest([
      this.referenceLibraryService.getReferenceData('IMMTYP'),
      this.referenceLibraryService.getReferenceData('SUPTSKTYP')
    ]).pipe(take(1)).subscribe((response: any[]) => {
      this.taskTypeList = response[1];
      this.onTaskTypeChange();
    });
    // this.maxDate = new Date();
    if(this.taskLevelStatus ? this.taskLevelStatusData.id : this.beneficiaryDetails?.beneficiaryEmployeeId){
      this.apiService.getAccessCodeAndLinkForBeneficiary(this.taskLevelStatus ? this.taskLevelStatusData.id : this.beneficiaryDetails.beneficiaryEmployeeId).subscribe((data: any) => {
        this.beneReqLinkDetails = data;
      })
    }
    if(this.isWizard && this.wizardData && this.requestData?.superTaskTypeId && this.requestData?.petitionType && this.requestData?.visaType && this.requestData?.taskTypeRefId){
      this.generateDynamicGroup();
    }
  }

  addNumberToDynamicData(data, custom) {
    this.uiData = [];
    let conditionalCheck;
    conditionalCheck = ((data.additionalPetitionInfo == null) && (data.documentCheckList == null) && (data.beneficiaryProfileInfo == null) && (data.beneficiaryEducationInfoForCaseRequest == null) && (data.beneficiaryImmigrationInfoForCaseRequest == null) && (data.beneficiaryProfileInfoForCaseRequest == null) && (data.beneficiaryWorkExperienceInfoForCaseRequest == null) && (data.qfamilyDependents == null) && (data.qfamilyOtherDependents == null)) || ((data.additionalPetitionInfo && data.additionalPetitionInfo.length == 0) && (data.documentCheckList && data.documentCheckList.length == 0) && (data.beneficiaryProfileInfo && data.beneficiaryProfileInfo.qfamilySubSectionList && data.beneficiaryProfileInfo.qfamilySubSectionList.length == 0) && (data.beneficiaryEducationInfoForCaseRequest && data.beneficiaryEducationInfoForCaseRequest.qfamilySubSectionList && data.beneficiaryEducationInfoForCaseRequest.qfamilySubSectionList.length == 0) && (data.beneficiaryImmigrationInfoForCaseRequest && data.beneficiaryImmigrationInfoForCaseRequest.qfamilySubSectionList && data.beneficiaryImmigrationInfoForCaseRequest.qfamilySubSectionList.length == 0) && (data.beneficiaryProfileInfoForCaseRequest && data.beneficiaryProfileInfoForCaseRequest.qfamilySubSectionList && data.beneficiaryProfileInfoForCaseRequest.qfamilySubSectionList.length == 0) && (data.beneficiaryWorkExperienceInfoForCaseRequest && data.beneficiaryWorkExperienceInfoForCaseRequest.qfamilySubSectionList && data.beneficiaryWorkExperienceInfoForCaseRequest.qfamilySubSectionList.length == 0) && (data.qfamilyDependents && data.qfamilyDependents.length == 0) && (data.qfamilyOtherDependents && data.qfamilyOtherDependents.length == 0))
    this.isViewEditDisabled = true;
    if (conditionalCheck) {
      this.displayResult = false;
    } else {
      this.displayResult = true;
      this.dynamicData.data = data;
      if (this.dynamicData?.data) {
        let sectionIndex = 1;
        let formattedData: any = {}
        let allSelected = true;
        for (const [key, value] of Object.entries(this.dynamicData?.data)) {
          switch (key) {
            case 'additionalPetitionInfo':

              if (this.dynamicData.data['additionalPetitionInfo'].length !== 0) {
                let additionalPetitionInfoArray = this.dynamicData.data['additionalPetitionInfo'];
                // let filteredCustomStep = custom.customStepList.filter((value) => value.isBeneficiaryAction == 1);
                // console.log('filteredCustomStep',filteredCustomStep)
                // additionalPetitionInfoArray =   additionalPetitionInfoArray.concat(filteredCustomStep);
                // console.log('AfterfilteradditionalPetitionInfoArray',additionalPetitionInfoArray);

                formattedData = {
                  keyName: key,
                  sectionIndex: sectionIndex++,
                  options: [],
                  title: '',
                  class: '',
                  additionalInfo: true
                }
                formattedData.title = 'Additional Petition Info';
                formattedData.class = 'row-wise'
                formattedData.options = [{
                  title: '',
                  allSelected: false,
                  subOptions: [],
                  cusSubOptions: [],
                }];
                allSelected = true;
                additionalPetitionInfoArray.forEach((data: any) => {
                  allSelected = allSelected && data.selected;
                  formattedData.options[0].subOptions.push({
                    id: data.id,
                    displayLabel: data.stepName,
                    selected: data.selected,
                    interdependent: data.interdependent,
                    layOutCode: data.layOutCode
                  })
                })
                formattedData.options[0].allSelected = allSelected;
                if (custom) {
                  formattedData = this.questionsCustomise('additionalPetitionInfo', custom, formattedData, allSelected, '');
                }
                this.uiData.push(formattedData);
              }

              break;

            case 'documentCheckList':

              if (this.dynamicData.data['documentCheckList'].length !== 0) {
                formattedData = {
                  keyName: key,
                  sectionIndex: sectionIndex++,
                  options: [],
                  title: '',
                  accordion: true
                }
                let documentCheckListArray = this.dynamicData.data['documentCheckList'];
                formattedData.title = 'Document checklist';
                formattedData.class = 'row-wise';
                // if(this.isCaseRequestSatus){
                //   formattedData.customHide = true;
                // }
                documentCheckListArray.forEach((data: any) => {
                  let option: any = {
                    title: data.label,
                    allSelected: false,
                    subOptions: [],
                    openAccordian: true,
                  }
                  let allSelected = true;
                  data.qtitleSheetDetailDto.forEach((subData: any) => {
                    allSelected = allSelected && subData.selected;
                    option.subOptions.push({
                      id: subData.id,
                      displayLabel: subData.docName,
                      selected: subData.selected
                    })
                  });
                  option.allSelected = allSelected;
                  formattedData.options.push(option);

                });

                this.uiData.push(formattedData);
              }

              break;

            case 'beneficiaryProfileInfo':
              if (this.dynamicData.data['beneficiaryProfileInfo']?.qfamilySubSectionList.length !== 0) {
                let beneficiaryProfileInfoObject = this.dynamicData.data['beneficiaryProfileInfo'];
                formattedData = {
                  keyName: key,
                  sectionIndex: sectionIndex++,
                  options: [],
                  title: '',
                  additionalInfo: false
                }
                if(this.isCaseRequestSatus || this.isRequestDataDocument){
                  formattedData.infoMessage = "For enabling/ disabling the below sections, please select/ deselect the corresponding sections";
                }
                formattedData.title = 'Information related to Beneficiary ProfileInfo';
                formattedData.class = 'row-wise'
                formattedData.options = [{
                  title: '',
                  allSelected: false,
                  subOptions: [],
                  cusSubOptions: [],
                  code: beneficiaryProfileInfoObject?.refLibFamilySection?.code
                }]
                allSelected = true;
                if (beneficiaryProfileInfoObject && beneficiaryProfileInfoObject.qfamilySubSectionList && beneficiaryProfileInfoObject.qfamilySubSectionList.length) {
                  beneficiaryProfileInfoObject.qfamilySubSectionList.forEach((data: any) => {
                    allSelected = allSelected && data.selected;
                    formattedData.options[0].subOptions.push({
                      id: data.referenceLibrarysubSection.id,
                      displayLabel: data.referenceLibrarysubSection.name,
                      selected: data.selected
                    })
                  })
                }
                formattedData.options[0].allSelected = allSelected;

                if (custom) {
                  formattedData = this.questionsCustomise('beneficiaryProfileInfo', custom, formattedData, allSelected, '');
                }

                this.uiData.push(formattedData);
              }

              break;
            
            case 'beneficiaryEducationInfoForCaseRequest':
            if (this.dynamicData.data['beneficiaryEducationInfoForCaseRequest'] && this.dynamicData.data['beneficiaryEducationInfoForCaseRequest'].qfamilySubSectionList.length !== 0) {
              let educationalDetailsObject = this.dynamicData.data['beneficiaryEducationInfoForCaseRequest'];
              formattedData = {
                keyName: key,
                sectionIndex: sectionIndex++,
                options: [],
                title: '',
                additionalInfo: false
              }
              formattedData.title = 'Educational Details';
              formattedData.class = 'row-wise'
              formattedData.options = [{
                title: '',
                allSelected: false,
                subOptions: [],
                cusSubOptions: [],
                code: educationalDetailsObject?.refLibFamilySection?.code
              }]
              allSelected = true;
              if (educationalDetailsObject && educationalDetailsObject.qfamilySubSectionList && educationalDetailsObject.qfamilySubSectionList.length) {
                educationalDetailsObject.qfamilySubSectionList.forEach((data: any) => {
                  allSelected = allSelected && data.selected;
                  formattedData.options[0].subOptions.push({
                    id: data.referenceLibrarysubSection.id,
                    displayLabel: data.referenceLibrarysubSection.name,
                    selected: data.selected
                  })
                })
              }
              if(this.dynamicData.data['beneficiaryProfileInfo']?.qfamilySubSectionList.find(item=> item.referenceLibrarysubSection.code == 'QTPROFILESSEDUC').selected){
                formattedData.customDisabled = false;
              } else {
                formattedData.customDisabled = true;
              } 
              formattedData.options[0].allSelected = allSelected;
              // if (custom) {
              //   formattedData = this.questionsCustomise('beneficiaryProfileInfo', custom, formattedData, allSelected, '');
              // }
              this.uiData.push(formattedData);
            }

            break;

            case 'beneficiaryImmigrationInfoForCaseRequest':

            if (this.dynamicData.data['beneficiaryImmigrationInfoForCaseRequest'] && this.dynamicData.data['beneficiaryImmigrationInfoForCaseRequest'].qfamilySubSectionList.length !== 0) {
              let immigrationDetailsObject = this.dynamicData.data['beneficiaryImmigrationInfoForCaseRequest'];
              formattedData = {
                keyName: key,
                sectionIndex: sectionIndex++,
                options: [],
                title: '',
                additionalInfo: false
              }
              formattedData.title = 'Immigration Details';
              formattedData.class = 'row-wise'
              formattedData.options = [{
                title: '',
                allSelected: false,
                subOptions: [],
                cusSubOptions: [],
                code: immigrationDetailsObject?.refLibFamilySection?.code
              }]
              allSelected = true;
              if (immigrationDetailsObject && immigrationDetailsObject.qfamilySubSectionList && immigrationDetailsObject.qfamilySubSectionList.length) {
                immigrationDetailsObject.qfamilySubSectionList.forEach((data: any) => {
                  allSelected = allSelected && data.selected;
                  formattedData.options[0].subOptions.push({
                    id: data.referenceLibrarysubSection.id,
                    displayLabel: data.referenceLibrarysubSection.name,
                    selected: data.selected
                  })
                })
              }
              if(this.dynamicData.data['beneficiaryProfileInfo']?.qfamilySubSectionList.find(item=> item.referenceLibrarysubSection.code == 'QTPROFILESSIMM').selected){
                formattedData.customDisabled = false;
              } else {
                formattedData.customDisabled = true;
              }
              formattedData.options[0].allSelected = allSelected;
              // if (custom) {
              //   formattedData = this.questionsCustomise('beneficiaryProfileInfo', custom, formattedData, allSelected, '');
              // }
              this.uiData.push(formattedData);
            }

            break;

            case 'beneficiaryProfileInfoForCaseRequest':

            if (this.dynamicData.data['beneficiaryProfileInfoForCaseRequest'] && this.dynamicData.data['beneficiaryProfileInfoForCaseRequest'].qfamilySubSectionList.length !== 0) {
              let personalDetailsObject = this.dynamicData.data['beneficiaryProfileInfoForCaseRequest'];
              formattedData = {
                keyName: key,
                sectionIndex: sectionIndex++,
                options: [],
                title: '',
                additionalInfo: false
              }
              formattedData.title = 'Personal Details';
              formattedData.class = 'row-wise'
              formattedData.options = [{
                title: '',
                allSelected: false,
                subOptions: [],
                cusSubOptions: [],
                code: personalDetailsObject?.refLibFamilySection?.code
              }]
              allSelected = true;
              if (personalDetailsObject && personalDetailsObject.qfamilySubSectionList && personalDetailsObject.qfamilySubSectionList.length) {
                personalDetailsObject.qfamilySubSectionList.forEach((data: any) => {
                  allSelected = allSelected && data.selected;
                  formattedData.options[0].subOptions.push({
                    id: data.referenceLibrarysubSection.id,
                    displayLabel: data.referenceLibrarysubSection.name,
                    selected: data.selected
                  })
                })
              }
              if(this.dynamicData.data['beneficiaryProfileInfo']?.qfamilySubSectionList.find(item=> item.referenceLibrarysubSection.code == 'QTPROFILESSPERS').selected){
                formattedData.customDisabled = false;
              } else {
                formattedData.customDisabled = true;
              }
              formattedData.options[0].allSelected = allSelected;
              // if (custom) {
              //   formattedData = this.questionsCustomise('beneficiaryProfileInfo', custom, formattedData, allSelected, '');
              // }
              this.uiData.push(formattedData);
            }

            break;

            case 'beneficiaryWorkExperienceInfoForCaseRequest':

            if (this.dynamicData.data['beneficiaryWorkExperienceInfoForCaseRequest'] && this.dynamicData.data['beneficiaryWorkExperienceInfoForCaseRequest'].qfamilySubSectionList.length !== 0) {
              let workExperienceDetailsObject = this.dynamicData.data['beneficiaryWorkExperienceInfoForCaseRequest'];
              formattedData = {
                keyName: key,
                sectionIndex: sectionIndex++,
                options: [],
                title: '',
                additionalInfo: false
              }
              formattedData.title = 'Work Experience Details';
              formattedData.class = 'row-wise'
              formattedData.options = [{
                title: '',
                allSelected: false,
                subOptions: [],
                cusSubOptions: [],
                code: workExperienceDetailsObject?.refLibFamilySection?.code
              }]
              allSelected = true;
              if (workExperienceDetailsObject && workExperienceDetailsObject.qfamilySubSectionList && workExperienceDetailsObject.qfamilySubSectionList.length) {
                workExperienceDetailsObject.qfamilySubSectionList.forEach((data: any) => {
                  allSelected = allSelected && data.selected;
                  formattedData.options[0].subOptions.push({
                    id: data.referenceLibrarysubSection.id,
                    displayLabel: data.referenceLibrarysubSection.name,
                    selected: data.selected
                  })
                })
              }
              if(this.dynamicData.data['beneficiaryProfileInfo'].qfamilySubSectionList.find(item=> item.referenceLibrarysubSection.code == 'QTPROFILESSWRKEXP').selected){
                formattedData.customDisabled = false;
              } else {
                formattedData.customDisabled = true;
              }
              formattedData.options[0].allSelected = allSelected;
              // if (custom) {
              //   formattedData = this.questionsCustomise('beneficiaryProfileInfo', custom, formattedData, allSelected, '');
              // }
              this.uiData.push(formattedData);
            }

            break;
  
            case 'qfamilyDependents':

              if (this.dynamicData.data['qfamilyDependents'].length !== 0) {
                formattedData = {
                  keyName: key,
                  sectionIndex: sectionIndex++,
                  options: [],
                  title: '',
                  additionalInfo: false
                }
                let qfamilyDependentsArray = this.dynamicData.data['qfamilyDependents'];
                formattedData.title = 'Information related to Family Dependent';
                formattedData.class = 'row-wise';
                if(this.isCaseRequestSatus){
                  formattedData.customHide = true;
                }
                qfamilyDependentsArray.forEach((data: any) => {
                  let allSelected = true;
                  let option: any = {
                    title: data.refLibFamilySection.name + ' Details',
                    allSelected: false,
                    subOptions: [],
                    cusSubOptions: [],
                    code: data.refLibFamilySection.code
                  }

                  data.qfamilySubSectionList.forEach((subData: any) => {
                    allSelected = allSelected && subData.selected;
                    option.subOptions.push({
                      id: subData.referenceLibrarysubSection.id,
                      displayLabel: subData.referenceLibrarysubSection.name,
                      selected: subData.selected
                    });
                  });
                  option.allSelected = allSelected;
                  formattedData.options.push(option);
                  if (custom) {
                    formattedData = this.questionsCustomise('qfamilyDependents', custom, formattedData, allSelected, data.refLibFamilySection.code);
                  }
                });

                this.uiData.push(formattedData);
              }

              break;

            case 'qfamilyOtherDependents':

              if (this.dynamicData.data['qfamilyOtherDependents'].length !== 0) {
                let qfamilyOtherDependentsArray = this.dynamicData.data['qfamilyOtherDependents'];
                formattedData = {
                  keyName: key,
                  sectionIndex: sectionIndex++,
                  options: [],
                  title: '',
                  additionalInfo: false
                }
                formattedData.title = 'Information related to Other Dependent';
                formattedData.class = 'row-wise';
                if(this.isCaseRequestSatus){
                  formattedData.customHide = true;
                }
                qfamilyOtherDependentsArray.forEach((data: any) => {
                  let allSelected = true;
                  let option: any = {
                    title: data.refLibFamilySection.name + ' Details',
                    allSelected: false,
                    subOptions: [],
                    cusSubOptions: [],
                    code: data.refLibFamilySection.code
                  }

                  data.qfamilySubSectionList.forEach((subData: any) => {
                    allSelected = allSelected && subData.selected;
                    option.subOptions.push({
                      id: subData.referenceLibrarysubSection.id,
                      displayLabel: subData.referenceLibrarysubSection.name,
                      selected: subData.selected
                    })
                  });
                  option.allSelected = allSelected;
                  formattedData.options.push(option);

                  if (custom) {
                    formattedData = this.questionsCustomise('qfamilyOtherDependents', custom, formattedData, allSelected, data.refLibFamilySection.code);
                  }
                });
                // if(custom){
                //   formattedData = this.questionsCustomise('qfamilyOtherDependents', custom, formattedData, allSelected);
                // }

                this.uiData.push(formattedData)
              }

              break;
            
            default:
              break;
          }
        }
        this.lastIndex = sectionIndex;
        if(!this.isCaseRequestSatus && !this.isBeneficiaryQuestionnaireStatus){
          if (this.dynamicData.data.accessTillDate) {
            this.isViewEditDisabled = false;
            this.form.get("accessRights").setValue(moment(this.dynamicData.data.accessTillDate).format('MM-DD-YYYY'));
          }
  
          if (this.dynamicData.data.priority) {
            this.form.get("importance").setValue(this.dynamicData.data.priority);
          } else {
            this.form.get("importance").setValue(null);
          }
        }
      } 
    }
    
  }

  questionsCustomise(sectionInfo, custom, formattedData, allSelected, currentCode) {
    let defaultSelected = [
      { key: "QTPROFILEBEN", selected: false },
      { key: "CHILD", selected: false },
      { key: "SPOUSE", selected: false },
      { key: "BROTHER", selected: false },
      { key: "FATHER", selected: false },
      { key: "FIANCE", selected: false },
      { key: "MOTHER", selected: false },
      { key: "SISTER", selected: false },
    ];
    switch (sectionInfo) {
      case 'beneficiaryProfileInfo':
        if (custom.sectionList && custom.sectionList.length) {
          custom.sectionList.forEach((data: any) => {
            if (data.stakeHoldersSelected.length == 0) {
              allSelected = allSelected && false;
              formattedData.options[0].cusSubOptions.push({
                id: data.id,
                displayLabel: data.name,
                selected: false,
                type: 'section'
              })
            } else {
              data.stakeHoldersSelected.forEach((item: any) => {
                if (item.key == 'QTPROFILEBEN') {
                  allSelected = allSelected && item.selected;
                  formattedData.options[0].cusSubOptions.push({
                    id: data.id,
                    displayLabel: data.name,
                    selected: item.selected,
                    type: 'section'
                  })
                }
              });
            }
          })
        }
        if (custom.customSectionList && custom.customSectionList.length) {
          custom.customSectionList.forEach((data: any) => {
            if (data.stakeHoldersSelected.length == 0) {
              allSelected = allSelected && false;
              formattedData.options[0].cusSubOptions.push({
                id: data.id,
                displayLabel: data.name,
                selected: false,
                type: 'custSection'
              })
            } else {
              data.stakeHoldersSelected.forEach((item: any) => {
                if (item.key == 'QTPROFILEBEN') {
                  allSelected = allSelected && item.selected;
                  formattedData.options[0].cusSubOptions.push({
                    id: data.id,
                    displayLabel: data.name,
                    selected: item.selected,
                    type: 'custSection'
                  })
                }
              });
            }
          })
        }
        if (!(custom.sectionList && custom.sectionList.length) && !(custom.customSectionList && custom.customSectionList.length)) {
          formattedData.options[0].allSelected = allSelected;
        } else {
          formattedData.options[0].allSelected = allSelected;
        }
        break;
      case 'qfamilyDependents':
        formattedData.options.forEach((opt) => {
          let allSelect = allSelected;
          if (custom.sectionList && custom.sectionList.length) {
            custom.sectionList.forEach((data: any) => {
              if (data.stakeHoldersSelected.length == 0) {
                opt.cusSubOptions.push({
                  id: data.id,
                  displayLabel: data.name,
                  selected: false,
                  type: 'section'
                });
                opt.allSelected = false;
                data.stakeHoldersSelected = defaultSelected;
              } else {
                data.stakeHoldersSelected.forEach((item: any) => {
                  if ((opt.code == currentCode) && (item.key == currentCode)) {
                    allSelect = allSelect && item.selected;
                    opt.cusSubOptions.push({
                      id: data.id,
                      displayLabel: data.name,
                      selected: item.selected,
                      type: 'section'
                    });
                    opt.allSelected = allSelect;
                  }
                });
              }
            })
          }
          if (custom.customSectionList && custom.customSectionList.length) {
            custom.customSectionList.forEach((data: any) => {
              if (data.stakeHoldersSelected.length == 0) {
                opt.cusSubOptions.push({
                  id: data.id,
                  displayLabel: data.name,
                  selected: false,
                  type: 'custSection'
                });
                opt.allSelected = false;
                data.stakeHoldersSelected = defaultSelected;
              } else {
                data.stakeHoldersSelected.forEach((item: any) => {
                  if ((opt.code == currentCode) && (item.key == currentCode)) {
                    allSelect = allSelect && item.selected;
                    opt.cusSubOptions.push({
                      id: data.id,
                      displayLabel: data.name,
                      selected: item.selected,
                      type: 'custSection'
                    });
                    opt.allSelected = allSelect;
                  }
                });
              }
            })
          }
          if (!(custom.sectionList && custom.sectionList.length) && !(custom.customSectionList && custom.customSectionList.length)) {
            opt.allSelected = allSelect;
          }
        });

        break;
      case 'qfamilyOtherDependents':
        formattedData.options.forEach((opt) => {
          if (custom.sectionList && custom.sectionList.length) {
            custom.sectionList.forEach((data: any) => {
              if (data.stakeHoldersSelected.length == 0) {
                opt.cusSubOptions.push({
                  id: data.id,
                  displayLabel: data.name,
                  selected: false,
                  type: 'section'
                });
                opt.allSelected = false;
                data.stakeHoldersSelected = defaultSelected;
              } else {
                data.stakeHoldersSelected.forEach((item: any) => {
                  if ((opt.code == currentCode) && (item.key == currentCode)) {
                    allSelected = allSelected && item.selected;
                    opt.cusSubOptions.push({
                      id: data.id,
                      displayLabel: data.name,
                      selected: item.selected,
                      type: 'section'
                    });
                    opt.allSelected = allSelected;
                  }
                });
              }
            })
          }
          if (custom.customSectionList && custom.customSectionList.length) {
            custom.customSectionList.forEach((data: any) => {
              if (data.stakeHoldersSelected.length == 0) {
                opt.cusSubOptions.push({
                  id: data.id,
                  displayLabel: data.name,
                  selected: false,
                  type: 'custSection'
                });
                opt.allSelected = false;
                data.stakeHoldersSelected = defaultSelected;
              } else {
                data.stakeHoldersSelected.forEach((item: any) => {
                  if ((opt.code == currentCode) && (item.key == currentCode)) {
                    allSelected = allSelected && item.selected;
                    opt.cusSubOptions.push({
                      id: data.id,
                      displayLabel: data.name,
                      selected: item.selected,
                      type: 'custSection'
                    });
                    opt.allSelected = allSelected;
                  }
                });
              }
            })
          }
          if (!(custom.sectionList && custom.sectionList.length) && !(custom.customSectionList && custom.customSectionList.length)) {
            // allSelected = true;
            opt.allSelected = allSelected;
          }
        });

        break;
      case 'additionalPetitionInfo':
        if (custom.stepList && custom.stepList.length) {
          custom.stepList.forEach((data: any) => {
            allSelected = allSelected && data.selected;
            formattedData.options[0].cusSubOptions.push({
              id: data.id,
              displayLabel: data.stepName,
              selected: data.selected,
              type: 'section',
              data: data,
            });
          });
        }
        if (custom.customStepList && custom.customStepList.length) {
          custom.customStepList.forEach((data: any) => {
            allSelected = allSelected && data.selected;
            formattedData.options[0].cusSubOptions.push({
              id: data.id,
              displayLabel: data.stepName,
              selected: data.selected,
              type: 'custSection',
              data: data,
            });
          });
        }
        if (!(custom.stepList && custom.stepList.length) && !(custom.customStepList && custom.customStepList.length)) {
          formattedData.options[0].allSelected = allSelected;
        } else {
          formattedData.options[0].allSelected = allSelected;
        }
        break;

      default:
        break;
    }

    return formattedData;
  }
  callGenerateDynamicGroup() {
    let payload;
    if (this.requestData.superTaskTypeId == 'NONIMMPET') {
      payload = {
        beneficiaryId: this.taskLevelStatus ? this.taskLevelStatusData.id : ((this.isCaseRequestSatus ? this.isCaseRequestSatus: this.isBeneficiaryQuestionnaireStatus) ? null : this.beneficiaryDetails.beneficiaryEmployeeId), 
        companyId: this.primaryCompanyId,
        immigrationType: 'VISA',
        petitionType: this.requestData.petitionType.toUpperCase(),
        taskType: this.requestData.taskTypeRefId.toUpperCase(),
        visaType: this.requestData.visaType.toUpperCase(),
        taskTypeId: this.requestData.taskTypeId
      };
      if(this.requestData?.version){
        payload = { ...payload,version: this.requestData?.version};
      }
      this.visaType = 'visa';
    } else {
      payload = {
        beneficiaryId: this.taskLevelStatus ? this.taskLevelStatusData.id : ((this.isCaseRequestSatus ? this.isCaseRequestSatus: this.isBeneficiaryQuestionnaireStatus) ? null : this.beneficiaryDetails.beneficiaryEmployeeId), 
        companyId: this.primaryCompanyId,
        immigrationCategory: this.requestData.immCategoryType.toUpperCase(),
        immigrationClassfication: this.requestData.immClassificationType.toUpperCase(),
        taskType: this.requestData.taskTypeRefId.toUpperCase(),
        taskTypeId: this.requestData.taskTypeId
      };
      this.visaType = 'visa';
    }

    // this.apiService.getQuestionnaireRequestDataDocuments(payload, this.isCaseRequestSatus, this.isBeneficiaryQuestionnaireStatus)
    let tempIsCaseRequestSatus;
    let tempIsBeneficiaryQuestionnaireStatus;

    if(this.isViewDataLogData){
      tempIsCaseRequestSatus = false;
      tempIsBeneficiaryQuestionnaireStatus = false;
      payload.beneficiaryId = sessionStorage.getItem('tempBeneficiaryEmployeeId');
    } else {
      tempIsCaseRequestSatus = this.isCaseRequestSatus;
      tempIsBeneficiaryQuestionnaireStatus = this.isBeneficiaryQuestionnaireStatus;
    }

    this.apiService.getQuestionnaireRequestDataDocuments(payload, tempIsCaseRequestSatus, tempIsBeneficiaryQuestionnaireStatus)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: any) => {
        if (data) {
          let conditionalSubCheck;
          conditionalSubCheck = ((data.additionalPetitionInfo == null) && (data.documentCheckList == null) && (data.beneficiaryProfileInfo == null) && (data.beneficiaryEducationInfoForCaseRequest == null) && (data.beneficiaryImmigrationInfoForCaseRequest == null) && (data.beneficiaryProfileInfoForCaseRequest == null) && (data.beneficiaryWorkExperienceInfoForCaseRequest == null) && (data.qfamilyDependents == null) && (data.qfamilyOtherDependents == null)) || ((data.additionalPetitionInfo && data.additionalPetitionInfo.length == 0) && (data.documentCheckList && data.documentCheckList.length == 0) && (data.beneficiaryProfileInfo && data.beneficiaryProfileInfo.qfamilySubSectionList && data.beneficiaryProfileInfo.qfamilySubSectionList.length == 0) && (data.beneficiaryEducationInfoForCaseRequest && data.beneficiaryEducationInfoForCaseRequest.qfamilySubSectionList && data.beneficiaryEducationInfoForCaseRequest.qfamilySubSectionList.length == 0) && (data.beneficiaryImmigrationInfoForCaseRequest && data.beneficiaryImmigrationInfoForCaseRequest.qfamilySubSectionList && data.beneficiaryImmigrationInfoForCaseRequest.qfamilySubSectionList.length == 0) && (data.beneficiaryProfileInfoForCaseRequest && data.beneficiaryProfileInfoForCaseRequest.qfamilySubSectionList && data.beneficiaryProfileInfoForCaseRequest.qfamilySubSectionList.length == 0) && (data.beneficiaryWorkExperienceInfoForCaseRequest && data.beneficiaryWorkExperienceInfoForCaseRequest.qfamilySubSectionList && data.beneficiaryWorkExperienceInfoForCaseRequest.qfamilySubSectionList.length == 0) && (data.qfamilyDependents && data.qfamilyDependents.length == 0) && (data.qfamilyOtherDependents && data.qfamilyOtherDependents.length == 0))

          if (conditionalSubCheck) {
            this.displayResult = false;
          }
          if (data.documentCheckList) {
            for (var i = 0; i < data.documentCheckList.length; i++) {
              data.documentCheckList[i].qtitleSheetDetailDto = data.documentCheckList[i].qtitleSheetDetailDto.filter(x => x.docName != 'Other')
            }
          }

          if (data.taskTypeId) {
            sessionStorage.setItem('taskTypeId', JSON.stringify(data.taskTypeId));
            const payloadItem = {
              taskTypeId: data.taskTypeId,
              beneficiaryId:  this.taskLevelStatus ? this.taskLevelStatusData.id : ((this.isCaseRequestSatus ? this.isCaseRequestSatus: this.isBeneficiaryQuestionnaireStatus) ? null : this.beneficiaryDetails.beneficiaryEmployeeId), 
              companyId: this.primaryCompanyId
            }

            if(this.isViewDataLogData){
              tempIsCaseRequestSatus = false;
              tempIsBeneficiaryQuestionnaireStatus = false;
              payloadItem.beneficiaryId = sessionStorage.getItem('tempBeneficiaryEmployeeId');
            } else {
              tempIsCaseRequestSatus = this.isCaseRequestSatus;
              tempIsBeneficiaryQuestionnaireStatus = this.isBeneficiaryQuestionnaireStatus;
            }

            this.apiService.getQuestionnaireCustomQuestionsDetails(payloadItem, tempIsCaseRequestSatus, tempIsBeneficiaryQuestionnaireStatus)
              .pipe(takeUntil(this.observableSubscription$))
              .subscribe((item: any) => {
                if (item) {
                  this.tempCustomListOptions = item.data;
                  this.customListOptions = item.data;
                  sessionStorage.setItem('custQuestion', JSON.stringify(this.tempCustomListOptions));
                  this.customiseCustomQuestions(this.customListOptions);
                  if (this.customiseCustomQuestions(this.customListOptions)) {
                    this.addNumberToDynamicData(data, this.customListOptions);
                  }
                } else {
                  this.addNumberToDynamicData(data, null);
                }
              })

            if(!this.isCaseRequestSatus){
              this.apiService.getTaskFordisplay(payloadItem)
              .pipe(takeUntil(this.observableSubscription$))
              .subscribe((res: any) => {
                if (res.taskId == "") {
                  if(this.isBeneficiaryQuestionnaireStatus){
                    // this.isTasktypeId = false;
                    if(this.isViewDataLogData){
                      this.isTasktypeId = true;
                    } else {
                      this.isTasktypeId = false;
                    }
                  } else {
                    this.isTasktypeId = true;
                  }
                } else {
                  this.isTasktypeId = false;
                  this.taskId = res.taskId;
                  this.getTask(res.taskId, payload);
                }
              })
            }
          }
        }
      });
  }

  customiseCustomQuestions(customListOptions) {
    this.customListOptions = customListOptions;
    if (this.customListOptions) {
      if (this.customListOptions.stepList.length !== 0) {
        if(this.isCaseRequestSatus){
          this.deletedAdditionalStepList = this.customListOptions.stepList.filter((res) => res.isPetitionerAction == 1);
        } else {
          this.deletedAdditionalStepList = this.customListOptions.stepList.filter((res) => res.isBeneficiaryAction == 1);
        }
        
        this.customListOptions.stepList = [];
        this.deletedAdditionalStepList.forEach((elem2) => {
          if ((elem2.isBeneficiaryEditable == 0 && elem2.isBeneficiaryVisible == 1) || (elem2.isBeneficiaryEditable == 1 && elem2.isBeneficiaryVisible == 1)) {
            this.customListOptions.stepList.push(elem2);
            // this.tempDeletedAdditionalStepList.push(elem2);
            // this.customListOptions.stepList.forEach((elem1, index) => {
            //   if(elem2.id==elem1.id){
            //     this.customListOptions.stepList.splice(index, 1);
            //     this.tempDeletedAdditionalStepList.push(elem2);
            //   }
            // });
          }
        });
        // this.customListOptions.stepList.forEach(elem1 => {
        //   this.deletedAdditionalStepList.forEach(elem2 => {
        //     if(elem1.id==elem2.id){
        //       if((elem2.isBeneficiaryEditable==1 && elem2.isBeneficiaryVisible==0) || (elem2.isBeneficiaryEditable==0 && elem2.isBeneficiaryVisible==0)){
        //         this.customListOptions.stepList.splice(elem2, 1);
        //         this.tempDeletedAdditionalStepList.push(elem2);
        //       }
        //     }
        //   });
        // });
      }
      if (this.customListOptions.customStepList.length !== 0) {
        if(this.isCaseRequestSatus){
          this.deletedAdditionalcustomStepList = this.customListOptions.customStepList.filter((res) => res.isPetitionerAction == 1);
        } else {
          this.deletedAdditionalcustomStepList = this.customListOptions.customStepList.filter((res) => res.isBeneficiaryAction == 1);
        }
        
        this.customListOptions.customStepList = [];
        this.deletedAdditionalcustomStepList.forEach((elem2) => {
          if ((elem2.isBeneficiaryEditable == 0 && elem2.isBeneficiaryVisible == 1) || (elem2.isBeneficiaryEditable == 1 && elem2.isBeneficiaryVisible == 1)) {
            this.customListOptions.customStepList.push(elem2);
          }
        });
      }
    }

    return this.customListOptions;
  }

  getTask(taskId, payload) {
    this.apiDocService.getSuperTask(taskId)
      .pipe(takeUntil(this.observableSubscription$), take(1)).subscribe((data) => {
        this.petitioncaseId = data?.subTaskList[0]?.petitionId ? data?.subTaskList[0]?.petitionId : data?.subTaskList[0]?.caseId;
        if (this.petitioncaseId) {
          payload.petitioncaseId = this.petitioncaseId;
          if (payload.immigrationCategory) {
            payload.visaType = payload.immigrationCategory;
          }
          this.categoryType = payload.visaType;
          this.mapDocumentChecklist(payload, this.uiData);
        } else if (data?.subTaskList[0]?.petitionId == null && data?.subTaskList[0]?.caseId == null) {
          if (payload.immigrationCategory) {
            payload.visaType = payload.taskType;
          }
          this.apiService.getTask(payload.taskType, data?.subTaskList[0]?.id)
            .pipe(takeUntil(this.observableSubscription$), take(1)).subscribe((data) => {
              if (data) {
                this.categoryType = payload.taskType;
                payload.petitioncaseId = data.data.petitionId;
                this.mapDocumentChecklist(payload, this.uiData);
              }
            })
        }
      });
  }

  mapDocumentChecklist(payload, uiData) {
    this.uiData = uiData;
    this.apiService.getRequiredDocuments(payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: any) => {
        let docArray: any = [];
        let docResult;
        data?.data?.forEach((res: any) => {
          res.documentTypes.forEach((item) => {
            docResult = item.documents.filter((fData) => fData.isPresent);
            if (docResult.length !== 0) {
              docArray.push({
                titleSheetName: res.titleSheetName,
                docName: item.docName,
                documentTypes: docResult
              })
            }
          });
        });
        docArray.forEach((item) => {
          this.uiData.forEach((sData) => {
            if (sData.keyName === "documentCheckList") {
              if (sData.label == docArray.titleSheetName) {
                sData.options.forEach((data) => {
                  if (data.title == item.titleSheetName) {
                    data.subOptions.forEach((rData) => {
                      if (item.docName === rData.displayLabel) {
                        rData.isDisabled = true;
                        rData.selected = false;
                      }
                    })
                  }
                })
              }
            }
          })
        })
      });
  }

  onClickCancel() {
    if(this.isWizard){
      this.onSaveCancel.emit({cancel: true, save: false});
    }else{
      this.redirectToListing();
    }
  }

  redirectToListing() {
    if (sessionStorage.getItem('userTypeRole') === 'Attorney') {
      if(!this.taskLevelStatus){
        this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/attorney/beneficiaries`]);
      } else {
        this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/attorney/task-list`]);
      }
    } else if (sessionStorage.getItem('userTypeRole') === 'Petitioner') {
      if(!this.taskLevelStatus){
        this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/beneficiaries/list`]);
      } else {
        this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/companies/task-list`]);
        
      }
    }
  }

  confirm(event: Event) {
    this.confirmationService.confirm({
      // target: event.target,
      key: 'confirm-drop-database',
      message: `Document is already uploaded, Do you want to delete the uploaded item for enable this checkbox ?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(new Task.SetTaskRouteId(this.taskId));
        if (this.requestData.taskTypeRefId == "I485") {
          this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/immigration/${this.categoryType}/details`]);
        } else if (this.requestData.taskTypeRefId == "I140") {
          this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/I-140/details`]);
        } else {
          this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/${this.visaType}/${this.categoryType}/details`]);
        }
      },
      reject: () => {
        console.log('reject');
      }
    });
  }

  generateDynamicGroup() {
    this.initializeFormGroup();
    this.callGenerateDynamicGroup();
  }

  getNoOfSelected(data: any, index: number) {
    let cusOptSelected = 0;
    if (data.options[index].cusSubOptions) {
      cusOptSelected = data.options[index].cusSubOptions.filter((cubOption: any) => cubOption.selected).length;
    }

    return data.options[index].subOptions.filter((subOption: any) => subOption.selected).length + cusOptSelected;
  }

  initializeFormGroup() {
    if(!this.isCaseRequestSatus && !this.isBeneficiaryQuestionnaireStatus){
      this.form = this.formBuilder.group({
        accessRights: ['', Validators.required],
        importance: ['', Validators.required],
      });
    } else {
      this.form = this.formBuilder.group({
        accessRights: [''],
        importance: [''],
      });
    }
  }

  mapUIDataToAPIData(byPassValidation: boolean = false) {
    if(!this.isCaseRequestSatus && !this.isBeneficiaryQuestionnaireStatus && !byPassValidation){
      if (this.form.controls.accessRights.value < moment(this.newDate).format('MM-DD-YYYY')) {
        this.toastr.error("Access rights till date should be greater than or equal to today's date!");
      } else {
        this.customMappingUI();
      }
    } else {
      this.customMappingUI();
    }
    
  }

  customMappingUI(viewEdit: boolean = false){
    if(!this.uiData?.length){
      this.toastr.error("Please select any section!");
      return;
    }
    this.uiData.forEach((eachData: any) => {
      this.customListOptionsModify(eachData);

      switch (eachData.keyName) {
        case 'beneficiaryProfileInfo':

          eachData.options[0].subOptions.forEach((subOption: any, subOptionIndex: number) => {
            this.dynamicData.data[eachData.keyName]['qfamilySubSectionList'][subOptionIndex]['selected'] = subOption.selected;
          });
          // this.dynamicData.data['beneficiaryProfileInfo']['selected'] = eachData.options[0].allSelected;
          if(this.dynamicData.data['beneficiaryProfileInfo']){
            this.dynamicData.data['beneficiaryProfileInfo']['selected'] = eachData.options[0].subOptions.filter(x => x.selected).length > 0;
          }
          
          break;
        case 'beneficiaryEducationInfoForCaseRequest':

          eachData.options[0].subOptions.forEach((subOption: any, subOptionIndex: number) => {
            this.dynamicData.data[eachData.keyName]['qfamilySubSectionList'][subOptionIndex]['selected'] = subOption.selected;
          });
          // this.dynamicData.data['beneficiaryEducationInfoForCaseRequest']['selected'] = eachData.options[0].allSelected;
          this.dynamicData.data['beneficiaryEducationInfoForCaseRequest']['selected'] = eachData.options[0].subOptions.filter(x => x.selected).length > 0
          break;
        case 'beneficiaryImmigrationInfoForCaseRequest':

          eachData.options[0].subOptions.forEach((subOption: any, subOptionIndex: number) => {
            this.dynamicData.data[eachData.keyName]['qfamilySubSectionList'][subOptionIndex]['selected'] = subOption.selected;
          });
          // this.dynamicData.data['beneficiaryImmigrationInfoForCaseRequest']['selected'] = eachData.options[0].allSelected;
          this.dynamicData.data['beneficiaryImmigrationInfoForCaseRequest']['selected'] = eachData.options[0].subOptions.filter(x => x.selected).length > 0
          break;
        case 'beneficiaryProfileInfoForCaseRequest':

          eachData.options[0].subOptions.forEach((subOption: any, subOptionIndex: number) => {
            this.dynamicData.data[eachData.keyName]['qfamilySubSectionList'][subOptionIndex]['selected'] = subOption.selected;
          });
          // this.dynamicData.data['beneficiaryProfileInfoForCaseRequest']['selected'] = eachData.options[0].allSelected;
          this.dynamicData.data['beneficiaryProfileInfoForCaseRequest']['selected'] = eachData.options[0].subOptions.filter(x => x.selected).length > 0
          break;
        case 'beneficiaryWorkExperienceInfoForCaseRequest':

          eachData.options[0].subOptions.forEach((subOption: any, subOptionIndex: number) => {
            this.dynamicData.data[eachData.keyName]['qfamilySubSectionList'][subOptionIndex]['selected'] = subOption.selected;
          });
          // this.dynamicData.data['beneficiaryWorkExperienceInfoForCaseRequest']['selected'] = eachData.options[0].allSelected;
          this.dynamicData.data['beneficiaryWorkExperienceInfoForCaseRequest']['selected'] = eachData.options[0].subOptions.filter(x => x.selected).length > 0
          break;
        case 'additionalPetitionInfo':
          eachData.options[0].subOptions.forEach((subOption: any, subOptionIndex: number) => {
            this.dynamicData.data[eachData.keyName][subOptionIndex]['selected'] = subOption.selected;
          });
          break;

        case 'documentCheckList':
          eachData.options.forEach((option: any, optionIndex: number) => {
            option.subOptions.forEach((subOption: any, subOptionIndex: number) => {
              this.dynamicData.data[eachData.keyName][optionIndex]['qtitleSheetDetailDto'][subOptionIndex]['selected'] = subOption.selected;
            })
            // this.dynamicData.data[eachData.keyName][optionIndex]['selected'] = eachData.options[optionIndex].allSelected;
            this.dynamicData.data[eachData.keyName][optionIndex]['selected'] = this.dynamicData.data[eachData.keyName][optionIndex]['qtitleSheetDetailDto'].filter(x => x.selected).length > 0;

          });
          break;

        case 'qfamilyDependents':

          eachData.options.forEach((option: any, optionIndex: number) => {
            option.subOptions.forEach((subOption: any, subOptionIndex: number) => {
              this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex]['selected'] = subOption.selected;
              if (this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.name === 'Personal Details') {
                switch (this.dynamicData.data[eachData.keyName][optionIndex]['refLibFamilySection'].code) {
                  case 'SPOUSE':
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.name = 'Spouse Details';
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.description = 'Spouse Details';
                    break;
                  case 'CHILD':
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.name = 'Child Details';
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.description = 'Child Details';
                    break;
                  case 'default':
                    break;
                }
              }
            });
            // this.dynamicData.data[eachData.keyName][optionIndex]['selected'] = option.allSelected;

            this.dynamicData.data[eachData.keyName][optionIndex]['selected'] = eachData.options[optionIndex]['subOptions'].filter(x => x.selected).length > 0;
          });
          break;

        case 'qfamilyOtherDependents':

          eachData.options.forEach((option: any, optionIndex: number) => {
            option.subOptions.forEach((subOption: any, subOptionIndex: number) => {
              this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex]['selected'] = subOption.selected;
              this.dynamicData.data[eachData.keyName][optionIndex]['selected'] = this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'].filter(x => x.selected).length > 0;
              if (this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.name === 'Personal Details') {
                switch (this.dynamicData.data[eachData.keyName][optionIndex]['refLibFamilySection'].code) {
                  case 'BROTHER':
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.name = 'Brother Details';
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.description = 'Brother Details';
                    break;
                  case 'SISTER':
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.name = 'Sister Details';
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.description = 'Sister Details';
                    break;
                  case 'FATHER':
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.name = 'Father Details';
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.description = 'Father Details';
                    break;
                  case 'MOTHER':
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.name = 'Mother Details';
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.description = 'Mother Details';
                    break;
                  case 'FIANCE':
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.name = 'Fiance Details';
                    this.dynamicData.data[eachData.keyName][optionIndex]['qfamilySubSectionList'][subOptionIndex].referenceLibrarysubSection.description = 'Fiance Details';
                    break;
                  case 'default':
                    break;
                }
              }
            });
            // this.dynamicData.data[eachData.keyName][optionIndex]['selected'] = option.allSelected;
          });
          break;

        default:
          break;
      }
    });
    
    if(!this.isCaseRequestSatus && !this.isBeneficiaryQuestionnaireStatus){
      if (this.form.controls.accessRights.value) {
        this.dynamicData.data.accessTillDate = moment(this.form.controls.accessRights.value).format('YYYY-MM-DD');
      }
  
      if (this.form.controls.importance.value) {
        this.dynamicData.data.priority = this.form.controls.importance.value;
      }
    }
    
    let finalResultStatus: any = this.findSelectedItems(this.dynamicData.data);

    if (finalResultStatus) {
      if (this.markCompleteStatus()) {
        const reqLoad = {
          beneficiaryId: this.taskLevelStatus ? this.taskLevelStatusData.id : ((this.isCaseRequestSatus ? this.isCaseRequestSatus: this.isBeneficiaryQuestionnaireStatus) ? null : this.beneficiaryDetails.beneficiaryEmployeeId), 
          companyId: this.primaryCompanyId
        };

        let payLoad: any = {
          qstepDto: this.dynamicData?.data
        };

        const storedCustomQuestions: any = JSON.parse(sessionStorage.getItem('custQuestion'));
        if (storedCustomQuestions && storedCustomQuestions.stepList.length !== 0) {
          this.customListOptions.stepList = storedCustomQuestions.stepList.reduce((c, v) => c.concat(c.some(e => e.id == v.id) ? [] : [v]), this.customListOptions.stepList);
        }
        if (storedCustomQuestions && storedCustomQuestions.customStepList !== 0) {
          this.customListOptions.customStepList = storedCustomQuestions.customStepList.reduce((c, v) => c.concat(c.some(e => e.id == v.id) ? [] : [v]), this.customListOptions.customStepList);
        }
        if(!this.isCaseRequestSatus){
          if(this.isBeneficiaryQuestionnaireStatus){
            this.saveCaseRequestCommon(reqLoad, payLoad);
          } else {

            if(this.isRequestDataDocument){
              payLoad = this.subLevelPortion(payLoad);
            }
            this.apiService.saveRequestDataDocuments(reqLoad, payLoad)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((data: any) => {
              if (data) { 
                if (data.data && this.customListOptions) {
                  let selectedCoId = parseInt(this.selectedCompanyId);
                  this.customListOptions.customSectionList.forEach(item => item.companyId = selectedCoId);
                  this.customListOptions.customStepList.forEach(item => item.companyId = selectedCoId);
                  this.customListOptions.sectionList.forEach(item => item.companyId = selectedCoId);
                  this.customListOptions.stepList.forEach(item => item.companyId = selectedCoId);
                  this.apiService.saveCustomQuestions(data.data, this.customListOptions)
                    .pipe(takeUntil(this.observableSubscription$))
                    .subscribe((res: any) => {
                      if (res) {
                        //console.log('daaatttaaaa', res);
                      }
                    });

                    this.apiService.getAccessCodeAndLinkForBeneficiary(reqLoad.beneficiaryId).subscribe((data: any) => {
                      this.isViewEditDisabled = false;
                      this.beneReqLinkDetails = data;
                      if(viewEdit){
                        localStorage.setItem('accessCode',this.beneReqLinkDetails?.AccessToken);
                        window.open( `beneficiary-profile`, '_blank');
                      }
                    })
                }

                this.toastr.success(data.message);
                if(this.isWizard){
                  this.onSaveCancel.emit({cancel: false, save:true});
                }else{
                  //this.redirectToListing();
                }
              }else{
                this.isViewEditDisabled = true;
              }
            });
          }
        } else {
          if(this.isCaseRequestSatus){
            payLoad = this.subLevelPortion(payLoad);
          }
          this.saveCaseRequestCommon(reqLoad, payLoad);
        }
        
      }
    } else {
      this.toastr.error("Please select any section!");
    }
  }

  subLevelPortion(payLoad: any){
    let subEducationDetails: any = [];
    payLoad.qstepDto.beneficiaryEducationInfoForCaseRequest.qfamilySubSectionList.filter(item=>item.selected).forEach(x=> {
      subEducationDetails.push({
        id: x.referenceLibrarysubSection.id,
        group: x.referenceLibrarysubSection.group,
        code: x.referenceLibrarysubSection.code,
        name: x.referenceLibrarysubSection.name,
        selected: x.selected
      })
    });
    if(subEducationDetails.length > 0 && payLoad.qstepDto.beneficiaryProfileInfo){
      payLoad.qstepDto.beneficiaryProfileInfo.qfamilySubSectionList.find(x => x.referenceLibrarysubSection.code == "QTPROFILESSEDUC").subLevel = [...subEducationDetails];
    }
    let subImmigrationDetails: any = [];
    payLoad.qstepDto.beneficiaryImmigrationInfoForCaseRequest.qfamilySubSectionList.filter(item=>item.selected).forEach(x=> {
      subImmigrationDetails.push({
        id: x.referenceLibrarysubSection.id,
        group: x.referenceLibrarysubSection.group,
        code: x.referenceLibrarysubSection.code,
        name: x.referenceLibrarysubSection.name,
        selected: x.selected
      })
    });
    if(subImmigrationDetails.length > 0 && payLoad.qstepDto.beneficiaryProfileInfo){
      payLoad.qstepDto.beneficiaryProfileInfo.qfamilySubSectionList.find(x => x.referenceLibrarysubSection.code == "QTPROFILESSIMM").subLevel = [...subImmigrationDetails];
    }
    let subPersonalDetails: any = [];
    payLoad.qstepDto.beneficiaryProfileInfoForCaseRequest.qfamilySubSectionList.filter(item=>item.selected).forEach(x=> {
      subPersonalDetails.push({
        id: x.referenceLibrarysubSection.id,
        group: x.referenceLibrarysubSection.group,
        code: x.referenceLibrarysubSection.code,
        name: x.referenceLibrarysubSection.name,
        selected: x.selected
      })
    });
    if(subPersonalDetails.length > 0 && payLoad.qstepDto.beneficiaryProfileInfo){
      payLoad.qstepDto.beneficiaryProfileInfo.qfamilySubSectionList.find(x => x.referenceLibrarysubSection.code == "QTPROFILESSPERS").subLevel = [...subPersonalDetails];
    }
    let subWorkExperienceDetails: any = [];
    payLoad.qstepDto.beneficiaryWorkExperienceInfoForCaseRequest.qfamilySubSectionList.filter(item=>item.selected).forEach(x=> {
      subWorkExperienceDetails.push({
        id: x.referenceLibrarysubSection.id,
        group: x.referenceLibrarysubSection.group,
        code: x.referenceLibrarysubSection.code,
        name: x.referenceLibrarysubSection.name,
        selected: x.selected
      })
    });
    if(subWorkExperienceDetails.length > 0 && payLoad.qstepDto.beneficiaryProfileInfo){
      payLoad.qstepDto.beneficiaryProfileInfo.qfamilySubSectionList.find(x => x.referenceLibrarysubSection.code == "QTPROFILESSWRKEXP").subLevel = [...subWorkExperienceDetails];
    }

    payLoad.qstepDto.beneficiaryProfileInfo?.qfamilySubSectionList?.forEach((x)=> {
      if(!x.selected){
        x.subLevel = [];
      }
    });

    return payLoad;
  }

  saveCaseRequestCommon(reqLoad, payLoad){
    this.apiService.saveCaseRequestDataDocuments(reqLoad, payLoad, this.isCaseRequestSatus, this.isBeneficiaryQuestionnaireStatus)
    .pipe(takeUntil(this.observableSubscription$))
    .subscribe((data: any) => {
      if (data) { 
        if (data.data && this.customListOptions) {
          let selectedCoId = parseInt(this.selectedCompanyId);
          this.customListOptions.customSectionList.forEach(item => item.companyId = selectedCoId);
          this.customListOptions.customStepList.forEach(item => item.companyId = selectedCoId);
          this.customListOptions.sectionList.forEach(item => item.companyId = selectedCoId);
          this.customListOptions.stepList.forEach(item => item.companyId = selectedCoId);
          this.apiService.saveCustomQuestions(data.data, this.customListOptions)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((res: any) => {
              if (res) {
                console.log('daaatttaaaa', res);
              }
            });
        }

        this.toastr.success(data.message);
        this.onClickBack();
      }
    });
  }

  customListOptionsModify(eachData) {
    if (eachData.keyName == 'beneficiaryProfileInfo' || eachData.keyName == 'qfamilyDependents' || eachData.keyName == 'qfamilyOtherDependents' || eachData.keyName == 'additionalPetitionInfo') {
      eachData.options.forEach((mainData) => {
        mainData.cusSubOptions.forEach((cusSubOption: any) => {
          this.modifyQuestionsData(cusSubOption, mainData, cusSubOption.type);
        });
      });
    }
  }

  modifyQuestionsData(cusSubOption, mainData, type) {
    if (type == 'section') {
      this.customListOptions.sectionList.forEach((item) => {
        if ((cusSubOption.id === item.id) && (cusSubOption.type == 'section')) {
          if (item.stakeHoldersSelected.length !== 0) {
            item.stakeHoldersSelected.forEach((res) => {
              if (res.key == mainData.code) {
                res.selected = cusSubOption.selected;
              }
            });
          }
        }
      });
      this.customListOptions.stepList.forEach((item) => {
        if ((cusSubOption.id === item.id) && (cusSubOption.type == 'section')) {
          item.selected = cusSubOption.selected;
        }
      });
    }
    if (type == 'custSection') {
      this.customListOptions.customSectionList.forEach((item) => {
        if ((cusSubOption.id === item.id) && (cusSubOption.type == 'custSection')) {
          if (item.stakeHoldersSelected.length !== 0) {
            item.stakeHoldersSelected.forEach((res) => {
              if (res.key == mainData.code) {
                res.selected = cusSubOption.selected;
              }
            });
          }
        }
      });
      this.customListOptions.customStepList.forEach((item) => {
        if ((cusSubOption.id === item.id) && (cusSubOption.type == 'custSection')) {
          item.selected = cusSubOption.selected;
        }
      });
    }
  }

  findSelectedItems(data) {
    let finalResult = [];
    if (data.additionalPetitionInfo) {
      finalResult.push(data.additionalPetitionInfo.find(o => o.selected === true) ? true : undefined);
    }
    if (data.beneficiaryProfileInfo) {
      finalResult.push(data.beneficiaryProfileInfo.qfamilySubSectionList.find(o => o.selected === true) ? true : undefined);
    }
    if (data.beneficiaryEducationInfoForCaseRequest) {
      finalResult.push(data.beneficiaryEducationInfoForCaseRequest.qfamilySubSectionList.find(o => o.selected === true) ? true : undefined);
    }
    if (data.beneficiaryImmigrationInfoForCaseRequest) {
      finalResult.push(data.beneficiaryImmigrationInfoForCaseRequest.qfamilySubSectionList.find(o => o.selected === true) ? true : undefined);
    }
    if (data.beneficiaryProfileInfoForCaseRequest) {
      finalResult.push(data.beneficiaryProfileInfoForCaseRequest.qfamilySubSectionList.find(o => o.selected === true) ? true : undefined);
    }
    if (data.beneficiaryWorkExperienceInfoForCaseRequest) {
      finalResult.push(data.beneficiaryWorkExperienceInfoForCaseRequest.qfamilySubSectionList.find(o => o.selected === true) ? true : undefined);
    }
    if (data.documentCheckList) {
      finalResult.push(data.documentCheckList.find(item => item.qtitleSheetDetailDto.find(o => o.selected === true)) ? true : undefined);
    }
    if (data.qfamilyDependents) {
      finalResult.push(data.qfamilyDependents.find(item =>
        item.qfamilySubSectionList.find(o => o.selected === true)) ? true : undefined);
    }
    if (data.qfamilyOtherDependents) {
      finalResult.push(data.qfamilyOtherDependents.find(item =>
        item.qfamilySubSectionList.find(o => o.selected === true)) ? true : undefined);
    }

    return finalResult.find(res => res === true ? true : undefined);
  }

  markCompleteStatus() {

    if (this.dynamicData.data.additionalPetitionInfoCompleted) {
      if (this.dynamicData.data.additionalPetitionInfo) {
        this.dynamicData.data.additionalPetitionInfo.forEach((item: any) => {
          if (item.selected === true && item.markComplete === false) {
            this.dynamicData.data.additionalPetitionInfoCompleted = false;
          }
        })
      }
    }

    if (this.dynamicData.data.beneficiaryProfileInfoDetailsCompleted) {
      if (this.dynamicData.data.beneficiaryProfileInfo) {
        this.dynamicData.data.beneficiaryProfileInfo.qfamilySubSectionList.forEach((item: any) => {
          if (item.selected === true && item.markComplete === false) {
            this.dynamicData.data.beneficiaryProfileInfoDetailsCompleted = false;
          }
        })
      }
    }

    if (this.dynamicData.data.documentCheckListCompleted) {
      if (this.dynamicData.data.documentCheckList) {
        this.dynamicData.data.documentCheckList.forEach((item: any) => {
          item.qtitleSheetDetailDto.forEach((res: any) => {
            if (res.selected === true && res.markComplete === false) {
              this.dynamicData.data.documentCheckListCompleted = false;
            }
          })
        })
      }
    }

    if (this.dynamicData.data.qfamilyDependentsCompleted) {
      if (this.dynamicData.data.qfamilyDependents) {
        this.dynamicData.data.qfamilyDependents.forEach((item: any) => {
          item.qfamilySubSectionList.forEach((res: any) => {
            if (res.selected === true && res.markComplete === false) {
              this.dynamicData.data.qfamilyDependentsCompleted = false;
            }
          })
        })
      }
    }

    if (this.dynamicData.data.qfamilyOtherDependentsCompleted) {
      if (this.dynamicData.data.qfamilyOtherDependents) {
        this.dynamicData.data.qfamilyOtherDependents.forEach((item: any) => {
          item.qfamilySubSectionList.forEach((res: any) => {
            if (res.selected === true && res.markComplete === false) {
              this.dynamicData.data.qfamilyOtherDependentsCompleted = false;
            }
          })
        })
      }
    }

    return this.dynamicData.data;
  }

  selectAllCheckboxes(event: any, option: any) {
    option.subOptions.forEach((subOption: any) => {
      if (subOption.isDisabled) {
        subOption.selected = false;
        if(this.isCaseRequestSatus || this.isRequestDataDocument){
          this.selectedSubOptionLevel(subOption);
        }
      } else {
        subOption.selected = event.checked;
        if(this.isCaseRequestSatus || this.isRequestDataDocument){
          this.selectedSubOptionLevel(subOption);
        }
      }
    });
    if (option.cusSubOptions) {
      option.cusSubOptions.forEach((cusSubOptions: any) => {
        if (cusSubOptions.isDisabled) {
          cusSubOptions.selected = false;
        } else {
          cusSubOptions.selected = event.checked;
        }
      });
    }
    option.allSelected = event.checked;
  }

  setSubOptionSelected(event: any, subOption: any, option: any) {
    subOption.selected = event.checked;
    let allSelected: boolean = true;
    if(subOption.interdependent){
      option.subOptions.forEach((subOpt: any) => {
        if((subOpt.layOutCode==subOption.layOutCode) && subOption.selected){
          if(subOption.interdependent.parent){
            subOption.interdependent.child.forEach((item)=> {
              option.subOptions.find(x=> x.layOutCode==item).selected = true; 
              if(option.subOptions.find(x=> x.layOutCode==item)){
                this.uiData.find(item=>item.keyName== "additionalPetitionInfo").options[0].subOptions.find(y=> y.layOutCode==item).isDefaultSelected = true;
              }
            });
          } else {
            option.subOptions.find(x=> x.layOutCode == subOption.interdependent.parentCode).selected = true;
            option.subOptions.find(x=> x.layOutCode == subOption.interdependent.parentCode).isDefaultSelected = true;
            option.subOptions.find(x=> x.layOutCode == subOption.interdependent.parentCode).interdependent.child.forEach((item)=> {
              option.subOptions.find(x=> x.layOutCode==item).selected = true; 
              if(option.subOptions.find(x=> x.layOutCode==item)){
                if(subOption.layOutCode==item){
                  this.uiData.find(item=>item.keyName== "additionalPetitionInfo").options[0].subOptions.find(y=> y.layOutCode==item).isDefaultSelected = false;
                } else {
                  this.uiData.find(item=>item.keyName== "additionalPetitionInfo").options[0].subOptions.find(y=> y.layOutCode==item).isDefaultSelected = true;
                }
              }
            });
          }
        }
        if((subOpt.layOutCode==subOption.layOutCode) && !subOption.selected){
          if(subOption.interdependent.parent){
            this.uiData.find(item=>item.keyName== "additionalPetitionInfo").options[0].subOptions.find(y=> y.layOutCode==subOption.layOutCode).isDefaultSelected = false;
            subOption.interdependent.child.forEach((item)=> {
              option.subOptions.find(x=> x.layOutCode==item).selected = false;
              if(option.subOptions.find(x=> x.layOutCode==item)){
                this.uiData.find(item=>item.keyName== "additionalPetitionInfo").options[0].subOptions.find(y=> y.layOutCode==item).isDefaultSelected = false;
              }
            });
          } else {
            option.subOptions.find(x=> x.layOutCode == subOption.interdependent.parentCode).selected = false;
            option.subOptions.find(x=> x.layOutCode == subOption.interdependent.parentCode).isDefaultSelected = false;
            option.subOptions.find(x=> x.layOutCode == subOption.interdependent.parentCode).interdependent.child.forEach((item)=> {
              option.subOptions.find(x=> x.layOutCode==item).selected = false; 
              if(option.subOptions.find(x=> x.layOutCode==item)){
                this.uiData.find(item=>item.keyName== "additionalPetitionInfo").options[0].subOptions.find(y=> y.layOutCode==item).isDefaultSelected = false;
              }
            });
          }
        } 
      });
    }
    
    option.subOptions.forEach((subOption: any) => {
      allSelected = allSelected && subOption.selected;
    });
    if (option.cusSubOptions) {
      option.cusSubOptions.forEach((cusSubOptions: any) => {
        allSelected = allSelected && cusSubOptions.selected;
      });
    }
    option.allSelected = allSelected;

    if(this.isCaseRequestSatus || this.isRequestDataDocument){
      this.selectedSubOptionLevel(subOption);
    }
    
  }

  selectedSubOptionLevel(subOption){
    if(subOption.displayLabel == "Education Details"){
      if(subOption.selected){
        this.uiData.find((item) => item.keyName == "beneficiaryEducationInfoForCaseRequest").customDisabled = false;
      }else {
        this.uiData.find((item) => item.keyName == "beneficiaryEducationInfoForCaseRequest").customDisabled = true;
        //this.selectAllCustomCheckboxes({checked: false}, this.uiData.find((item) => item.keyName == "beneficiaryEducationInfoForCaseRequest").options[0]);
      }
      this.selectAllCustomCheckboxes({checked: subOption.selected}, this.uiData.find((item) => item.keyName == "beneficiaryEducationInfoForCaseRequest").options[0]);
    } 
    if(subOption.displayLabel == "Personal Details"){
      if(subOption.selected){
        this.uiData.find((item) => item.keyName == "beneficiaryProfileInfoForCaseRequest").customDisabled = false;
      }else {
        this.uiData.find((item) => item.keyName == "beneficiaryProfileInfoForCaseRequest").customDisabled = true;
        //this.selectAllCustomCheckboxes({checked: false}, this.uiData.find((item) => item.keyName == "beneficiaryProfileInfoForCaseRequest").options[0]);
      }
      this.selectAllCustomCheckboxes({checked: subOption.selected}, this.uiData.find((item) => item.keyName == "beneficiaryProfileInfoForCaseRequest").options[0]);
    }
    if(subOption.displayLabel == "Immigration Details"){
      if(subOption.selected){
        this.uiData.find((item) => item.keyName == "beneficiaryImmigrationInfoForCaseRequest").customDisabled = false;
      }else {
        this.uiData.find((item) => item.keyName == "beneficiaryImmigrationInfoForCaseRequest").customDisabled = true;
        //this.selectAllCustomCheckboxes({checked: false}, this.uiData.find((item) => item.keyName == "beneficiaryImmigrationInfoForCaseRequest").options[0]);
      }
      this.selectAllCustomCheckboxes({checked: subOption.selected}, this.uiData.find((item) => item.keyName == "beneficiaryImmigrationInfoForCaseRequest").options[0]);
    }
    if(subOption.displayLabel == "Work Experience Details"){
      const itemFound = this.uiData.find((item) => item.keyName == "beneficiaryWorkExperienceInfoForCaseRequest");
      // if(subOption.selected){
        // const itemFound = this.uiData.find((item) => item.keyName == "beneficiaryWorkExperienceInfoForCaseRequest");
        // if(itemFound){
        //     itemFound.customDisabled = false;
        //   }
      // }else {
      //   const itemFound = this.uiData.find((item) => item.keyName == "beneficiaryWorkExperienceInfoForCaseRequest");
      //   if(itemFound){
      //     itemFound.customDisabled = true;
      //   }
      //   //this.selectAllCustomCheckboxes({checked: false}, itemFound ? itemFound.options[0] : []);
      // }
      if(itemFound){
        itemFound.customDisabled = !subOption.selected
      }
      this.selectAllCustomCheckboxes({checked: subOption.selected}, itemFound ? itemFound.options[0] : []);

    }
  }

  selectAllCustomCheckboxes(event: any, option: any) {
    option?.subOptions?.forEach((subOption: any) => {
      if (subOption.isDisabled) {
        subOption.selected = false;
      } else {
        subOption.selected = event.checked;
      }
    });
    if (option?.cusSubOptions) {
      option.cusSubOptions.forEach((cusSubOptions: any) => {
        if (cusSubOptions.isDisabled) {
          cusSubOptions.selected = false;
        } else {
          cusSubOptions.selected = event.checked;
        }
      });
    }
    if(option?.subOptions?.length){
      option.allSelected = event.checked;
    }
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  onTaskTypeChange() {
    this.requestData = new RequestData({
      superTaskTypeId: this.requestData.superTaskTypeId
    });
    if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
      this.requestData.superTaskTypeId = this.isCaseRequestEditSatusValue.taskTypeCd;  
    } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
      this.requestData.superTaskTypeId = this.isBeneficiaryQuestionnaireStatusValue.taskTypeCd;
    } else if(this.taskLevelStatus){
      this.requestData.superTaskTypeId = this.taskLevelStatusData.taskTypeCd;
    }
    this.getImmigrationVisaType();
  }

  getImmigrationVisaType() {
    if (this.requestData.superTaskTypeId === 'NONIMMPET' && (!this.visaTypeList || (this.visaTypeList && this.visaTypeList.length === 0))) {
      this.referenceLibraryService.getReferenceData('VISATYP')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.visaTypeList = response;
          if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
            this.requestData.visaType = this.isCaseRequestEditSatusValue.visaTypeCd;  
          } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
            this.requestData.visaType = this.isBeneficiaryQuestionnaireStatusValue.visaTypeCd;
          } else if(this.taskLevelStatus){
            this.requestData.visaType = this.taskLevelStatusData.visaTypeCd;
          }

          if (this.requestData.visaType) {
            this.getPetitionList();
          }
        });
    } else if (this.requestData.superTaskTypeId === 'IMMPET' &&
      (!this.immigrationTypeList || (this.immigrationTypeList && this.immigrationTypeList.length === 0))) {
      this.referenceLibraryService.getReferenceData('GCIMMCATG')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          // response = response.filter(ele => ele.code === 'GCEB');
          this.immigrationTypeList = response;
          if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
            this.requestData.immCategoryType = this.isCaseRequestEditSatusValue.visaTypeCd;  
          } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
            this.requestData.immCategoryType = this.isBeneficiaryQuestionnaireStatusValue.visaTypeCd;  
          } else if(this.taskLevelStatus){
            this.requestData.immCategoryType = this.taskLevelStatusData.visaTypeCd;
          }
          if (this.requestData.immCategoryType) {
            this.getImmigrationClassification();
          }
        });
    }
  }

  getImmigrationClassification() {
    this.requestData.immClassificationType = null;
    this.referenceLibraryService.getReferenceData(this.requestData.immCategoryType).subscribe((response: ReferenceLibrary[]) => {
      this.immigrationClassificationList = response;
      if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
        this.requestData.immClassificationType = this.isCaseRequestEditSatusValue.petitionTypeCd;
        this.onImmigrationClassificationChange();
      } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
        this.requestData.immClassificationType = this.isBeneficiaryQuestionnaireStatusValue.petitionTypeCd;
        this.onImmigrationClassificationChange();
      } else if(this.taskLevelStatus){
        this.requestData.immClassificationType = this.taskLevelStatusData.petitionTypeCd;
        this.onImmigrationClassificationChange();
      }
    });
  }

  getSubTaskTypeListForFamilyImmigration() {
    this.dashboardWidgetsService.getSubTaskTypeListForFamilyImmigration(
      this.requestData.superTaskTypeId, this.requestData.immCategoryType,
      this.requestData.immClassificationType)
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response) {
          this.subTaskTypeList = response;
          this.subTaskTypeList = [...new Map(this.subTaskTypeList.map(item => [item['code'], item])).values()];

          if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
            this.requestData.taskTypeRefId = this.isCaseRequestEditSatusValue.subTaskTypeCd; 
            this.getVisaTemplates(); 
          } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
            this.requestData.taskTypeRefId = this.isBeneficiaryQuestionnaireStatusValue.subTaskTypeCd; 
            this.getVisaTemplates(); 
          } else if(this.taskLevelStatus){
            this.requestData.taskTypeRefId = this.taskLevelStatusData.subTaskTypeCd;
            this.getVisaTemplates(); 
          }

        }
      });
  }

  getSubTaskTypeList() {
    this.dashboardWidgetsService.getSubTasksTypeListImmigrant(this.requestData.immCategoryType, this.requestData.immClassificationType)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.subTaskTypeList = response.subTaskTypeList;
        this.subTaskTypeList = [...new Map(this.subTaskTypeList.map(item => [item['code'], item])).values()];
        if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
          this.requestData.taskTypeRefId = this.isCaseRequestEditSatusValue.subTaskTypeCd;
          this.getVisaTemplates();  
        } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
          this.requestData.taskTypeRefId = this.isBeneficiaryQuestionnaireStatusValue.subTaskTypeCd;
          this.getVisaTemplates();  
        } else if(this.taskLevelStatus){
          this.requestData.taskTypeRefId = this.taskLevelStatusData.subTaskTypeCd;
          this.getVisaTemplates();
        }
      });
  }

  getPetitionList() {
    this.requestData.petitionType = null;
    this.requestData.immClassificationType = null;
    const visaTypeSelected = this.requestData.visaType || null;

    let companyListReq;
    switch (visaTypeSelected) {
      case 'H1-B':
        if (this.userType === 'Petitioner') {
          companyListReq = this.petitionerService.getPetitionerClientList(this.primaryCompanyId);
        } else if (this.userType === 'Attorney') {
          const reqJson = {
            searchText: '',
            searchCriteria: '',
            sortBy: '',
            sortOrder: '',
            pageNumber: 0,
            recordsPerPage: 0
          };
          companyListReq = this.attorneyService.getClientsList(this.primaryCompanyId, reqJson);
        }
        combineLatest([
          this.createTaskService.getPetitionList(visaTypeSelected),
          companyListReq
        ])
          .pipe(take(1))
          .subscribe((response: any[]) => {
            this.petitionTypeList = response[0].map(x => {
              return {
                code: x.code,
                name: x.name,
                id: x.id,
                desc: x.description,
                group: x.group
              };
            });
            if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
              this.requestData.petitionType = this.isCaseRequestEditSatusValue.petitionTypeCd;  
            } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
              this.requestData.petitionType = this.isBeneficiaryQuestionnaireStatusValue.petitionTypeCd;  
            } else if(this.taskLevelStatus){
              this.requestData.petitionType = this.taskLevelStatusData.petitionTypeCd;
            }
            if (this.requestData.petitionType) {
              this.getSubtaskForNonImmigrant();
            }
          });
        break;
      default:
        // this.fields.disableIsOutsideImagility = false;
        const req = [
          this.createTaskService.getPetitionList(visaTypeSelected)
        ];
        if (this.userType === 'Petitioner') {
          companyListReq = this.petitionerService.getPetitionerClientList(this.primaryCompanyId, 'countryCode');
          req.push(companyListReq);
        } else if (this.userType === 'Attorney') {
          const reqJson = {
            searchText: '',
            searchCriteria: '',
            sortBy: '',
            sortOrder: '',
            pageNumber: 0,
            recordsPerPage: 0
          };
          companyListReq = this.attorneyService.getClientsList(this.primaryCompanyId, reqJson);
          req.push(companyListReq);
        }
        combineLatest(req)
          .pipe(take(1))
          .subscribe((response: any[]) => {
            this.petitionTypeList = response[0].map(x => {
              return {
                code: x.code,
                name: x.name,
                id: x.id,
                desc: x.description,
                group: x.group
              };
            });
            if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
              this.requestData.petitionType = this.isCaseRequestEditSatusValue.petitionTypeCd;  
            } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
              this.requestData.petitionType = this.isBeneficiaryQuestionnaireStatusValue.petitionTypeCd;  
            } else if(this.taskLevelStatus){
              this.requestData.petitionType = this.taskLevelStatusData.petitionTypeCd;
            }
            if (this.requestData.petitionType) {
              this.getSubtaskForNonImmigrant();
            }
          });
        break;
    }
  }

  getSubtaskForNonImmigrant() {
    this.subTaskTypeList = [];
    this.dashboardWidgetsService.getSubTasksType(this.requestData.superTaskTypeId, this.requestData.petitionType,
      this.requestData.visaType).subscribe((response: ReferenceLibrary[]) => {
        if (response && response.length > 0) {
          this.subTaskTypeList = response.filter(ele => ele.code !== 'RFE');

          if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
            this.requestData.taskTypeRefId = this.isCaseRequestEditSatusValue.subTaskTypeCd;
            this.getVisaTemplates();
          } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
            this.requestData.taskTypeRefId = this.isBeneficiaryQuestionnaireStatusValue.subTaskTypeCd;
            this.getVisaTemplates();
          } else if(this.taskLevelStatus){
            this.requestData.taskTypeRefId = this.taskLevelStatusData.subTaskTypeCd;
            this.getVisaTemplates();
          }

        }
      });
  }

  onPetitionTypeChange() {
    this.requestData = new RequestData({
      // templateType: 'HTML',
      // companyId: this.template.companyId ?? null,
      // documentType: this.template.documentType ?? null,
      superTaskTypeId: this.requestData.superTaskTypeId,
      visaType: this.requestData.visaType,
      petitionType: this.requestData.petitionType
    });
    this.getSubtaskForNonImmigrant();
  }

  onImmigrationClassificationChange() {
    this.requestData.petitionType = null;
    this.requestData = new RequestData({
      // templateType: 'HTML',
      // companyId: this.template.companyId ?? null,
      // documentType: this.template.documentType ?? null,
      superTaskTypeId: this.requestData.superTaskTypeId,
      immCategoryType: this.requestData.immCategoryType,
      immClassificationType: this.requestData.immClassificationType
    });

    if (this.requestData.immCategoryType && this.requestData.immCategoryType === 'GCFLY') {
      this.getSubTaskTypeListForFamilyImmigration();
    } else {
      this.getSubTaskTypeList();
    }
  }

  getVisaTemplates() {
    this.visaTemplates = [];
    const payload = {
      formType: 'I129FORM',
      countryCode: 'USA',
      language: 'ENG',
      petitionType: this.requestData?.petitionType || "",
      subTaskType: this.requestData.taskTypeRefId,
      taskType: this.requestData.superTaskTypeId,
      visaType: this.requestData?.visaType || "",
      immCategory: this.requestData?.immCategoryType || "",
      immClassification: this.requestData?.immClassificationType || ""
    };

    if (payload.taskType === 'NONIMMPET') {
      // payload.hasOwnProperty('property1')
      delete payload.immCategory;
      delete payload.immClassification;
    } else {
      delete payload.visaType;
      delete payload.petitionType;
    }
    this.createTaskService.getVisaTemplates(payload)
      .pipe(take(1))
      .subscribe((response: any[]) => {
        this.visaTemplates = response || [];
        if (standardVisas.includes(payload.visaType) || standardImmClassification.includes(payload.immClassification) || standardSubtasks.includes(payload.subTaskType)) {
          this.visaTemplates.push({ 'visaId': 0, 'visaName': 'STANDARD WORKFLOW' });
        }


        if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
          this.requestData.visaId = parseInt(this.isCaseRequestEditSatusValue.visaTemplateCode);
          // this.requestData.visaId = 0;  
          if(this.requestData.visaId && this.requestData.visaId!==0){
            this.getVisaTemplateJson();
          } else {
            if (standardVisas.includes(payload.visaType) || standardImmClassification.includes(payload.immClassification) || standardSubtasks.includes(payload.subTaskType)) {
              this.requestData.visaId = 0;
            }
            this.generateDynamicGroup();
          }
        } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
          this.requestData.visaId = parseInt(this.isBeneficiaryQuestionnaireStatusValue.visaTemplateCode);
          // this.requestData.visaId = 0;  
          if(this.requestData.visaId && this.requestData.visaId!==0){
            this.getVisaTemplateJson();
          } else {
            if (standardVisas.includes(payload.visaType) || standardImmClassification.includes(payload.immClassification) || standardSubtasks.includes(payload.subTaskType)) {
              this.requestData.visaId = 0;
            }
            this.generateDynamicGroup();
          }
        }  else if(this.taskLevelStatus){
          // this.requestData.superTaskTypeId = this.taskLevelStatusData.taskTypeCd;
          this.requestData.visaId = parseInt(this.taskLevelStatusData.visaTemplateCode);
          // this.requestData.visaId = 0;  
          if(this.requestData.visaId && this.requestData.visaId!==0){
            this.getVisaTemplateJson();
          } else {
            if (standardVisas.includes(payload.visaType) || standardImmClassification.includes(payload.immClassification) || standardSubtasks.includes(payload.subTaskType)) {
              this.requestData.visaId = 0;
            }
            this.generateDynamicGroup();
          }
        }

      });
  }

  getVisaTemplateJson() {
    if (this.requestData.visaId) {
      this.createTaskService.getVisaTemplateJSON(this.requestData.visaId)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.requestData.taskTypeId = res.taskTypeId;
          if(this.isCaseRequestSatus && this.isCaseRequestEditSatus){
            this.generateDynamicGroup();
          } else if(this.isBeneficiaryQuestionnaireStatus && this.isBeneficiaryQuestionnaireEditSatus){
            this.generateDynamicGroup();
          }  else if(this.taskLevelStatus){
            this.generateDynamicGroup();
          }
        });
    }
  }

  addNewTask() {
    sessionStorage.removeItem('taskId');
    sessionStorage.removeItem('custQuestion');
    sessionStorage.setItem('requestDataDocument', JSON.stringify(this.beneficiaryDetails));
    this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/new`]);
  }

  onClickBack() {
    this.router.navigate([
      `${RolesRouteMap.userRoleRouteMapping[
      sessionStorage.getItem("userTypeRole")
      ]
      }/custom-questionaire`,
    ],{ queryParams: { index: this.isCaseRequestSatus ? 2: (this.isBeneficiaryQuestionnaireStatus ? 3: '') } });
  }

  viewEdit(){
    // if(!this.beneReqLinkDetails?.AccessToken){
    //   this.apiService.getAccessCodeAndLinkForBeneficiary(this.taskLevelStatus ? this.taskLevelStatusData.id : this.beneficiaryDetails.beneficiaryEmployeeId).subscribe((data: any) => {
    //     if(data?.AccessToken){
    //       this.beneReqLinkDetails = data;
    //       localStorage.setItem('accessCode',this.beneReqLinkDetails?.AccessToken);
    //       window.open( `beneficiary-profile`, '_blank');
    //     }else{
    //       // Save the data log first and then redirect to the bene profile questionaire
    //       this.customMappingUI(true);
    //     }
        
    //   });
    // }else{
    //   localStorage.setItem('accessCode',this.beneReqLinkDetails?.AccessToken);
    //   window.open( `beneficiary-profile`, '_blank');
    // }


    // Save the data log first and then redirect to the bene profile questionaire
    this.customMappingUI(true);
  }

  ngOnDestroy() {
    sessionStorage.removeItem('taskTypeId');
  }

}
