<h5 class="mb-0" mat-dialog-title>
  <div class="col-md-12 p-0 pb-2 row modal-header">
    <div class="col-md-10 p-0">
      {{title}}
    </div>
    <div class="col-md-2 p-0 justify-content-end d-flex">
      <i (click)="handleNo();" class="close-icon pi pi-times"></i>
    </div>
  </div>
</h5>
<div class="message" mat-dialog-content>
  <p class="migration-count">{{migrationData.length}} {{migrationData.length > 1 ? 'Individuals' : 'Individual'}}
    selected to be Migrated / Onboarded</p>
  <p>Application: <strong>HR</strong></p>
  <p>We are migrating the data of the selected Individuals accounts from <strong>[Immigration Application]</strong> to
    <strong>[HR Application]</strong>.</p>
  <p><strong>[Immigration Application]</strong> <span class="fa fa-long-arrow-right"></span> <strong>[HR
      Application]</strong>.</p>
  <p>Your data and settings will be securely tranferred to ensure a seamless experience.</p>
</div>

<div mat-dialog-actions class="float-right _action">
  <button mat-button (click)="onDismiss()">{{buttons[0]}}</button>
  <button mat-flat-button color="default" (click)="onConfirm()" class="_btn">{{buttons[1]}}</button>
</div>