import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-out',
  templateUrl: './time-out.component.html',
  styleUrls: ['./time-out.component.scss']
})
export class TimeOutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
