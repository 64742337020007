<div class="validity-rule mb--20">
  <ul>
    <li class="flex-row" *ngIf="checkUserIdAvailability">
      <span class="material-icons valid-icon" *ngIf="userAvailable">
        check_circle
      </span>
      <span class="material-icons not-valid-icon" *ngIf="!userAvailable">
        cancel
      </span>
      <span class="pl-1">
        User Name / Id is available
      </span>
    </li>
    <li class="flex-row">
      <span class="material-icons valid-icon" *ngIf="validateInput('totalCount')">
        check_circle
      </span>
      <span class="material-icons not-valid-icon" *ngIf="!validateInput('totalCount')">
        cancel
      </span>
      <span class="pl-1">
        Must be within 5 to 12 characters
      </span>
    </li>
    <li class="flex-row">
      <span class="material-icons valid-icon" *ngIf="validateInput('aA1')">
        check_circle
      </span>
      <span class="material-icons not-valid-icon" *ngIf="!validateInput('aA1')">
        cancel
      </span>
      <span class="pl-1">
        Must contain atleast 1 alphabet (A-Z, a-z) character(s)
      </span>
    </li>
    <li class="flex-row">
      <span class="material-icons valid-icon" *ngIf="validateInput('number')">
        check_circle
      </span>
      <span class="material-icons not-valid-icon" *ngIf="!validateInput('number')">
        cancel
      </span>
      <span class="pl-1">
        Must contain atleast 1 number(0-9) characters(s)
      </span>
    </li>
    <li class="flex-row">
      <span class="material-icons valid-icon" *ngIf="validateInput('specChar')">
        check_circle
      </span>
      <span class="material-icons not-valid-icon" *ngIf="!validateInput('specChar')">
        cancel
      </span>
      <span class="pl-1">
        May contain special character(s)
        <small>(.-_)</small>
      </span>
    </li>
  </ul>
</div>
