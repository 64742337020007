<form #f="ngForm" [formGroup]="creatingUserForm">
    <div class="row boder-dashed highlighted-box p-2">
        <div class="col-12 px-0">
            <div class="row" class="font-weight-bold mb-2">
                <div class="col-12 px-0">
                  Enter details of users (of client) who will be using Imagility.
                </div>
            </div>
            <div class="row">
                <div class="col-4 px-0 d-flex">
                    <label>Role :</label>
                    <div class="row pl-2" *ngIf="companyType === 'Petitioner';else attorneyFlow">
                        Petitioner Admin
                    </div>
                    <ng-template #attorneyFlow>
                      <div class="row pl-2">
                        Lawfirm Admin
                      </div>
                    </ng-template>    
                </div>
                <div class="col-4 px-2 d-flex">
                  <p-checkbox styleClass="checkbox-label-allign" name="grantAccess"
                            formControlName="isSendInvite" binary="true" class="align-middle"></p-checkbox>
                  <label class="checkBoxLabel mb-0 pl-2">Send Invite</label>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-4 px-0">
                    <label class="required">First Name</label>
                    <input [disabled]="disableFields" pInputText type="text" class="im-input" formControlName="firstName" placeholder="Enter" />
                </div>
                <div class="col-4">
                    <label class="required">Last Name</label>
                    <input [disabled]="disableFields" pInputText type="text" class="im-input" formControlName="lastName" placeholder="Enter" />
                </div>
                <div class="col-4">
                    <label>Middle Name (optional)</label>
                    <input [disabled]="disableFields" pInputText type="text" class="im-input" formControlName="middleName" placeholder="Enter" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-4 px-0">
                    <label class="required">Communication Email</label>
                    <input [disabled]="disableFields" pInputText type="email" class="im-input" formControlName="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$" placeholder="Enter" />
                </div>
                <div class="col-4">
                    <label class="required">Phone Number</label>
                    <div class="row">
                        <div class="col-3 px-0">
                            <p-dropdown [options]="listCountry" formControlName="countryCode" [disabled]="disableFields"
                            optionLabel="countryPhoneCode" placeholder="Select" class="d-block" 
                            optionValue="countryCode" name="phoneCode" required>
                            </p-dropdown>
                        </div>
                        <div class="col-9 px-0">
                            <input pInputText class="im-input" name="phoneNo" maxlength="10" [disabled]="disableFields"
                            formControlName="phoneNumber" type="text" form placeholder="Office" required
                            pattern="\d{1,10}" />
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <label class="required">Designation</label>
                    <input [disabled]="disableFields" pInputText type="text" class="im-input" formControlName="designation" placeholder="Enter" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-4 px-0">
                    <label class="required">Password</label>
                    <div>
                    <input #newpwd [disabled]="disableFields" pInputText type="password" class="im-input w-75 d-inline" formControlName="password" placeholder="Enter" 
                    pattern="(?=.*\W)(?!.*\s)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                    [type]="new_password_hide ?'text': 'password'" name="new_password"
                    (keyup)=" confirmPasswordMatch()"/>
                    <mat-icon matTooltip="Show/Hide" class="mx-2" matSuffix (click)="new_password_hide = !new_password_hide">
                      {{new_password_hide ?'visibility': 'visibility_off'}}</mat-icon>
                      </div>

                    <div class="newpassword-validity-status" *ngIf="creatingUserForm.controls['password'].dirty">
                        <ul>
                          <li class="flex-row">
                            <span class="material-icons valid-icon" *ngIf="validateInput('totalCount',newpwd)">
                              check_circle
                            </span>
                            <span class="material-icons not-valid-icon" *ngIf="!validateInput('totalCount',newpwd)">
                              cancel
                            </span>
                            <span class="pl-1">
                              Must be 8 characters at minimum
                            </span>
                          </li>
                          <li class="flex-row">
                            <span class="material-icons valid-icon" *ngIf="validateInput('aA3',newpwd)">
                              check_circle
                            </span>
                            <span class="material-icons not-valid-icon" *ngIf="!validateInput('aA3',newpwd)">
                              cancel
                            </span>
                            <span class="pl-1">
                              Must contain atleast 3 alpha (A-z) character(s)
                            </span>
                          </li>
                          <li class="flex-row">
                            <span class="material-icons valid-icon" *ngIf="validateInput('number',newpwd)">
                              check_circle
                            </span>
                            <span class="material-icons not-valid-icon" *ngIf="!validateInput('number',newpwd)">
                              cancel
                            </span>
                            <span class="pl-1">
                              Must contain atleast 1 number(0-9) characters(s)
                            </span>
                          </li>
                          <li class="flex-row">
                            <span class="material-icons valid-icon" *ngIf="validateInput('specChar',newpwd)">
                              check_circle
                            </span>
                            <span class="material-icons not-valid-icon" *ngIf="!validateInput('specChar',newpwd)">
                              cancel
                            </span>
                            <span class="pl-1">
                              Must contain atleast 1 special character(s)
                              <!-- <small>(~`!@#$%^&*()*-_+ {{ '{' }} {{ '}' }}[];:,.?<>/|\)</small> -->
                            </span>
                          </li>
                        </ul>
                      </div>
                </div>
                <div class="col-4">
                    <div  class="form-group">
                    <label class="required">Confirm Password</label>
                    <div>
                    <input [disabled]="disableFields" pInputText type="password" class="im-input w-75 d-inline"
                    [type]="confirm_password_hide ?'text': 'password'" name="confirm_password"
                        placeholder="Confirm New Password" formControlName="confirmPassword" (keyup)=" confirmPasswordMatch()" />
                        <mat-icon matTooltip="Show/Hide" class="mx-2" matSuffix (click)="confirm_password_hide = !confirm_password_hide">
                          {{confirm_password_hide ?'visibility': 'visibility_off'}}</mat-icon>
                        </div>
                        <div *ngIf="creatingUserForm.controls['confirmPassword'].dirty">    
                      <small
                      *ngIf="creatingUserForm.controls['confirmPassword'].hasError('required') 
                      && creatingUserForm.controls['confirmPassword'].touched"
                      class="text-danger">Confirm Password Required</small>
                      <small
                      *ngIf="this.creatingUserForm.controls['confirmPassword'].hasError('passwordMatch') 
                      && creatingUserForm.controls['confirmPassword'].touched"
                      class="text-danger">New and Confirm Passwords mismatch</small>
                    </div>
                </div>
                </div>
            </div>
            <div class="row mt-2 mb-2">
                <div class="col-12 pl-0">
                    <p-button [disabled]="disableFields || creatingUserForm.invalid || creatingUserForm.disabled || !confirmPwdValidity" label="Add" type="button" icon="pi pi-plus-circle" (click)="addUser()"></p-button>
                    <p><strong>Note: </strong><small>After adding users into the list you need to click on {{companyType === 'Petitioner' ? '"Save as Draft" or ' : ''}}"Save" button to save the users for the company.</small></p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2 mb-2">
        <div class="col-12 px-0">
            <p class="text-danger" *ngIf="userDetails.length == 0">No user added to list yet</p>
        </div>
        <div class="col-12 px-0" *ngIf="userDetails.length">
            <p-table [responsive]="true" [value]="userDetails">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Email Address</th>
                        <th>Designation</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-userDetails>
                    <tr>
                        <td>{{userDetails.firstName}} {{userDetails.lastName}}</td>
                        <td *ngIf="companyType === 'Petitioner';else attorneyFlowPlan">
                          Petitioner Admin
                        </td>
                        <ng-template #attorneyFlowPlan>
                          <td>Lawfirm Admin</td>
                        </ng-template>
                        <td>{{userDetails.email}}</td>
                        <td>{{userDetails.designation}}</td>
                        <td>
                          <div class="ml-2">
                            <span class="ml-2 mr-2"><mat-icon class="mr-1 icn"
                              matTooltip="Edit" (click)="editUser(userDetails)"
                              class="material-icons">edit</mat-icon></span>
                            <span  (click)="confirmDelete(userDetails)">
                              <mat-icon
                                class="mr-1 icn"
                                matTooltip="Delete User"
                                class="material-icons">delete</mat-icon>
                            </span>                            
                          </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</form>