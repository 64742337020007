<div class="container-fuild">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-9 step-title">
      <!-- <h5 class="mb-0">Enter Current Job Description (Foreign Company)</h5>
      <p class="mb-0">Please provide Work Location, Position Details & List of Duties</p> -->
      <div class="page-header">
        <h2>Enter Current Job Description (Foreign Company)</h2>
        <p> Please provide Work Location, Position Details & List of Duties.</p>
      </div>
    </div>
    <div class="col-md-2 editBtn">
      <gc-status-update [stepDetails]="stepDetails"
        [visaType]="inputData.caseType === 'I130' ? inputData.caseType : inputData.visatype" type="EDIT">
      </gc-status-update>
    </div>
    <div class="col-md-1 pr-0 d-flex justify-content-end">
      <lib-maximize-dialog-library [configData]="{'layoutCode':inputData?.layoutCode}"></lib-maximize-dialog-library>
      <i (click)="handleClose();" class="close-icon pi pi-times"></i>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <!-- <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)">
      </lib-assigning-task-library> -->
      <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails"
        (updateStatus)="updateStepStatus($event)" [isViewMode]="inputData['isViewMode']"
        [isCaseRequest]="inputData['isCaseRequest']">
      </lib-assigning-task-library>
    </div>
  </div>
  <p-divider></p-divider>
  <form (ngSubmit)="prePostJobDescription()" [formGroup]="jobDescriptionForm">
    <div class="main-body footer-adjust">
      <div class="row col-md-12 mb-3">
        <h6 class="heading p-text-bold">Identify The Work Location</h6>
      </div>
      <div class="row mb-3" formArrayName="address">
        <div class="row" *ngFor="let item of getAddressFormArray(); let addressIndex= index">
          <div class="row w-100" [formGroupName]="addressIndex">
            <ng-template [ngIf]="addressIndex === 0">
              <div class="row col-md-12 mb-3">
                <div class="col-4 pl-0 radioBtnLabel">
                  <p-radioButton [disabled]="disabledControls" formControlName="workLocationTypeCode" value="INHSE"
                    inputId="INHSE" name="workLocationTypeCode" label="In-House"
                    (click)="handleWorkLocationTypeChange('INHSE')">
                  </p-radioButton>
                </div>
                <div class="col-4 pl-0" *ngIf="dynamicDialogConfig.data.visatype !== 'L1A'">
                  <p-radioButton [disabled]="disabledControls" formControlName="workLocationTypeCode" value="TPARTY"
                    inputId="TPARTY" name="workLocationTypeCode" label="Third Party Site"
                    (click)="handleWorkLocationTypeChange('TPARTY')">
                  </p-radioButton>
                </div>
              </div>
            </ng-template>
            <div class="row w-100 pr-0 d-flex justify-content-end">
              <button type="button" [disabled]="disabledControls || (jobDescriptionForm
            .get('address')['controls'].length === 1)" title="Remove" pButton class="mt-1 remove-work-location-btn"
                (click)="handleRemoveWorkClose(addressIndex)"><i class="remove-work-location pi pi-times"></i></button>
            </div>
            <div class="row w-100 mb-3" *ngIf="dynamicDialogConfig.data.visatype !== 'L1A' && jobDescriptionForm
            .get('address')['controls']['0'].get('workLocationTypeCode').value === 'TPARTY'">
              <div class="col-md-6 form-group input-disabled">
                <label class="label-cls"> Client Name </label>
                <input class="im-input mw-100" id="clientName" formControlName="clientName" type="text" pInputText
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('clientName').touched && item.get('clientName').invalid">
                  <span class="invalid-message" *ngIf="item.get('clientName').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled">
                <label class="label-cls"> Phone Number </label>
                <input class="im-input mw-100" id="clientPhoneNo" formControlName="clientPhoneNo" pInputText type="text"
                  [readonly]="disabledControls" maxlength="15" placeholder="Enter" />
                <div *ngIf="item.get('clientPhoneNo').touched && item.get('clientPhoneNo').invalid">
                  <span class="invalid-message" *ngIf="item.get('clientPhoneNo').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                  <span class="invalid-message" *ngIf="item.get('clientPhoneNo').errors?.pattern">
                    {{getGlobalErrorMessages('INVALID_FORMAT')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled">
                <label class="label-cls"> Email ID </label>
                <input class="im-input mw-100" id="clientEmail" formControlName="clientEmail" pInputText type="email"
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('clientEmail').touched && item.get('clientEmail').invalid">
                  <span class="invalid-message" *ngIf="item.get('clientEmail').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                  <span class="invalid-message" *ngIf="item.get('clientEmail').errors?.pattern">
                    {{getGlobalErrorMessages('INVALID_FORMAT')}}
                  </span>
                </div>
              </div>
            </div>
            <div class="row w-100 mb-3">
              <div class="col-md-6 form-group input-disabled">
                <label class="label-cls required"> Address Line 1 </label>
                <input class="im-input mw-100" id="addressLine1" formControlName="addressLine1" type="text" pInputText
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('addressLine1').touched && item.get('addressLine1').invalid">
                  <span class="invalid-message" *ngIf="item.get('addressLine1').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-6 input-disabled">
                <label class="label-cls"> Address Line 2 </label>
                <input class="im-input mw-100" id="addressLine2" formControlName="addressLine2" pInputText type="text"
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('addressLine2').touched && item.get('addressLine2').invalid">
                  <span class="invalid-message" *ngIf="item.get('addressLine2').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
            </div>
            <div class="row w-100 mb-0">
              <div class="col-md-3 form-group processing-dropdown">
                <label class="label-cls  required"> Country </label>
                <p-dropdown appendTo="body" [options]="countryList" id="countryCode" formControlName="countryCode"
                  [disabled]="disabledControls" name="country" optionLabel="countryName" optionValue="countryCode"
                  placeholder="select" (onChange)="handleCountryChange($event.value, addressIndex, 'workAddress')">
                </p-dropdown>
                <div *ngIf="item.get('countryCode').touched && item.get('countryCode').invalid">
                  <span class="invalid-message" *ngIf="item.get('countryCode').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 form-group processing-dropdown"
                *ngIf="statelistArrObj.workAddress.length > 0 && statelistArrObj.workAddress[addressIndex] && statelistArrObj.workAddress[addressIndex].stateList && statelistArrObj.workAddress[addressIndex].stateList.length > 0">
                <label class="label-cls required"> State </label>
                <p-dropdown appendTo="body" [options]="statelistArrObj.workAddress[addressIndex].stateList"
                  id="workAddressStateProvinceCode" formControlName="stateProvinceCode" [disabled]="disabledControls"
                  name="state" optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="select">
                </p-dropdown>
                <div *ngIf="item.get('stateProvinceCode').touched && item.get('stateProvinceCode').invalid">
                  <span class="invalid-message" *ngIf="item.get('stateProvinceCode').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3  form-group input-disabled"
                *ngIf="statelistArrObj.workAddress.length > 0 && statelistArrObj.workAddress[addressIndex] && (!statelistArrObj.workAddress[addressIndex].stateList || (statelistArrObj.workAddress[addressIndex].stateList && statelistArrObj.workAddress[addressIndex].stateList.length === 0))">
                <label class="label-cls required"> State </label>
                <input class="im-input mw-100" id="workAddressStateProvinceCodeText" formControlName="stateProvinceName"
                  pattern="[a-zA-Z ]*" type="text" [readonly]="disabledControls" pInputText placeholder="Enter" />
                <div *ngIf="item.get('stateProvinceName').touched && item.get('stateProvinceName').invalid">
                  <span class="invalid-message" *ngIf="item.get('stateProvinceName').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3  form-group input-disabled">
                <label class="label-cls required">City </label>
                <input class="im-input mw-100" id="city" formControlName="city" pattern="[a-zA-Z ]*" type="text"
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('city').touched && item.get('city').invalid">
                  <span class="invalid-message" *ngIf="item.get('city').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                  <span class="invalid-message" *ngIf="item.get('city').touched && item.get('city').errors?.pattern">
                    {{getGlobalErrorMessages('INVALID_FORMAT')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled">
                <label class="label-cls required"> Postal Code </label>
                <input class="im-input" id="postCode" formControlName="postCode" type="text" pInputText
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('postCode').touched && item.get('postCode').invalid">
                  <span class="invalid-message" *ngIf="item.get('postCode').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                  <span class="invalid-message"
                    *ngIf="item.get('postCode').touched && item.get('postCode').errors?.pattern">
                    {{getGlobalErrorMessages('INVALID_FORMAT')}}
                  </span>
                </div>
              </div>
            </div>
            <!-- <p-divider class="mb-3 w-100" *ngIf="addressIndex < (getAddressFormArray().length - 1)"></p-divider> -->
          </div>
        </div>
      </div>
      <p-divider></p-divider>
      <div class="row w-100 save-location pl-3">
        <button type="button" [disabled]="disabledControls" pButton class="p-button-outlined btn btn-primary"
          (click)="handleAddLocationClick()"><i aria-hidden="true" class="fa fa-plus-circle"></i> Add more
          location</button>
      </div>
      <p-divider></p-divider>
      <div class="row col-md-12">
        <h6 class="heading p-text-bold">Position Details</h6>
      </div>
      <div class="row mb-3">
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls  required"> Position Title </label>
          <input class="im-input mw-100" [readonly]="disabledControls" formControlName="jobTitle" type="text" pInputText
            placeholder="Enter" />
          <div
            *ngIf="jobDescriptionForm.controls['jobTitle'].touched && jobDescriptionForm.controls['jobTitle'].invalid">
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['jobTitle'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['jobTitle'].errors?.pattern">
              {{getGlobalErrorMessages('INVALID_FORMAT')}}
            </span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4 form-group">
          <label class="label-cls  required"> Start Date </label><br>
          <p-calendar styleClass="p-form-datepicker" appendTo="body" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
            [showIcon]="true" [maxDate]="jobDescriptionForm.get('endDate').value" [disabled]="disabledControls"
            formControlName="startDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1910:2050"
            [showButtonBar]="true">
          </p-calendar>
          <div
            *ngIf="jobDescriptionForm.controls['startDate'].touched && jobDescriptionForm.controls['startDate'].invalid">
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['startDate'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 form-group">
          <label class="label-cls"> End Date </label><br>
          <p-calendar class="mw-100" appendTo="body" styleClass="p-form-datepicker" dateFormat="mm/dd/yy"
            placeholder="MM/DD/YYYY" [showIcon]="true" [minDate]="jobDescriptionForm.get('startDate').value"
            [disabled]="disabledControls || !jobDescriptionForm.get('startDate').value" formControlName="endDate"
            [showButtonBar]="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="1910:2050">
          </p-calendar>
          <div *ngIf="jobDescriptionForm.controls['endDate'].touched && jobDescriptionForm.controls['endDate'].invalid">
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['endDate'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 form-group" style="height: 30px;">
        </div>
      </div>
      <div class="row mb-3">
      </div>
      <div class="form-group">
        <div class="row col-md-6">
          <div class="col-md-11 pl-0 bold-label" name="question">
            <label>Is this a full time position?</label>
          </div>
          <div class="col-md-1">
            <p-inputSwitch id="thisFulltime" [disabled]="disabledControls" formControlName="thisFulltime"
              (onChange)="handleDataChange($event)">
            </p-inputSwitch>
          </div>
        </div>
        <div class="row" *ngIf="jobDescriptionForm.get('thisFulltime').value === false">
          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls required"> Working Hours per week </label>
            <input [readonly]="disabledControls" type="text" class="im-input mw-100" id="workingHoursPerWeek"
              formControlName="workingHoursPerWeek" placeholder="Enter" pattern="\d{1,5}" pInputText />
            <div
              *ngIf="jobDescriptionForm.get('workingHoursPerWeek').touched && jobDescriptionForm.get('workingHoursPerWeek').invalid">
              <span class="invalid-message" *ngIf="jobDescriptionForm.get('workingHoursPerWeek').errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
              <span class="invalid-message" *ngIf="jobDescriptionForm.get('workingHoursPerWeek').errors?.pattern">
                {{getGlobalErrorMessages('NUMBER_ONLY')}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row col-md-12">
        <h6 class="heading p-text-bold">Wage offer Information</h6>
      </div>
      <div class="row mb-3">
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls required"> Wage </label>
          <span class="p-input-icon-left mask-input mw-100"> <i class="pi pi-dollar"></i>
            <input class="im-input mask-in mw-100" id="wagesFrom" formControlName="wagesFrom" type="text"
              [readonly]="disabledControls" pInputText placeholder="" /> </span>
          <div
            *ngIf="jobDescriptionForm.controls['wagesFrom'].touched && jobDescriptionForm.controls['wagesFrom'].invalid">
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['wagesFrom'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['wagesFrom'].errors?.pattern">
              {{getGlobalErrorMessages('NUMBER_ONLY')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 processing-dropdown">
          <label class="label-cls  required"> Wage Unit </label>
          <p-dropdown appendTo="body" [options]="wageUnitType" [filter]="false" id="wageTypeCode"
            formControlName="wageTypeCode" [disabled]="disabledControls" optionLabel="name" optionValue="code"
            placeholder="select">
          </p-dropdown>
          <div
            *ngIf="jobDescriptionForm.controls['wageTypeCode'].touched && jobDescriptionForm.controls['wageTypeCode'].invalid">
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['wageTypeCode'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
      </div>
      <div class="row col-md-12">
        <h5 class="sub-title">Upload Offer Letter</h5>
      </div>
      <div class="row">
        <i140-file-upload style="width: 100%;" [fileUploadConfigurations]="fileUploadConfigurations">
        </i140-file-upload>
      </div>
      <!-- Employment Interuptions section starts here -->
      <div class="mb-3" formArrayName="employmentInterruptionsArr">
        <h6 class="ml-3 mb-3 sec-title">Dates of beneficiary's employment with this employer. Explain any interruptions
          in employment</h6>
        <div class="row" *ngFor="let item2 of getEmploymentInterruptionsArrFormArray(); let empIndex= index">
          <div class="row w-100" [formGroupName]="empIndex">
            <div class="row w-100 mb-3">
              <div class="col-md-3 form-group input-disabled">
                <label class="label-cls display-blk-label"> Employment From Date </label>
                <p-calendar styleClass="p-form-datepicker" appendTo="body" dateFormat="mm/dd/yy"
                  placeholder="MM/DD/YYYY" [showIcon]="true" formControlName="employmentInterruptionFromDate"
                  [monthNavigator]="true" [yearNavigator]="true" [showButtonBar]="true" yearRange="1910:2050">
                </p-calendar>
                <div
                  *ngIf="item2.get('employmentInterruptionFromDate').touched && item2.get('employmentInterruptionFromDate').invalid">
                  <span class="invalid-message" *ngIf="item2.get('employmentInterruptionFromDate').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 form-group input-disabled">
                <label class="label-cls display-blk-label"> Employment To Date </label>
                <p-calendar styleClass="p-form-datepicker" appendTo="body" dateFormat="mm/dd/yy"
                  placeholder="MM/DD/YYYY" [showIcon]="true" formControlName="employmentInterruptionToDate"
                  [monthNavigator]="true" [yearNavigator]="true" [showButtonBar]="true" yearRange="1910:2050">
                </p-calendar>
                <div
                  *ngIf="item2.get('employmentInterruptionToDate').touched && item2.get('employmentInterruptionToDate').invalid">
                  <span class="invalid-message" *ngIf="item2.get('employmentInterruptionToDate').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-4 form-group input-disabled">
                <label class="label-cls display-blk-label"> Explanation of Interruptions </label>
                <input class="im-input mw-100" id="employmentInterruptionExplanation"
                  formControlName="employmentInterruptionExplanation" pInputText type="text"
                  [readonly]="disabledControls" placeholder="Enter" />
                <div
                  *ngIf="item2.get('employmentInterruptionExplanation').touched && item2.get('employmentInterruptionExplanation').invalid">
                  <span class="invalid-message" *ngIf="item2.get('employmentInterruptionExplanation').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col">
                <button type="button" [disabled]="disabledControls || (jobDescriptionForm
                .get('employmentInterruptionsArr')['controls'].length === 1)" title="Remove" pButton
                  class="mt-3 remove-work-location-btn"
                  (click)="handleRemoveOwenerShipClose(empIndex,'employmentInterruptionsArr')"><i
                    class="remove-work-location pi pi-times"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p-divider></p-divider>
      <div class="row w-100 save-location pl-3">
        <button type="button" [disabled]="disabledControls" pButton class="p-button-outlined btn btn-primary"
          (click)="handleAddEmploymentInterruptionsClick()"><i aria-hidden="true" class="fa fa-plus-circle"></i> Add
          more Employment Interruption</button>
      </div>
      <!-- Employment Interuptions section ends here -->
      <!-- ownership share section starts here -->
      <div class="mb-3 mt-3" formArrayName="stockOwnershipFeinArr">
        <h6 class="ml-3 mb-3 sec-title">Describe the percentage of stock ownership and managerial control of each
          company that has a qualifying relationship. Provide the Federal Employer Identification Number for each U.S.
          company that has a qualifying relationship.</h6>
        <div class="row" *ngFor="let item1 of getStockOwnerShipFeinFormArray(); let shareIndex= index">
          <div class="row w-100" [formGroupName]="shareIndex">
            <div class="row w-100 mb-3">
              <div class="col-md-5 form-group input-disabled">
                <!--  <label class="label-cls"> Percentage of company stock ownership and managerial control of each company that has a qualifying relationship. </label> -->
                <label class="label-cls">Percentage of company stock ownership</label>
                <input class="im-input mw-100" id="companyStockOwnershipPercentage"
                  formControlName="companyStockOwnershipPercentage" type="number" pInputText
                  [readonly]="disabledControls" placeholder="Enter" />
                <div
                  *ngIf="item1.get('companyStockOwnershipPercentage').touched && item1.get('companyStockOwnershipPercentage').invalid">
                  <span class="invalid-message" *ngIf="item1.get('companyStockOwnershipPercentage').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-6 input-disabled">
                <!-- <label class="label-cls"> Federal Employer Identification Number for each U.S company that has a qualifying relationship </label> -->
                <label class="label-cls">Company Federal Employer Identification Number (FEIN)</label>
                <input class="im-input mw-100" id="companyFeiNumber" formControlName="companyFeiNumber" pInputText
                  type="text" [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item1.get('companyFeiNumber').touched && item1.get('companyFeiNumber').invalid">
                  <span class="invalid-message" *ngIf="item1.get('companyFeiNumber').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col">
                <button type="button" [disabled]="disabledControls || (jobDescriptionForm  
                  .get('stockOwnershipFeinArr')['controls'].length === 1)" title="Remove" pButton
                  class="mt-3 remove-work-location-btn"
                  (click)="handleRemoveOwenerShipClose(shareIndex,'stockOwnershipFeinArr')"><i
                    class="remove-work-location pi pi-times"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p-divider></p-divider>
      <div class="row w-100 save-location pl-3">
        <button type="button" [disabled]="disabledControls" pButton class="p-button-outlined btn btn-primary"
          (click)="handleAddShareOwnershipFeinClick()"><i aria-hidden="true" class="fa fa-plus-circle"></i> Add more
          Ownership Share</button>
      </div>
      <!-- ownership share section ends here -->
      <!-- <div *ngIf="inputData?.visatype === 'L1B'">
                <div class="row pb-2">
                    <div class="col-md-10 form-group bold-label">
                        <label class="label-cls">Do the companies currently have the same qualifying relationship as they did during
              the one-year period of the beneficiary's
              employment with the company abroad?</label>
            </div>
          <div class="col-md-1 form-group">
                        <p-inputSwitch [disabled]="disabledControls" id="isSameBeneficiaryEmploymentWithAbroad"
              formControlName="isSameBeneficiaryEmploymentWithAbroad">
            </p-inputSwitch>
          </div>
        </div>
        <div class="row pb-2 ml-3"
          *ngIf="jobDescriptionForm.get('isSameBeneficiaryEmploymentWithAbroad').value === true">
          <label class="label-cls">If you answered yes to the preceding question, describe how and by whom the
            beneficiary's work will be controlled and Supervised. Include a description of the amount of time each
            supervisor is expected to control and supervise the work.</label>
            <textarea pInputTextarea name="responseNote" class="im-input  mw-100"
            formControlName="beneficiaryWorksSupervisedInfo" [readOnly]="disabledControls"></textarea>
          </div>
        <div class="row pb-2 ml-3"
          *ngIf="jobDescriptionForm.get('isSameBeneficiaryEmploymentWithAbroad').value === true">
          <label class="label-cls">If you answered yes to the preceding question, describe the reasons why placement at
            another worksite outside the petitioner, subsidiary, affiliate, or parent is needed. Include a description
            of how the beneficiary's duties at another worksite relate to the need for the specialized knowledge he or
            she possesses.</label>
            <textarea pInputTextarea name="responseNote" class="im-input  mw-100"
            formControlName="specializedKnowledgeDuties" [readOnly]="disabledControls"></textarea>
          </div>
      </div> -->
      <job-duties *ngIf="inputData && jobDescriptionForm" [disabledControls]="disabledControls"
        (deleteAllDutie)="handleDeleteAllDuties()" [inputData]="inputData" (addUpdateDuty)="handleAddUpdateDuty($event)"
        [parentForm]="jobDescriptionForm" (deleteDuty)="handleDutyDelete($event)"
        (deleteSubDuty)="handleSubDutyDelete($event)" (deleteDutiesOnPopulateDuties)="alldutiesDelete()"></job-duties>
    </div>
    <p-divider></p-divider>
    <!-- pending UI verification -->
    <div class="row pt-3 mt-n3">
      <lib-custom-questionnaire [stepId]="inputData.stepId" [taskId]="inputData.taskId" [viewQuestions]="false"
        [showSaveBtn]="false" [loadedIn]="stepDetails.stepName" [isSection]="false"></lib-custom-questionnaire>
    </div>
    <p-divider></p-divider>
    <div class="row">
      <div class="col-md-6  pl-0 d-flex justify-content-start">
        <button pButton type="submit" label="Save" title="Save" class="btn btn-primary"
          [ngClass]="jobDescriptionForm.invalid  || disabledControls ? 'btn-success_disabled btn btn-primary':'btn btn-primary'"
          [disabled]="jobDescriptionForm.invalid  || disabledControls"></button>
        <button pButton type="button" label="Cancel" class="btn btn-primary ml-4
                d-flex alogn-items-center" [disabled]="disabledControls" (click)="handleCancel()">
        </button>
      </div>
      <div class="col-md-6">
        <gc-status-update [stepDetails]="stepDetails"
          [visaType]="inputData.caseType === 'I130' ? inputData.caseType : inputData.visatype" [type]="typeOfButton">
        </gc-status-update>
      </div>
    </div>
  </form>
</div>