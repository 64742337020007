<div #menu class="fb-menu-holder clearfix" (click)="closeAll()">
  <ul class="left-ul" (click)="closeAll()" [ngStyle]="{'max-width': rightMenuList?.length === 1 ? '85%' : '55%'}">
    <li #elementLeft class="left-menu" *ngFor="let item of leftMenuList; let i = index;"
      [ngClass]="{'only-child': leftMenuList.length === 1, 'first-child': leftMenuList.length > 1 && i === 0, 'last-child': leftMenuList.length > 1 && i === (leftMenuList.length - 1), 'last-in-line': leftMenuList.length > 1 && i > 0 && i < (leftMenuList.length - 1) && elementLeft.classList.contains('last-in-line'), 'first-in-line': leftMenuList.length > 1 && i > 0 && i < (leftMenuList.length - 1) && elementLeft.classList.contains('first-in-line') }">
      <a class="menu-click" [ngClass]="{'menu-active': item.activeMenu}"
        (click)="item.children?.length > 0 ? openDropdown($event, item, leftMenuList, rightMenuList) : openPage($event, item)">
        <span class="menu-obj clearfix">
          <img class="menu-icon" *ngIf="item.customObj.iconImagePath" [src]="item.customObj.iconImagePath"
            [alt]="item.label">
          <span class="menu-text">
            {{ item.label }}
          </span>
          <a class="menu-dropdown-icon" *ngIf="item.children?.length > 0">
            <span class="pi pi-angle-down"></span>
          </a>
          <ul class="menu-dropdown" *ngIf="item.children?.length > 0 && item.showChildren">
            <ng-container *ngTemplateOutlet="leftRecursiveMenu; context:{ $implicit: item.children }"></ng-container>
          </ul>
        </span>
      </a>
    </li>
  </ul>
  <ng-template #leftRecursiveMenu let-items>
    <li #elementLeft class="left-menu sub-menu" *ngFor="let item of items; let i = index;">
      <a class="menu-click" [ngClass]="{'menu-active': item.activeMenu}"
        (click)="item?.children?.length > 0 ? openDropdown($event, item, items) : openPage($event, item)">
        <span class="menu-obj clearfix">
          <img class="menu-icon" *ngIf="item.customObj.iconImagePath" [src]="item.customObj.iconImagePath"
            [alt]="item.label">
          <span class="menu-text">
            {{ item.label }}
          </span>
          <a class="menu-dropdown-icon" *ngIf="item.children?.length > 0">
            <span class="pi pi-angle-right"></span>
          </a>
          <ul class="menu-dropdown" *ngIf="item?.children?.length > 0 && item.showChildren">
            <ng-container *ngTemplateOutlet="leftRecursiveMenu; context:{ $implicit: item.children }"></ng-container>
          </ul>
        </span>
      </a>
    </li>
  </ng-template>
  <ul class="right-ul" (click)="closeAll()"
    [ngStyle]="{'max-width': rightMenuList?.length === 1 ? 'calc(15% - 10px)' : 'calc(45% - 10px)'}">
    <li #elementRight class="right-menu" *ngFor="let item of rightMenuList; let i = index;"
      [ngClass]="{'only-child': rightMenuList.length === 1, 'first-child': rightMenuList.length > 1 && i === 0, 'last-child': rightMenuList.length > 1 && i === (rightMenuList.length - 1), 'last-in-line': rightMenuList.length > 1 && i > 0 && i < (rightMenuList.length - 1) && elementRight.classList.contains('last-in-line'), 'first-in-line': rightMenuList.length > 1 && i > 0 && i < (rightMenuList.length - 1) && elementRight.classList.contains('first-in-line') }">
      <ng-template [ngIf]="item.menuType && item.menuType === 'multipleRole'">
        <a class="menu-click">
          <span class="menu-obj clearfix">
            <app-switch-app *ngIf="appRoleData?.length > 0" [appRoleData]="appRoleData"></app-switch-app>
          </span>
        </a>
      </ng-template>
      <ng-template [ngIf]="!item.menuType">
        <a class="menu-click" [ngClass]="{'menu-active': item.activeMenu}"
          (click)="item?.children?.length > 0 ? openDropdown($event, item, rightMenuList, leftMenuList) : openPage($event, item)">
          <span class="menu-obj clearfix">
            <img class="menu-icon" *ngIf="item.customObj.iconImagePath" [src]="item.customObj.iconImagePath"
              [alt]="item.label">
            <span class="menu-text">
              {{ item.label }}
            </span>
            <a class="menu-dropdown-icon" *ngIf="item.children?.length > 0">
              <span class="pi pi-angle-down"></span>
            </a>
            <ul class="menu-dropdown" *ngIf="item.children?.length > 0 && item.showChildren">
              <ng-container *ngTemplateOutlet="rightRecursiveMenu; context:{ $implicit: item.children }"></ng-container>
            </ul>
          </span>
        </a>
      </ng-template>
    </li>
  </ul>
  <ng-template #rightRecursiveMenu let-items>
    <li #elementLeft class="right-menu sub-menu" *ngFor="let item of items; let i = index;">
      <a class="menu-click" [ngClass]="{'menu-active': item.activeMenu}"
        (click)="item?.children?.length > 0 ? openDropdown($event, item, items) : openPage($event, item)">
        <span class="menu-obj clearfix">
          <a class="menu-dropdown-icon" *ngIf="item.children?.length > 0">
            <span class="pi pi-angle-left"></span>
          </a>
          <img class="menu-icon" *ngIf="item.customObj.iconImagePath" [src]="item.customObj.iconImagePath"
            [alt]="item.label">
          <span class="menu-text">
            {{ item.label }}
          </span>
          <ul class="menu-dropdown" *ngIf="item?.children?.length > 0 && item.showChildren">
            <ng-container *ngTemplateOutlet="rightRecursiveMenu; context:{ $implicit: item.children }"></ng-container>
          </ul>
        </span>
      </a>
    </li>
  </ng-template>
</div>
