<!-- <mat-toolbar class="mat-toolbar-row  header-container">
  <div class="pr-4 pl-4">
    <a routerLink="dashboard" routerLinkActive="header--menuItem-active" class="header--menu-item">
      Dashboard
    </a>
  </div>
  <div class="pr-4 pl-4" *ngIf="!isAdmin">
    <a  routerLinkActive="header--menuItem-active" routerLink="attorney/task-list" class="header--menu-item">
      Tasks
    </a>
  </div>
  <div class="pr-4 pl-4" *ngIf="!isAdmin">
    <a routerLinkActive="header--menuItem-active" routerLink="attorney/beneficiaries" class="header--menu-item">
      Beneficiaries
    </a>
  </div>
  <div class="pr-4 pl-4" *ngxPermissionsOnly="['ISADMIN']">
    <a routerLink="attorney/firm-info" *ngxPermissionsOnly="['VIEW_COMPANY']" routerLinkActive="header--menuItem-active" class="header--menu-item">
      Firm Info
    </a>
  </div>
  <div class="pr-4 pl-4">
    <a routerLink="clients" routerLinkActive="header--menuItem-active" class="header--menu-item">
      Clients
    </a>
  </div>
  <div class="pr-4 pl-4" *ngIf="!isAdmin">
    <a routerLinkActive="header--menuItem-active" class="header--menu-item">
      Petitioners
    </a>
  </div>
  <div class="pr-3 pl-3" *ngIf="isAdmin">
    <button mat-button class="pr-3 pl-3 header-menuitem-text bg-white" [matMenuTriggerFor]="menu">
      Admin
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="users" routerLinkActive="header--menuItem-active" class="header--menu-item">Users</button>
      <button mat-menu-item routerLink="roles" routerLinkActive="header--menuItem-active" class="header--menu-item">Roles</button>
    </mat-menu>
  </div>
  <div class="pr-4 pl-4">
    <a routerLinkActive="header--menuItem-active" class="header--menu-item">
      Reports
    </a>
  </div>
</mat-toolbar> -->


<!--
<mat-toolbar class="mat-toolbar-row  header-container">
  <div class="pr-3 pl-3">
    <a routerLink="dashboard" routerLinkActive="header--menuItem-active" class="header--menu-item">
      Dashboard
    </a>
  </div>
  <div class="pr-3 pl-3" *ngIf="!isAdmin">
    <a routerLink="petitions" routerLinkActive="header--menuItem-active" class="header--menu-item">
      Petitions
    </a>
  </div>
  <div class="pr-3 pl-3" *ngIf="!isAdmin">
    <a routerLink="beneficiaries" routerLinkActive="header--menuItem-active" class="header--menu-item">
      Beneficiaries
    </a>
  </div>
  <div class="pr-3 pl-3">
    <a routerLink="attorneys/attorney-connections" routerLinkActive="header--menuItem-active" class="header--menu-item">
      Attorneys
    </a>
  </div>
  <div class="pr-3 pl-3">
      <button mat-button class="pr-3 pl-3 bg-white header-menuitem-text" [matMenuTriggerFor]="menu">
        Data Setup
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="lca" routerLinkActive="header--menuItem-active"
          class="header--menu-item">LCAs</button>
        <button mat-menu-item routerLink="template/template-list" routerLinkActive="header--menuItem-active"
          class="header--menu-item">Template
          Builder</button>
        <button mat-menu-item routerLinkActive="header--menuItem-active" class="header--menu-item">Receipt
          Management</button>
      </mat-menu>
  </div>
  <div class="pr-3 pl-3">
    <a routerLinkActive="header--menuItem-active" class="header--menu-item">

      Reports
    </a>
  </div>
  <div class="pr-3 pl-3">
    <div *ngIf="isAdmin">
      <button mat-button class="pr-3 pl-3 bg-white header-menuitem-text" [matMenuTriggerFor]="menu">
        Admin
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="users" routerLinkActive="header--menuItem-active"
          class="header--menu-item">Users</button>
        <button mat-menu-item routerLink="roles" routerLinkActive="header--menuItem-active"
          class="header--menu-item">Roles</button>
        <button mat-menu-item routerLink="companies/petitioner/company/details" routerLinkActive="header--menuItem-active"
          class="header--menu-item">Company</button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>

-->



<nav class="im-navbar navbar-expand-xl navbar-light mb-3">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

      <li class="im-nav-item dropdown" routerLinkActive="active" *ngIf="isAdmin">
        <a joyrideStep="fourthStep" [stepContent]="customContent4" class="im-nav-link dropdown-toggle"
          id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="/assets/images/menu-dashboard.svg" width="17px" class="mr-1" alt=""> DASHBOARD
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="dashboard" (click)="adminAttoney(false)">Lawfirm</a>
          <a *ngIf="!roleName" class="dropdown-item" routerLink="dashboard"
            (click)="adminAttoney(true)">Administrator</a>
        </div>
      </li>

      <li class="im-nav-item overflow-auto" *ngIf="!isAdmin" routerLinkActive="active">
        <a joyrideStep="fourthStep" [stepContent]="customContent4" class="im-nav-link" routerLink="dashboard"><img
            src="/assets/images/menu-dashboard.svg" width="17px" class="mr-1" alt=""> Dashboard</a>
      </li>

      <ng-template #customContent4>
        <app-guided-tour-steps [content]="attorneyGuidedTourContent[3]"></app-guided-tour-steps>
      </ng-template>

      <li class="im-nav-item overflow-auto" routerLinkActive="active">
        <a joyrideStep="firstStep" [stepContent]="customContent" class="im-nav-link"
          routerLink="attorney/task-list"><img src="/assets/images/menu-petitions.png" width="20px" alt="">Tasks</a>
      </li>

      <ng-template #customContent>
        <app-guided-tour-steps [content]="attorneyGuidedTourContent[0]"></app-guided-tour-steps>
      </ng-template>

      <li class="im-nav-item overflow-auto" routerLinkActive="active" *ngxPermissionsOnly="['VIEW_BENEFICIARY']">
        <a joyrideStep="thirdStep" [stepContent]="customContent3" class="im-nav-link" *ngIf="!roleName"
          routerLink="attorney/beneficiaries"><img src="/assets/images/menu-beneficiary.png" width="10px" class="mr-1"
            alt=""> Individual</a>
      </li>

      <ng-template #customContent3>
        <app-guided-tour-steps [content]="attorneyGuidedTourContent[2]"></app-guided-tour-steps>
      </ng-template>

      <li class="im-nav-item" *ngIf="isAdminSelected" routerLinkActive="active">
        <a class="im-nav-link" routerLink="attorney/firm-info" *ngxPermissionsOnly="['VIEW_COMPANY']"><i
            class="fa fa-file-text"></i> Firm Info</a>
      </li>

      <li class="im-nav-item overflow-auto" routerLinkActive="active">
        <a joyrideStep="secondStep" [stepContent]="customContent2" class="im-nav-link" routerLink="clients"><img
            src="/assets/images/menu-clients.png" width="10px" class="mr-1" alt=""> Company</a>
      </li>

      <ng-template #customContent2>
        <app-guided-tour-steps [content]="attorneyGuidedTourContent[1]"></app-guided-tour-steps>
      </ng-template>

      <li class="im-nav-item dropdown" *ngIf="isAdminSelected" routerLinkActive="active">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-home"></i> Admin
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" *ngxPermissionsOnly="['VIEW_EMPLOYEES']" routerLink="users"
            routerLinkActive="active">Users</a>
          <a class="dropdown-item" *ngxPermissionsOnly="['VIEW_ROLE']" routerLink="roles"
            routerLinkActive="active">Roles</a>
          <a class="dropdown-item" routerLink="policies" routerLinkActive="active">Policies</a>
          <a class="dropdown-item" routerLink="attorney/interpreter" routerLinkActive="active">Interpreter</a>
          <a class="dropdown-item" routerLink="bulk-upload">Bulk Upload Petition</a>
        </div>

      </li>
      <li class="im-nav-item" routerLinkActive="active">
        <a *ngIf="!roleName" class="im-nav-link" [routerLink]="['attorney/reports']">
          <!-- <a class="im-nav-link"  routerLink="reports"> -->
          <i class="fa fa-file-text"></i> Reports
        </a>
      </li>
      <li class="im-nav-item" routerLinkActive="active">
        <a *ngIf="!roleName" class="im-nav-link" [routerLink]="['leads/marketing']">
          <!-- <a class="im-nav-link"  routerLink="reports"> -->
          <i class="fa fa-file-text"></i> Leads
        </a>
      </li>
      <li class="im-nav-item dropdown" routerLinkActive="active">
        <a *ngIf="!roleName" class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- <i class="fa fa-home"></i> -->
          <img src="../../../../assets/images/invoice/service_icon.svg" width='20' /> Services
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="attorney-invoice">Billing & Invoicing</a>
          <a class="dropdown-item" routerLink="case-status">Case Status</a>
        </div>
      </li>
      <li class="im-nav-item dropdown" routerLinkActive="active">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-home"></i> Data Setup
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="lca">LCAs</a>
          <a class="dropdown-item" routerLink="template/template-list">Template Builder</a>
          <a class="dropdown-item" routerLink="rfe/template-list">Defined RFE Responses</a>
          <a class="dropdown-item" routerLink="receipts-notices/receipts-list">Receipt & Notice ETA</a>
          <a *ngIf="!roleName" class="dropdown-item" routerLink="email-temp/manage-email">Leads Email
            Template</a>
          <a *ngIf="!roleName" class="dropdown-item" routerLink="task-allocation">Steps Time Configurations</a>
          <!-- <a class="dropdown-item" routerLink="receipt/receipt-management-list">Receipt Management</a> -->
          <a class="dropdown-item" *ngxPermissionsOnly="['JD_AND_PD']" routerLink="master-data/manage">Master Data</a>
          <a *ngIf="!roleName" class="dropdown-item" routerLink="faq/manage-faq">FAQ Details</a>
          <a class="dropdown-item" *ngxPermissionsOnly="['VIEW_QUESTIONNAIRE']" routerLink="custom-questionaire">Custom
            Questionaire</a>
        </div>
      </li>
      <!-- <li class="im-nav-item" routerLinkActive="active">
      <a class="im-nav-link" routerLink="companies/compliance"><i class="fa fa-file-text"></i> Compliance</a>
    </li> -->
      <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="resources"><img src="/assets/images/Resources_icon.png" width="20px" alt="">
          Resources</a>
      </li>
    </ul>
  </div>
  <!-- <div class="navbar-rgt-btn mr-2" href="#">
    <img src="/assets/images/menu-recomended.png" width="25%" alt="">
    <div>RECOMMENDED<br>TOOLS FOR YOU </div>
  </div> -->
  <div>
    <app-switch-app [appRoleData]="null"></app-switch-app>
  </div>

</nav>