<div class="page-caption row">
    <div class="col-md-12 px-0">
      <p class="mb-0">
        <small class="link" (click)="breadCrumbNavigation()">Home</small> &raquo; <small> Services</small> &raquo; <small> Case Status</small>
      </p>
    </div>
    <div class="col-md-8 px-0">
        <h5 class="mb-0">Manage Case Status</h5>
        <small>Manage existing case status or create new</small>
    </div>
    <div class="col-md-4 px-0 justify-content-end d-flex">
        <button type="button" class="btn btn-primary add-btn"(click)="addnew()"> <i class="pi pi-plus"></i>Add New</button>
    </div>
</div>
<div class="mat-elevation-z8 mt-3 manage-case-status">
  <div class="row py-0">
      <div class="col-md-6 pt-3 pb-3">
          <h6 class="pl-4">List of Cases</h6>
      </div>
      <div class="col-md-6 pt-3 pb-3 justify-content-end d-flex">
        <div class="manage-case-refresh">
            <i class="pi pi-refresh" (click)="refreshAllCases()" tooltipPosition="bottom" pTooltip="Refresh USCIS Case Status"></i>
        </div>
        <div class="filter-manage-case-status">
          <p-overlayPanel #filterPanel [style]="{width: '410px', padding: 0}" [dismissable]="false">
            <ng-template pTemplate>
              <div class="row col-md-12 pl-0 pr-0">
                  <div class="col-md-6 pl-0 pb-2">
                    <label class="filter-label">Task Type</label> <br>
                    <p-dropdown class="filter-dropdown" [options]="taskType" optionValue="code" [(ngModel)]="filter.superTaskType" optionLabel="name" (onChange)="onTaskTypeChange();">
                    </p-dropdown>
                  </div>
                  <div class="col-md-6 p-0" *ngIf="filter.superTaskType ==='NONIMMPET'">
                    <label class="filter-label">Visa Type</label> <br>
                    <p-multiSelect optionValue="code" [options]="visaTypes"
                      [(ngModel)]="filter.visaTypes" defaultLabel="Select Petition" optionLabel="name"(onChange)="onVisaTypeChange();">
                    </p-multiSelect>
                  </div>
                  <div class="col-md-6 p-0" *ngIf="filter.superTaskType ==='IMMPET'">
                    <label class="filter-label">Immigration Category</label> <br>
                    <p-multiSelect optionValue="code" [options]="immCategoryTypes"
                      [(ngModel)]="filter.immCategoryTypes" defaultLabel="Select Immigration Category" optionLabel="name">
                    </p-multiSelect>
                  </div>
                  <div class="col-md-6 pl-0 pb-2" *ngIf="filter.superTaskType ==='IMMPET'">
                    <label class="filter-label">Immigration Clssification</label> <br>
                    <p-multiSelect optionValue="code" [options]="immClassificationTypes"
                      [(ngModel)]="filter.immClassificationTypes" defaultLabel="Select Immigration Classification"
                      optionLabel="name">
                    </p-multiSelect>
                  </div>
                  <div class="col-md-6 pb-2 p-0" *ngIf="filter.superTaskType ==='IMMPET'">
                    <label class="filter-label">Sub Task Type</label> <br>
                    <p-multiSelect optionValue="code" [options]="subTaskTypes"
                      [(ngModel)]="filter.subTaskTypes" defaultLabel="Select Task Type" optionLabel="name">
                    </p-multiSelect>
                  </div>
                  <div class="col-md-6 pb-2 pl-0" *ngIf="filter.superTaskType ==='NONIMMPET'">
                    <label class="filter-label">Petition Type</label> <br>
                    <p-multiSelect optionValue="code" [options]="petitionTypes"
                      [(ngModel)]="filter.petitionType" defaultLabel="Select Petition" optionLabel="name">
                    </p-multiSelect>
                  </div>
                  <div class="col-md-6 pb-2" [ngClass]="{'p-0': filter.superTaskType ==='NONIMMPET','pl-0': filter.superTaskType ==='IMMPET'}">
                    <label class="filter-label">Process Type</label> <br>
                    <p-dropdown class="filter-dropdown" [options]="processTypes" optionValue="code" [(ngModel)]="filter.processType" optionLabel="name" (onChange)="getServiceCenters(filter.processType);" >
                    </p-dropdown>
                  </div>
                  <div class="col-md-6 pb-2" [ngClass]="{'pl-0': filter.superTaskType ==='NONIMMPET','p-0': filter.superTaskType ==='IMMPET'}">
                    <label class="filter-label">Service Center</label> <br>
                    <p-multiSelect optionValue="id" [options]="serviceCenters"
                      [(ngModel)]="filter.serviceCenterNameIds" defaultLabel="Select Service Center" optionLabel="name">
                    </p-multiSelect>
                  </div>
                  <div class="pb-2 pr-0 pt-2 d-flex justify-content-end align-items-end" [ngClass]="{'col-md-12': filter.superTaskType ==='IMMPET', 'col-md-6': filter.superTaskType ==='NONIMMPET'}">
                    <button pButton type="button" title="Apply" label="Apply" class="p-button-primary p-button apply-btn  pull-right"
                      (click)="applyFilters();filterPanel.hide()"></button>
                  </div>
              </div>
            </ng-template>
          </p-overlayPanel>
    
          <button type="text" class="taskFilter-btn pi" pButton label="Filter" (click)="filterPanel.toggle($event)"  icon="pi pi-filter" iconPos="right"> </button>
        </div>
        <div class="search-btn">
          <div class="input-group search-text">
            <input class="form-control im-pt-3 pr-2rem" #searchCaseStatusListTable  type="search"
              placeholder="Search Case By Receipt Number / Beneficiary Name">
            <span class="input-group-search-icon">
              <button class="btn rounded-pill" title="search" type="button">
                <i class="fa fa-search border-0" alt=""></i>
              </button>
            </span>   
          </div>
        </div>
      </div>
  </div>


  <p-table [columns]="cols" class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
      styleClass="p-datatable-gridlines" [value]="caseStatusList" dataKey="id">
      
      <ng-template pTemplate="header" let-columns>
        <tr class="">
          <th style="width: 1.5rem;padding: 0 !important;"></th>
          <th *ngFor="let col of columns" [style.width]="col.width" [attr.class]="col.cssClass">
            {{col.header}}
          </th>
          <th width="10%" class="text-center">Actions</th>
        </tr>
      </ng-template>


      <ng-template pTemplate="body" let-case let-columns="columns">
        <tr>
          <td></td>
          <td *ngFor="let col of columns">
            <div *ngIf="col.field === 'status'" class="d-flex">
              <span class="case-short-status">{{case?.shortStatus}}</span>
            </div>
            <span *ngIf="!['status'].includes(col.field)">
              {{case[col.field]}}  
            </span> 
            <small *ngIf="['visaType','immClassification'].includes(col.field) && !case.taskId" class="text-secondary d-block">(Petition not filed in Imagility)</small>
          </td>
          <td>
            <div class="d-flex justify-content-center">
              <button pButton type="button" aria-label="View" icon="pi pi-eye"  (click)="showUSCISDialog(case)"  pTooltip="View Status Summary" class="p-button-text"></button>
              <p-divider layout="vertical"></p-divider>
              <button pButton type="button" aria-label="Edit" icon="pi pi-pencil" (click)="editCase(case, case.id)" pTooltip="Edit" class="p-button-text"></button>
              <p-divider layout="vertical"></p-divider>
              <button pButton type="button" aria-label="Delete" icon="pi pi-trash" pTooltip="Delete" class="p-button-text" (click)="confirmDelete(case)"></button>
            </div>
          </td>
        </tr>
      </ng-template> 

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="9" class="text-center">
                No Data Available
            </td>
        </tr>
    </ng-template>
  </p-table>
  <p-paginator [rows]="pageSize" (onPageChange)="paginationEvent($event)" *ngIf="this.caseStatusList?.length > 0"
    [totalRecords]="totalElementCount" [rowsPerPageOptions]="pageSizeOptions" length="totalElementCount">
  </p-paginator>
</div>
<p-confirmDialog header="Delete" key="deletecasestatus"></p-confirmDialog>



<p-dialog [(visible)]="display" [modal]="true" [style]="{width: '40vw'}"
    [draggable]="false" [resizable]="false">

    <ng-template pTemplate="header">
      <div class="row w-100">
          <div class="col-6 p-0 m-0">
              <h6><b>USCIS Case Status</b></h6>
          </div> 
          <div class="col-6 p-0 m-0 pt-1">
            <i class="pi pi-refresh pl-2 pr-2 pull-right" (click)="refreshReceipt()" tooltipPosition="bottom" pTooltip="Refresh"></i>
            <span class="receptTime pull-right" *ngIf="caseUpdatedTime">Updated on: {{caseUpdatedTime}}</span>  
          </div>        
      </div>     
  </ng-template>
    
    <div class="divider"></div>
    
    <div class="row mt-2 p-1 case-status-content">
      <p *ngIf="receiptNumberContent">{{receiptNumberContent}}</p>
      <p *ngIf="!receiptNumberContent">
          Sorry, there is no <b>case status</b> available for this receipt number.
      </p>
    </div>

    <div class="divider mt-2 mb-3"></div>

    <div class="row d-flex">
      <button type="submit" style="padding: 0px 22px !important; height:31px!important" class="btn btn-primary pull-left" (click)="cancelDialog()">Okay</button>
    </div>
</p-dialog>