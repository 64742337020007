import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class I9Service {

  constructor(private http: HttpClient) { }
  onGetUploadedFile(companyId, i9FormId){
    return this.http.get(`/document/i9/company/${companyId}/i9form/${i9FormId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }

  onDownload(path: string, fileName: string) {
    this.http.get(path, { responseType: 'arraybuffer' })
      .pipe(take(1))
      .subscribe(data => {
        const blob = new Blob([data], { type: 'application/pdf' });
        saveAs(blob, fileName);
      });
  }

  onDeleteUploadedI9Form(i9FormId: number, companyId: number) {
    return this.http.delete(`/i9/form/company/${companyId}/i9form/${i9FormId}`);
  }

  onFileUpload(companyId, beneficiaryEmployeeId, file) {
    let APIURI = `/document/i9/company/${companyId}/beneficiary/${beneficiaryEmployeeId}   `;    
    return this.http.post(APIURI, file);
  }

  getI9Section1Info(companyId, i9FormId){
    return this.http.get(`/i9/company/${companyId}/i9form/${i9FormId}/I9Section1Info`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }

  postI9Section1Info(companyId, i9FormId, payload){
    return this.http.post(`/i9/company/${companyId}/i9form/${i9FormId}/I9Section1Info`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
          else {
            return null;
          }
        })
    );
  }
  updateI9Status(i9FormId, statusCode){
    return this.http.post(`/i9/${i9FormId}/status/${statusCode}/`, null).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
          else {
            return null;
          }
        })
    );
  }
  getBeneficiarySignature(beneficiaryEmployeeId) {
    let url = environment.baseUrl+`/beneficiary/${beneficiaryEmployeeId}/profile/self/signPic`;    
    return this.http.get(url).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }
  uploadPreparerSignature(companyId,i9FormId,payload) {
    return this.http.post(`/i9/company/${companyId}/i9form/${i9FormId}/preparer/signPic`, payload).pipe(
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  uploadBeneficiarySignature(beneficiaryId, payload){
    return this.http.post(`/beneficiary/${beneficiaryId}/profile/self/signPic`, payload).pipe(
      map((data: Array<any>) => {
        return data;
      })
    );    
  }

  getBeneficiaryFormList(formType,beneficiaryId,payload){
    return this.http.post(`/i9/form/${formType}/beneficiary/${beneficiaryId}/i9FormList`,payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status) {
            return response.data;
          }
        })
      );
    } 

  fetchUploadedDocument(i9FormId,categoryName,fileCategory){
    return this.http.get(`/document/i9/${i9FormId}/category/${categoryName}/fileCategory/${fileCategory}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }  

  uploadDocument(companyId,i9FormId,categoryName,fileCategory, payload){
    return this.http.post(`/document/i9/company/${companyId}/i9form/${i9FormId}/category/${categoryName}/fileCategory/${fileCategory}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    })
    );
  }

  getI9DiscrimintaionNotice(){
    return this.http.get(`/i9/getI9AntiDiscr`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }


  deleteDoc(i9FormId,documentId){
    return this.http.delete(`/document/i9/${i9FormId}/document/${documentId}`).pipe(
      map((data) => {
        return data;
      })
    )
  }
}
