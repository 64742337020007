<div class="container-fuild" [ngClass]="{'add-individual-page': !inputData,'add-individual-modal': inputData}">
    <div *ngIf="inputData" class="p-grid pt-2">
        <div class="p-col-7 align-middle">
            <h5 class="mb-0">Add Individual/Sponsor</h5>
        </div>
        <div class="p-col-5 text-right align-middle">
            <i (click)="handleClose()" class="close-icon pi pi-times"></i>
        </div>
    </div>
    <hr *ngIf="inputData" class="mt-0 mb-1" />
    <form [formGroup]="newBeneForm" (ngSubmit)="onSubmit()">
        <div [ngClass]="{'main-body': inputData, 'p-card p-component': !inputData}">
            <div class="p-col-12 p-0">
                <div class="p-grid" [ngClass]="{'pt-4': inputData,'p-4': !inputData}">
                    <div class="p-col-{{inputData ? 4 : 3}} mb-2 px-{{inputData ? 2 : 4}} pb-3">
                        <div class="p-field-radiobutton align-items-start">
                            <p-radioButton name="btype" value="IMMPET" [(ngModel)]="beneType"
                                (onClick)="BeneTypeChanged(beneType)" [ngModelOptions]="{standalone: true}"></p-radioButton>
                            <label for="empBased" class="ml-2">Employment Based<br><small class="pt-1">Individuals who
                                    will be
                                    applying for Employment visas</small></label>
                        </div>
                    </div>
                    <div class="p-col-{{inputData ? 4 : 3}} mb-2 px-4 pb-3">
                        <div class="p-field-radiobutton align-items-start">
                            <p-radioButton name="btype" value="NONIMMPET" [(ngModel)]="beneType"
                                (onClick)="BeneTypeChanged(beneType)" [ngModelOptions]="{standalone: true}"></p-radioButton>
                            <label for="empBased" class="ml-2">Individual<br><small class="pt-1">Individuals who will
                                    be applying for Visas apart from employment based</small></label>
                        </div>
                    </div>
                    <div *ngIf="beneType !== 'IMMPET'" class="col-12 d-flex align-items-center mb-{{inputData ? 0 : 4}}">
                        <label>Is this individual a self sponsor?</label>
                        <p-inputSwitch [(ngModel)]="isSelfSponsor" [ngModelOptions]="{standalone: true}"
                            (onChange)="isSponsorChanged(isSelfSponsor)" class="ml-2"></p-inputSwitch>
                    </div>
                    <div class="p-col-12 mb-{{inputData ? 0 : 4}} highlighted-box" *ngIf="beneType === 'IMMPET'">
                        <div class="p-grid pl-{{inputData ? 2 : 4}} pr-4 pb-3">
                            <div class="w-100 pt-2"></div>
                            <div [ngClass]="{'w-45 p-col-5': !inputData, 'p-col-6': inputData}">
                                <label class="create-task-for-label">Which Client/Company you want to processed
                                    with?</label>
                                <label class="create-task-label d-block">Client/Company<span class="required"></span><button
                                        pButton type="button" label="Add New Company" class="p-button-text p-0 pull-right"
                                        (click)="handleAddClick('addClientCompany')" *ngIf="!inputData"></button></label>
                                <p-autoComplete class="client-company-autocomplete w-100 d-block" formControlName="company"
                                    [suggestions]="filteredCompanies" field="petitionerName" dataKey="petitionerId"
                                    [dropdown]="true" [placeholder]="autoCompletePlaceholder"
                                    (completeMethod)="filterCompanies($event)" (onSelect)="onCompanyChange($event)"
                                    [forceSelection]="true" name="company" [showEmptyMessage]="true" appendTo="body">
                                    <ng-template pTemplate="header">
                                        <div class="autocomplete-header row pt-1 pb-1">
                                            <div class="col-6 pl-2">
                                                <span class="autocomplete-heading-text">Company Name</span>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <span class="autocomplete-heading-text">Primary Contact</span>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-item pTemplate="item">
                                        <div class="group-item row">
                                            <div class="col-6 pl-2">
                                                <span class="autocomplete-value-text" title="{{item.petitionerName}}"
                                                    *ngIf="userType === 'Attorney'">{{item.petitionerName}}</span>
                                                <span class="autocomplete-value-text" title="{{item.companyName}}"
                                                    *ngIf="userType === 'Petitioner'">{{item?.companyName}}</span>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <span class="autocomplete-value-text"
                                                    title="{{item.contactPersonFirstName}} {{item.contactPersonMiddleName ? item.contactPersonMiddleName +' ' : ''}}{{item.contactPersonLastName}}"
                                                    *ngIf="userType === 'Attorney'">{{item.contactPersonFirstName}}
                                                    {{item.contactPersonMiddleName ? item.contactPersonMiddleName +' ' :
                                                    ''}}{{item.contactPersonLastName}}</span>
                                                <span class="autocomplete-value-text"
                                                    title="{{item.signatoryFirstName}} {{item.signatoryLastName}}"
                                                    *ngIf="userType === 'Petitioner'">{{item.signatoryFirstName}}
                                                    {{item.signatoryLastName}}</span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-autoComplete>
                                <span class="invalid-message"
                                    *ngIf="newBeneForm.get('company').touched && newBeneForm.get('company').errors?.required">
                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 mb-{{inputData ? 0 : 4}} highlighted-box">
                        <div class="px-{{inputData ? 2 : 4}} py-3">
                            <div class="float-right p-field-radiobutton align-items-start"
                                *ngIf="!isSelfSponsor && beneType === 'NONIMMPET'">
                                <p-radioButton name="primaryContact" value="sponsor" formControlName="primaryContact"
                                    [(ngModel)]="primaryContact" id="pc1"></p-radioButton>
                                <label for="pc1" class="ml-2">Primary Contact</label>
                            </div>

                            <div class="d-flex align-items-center justify-content-start">
                                <label class="create-task-for-label mb-0" *ngIf="beneType === 'IMMPET'">Individual
                                    Details</label>
                                <label class="create-task-for-label mb-0" *ngIf="beneType !== 'IMMPET'">Sponsor</label>
                            </div>
                            <div class="p-fluid p-formgrid p-grid pt-2" [ngClass]="{'p-col-12 pl-0': inputData, 'p-col-8': !inputData}">
                                <div class="p-col-4 pb-3">
                                    <label class="label-cls required"> First Name </label>
                                    <input class="im-input mw-100" id="firstName" formControlName="fName" type="text" pInputText
                                        placeholder="Enter" />
                                    <div *ngIf="newBeneForm.get('fName').touched && newBeneForm.get('fName').invalid">
                                        <span class="invalid-message" *ngIf="newBeneForm.get('fName').errors?.required">
                                            {{getGlobalErrorMessages('REQUIRED')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="p-col-4 pb-3">
                                    <label class="label-cls required"> Last Name </label>
                                    <input class="im-input mw-100" id="lastName" formControlName="lName" type="text" pInputText
                                        placeholder="Enter" />
                                    <div *ngIf="newBeneForm.get('lName').touched && newBeneForm.get('lName').invalid">
                                        <span class="invalid-message" *ngIf="newBeneForm.get('lName').errors?.required">
                                            {{getGlobalErrorMessages('REQUIRED')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="p-col-4 pb-3">
                                    <label class="label-cls"> Middle Name (Optional) </label>
                                    <input class="im-input mw-100" id="middleName" formControlName="mName" type="text"
                                        pInputText placeholder="Enter" />
                                </div>
                                <div class="p-col-4 pb-3">
                                    <label class="label-cls required"> Email
                                    </label>
                                    <input type="email" class="im-input m-0" formControlName="email" placeholder="Enter"
                                        [pattern]="emailPattern" />
                                    <div *ngIf="newBeneForm.get('email').touched && newBeneForm.get('email').invalid">
                                        <span class="invalid-message" *ngIf="newBeneForm.get('email').errors?.required">
                                            {{getGlobalErrorMessages('REQUIRED')}}
                                        </span>
                                        <span class="invalid-message"
                                            *ngIf="newBeneForm.get('email').touched && newBeneForm.get('email').invalid && newBeneForm.get('email').errors?.pattern">
                                            {{getGlobalErrorMessages('INVALID_EMAIL')}}
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <label required class="create-task-for-label mb-0"
                                *ngIf="beneType !== 'IMMPET' && !isSelfSponsor">Is the
                                sponsor a Green Card holder or a US Citizen?</label>
                            <div class="d-flex">
                                <div class="p-col-3 mb-2 pb-3" *ngIf="beneType !== 'IMMPET' && !isSelfSponsor">
                                    <div class="p-field-radiobutton align-items-start">
                                        <p-radioButton name="GC" value="GC" [(ngModel)]="citizenType" id="gc"
                                            [ngModelOptions]="{standalone: true}"></p-radioButton>
                                        <label for="gc" class="ml-2">Green Card Holder</label>
                                    </div>
                                </div>
                                <div class="p-col-3 mb-2 pb-3" *ngIf="beneType !== 'IMMPET' && !isSelfSponsor">
                                    <div class="p-field-radiobutton align-items-start">
                                        <p-radioButton name="citizen" value="USA" [(ngModel)]="citizenType" id="us"
                                            [ngModelOptions]="{standalone: true}"></p-radioButton>
                                        <label for="us" class="ml-2">US Citizen</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="p-col-12 mb-{{inputData ? 0 : 4}}" [ngClass]="!isSelfSponsor && beneType === 'NONIMMPET'?'highlighted-box':''">
                        <div [ngClass]="{'px-2 py-0': inputData,'px-4 py-3': !inputData }">
                            <div class="float-right p-field-radiobutton align-items-start"
                                *ngIf="!isSelfSponsor && beneType === 'NONIMMPET'">
                                <p-radioButton name="primaryContact" value="applicant" formControlName="primaryContact"
                                    [(ngModel)]="primaryContact" id="pc2"></p-radioButton>
                                <label for="pc2" class="ml-2">Primary Contact</label>
                            </div>
                            <div class="p-fluid p-formgrid p-grid pt-2 pl-0"
                                *ngIf="beneType !== 'IMMPET' && !isSelfSponsor" [ngClass]="{'p-col-12 pl-0': inputData, 'p-col-8': !inputData}">

                                <div class="p-col-12 pb-{{inputData ? 0 : 3}}">
                                    <h2 required class="create-task-for-label mb-0">Individual / Applicant (Family
                                        Member Details)</h2>
                                    <p><b>If you do not have applicant/dependent details, here are a few options:</b></p>
                                    <ol>
                                        <li>You can send invite to the Sponsor post creation OR</li>
                                        <li>You can send a Questionnaire to the Sponsor to add the details</li>
                                    </ol>

                                </div>
                                <div class="p-col-4 pb-{{inputData ? 0 : 3}}">
                                    <label class="label-cls"> First Name </label>
                                    <input class="im-input mw-100" id="firstName" formControlName="fFname" type="text"
                                        pInputText placeholder="Enter" />
                                </div>
                                <div class="p-col-4 pb-3">
                                    <label class="label-cls"> Last Name </label>
                                    <input class="im-input mw-100" id="lastName" formControlName="fLname" type="text" pInputText
                                        placeholder="Enter" />
                                </div>
                                <div class="p-col-4 pb-3">
                                    <label class="label-cls"> Middle Name (Optional) </label>
                                    <input class="im-input mw-100" id="middleName" formControlName="fmName" type="text"
                                        pInputText placeholder="Enter" />
                                </div>
                                <div class="p-col-4 pb-3">
                                    <label class="label-cls"> Email </label>
                                    <input type="email" class="im-input m-0" formControlName="applicantemail"
                                        placeholder="Enter" [pattern]="emailPattern" />
                                    <span class="invalid-message"
                                        *ngIf="newBeneForm.get('applicantemail').touched && newBeneForm.get('applicantemail').invalid && newBeneForm.get('applicantemail').errors?.pattern">
                                        {{getGlobalErrorMessages('INVALID_EMAIL')}}
                                    </span>
                                </div>
                                <div class="p-col-4 pb-3">
                                    <label class="label-cls">Relationship with the Sponsor</label>
                                    <p-dropdown [options]="relationShipTypes" id="title" formControlName="relationShipType"
                                        optionLabel="name" optionValue="code" placeholder="Select"></p-dropdown>
                                </div>
                            </div>
                        </div>
                        <small class="text-danger pl-2" *ngIf="!isSelfSponsor && beneType === 'NONIMMPET' && !primaryContact">*Please select primary contact</small>
                    </div>
                    <ng-container *ngIf="!inputData">
                        <p-divider type="dashed" class="w-100"></p-divider>
                        <div class="p-col-12">
                            <button pButton type="submit" label="CREATE" title="CREATE" [disabled]="!newBeneForm.valid"></button>
                            <button pButton type="button" label="CANCEL" title="CANCEL" (click)="cancel()"
                                class="p-button-secondary ml-3"></button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="w-100" *ngIf="inputData">
            <p-divider type="dashed" class="w-100"></p-divider>
            <div class="p-col-12">
                <button pButton type="submit" label="CREATE" title="CREATE" [disabled]="!newBeneForm.valid"></button>
                <button pButton type="button" label="CANCEL" title="CANCEL" (click)="cancel()"
                    class="p-button-secondary ml-3"></button>
            </div>
        </div>
    </form>
</div>