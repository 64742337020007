<div class="fluid-container">
    <div class="row pt-4 d-flex align-items-center">
        <div class="col-md-9 step-title p-0">
            <h5 class="mb-0">Dependent Details</h5>
            <p class="mb-0" style="font-size:14px">Enter the dependant details</p>
        </div>
        <div class="col-md-2">
            <gc-status-update [stepDetails]="stepDetails" type="EDIT"></gc-status-update>
        </div>
        <div class="col-md-1 pr-0 d-flex justify-content-end">
            <lib-maximize-dialog-library
                [configData]="{'layoutCode':stepDetails?.stepActionList[0].layoutCode}"></lib-maximize-dialog-library>
            <i (click)="handleClose();" class="close-icon pi pi-times"></i>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 p-0">
            <lib-assigning-task-library [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)">
            </lib-assigning-task-library>
        </div>
    </div>
    <p-divider></p-divider>
    <div class="main-body footer-adjust-v1">
        <form *ngIf="showDependentDetailsForm" [formGroup]="dependentForm" (ngSubmit)="postDependent()"
            class="pb-3 pt-2">

            <div class="row mb-3">
                <div class="col-md-3 form-group processing-dropdown">
                    <label class="label-cls required"> Select Relationship </label>
                    <p-dropdown [options]="listRelationship" id="relationshipType" formControlName="relationshipType"
                        [disabled]="disabledControls || relastionShipEditType" optionLabel="name" optionValue="code" placeholder="select" optionDisabled="inactive">
                    </p-dropdown>
                    <div
                        *ngIf="dependentForm.controls['relationshipType'].touched && dependentForm.controls['relationshipType'].invalid">
                        <span class="invalid-message"
                            *ngIf="dependentForm.controls['relationshipType'].errors?.required">
                            {{getGlobalErrorMessages('REQUIRED')}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-3 form-group processing-dropdown">
                    <label class="label-cls row required"> Title </label>
                    <p-dropdown [options]="listTitle" id="title" formControlName="title" [disabled]="disabledControls"
                        optionLabel="name" optionValue="code" placeholder="select"> </p-dropdown>
                    <div *ngIf="dependentForm.controls['title'].touched && dependentForm.controls['title'].invalid">
                        <span class="invalid-message" *ngIf="dependentForm.controls['title'].errors?.required">
                            {{getGlobalErrorMessages('REQUIRED')}}
                        </span>
                    </div>
                </div>
                <div class="col-md-3 form-group input-disabled">
                    <label class="label-cls required"> First Name </label>
                    <input type="text" class="im-input mw-100" id="firstName" formControlName="firstName"
                        [readOnly]="disabledControls" placeholder="Enter" pInputText />
                    <div
                        *ngIf="dependentForm.controls['firstName'].touched && dependentForm.controls['firstName'].invalid">
                        <span class="invalid-message" *ngIf="dependentForm.controls['firstName'].errors?.required">
                            {{getGlobalErrorMessages('REQUIRED')}}
                        </span>
                    </div>
                </div>
                <div class="col-md-3 form-group input-disabled">
                    <label class="label-cls required"> Last Name </label>
                    <input type="text" class="im-input mw-100" id="lastName" formControlName="lastName"
                        [readOnly]="disabledControls" placeholder="Enter" pInputText />
                    <div
                        *ngIf="dependentForm.controls['lastName'].touched && dependentForm.controls['lastName'].invalid">
                        <span class="invalid-message" *ngIf="dependentForm.controls['lastName'].errors?.required">
                            {{getGlobalErrorMessages('REQUIRED')}}
                        </span>
                    </div>
                </div>
                <div class="col-md-3 form-group input-disabled">
                    <label class="label-cls"> Middle Name </label>
                    <input type="text" class="im-input mw-100" id="middleName" formControlName="middleName"
                        [readOnly]="disabledControls" placeholder="Enter" pInputText />
                    <div
                        *ngIf="dependentForm.controls['middleName'].touched && dependentForm.controls['middleName'].invalid">
                        <span class="invalid-message" *ngIf="dependentForm.controls['middleName'].errors?.required">
                            {{getGlobalErrorMessages('REQUIRED')}}
                        </span>
                    </div>
                </div>
            </div>
            <!-- <div class="row mb-3">
                <div class="row col-12 form-group">
                    <div class="p-field-checkbox">
                        <p-checkbox id="otherCheck" formControlName="otherCheck" [disabled]="disabledControls"
                            binary="true">
                        </p-checkbox>
                        <label class="label-cls">Do you have any other name?</label>
                    </div>
                </div>
                <div class="col-md-3 form-group input-disabled" *ngIf="dependentForm.get('otherCheck').value === true"
                    formArrayName="otherNames">
                    <div formGroupName="0">
                        <label class="label-cls row required"> Other Name</label>
                        <input [readOnly]="disabledControls" type="text" class="im-input mw-100" id="firstName"
                            formControlName="firstName" placeholder="Enter" pInputText />
                        <div
                            *ngIf="getFormArrayControls('otherNames')[0].get('firstName').touched && getFormArrayControls('otherNames')[0].get('firstName').invalid">
                            <span class="invalid-message"
                                *ngIf="getFormArrayControls('otherNames')[0].get('firstName').errors?.required">
                                {{getGlobalErrorMessages('REQUIRED')}}
                            </span>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row mb-3">
                <div class="col-md-3 form-group processing-dropdown">
                    <label class="label-cls row required"> Gender </label>
                    <p-dropdown [options]="listGender" id="gender" formControlName="gender"
                        [disabled]="disabledControls" optionLabel="name" optionValue="code" placeholder="select">
                    </p-dropdown>
                    <div *ngIf="dependentForm.controls['gender'].touched && dependentForm.controls['gender'].invalid">
                        <span class="invalid-message" *ngIf="dependentForm.controls['gender'].errors?.required">
                            {{getGlobalErrorMessages('REQUIRED')}}
                        </span>
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <label class="label-cls required"> Date of Birth </label>
                    <p-calendar appendTo="body" type="text" class="mw-100" id="dob" formControlName="dob"
                        [disabled]="disabledControls" inputId="icon" [showIcon]="true" dateFormat="mm-dd-yy"
                        [maxDate]="maxDate" yearRange="1900:2023" [yearNavigator]="true">
                    </p-calendar>
                    <div *ngIf="dependentForm.controls['dob'].touched && dependentForm.controls['dob'].invalid">
                        <span class="invalid-message" *ngIf="dependentForm.controls['dob'].errors?.required">
                            {{getGlobalErrorMessages('REQUIRED')}}
                        </span>
                        <span class="invalid-message" *ngIf="dependentForm.controls['dob'].errors?.patternError">
                            {{ getGlobalErrorMessages("INVALID_FORMAT") }}
                        </span>
                        <span class="invalid-message" *ngIf="dependentForm.controls['dob'].errors?.currentDateError">
                            {{ getGlobalErrorMessages("INVALID_DATE") }}
                        </span>
                    </div>
                </div>
                <div class="col-md-3 form-group " formArrayName="phone">
                    <div formGroupName="0">
                        <label class="label-cls"> Mobile No </label>
                        <div class="processing-dropdown d-flex align-center-items input-disabled">
                            <p-dropdown style="width:80px" [options]="countryList" id="countryCode"
                                formControlName="countryCode" [disabled]="disabledControls" optionValue="countryCode"
                                placeholder="select" optionLabel="phoneCode">
                                <ng-template let-item pTemplate="item">
                                    <div class="p-d-flex p-ai-center">
                                        <span> (+{{item.phoneCode}}) - {{ item.countryName }} </span>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <input type="text" class="im-input mw-100" id="phoneNo" formControlName="phoneNo"
                                [disabled]="disabledControls" maxLength="10" placeholder="Enter" pInputText />
                        </div>
                        <div
                            *ngIf="getFormArrayControls('phone')[0].get('phoneNo').touched && getFormArrayControls('phone')[0].get('phoneNo').invalid">
                            <span class="invalid-message"
                                *ngIf="getFormArrayControls('phone')[0].get('phoneNo').errors?.pattern">
                                {{ getGlobalErrorMessages("INVALID_NUMBER") }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 form-group input-disabled" formArrayName="email">
                    <div formGroupName="0">
                        <label class="label-cls"> Email </label>
                        <input type="text" class="im-input mw-100" id="mailId" formControlName="mailId"
                            [readOnly]="disabledControls" placeholder="Enter" pInputText />
                        <div
                            *ngIf="getFormArrayControls('email')[0].get('mailId').touched && getFormArrayControls('email')[0].get('mailId').invalid">
                            <span class="invalid-message"
                                *ngIf="getFormArrayControls('email')[0].get('mailId').errors?.email">
                                {{ getGlobalErrorMessages("INVALID_EMAIL") }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-3 form-group processing-dropdown">
                    <label class="label-cls row required"> Country of Birth </label>
                    <p-dropdown [options]="countryList" id="birthCountryCode" formControlName="birthCountryCode"
                        [disabled]="disabledControls" optionLabel="countryName" optionValue="countryCode"
                        placeholder="select"> </p-dropdown>
                    <div
                        *ngIf="dependentForm.controls['birthCountryCode'].touched && dependentForm.controls['birthCountryCode'].invalid">
                        <span class="invalid-message"
                            *ngIf="dependentForm.controls['birthCountryCode'].errors?.required">
                            {{getGlobalErrorMessages('REQUIRED')}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row col-md-8  pb-2">
                <div class="col-md-10 form-group pl-0 bold-label">
                    <label class="label-cls">Is he or she applying for adjustment of status?</label>
                </div>
                <div class="col-md-1 form-group">
                    <p-inputSwitch [disabled]="disabledControls" id="isAdjustmentStatus"
                        formControlName="isAdjustmentStatus">
                    </p-inputSwitch>
                </div>
            </div>
            <div class="row col-md-8  pb-2">
                <div class="col-md-10 form-group pl-0 bold-label">
                    <label class="label-cls">Is he or she applying for a visa abroad?</label>
                </div>
                <div class="col-md-1 form-group">
                    <p-inputSwitch [disabled]="disabledControls" id="isVisaAboroad" formControlName="isVisaAboroad">
                    </p-inputSwitch>
                </div>
            </div>
            <p-divider></p-divider>
            <div class="row pt-3">
                <div class="col-md-2">
                    <button pButton type="submit" label="Save" icon="pi pi-save"
                        [ngClass]="dependentForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
                        [disabled]="dependentForm.invalid  || disabledControls"></button>
                </div>
                <div class="col-md-2">
                    <button pButton type="button" label="Cancel" class="p-button-secondary cancel-btn button-width-icon-left 
                d-flex alogn-items-center" [disabled]="disabledControls" (click)="handleCancel()">
                        <i class="fa fa-times-circle"></i>
                    </button>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="row col-md-12 p-0 pb-3 d-flex align-items-center profile-title">
                <div class="col-md-6 p-0">
                    <h6 class="mb-0">Added Dependent Details</h6>
                </div>
                <div class="col-md-3 p-0 text-right">
                    <button *ngIf="!showDependentDetailsForm" label="Add Dependent" type="button" pButton
                        icon="pi pi-plus" [disabled]="disabledControls" (click)="addNewDependent()"
                        pTooltip="Add New Dependent"></button>
                </div>
                <div class="col-md-3 p-0 text-right">
                    <button label="Sync Dependent Data" type="button" pButton [disabled]="disabledControls"
                        (click)="syncDependentDetails()" pTooltip="Sync Dependent Data"></button>
                </div>
            </div>
            
            <p-table *ngIf="dependentList.length > 0" selectionMode="single"
                class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
                styleClass="p-datatable-gridlines" [value]="dependentList" dataKey="id"
                (onRowExpand)="onRowExpand($event)">
                <ng-template pTemplate="header">
                    <tr class="text-uppercase">
                        <th style="width: 3rem"> </th>
                        <th>Relationship</th>
                        <th>Name</th>
                        <th>Date of Birth</th>
                        <th>Mobile No.</th>
                        <th>Email ID</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-dependent let-expanded="expanded">
                    <tr [pSelectableRow]="dependent">
                        <td class="expandIcon">
                            <button type="button" pButton pRipple [pRowToggler]="dependent"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                            </button>
                        </td>
                        <td> {{dependent['relationshipTypeName']}} </td>
                        <td> {{dependent['firstName']}} {{dependent['lastName']}} </td>
                        <td> {{dependent['dob'] | I140DateFormat}} </td>
                        <td> {{dependent['phone'] && dependent['phone'].length > 0 ? dependent['phone'][0]['phoneNo'] :
                            ''}} </td>
                        <td class="text-truncate"> {{dependent['email'] && dependent['email'].length > 0 ?
                            dependent['email'][0]['mailId']: ''}} </td>
                        <td class="table-btn">
                            <button type="button" pButton icon="pi pi-pencil" (click)="editDependentDetails(dependent)"
                                [disabled]="disabledControls" class="p-button-text p-button-rounded p-button-plain"
                                pTooltip="Edit"></button>
                            <button type="button" pButton icon="pi pi-trash" (click)="deleteDependentDetails(dependent)"
                                [disabled]="disabledControls" class="p-button-text p-button-rounded p-button-plain"
                                pTooltip="Delete"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-dependent style="width: 100% !important;">
                    <tr>
                        <td colspan="7">
                            <div class="p-p-3 sub-task-type">
                                <div class="row col-md-12">
                                    <div class="col-md-3">
                                        <label class="expand-label">Country of Birth</label>
                                        <div class="expand-readOnly">{{dependent['birthCountryName']}}</div>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="expand-label">Gender</label>
                                        <div class="expand-readOnly">{{dependent['genderName']}}</div>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="expand-label">DOB</label>
                                        <div class="expand-readOnly">{{dependent['dob'] | I140DateFormat}}</div>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="expand-label">Email</label>
                                        <div class="expand-readOnly">{{dependent['email'][0]['mailId']}}</div>
                                    </div>
                                    <!-- <div class="col-md-3">
                                        <label class="expand-label">State</label>
                                        <div class="expand-readOnly">{{dependent['birthStateName']}}</div>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="expand-label">City</label>
                                        <div class="expand-readOnly">{{dependent['birthCity']}}</div>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="expand-label">Citizenship</label>
                                        <div class="expand-readOnly">{{dependent['citizenshipCountryName']}}</div>
                                    </div> -->
                                </div>
                                <!-- <div class="row col-md-12 pt-3 pb-3">
                                   
                                   
                                </div> -->
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="row col-md-12 pb-2 pt-2" style="border: 1px solid #707070;" *ngIf="dependentList.length == 0">
                <span style="color: #4A4A4A;">Currently No dependent added!</span>
            </div>
        </div>
        <div class="row">
            <lib-custom-questionnaire [stepId]="stepId" [taskId]="stepDetails.taskId" [viewQuestions]="false"
                [showSaveBtn]="true" [loadedIn]="stepDetails.stepName" [isSection]="false">
            </lib-custom-questionnaire>
        </div>
    </div>
    <p-divider></p-divider>
    <div class="row pt-3">
        <div class="col-md-3"></div>
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <gc-status-update [stepDetails]="stepDetails" [type]="typeOfButton"></gc-status-update>
        </div>
    </div>
</div>