<div class="fluid-container">
    <form (ngSubmit)="postBeneWorkExperience()" #workExperienceForm="ngForm">
        <div class="row pt-4 d-flex align-items-center">
            <div class="col-md-9 step-title p-0">
                <div class="w-100 d-flex">
                    <h5 class="mb-0">Enter Beneficiary Experience Details</h5>                    
                </div>
                <div class="w-100 pt-1">
                    <p class="mb-0" style="font-size:14px">Data fetched from the Beneficiary's profile. To update
                        navigate to Beneficiary's profile section</p>
                </div>
            </div>
            <div class="col-md-2">
                <gc-status-update [stepDetails]="stepDetails" type="EDIT"></gc-status-update>
            </div>
            <div class="col-md-1 pr-0 d-flex justify-content-end">
                <lib-maximize-dialog-library
                    [configData]="{'layoutCode':stepDetails?.stepActionList[0].layoutCode}"></lib-maximize-dialog-library>
                <i (click)="handleClose();" class="close-icon pi pi-times"></i>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 p-0">
                <lib-assigning-task-library [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)">
                </lib-assigning-task-library>
                <!-- <lib-assigning-task-library [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)" [isViewMode]="this.inputData['isViewMode']" [isCaseRequest]="this.inputData['isCaseRequest']">
                </lib-assigning-task-library> -->
            </div>
        </div>
        <p-divider></p-divider>

        <div class="main-body footer-adjust">
            
            <div class="btn-group">
                <ng-container *ngIf="!isEditWorkExperience">
                    <button pButton (click)="sync()" type="button" class="p-button-rounded p-button-text pull-right syncBtn"
                        pTooltip="Sync With Profile" icon="pi pi-arrow-right-arrow-left">SYNC</button>
                    <button pButton (click)="merge()" type="button" class="p-button-rounded p-button-text pull-right syncBtn"
                        pTooltip="Merge Both Profile & Petition Data" icon="pi pi-sync
                              ">MERGE</button>
                    <button pButton (click)="reset()" type="button" class="p-button-rounded p-button-text pull-right syncBtn"
                        pTooltip="Reset Petition Data" icon="pi pi-undo">RESET</button>
                </ng-container>
                <button pButton type="button" class="p-button-outlined btnData" [pTooltip]="isEditWorkExperience ? 'Close' : 'Edit'"
                    [disabled]="disabledControls" (click)="updateExperience()">
                    <mat-icon class="mr-1 icn">{{ isEditWorkExperience ? 'cancel' : 'edit' }}</mat-icon>
                    {{ isEditWorkExperience ? 'Close' : 'Edit' }}
                </button>
            </div>


            <div *ngIf="isEditWorkExperience">
                 <immigrant-edit-complete-work-experience-details [primaryData]="primaryData"></immigrant-edit-complete-work-experience-details>
            </div>

            <div *ngIf="!isEditWorkExperience">
                <div class="row pt-3">
                    <div class="profile-title">
                        <h6>Added Work Experience Details</h6>
                    </div>
                    <p-table [columns]="experienceCols"
                        *ngIf="experienceDetails?.gcWorkExperienceDetailsResponseList?.length > 0; else noWorkExp"
                        class="profile-table pa-table im-card-no-border-table im-card-table-noborder-spacing table"
                        styleClass="p-datatable-gridlines" [value]="experienceDetails?.gcWorkExperienceDetailsResponseList"
                        dataKey="id" (onRowExpand)="onRowExpand($event)">
                        <ng-template pTemplate="header" let-columns>
                            <tr class="text-uppercase">
                                <th style="width: 3rem"> </th>
                                <th *ngFor="let col of columns">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns"
                            let-rowIndex="rowIndex">
                            <tr>
                                <td class="expandIcon">
                                    <button type="button" pButton pRipple [pRowToggler]="rowData"
                                        class="p-button-text p-button-rounded p-button-plain"
                                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                                    </button>
                                </td>
                                <td>{{ rowData?.designation }}</td>
                                <td> {{ rowData?.employmentType?.name }} </td>
                                <td> {{ rowData?.startDate | I140DateFormat }} </td>
                                <td>
                                    <span *ngIf="rowData['endDate']">{{ rowData?.endDate | I140DateFormat }}</span>
                                    <span *ngIf="!rowData['endDate']">Present</span>
                                </td>
                                <td>{{ rowData?.salary | currency : rowData['currencyCode']}}
                                </td>
                                <td class="table-btn">
                                    <button type="button" pButton icon="pi pi-trash" pTooltip="Delete Experience"
                                        (click)="deleteExperienceList(rowData)" class="p-button-text p-button-rounded"
                                        [disabled]="disabledControls"></button>
                                    <button type="button" pButton icon="pi pi-refresh" pTooltip="Sync"
                                        (click)="handleExperienceSync(rowData)" class="p-button-text p-button-rounded"
                                        [disabled]="disabledControls"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-subexperiencelist>
                            <tr>
                                <td colspan="7">
                                    <div class="p-p-3 sub-task-type">
                                        <p-table class="sub-task-table profile-table" [columns]='experienceSubCols'
                                            [value]="[subexperiencelist]" dataKey="subTaskId">
                                            <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td *ngFor="let col of columns">
                                    <span>
                                        {{rowData[col.field]}}
                                    </span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">No Data</td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="courseList pt-4" *ngIf="subexperiencelist?.clients?.length > 0">
                        <h6>Clients Details</h6>
                        <p-card class="courses-card">
                            <div class="row document-header">
                                <div class="col-md-4">
                                    <h6>Client Name</h6>
                                </div>
                                <div class="col-md-4">
                                    <h6>Start Date</h6>
                                </div>
                                <div class="col-md-4">
                                    <h6>End Date</h6>
                                </div>
                            </div>
                            <div class="row courses courses-title d-flex align-items-center"
                                *ngFor="let item of subexperiencelist.clients; let i = index">
                                <div class="col-md-4">
                                    {{ item.clientName}}
                                </div>
                                <div class="col-md-4">
                                    {{ item.startDate | I140DateFormat}}
                                </div>
                                <div class="col-md-4">
                                    {{ item.endDate | I140DateFormat}}
                                </div>
                            </div>
                        </p-card>
                    </div>
                    <div class="courseList pt-4" *ngIf="subexperiencelist?.jobDuties?.length > 0">
                        <h6>Duties & Sub-duties</h6>
                        <p-card class="courses-card">
                            <div class="row courses courses-title mt-2 mb-2"
                                *ngFor="let item of subexperiencelist.jobDuties; let i = index">
                                <div class="col-md-1 mt-3 text-center"><span class="duty-list">{{i+1}}</span></div>
                                <div class="col-md-11 pl-0">
                                    <div class="row col-md-12 duty-title d-flex align-items-center">
                                        <div class="col-md-11 pr-0 pl-0" style="font-weight: 600;">
                                            Duty
                                        </div>
                                        <div class="col-md-1 pr-0 pl-0">
                                            <button pButton pTooltip="Delete Duty" [disabled]="disabledControls"
                                                type="button" (click)="dutiesDelete(item)"
                                                class="p-button-rounded p-button-text pull-right"
                                                icon="pi pi-trash"></button>
                                        </div>
                                    </div>
                                    <div class="row col-md-12  duty-txt" style="font-size: 14px;">
                                        {{item.duty}}
                                    </div>
                                    <div class="row col-md-12 duty-title" style="font-size: 13px;font-weight: 600;"
                                        *ngIf="item.subDuties.length > 0"> Sub-Duty
                                    </div>
                                    <div class="row col-md-12 p-0" *ngFor="let subDuty of item.subDuties">
                                        <div class="row col-md-12  duty-txt">
                                            <div class="col-md-11 p-0" style="font-size: 14px; ">
                                                {{subDuty.subDutyDescription}}
                                            </div>
                                            <div class="col-md-1 p-0">
                                                <button pButton [disabled]="disabledControls"
                                                    (click)="subDutiesDelete(subDuty)" type="button"
                                                    class="p-button-rounded p-button-text pull-right"
                                                    pTooltip="Delete Sub Duty" icon="pi pi-trash"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-card>
                    </div>
                    <div class="courseList pt-4" *ngIf="subexperiencelist?.tools?.length > 0">
                        <h6>Added Tools & Technology</h6>
                        <div class="row">
                            <p-chips separator="," disabled="false" class="greenCard-chip" allowDuplicate="false"
                                [(ngModel)]="subexperiencelist.tools">
                                <ng-template let-item pTemplate="item" (onRemove)="handleToolsDelete(item)">
                                    {{item.skillName}}
                                </ng-template>
                            </p-chips>
                        </div>
                    </div>
                    <div class="courseList pt-4" *ngIf="subexperiencelist?.documents?.length > 0">
                        <h6>List of Documents</h6>
                        <p-card class="courses-card">
                            <div class="row document-header">
                                <div class="col-md-5">
                                    <h6>Document Type</h6>
                                </div>
                                <div class="col-md-5">
                                    <h6>Document Name</h6>
                                </div>
                                <div class="col-md-2">
                                    <h6>Action</h6>
                                </div>
                            </div>
                            <div class="row courses courses-title d-flex align-items-center"
                                *ngFor="let item of subexperiencelist.documents; let i = index">
                                <div class="col-md-5">
                                    <span class="duty-list">{{i+1}}</span>
                                    <span>
                                        {{ item?.fileCategory.name }}
                                    </span>
                                </div>
                                <div class="col-md-5">
                                    <span *ngIf="item.isPresent">
                                        {{ item.fileName}}
                                    </span>
                                    <span *ngIf="!item.isPresent" class="redColor d-flex align-items-center">
                                        <i class="pi pi-exclamation-triangle pr-2"></i> Document Missing
                                    </span>
                                </div>
                                <div class="col-md-2 pull-right d-flex align-items-center" *ngIf="item?.isPresent">
                                    <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                        [disabled]="!item.fileLocation" (click)="openDoc(item)"
                                        class="p-button-text p-button-rounded"></button>
                                    <button *ngIf="userType !== 'Beneficiary'" pButton pRipple type="button"
                                        icon="pi pi-directions-alt icons-arrow" class="p-button-rounded p-button-text"
                                        pTooltip="Send Back" [disabled]="disabledControls"
                                        (click)="handleSendBack(item)"></button>
                                    <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="Delete"
                                        [disabled]="disabledControls" (click)="deleteDocuments(subexperiencelist, item)"
                                        class="p-button-text p-button-rounded"></button>
                                </div>
                                <div class="col-md-2" *ngIf="!item?.isPresent">
                                    <button *ngIf="userType !== 'Beneficiary'" pButton pRipple pTooltip="Notify"
                                        type="button" icon="pi pi-bell" [disabled]="disabledControls"
                                        (click)="handleNotification(item)" class="p-button-text p-button-rounded"></button>
                                    <!-- <button *ngIf="userType !== 'Beneficiary'" pButton pRipple pTooltip="Not Applicable"
                                        type="button" icon="pi pi-ban" [disabled]="disabledControls"
                                        class="p-button-text p-button-rounded"></button> -->
                                </div>
                            </div>
                        </p-card>
                    </div>
                </div>
                </td>
                </tr>
                </ng-template>

                </p-table>
                <ng-template #noWorkExp>
                    <p class="pb-3">If no work experience details are available. Starting work experience details need to be
                        filled
                        first before Sync work experience Data.</p>
                </ng-template>
                <div class="row col-md-12 pl-0 pr-0 profile-title pt-3">
                    <h6>Additional Summary</h6>
                    <div class="col-md-12 pl-0 pr-0">
                        <!-- <textarea
                            [(ngModel)]="experienceDetails.gcAdditionalInfoResponseDTO && experienceDetails.gcAdditionalInfoResponseDTO.additionalText"
                            name="text" [froalaEditor]="options"></textarea> -->
                        <kendo-editor
                            [(value)]="experienceDetails.gcAdditionalInfoResponseDTO && experienceDetails.gcAdditionalInfoResponseDTO.additionalText"
                            (valueChange)="workexperiencedetailseditorValueChange($event)" #workexpkendoeditor
                            style="height: 400px;" name="text">
                            <kendo-toolbar>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                                    <kendo-toolbar-button title="Upload’s Image at cursor point" text="Upload Image"
                                        (click)="workexpimageuploaddialog()"></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>

                            </kendo-toolbar>
                        </kendo-editor>
                        <app-upload-image-dialog #workexpimageupload [editor]="workexpkendoeditor">
                        </app-upload-image-dialog>
                    </div>
                </div>
                <div class="row">
                    <lib-custom-questionnaire [stepId]="stepId" [taskId]="stepDetails.taskId" [viewQuestions]="false"
                        [showSaveBtn]="true" [loadedIn]="stepDetails.stepName" [isSection]="false">
                    </lib-custom-questionnaire>
                </div>
                </div>
            </div>
            
        </div>

        <p-divider></p-divider>

        <div class="row pt-3">
            <div class="col-md-2">
                <button pButton type="submit" label="Save" icon="pi pi-save"
                    [ngClass]="workExperienceForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
                    [disabled]="workExperienceForm.invalid  || disabledControls"></button>
            </div>
            <div class="col-md-2">
                <button pButton type="button" label="Cancel" icon="pi pi-times-circle" class="p-button-secondary cancel-btn button-width-icon-left
                d-flex alogn-items-center" [disabled]="disabledControls" (click)="handleCancel()">
                </button>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-6">
                <gc-status-update [stepDetails]="stepDetails" [type]="typeOfButton"></gc-status-update>
            </div>
        </div>
    </form>
</div>