import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-migration-confirmation-modal',
  templateUrl: './migration-confirmation-modal.component.html',
  styleUrls: ['./migration-confirmation-modal.component.scss']
})
export class MigrationConfirmationModalComponent implements OnInit {

  title: string;
  migrationData: any;
  buttons = [];
  constructor(
    public dialogRef: MatDialogRef<MigrationConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData,
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.title = this.dialogData.update.title;
    this.migrationData = this.dialogData.update.migrationData;
    this.buttons = (this.dialogData.buttons) ? this.dialogData.buttons : ['No', 'Yes'];
  }
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  handleNo(){
    this.dialogRef.close(false);
  }

}
