<div class="container-fluid py-3">
  <div class="row mb-3" *ngFor="let item of checkBoxList; let i = index;">
    <div class="col-1">
      {{ i + 1 }}.
    </div>
    <div class="col-11">
      <div class="row" *ngFor="let inputItem of item.properties.inputArray; let j = index;">
        <div class="col-12 mb-1">
          <span class="mr-2">name:</span><span class="font-weight-bold">{{inputItem.name}}</span>
        </div>
        <div class="col-12 mb-1">
          <span class="mr-2">data_field_name:</span><span class="font-weight-bold">{{inputItem.data_field_name}}</span>
        </div>
        <div class="col-2">
          <span class="mr-2">Replace with:</span>
        </div>
        <div class="col-10">
          <input type="text" [(ngModel)]="inputItem.replaceDataFieldName">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button type="button" class="float-right" (click)="update()">Update</button>
    </div>
  </div>
</div>
