import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficiary-profile-landing',
  templateUrl: './beneficiary-profile-landing.component.html',
  styleUrls: ['./beneficiary-profile-landing.component.scss']
})
export class BeneficiaryProfileLandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
