<!-- <div class="tree-select-container" [class.disabled]="disabled">
  <div class="selected-value" (click)="toggleDropdown()" [class.disabled]="disabled">
    <span *ngIf="selectedValue">{{ selectedLabel }}</span>
    <span *ngIf="!selectedValue" class="placeholder">Select an option</span>
    <span class="pi pi-chevron-down dropdown-icon"></span>
  </div>

  <ul class="dropdown-list" [ngClass]="{'d-none': !dropdownVisible}">
    <li *ngFor="let node of treeData" (click)="onNodeSelect($event, node)">
      <span class="node-label" [ngClass]="{'disabled': node.disabled}">{{ node.label }}
        <span class="pi pi-chevron-down dropdown-icon" *ngIf="node.children"></span>
      </span>
      <ul *ngIf="node.children" [ngClass]="{'d-none': !node.showChildren}">
        <ng-container *ngTemplateOutlet="treeNode; context: { $implicit: node.children }"></ng-container>
      </ul>
    </li>
  </ul>
</div>

<ng-template #treeNode let-nodes>
  <li *ngFor="let node of nodes" (click)="onNodeSelect($event, node)">
    <span class="node-label" [ngClass]="{'disabled': node.disabled}">{{ node.label }}
      <span class="pi pi-chevron-down dropdown-icon" *ngIf="node.children"></span>
    </span>
    <ul *ngIf="node.children" [ngClass]="{'d-none': !node.showChildren}">
      <ng-container *ngTemplateOutlet="treeNode; context: { $implicit: node.children }"></ng-container>
    </ul>
  </li>
</ng-template> -->

<div class="tree-select-container" [class.disabled]="disabled">
  <ul class="dropdown-list">
    <li *ngFor="let node of treeData;let i=index">
      <p-checkbox label="{{node.displayName}}" [(ngModel)]="node.selected" labelStyleClass="tree-select-checkbox-label"
        (onChange)="onNodeSelect(node)" [disabled]="node.disabled" binary="true" name="item-{{i}}">
      </p-checkbox>
      <ul *ngIf="node.children" class="mt-2">
        <ng-container *ngTemplateOutlet="treeNode; context: { $implicit: node.children }"></ng-container>
      </ul>
    </li>
  </ul>
</div>

<ng-template #treeNode let-nodes>
  <li *ngFor="let node of nodes;let j=index">
    <p-checkbox label="{{node.displayName}}" [(ngModel)]="node.selected" labelStyleClass="tree-select-checkbox-label"
      (onChange)="onNodeSelect(node)" [disabled]="node.disabled" binary="true" name="node-{{j}}">
    </p-checkbox>
    <ul *ngIf="node.children" class="mt-2">
      <ng-container *ngTemplateOutlet="treeNode; context: { $implicit: node.children }"></ng-container>
    </ul>
  </li>
</ng-template>
