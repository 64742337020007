import {
  AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges,
  OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChildren
} from '@angular/core';
import { TreeNode } from 'app-models';
import { environment } from 'src/environments/environment';
import { FormDesignerService } from '../../form-designer/form-designer.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-fb-menu',
  templateUrl: './fb-menu.component.html',
  styleUrls: ['./fb-menu.component.scss']
})
export class FbMenuComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() menuList: TreeNode[];
  @Input() isFBModulePreview: boolean = false;
  leftMenuList: TreeNode[];
  rightMenuList: TreeNode[];
  @ViewChildren('elementLeft') elementLeft: QueryList<ElementRef>;
  @ViewChildren('elementRight') elementRight: QueryList<ElementRef>;
  @ViewChildren('menu') menu: QueryList<any>;
  @Output() pageOpen = new EventEmitter<TreeNode>();
  @Input() preSelectedMenu: number = null;
  menuParentChildGroupArray: number[][] = [];
  preSelectedMenuTreeNode: TreeNode;
  showSwitchAppSection: boolean;
  employeeId: number;
  companyId: number;
  observableSubscription$ = new Subject();
  @Input() appRoleData: any[] | null = null;
  redirectId: any;
  isSetActiveMenuCalled: boolean = false;

  constructor(
    private formDesignerService: FormDesignerService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.showSwitchAppSection = false;
    this.formDesignerService.activateMenuAndOpenPage
      .subscribe((menuId: number) => {
        if (menuId) {
          this.onOpenPage(this.menuList.find(x => x.value === menuId));
        }
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formDesignerService.clearRedirectId();
    if (changes.menuList && this.menuList) {
      this.configureMenuList();
    }
  }

  configureMenuList() {
    this.menuList.forEach(menu => {
      this.setMenuItems(menu);
    });
    this.leftMenuList = this.menuList.filter(menu => menu.customObj?.alignment && String(menu.customObj?.alignment).toLowerCase().trim() === 'left');
    this.rightMenuList = [{ label: null, value: null, menuType: 'multipleRole' }, ...this.menuList.filter(menu => menu.customObj?.alignment && String(menu.customObj?.alignment).toLowerCase().trim() === 'right').reverse()];
    if (this.preSelectedMenu) {
      this.onOpenPage(this.preSelectedMenuTreeNode);
    }
  }

  removeAndSetActiveMenu(menuId: number) {
    this.removeActiveMenu(this.leftMenuList);
    this.removeActiveMenu(this.rightMenuList);
    const activeMenuArray = this.menuParentChildGroupArray.find(x => x.includes(menuId));
    this.setActiveMenu(activeMenuArray, this.leftMenuList);
    this.setActiveMenu(activeMenuArray, this.rightMenuList);
  }

  setActiveMenu(activeMenuArray: number[], menuList: TreeNode[]) {
    if (menuList.some(x => activeMenuArray.includes(x.value))) {
      const foundMenuList = menuList.find(x => activeMenuArray.includes(x.value));
      foundMenuList.activeMenu = true;
      if (foundMenuList.children) {
        this.setActiveMenu(activeMenuArray, foundMenuList.children);
      }
    }
    this.isSetActiveMenuCalled = true;
    this.formDesignerService.setDashboardLink(false);
    this.formDesignerService.clearRedirectId();
  }

  removeActiveMenu(menuList: TreeNode[]) {
    let activeMenu = menuList.find(x => x.activeMenu);
    if (activeMenu) {
      activeMenu.activeMenu = false;
      if (activeMenu.children) {
        this.removeActiveMenu(activeMenu.children);
      }
    }
  }

  setMenuItems(menu: TreeNode, mappedMenuArray: number[] = null) {
    menu.showChildren = false;
    menu.label = menu.label ? String(menu.label).trim() : null;
    menu.customObj = {
      ...menu.customObj,
      alignment: menu.customObj?.alignment ? String(menu.customObj?.alignment).trim() : null,
      menuOrSubMenuName: menu.customObj?.menuOrSubMenuName ? String(menu.customObj?.menuOrSubMenuName).trim() : null,
      iconImagePath: menu.customObj.iconImagePath ? this.formDesignerService.removePortFromUrl(`${environment.docs}${menu.customObj.iconImagePath}`.replace('/docs//var/www', '')) : null
    };
    if (this.preSelectedMenu === menu.value) {
      this.preSelectedMenuTreeNode = menu;
    }
    let finalMappedArray;
    if (menu.children && menu.children.length > 0) {
      menu.children.forEach((child: TreeNode) => {
        if (!mappedMenuArray) {
          finalMappedArray = [menu.value];
        } else {
          finalMappedArray = [...mappedMenuArray, menu.value]
        }
        this.setMenuItems(child, finalMappedArray);
      });
    } else {
      if (!mappedMenuArray) {
        finalMappedArray = [menu.value];
      } else {
        finalMappedArray = [...mappedMenuArray, menu.value]
      }
      this.menuParentChildGroupArray.push(finalMappedArray);
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.menu.changes.subscribe(() => {
      setTimeout(() => {
        this.checkLineBreak();
      }, 0);
    });
  }

  checkLineBreak() {
    const elementsLeft = this.elementLeft.toArray();
    if (elementsLeft?.length > 0) {
      let previousTop = elementsLeft[0].nativeElement.getBoundingClientRect().top;
      for (let i = 1; i < elementsLeft.length; i++) {
        this.renderer.removeClass(elementsLeft[i].nativeElement, 'first-in-line');
        this.renderer.removeClass(elementsLeft[i].nativeElement, 'last-in-line');
      }

      for (let i = 1; i < elementsLeft.length; i++) {
        const currentTop = elementsLeft[i].nativeElement.getBoundingClientRect().top;

        if (currentTop > previousTop) {
          this.renderer.addClass(elementsLeft[i - 1].nativeElement, 'last-in-line');
          this.renderer.addClass(elementsLeft[i].nativeElement, 'first-in-line');
        }

        previousTop = currentTop;
      }
    }

    const elementsRight = this.elementRight.toArray();
    if (elementsRight?.length > 0) {
      let rightPreviousTop = elementsRight[0].nativeElement.getBoundingClientRect().top;
      for (let i = 1; i < elementsRight.length; i++) {
        this.renderer.removeClass(elementsRight[i].nativeElement, 'first-in-line');
        this.renderer.removeClass(elementsRight[i].nativeElement, 'last-in-line');
      }

      for (let i = 1; i < elementsRight.length; i++) {
        const currentTop = elementsRight[i].nativeElement.getBoundingClientRect().top;

        if (currentTop > rightPreviousTop) {
          this.renderer.addClass(elementsRight[i - 1].nativeElement, 'last-in-line');
          this.renderer.addClass(elementsRight[i].nativeElement, 'first-in-line');
        }

        rightPreviousTop = currentTop;
      }
    }
  }

  onCollapseNode(node: TreeNode, id?: number): void {
    if (!(id && node.customObj.id === id)) {
      node.showChildren = false;
      if (node.children && node.children.length > 0) {
        node.children.forEach((child: TreeNode) => {
          this.onCollapseNode(child, id || null);
        });
      }
    }
  }

  activateParentMenus(menuItem: TreeNode, menuList: TreeNode[]) {
    if (menuItem.pageFormId) {
      const parentMenu = this.findParentMenu(menuItem.pageFormId, menuList);
      if (parentMenu) {
        parentMenu.activeMenu = true;
        this.activateParentMenus(parentMenu, menuList);
      }
    }
  }
  findParentMenu(pageFormId: number, menuList: TreeNode[]): TreeNode | undefined {
    for (const item of menuList) {
      if (item.children && item.children.length > 0) {
        const parentMenu = this.findParentMenu(pageFormId, item.children);
        if (parentMenu) {
          return parentMenu;
        }
      }
      if (item.pageFormId === pageFormId) {
        return item;
      }
    }
    return undefined;
  }
  findMenuItemByPageFormId(menuItem: TreeNode, pageFormId: number): TreeNode | undefined {
    if (menuItem.pageFormId === pageFormId) {
      return menuItem;
    }
    if (menuItem.children && menuItem.children.length > 0) {
      for (const child of menuItem.children) {
        const foundChild = this.findMenuItemByPageFormId(child, pageFormId);
        if (foundChild) {
          return foundChild;
        }
      }
    }
    return undefined;
  }

  @HostListener('document:click', ['$event.target'])
  onClickOutside(target: any) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    //this.redirectId = this.formDesignerService.redirectId;
    //console.log('this.redirectId', this.redirectId);
    if (!clickedInside) {
      if (target.tagName === 'A') {
        this.formDesignerService.getRedirectId().subscribe(redirectId => {
          if (redirectId) {
            this.redirectId = redirectId;
            this.handleOutsideClick(target);
          }
        });
      }
      if (this.leftMenuList) {
        this.isSetActiveMenuCalled = false;
        this.leftMenuList.forEach((node: TreeNode) => {
          this.onCollapseNode(node);
        });
      }
      if (this.rightMenuList) {
        this.rightMenuList.forEach((node: TreeNode) => {
          this.onCollapseNode(node);
        });
      }
    }
  }
  handleOutsideClick(target: any) {
    if (target.tagName === 'A' && this.isSetActiveMenuCalled === false) {

      let foundMenuItem: TreeNode | undefined;
      for (const item of this.leftMenuList) {
        foundMenuItem = this.findMenuItemByPageFormId(item, Number(this.redirectId));
        if (foundMenuItem) {
          foundMenuItem = item;
          break;
        }
      }
      if (foundMenuItem) {
        this.removeActiveMenu(this.leftMenuList);
        foundMenuItem.activeMenu = true;
        this.activateParentMenus(foundMenuItem, this.leftMenuList);
      }
    }
  }


  openPage(event: Event, item: TreeNode) {
    event.stopPropagation();
    this.onOpenPage(item);
  }

  onOpenPage(item: TreeNode) {
    this.pageOpen.emit(item);
    setTimeout(() => {
      this.removeAndSetActiveMenu(item.value);
      this.closeAll();
    }, 0);
  }

  closeAll() {
    this.leftMenuList.forEach((node: TreeNode) => {
      this.onCollapseNode(node);
    });
    this.rightMenuList.forEach((node: TreeNode) => {
      this.onCollapseNode(node);
    });
  }

  openDropdown(event: Event, node: TreeNode, nodeList: TreeNode[], otherAlignedNodeList?: TreeNode[]) {
    event.stopPropagation();
    nodeList.filter((treeNode: TreeNode) =>
      !(treeNode.menuType && treeNode.menuType === "multipleRole"))
      .forEach((treeNode: TreeNode) => {
        this.onCollapseNode(treeNode, node.customObj.id);
      });
    if (otherAlignedNodeList) {
      otherAlignedNodeList.filter((treeNode: TreeNode) =>
        !(treeNode.menuType && treeNode.menuType === "multipleRole"))
        .forEach((treeNode: TreeNode) => {
          this.onCollapseNode(treeNode, node.customObj.id);
        });
    }
    node.showChildren = !node.showChildren;
    if (!node.showChildren) {
      this.onCollapseNode(node);
    }
  }

  handleSwitchRole() {
    this.showSwitchAppSection = !this.showSwitchAppSection;
  }


  ngOnDestroy() {

  }

}
