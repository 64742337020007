    <div class="row my-4 align-items-center">
        <div class="col-md-6">
            <h4>All Notes</h4>
        </div>
        <div class="col-md-6">
            <div class="float-right">
                <div class="d-inline align-middle mx-3 text-secondary" (click)="printSection('printable-section')">
                    <mat-icon>print</mat-icon> 
                    <div class="print-txt">Print</div>
                </div>
                <p-button (onClick)="showDialog()">ADD NOTES</p-button>
            </div>
        </div>
    </div>

<ng-container *ngIf="totalRecordCount > 0">

    <div class="card">
        <div class="card-body">
            <div class="my-2">
                <button type="button" class="btn btn-link" (click)="expandAllNotes()">Expand All Notes</button> |
                <button type="button" class="btn btn-link" (click)="collapseAllNotes()">Collapse All Notes</button>
            </div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Description Column -->
                <ng-container matColumnDef="noteText">
                    <th mat-header-cell *matHeaderCellDef> Description. </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <ng-container>
                            {{ element.show ? element.noteText : (element.noteText | slice:0:75) }}
                            <a [routerLink]="" *ngIf="element.noteText.length > 150;"
                                (click)="element.show = !element.show; $event.stopPropagation();">
                                {{ ((element.show)) ? 'Show less' : 'Show more' }}
                            </a>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Step Name Column -->
                <ng-container matColumnDef="timelineSteps">
                    <th mat-header-cell *matHeaderCellDef> Step Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.timelineSteps}} </td>
                </ng-container>

                <!-- Created By Column -->
                <ng-container matColumnDef="lastUpdatedBy">
                    <th mat-header-cell *matHeaderCellDef> Created By </th>
                    <td mat-cell *matCellDef="let element"> {{element.lastUpdatedBy}} </td>
                </ng-container>

                <!-- Created On Column -->
                <ng-container matColumnDef="lastUpdatedTime">
                    <th mat-header-cell *matHeaderCellDef> Created On </th>
                    <td mat-cell *matCellDef="let element"> {{element.lastUpdatedTime}} </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" class="btn btn-sm p-0 bg-transparent action-btn" [disabled]="element.timelineSteps"
                            (click)="editNotes(element); $event.stopPropagation();">
                            <i class="fa fa-pencil delete-icon" aria-hidden="true" title="Edit"></i>
                        </button>
                        <button type="button" class="btn btn-sm p-0 bg-transparent action-btn"
                            (click)="deleteNotes(element); $event.stopPropagation();">
                            <i class="fa fa-trash-o delete-icon" aria-hidden="true" title="Delete"></i>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
                </tr>
            </table>
        </div>
    </div>

</ng-container>

<div class="no-data" *ngIf="totalRecordCount === 0">
    <h6>No Data Found!</h6>
</div>

<!-- Confirm Delete Modal -->
<p-confirmDialog id="delete-note-modal" [style]="{width: '35vw'}"></p-confirmDialog>

<!-- Add Notes Modal -->
<p-dialog id="add-notes-modal" [header]="isEditMode ? 'Edit Notes' : 'Add Notes'" [modal]="true" [(visible)]="showAddNotesModal"
    [style]="{ width: '50rem' }">
    <form #noteForm="ngForm" (ngSubmit)="onSubmit(noteForm)">
        <div class="form-group mt-4">
            <label for="note">Note Details</label>
            <textarea class="form-control" id="note" name="note" [(ngModel)]="note" required rows="4"></textarea>
            <div *ngIf="noteForm.submitted && !note">
                <small>Note is required.</small>
            </div>
        </div>
        <hr />
        <div class="float-right">
            <p-button class="btn-cancel mx-2" severity="secondary" (onClick)="hideDialog(); noteForm.resetForm();">CANCEL</p-button>            
            <p-button [disabled]="noteForm.invalid" type="submit">{{ isEditMode ? 'UPDATE' : 'ADD' }}</p-button>
        </div>
    </form>
</p-dialog>


<div id="printable-section">
    <table border="1" style="width: 100%; border-collapse: collapse;">
        <thead>
            <tr>
                <th>Note Description</th>
                <th>Step Name</th>
                <th>Created By</th>
                <th>Created On</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let key of selection.selected">
                <td style="width: 60%;">{{ key.noteText }}</td>
                <td>{{ key.timelineSteps }}</td>
                <td>{{ key.lastUpdatedBy }}</td>
                <td>{{ key.lastUpdatedTime }}</td>
            </tr>
        </tbody>
    </table>
</div>