import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'vdBuilderFilter',
  pure: false
})
export class VdBuilderFilter implements PipeTransform {

  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter(item => this.applyFilter(item, filter));
  }

  applyFilter(item, filter): boolean {
    if (filter.keyword) {
      if (item.label) {
        if (item.label && item.label.toLowerCase().indexOf(filter.keyword.toLowerCase()) === -1) {
          return false;
        }
      } else if (item.stepName) {
        if (item.stepName && item.stepName.toLowerCase().indexOf(filter.keyword.toLowerCase()) === -1) {
          return false;
        }
      } else {
        return false;
      }

    }
    return true;
  }
}
