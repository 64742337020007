import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, pluck, catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class TaskNotesService {
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

  constructor(private http: HttpClient) { }

  getnotes(taskId: number, stepId:number){
   return this.http.get(`/L1/task/${taskId}/step/${stepId}/notes`).pipe(
     map((response) => response));
  }

  getAllNotes(userType: string, taskId:number){    
    return this.http.get(`/noteslist/L1/task/${taskId}?userType=${userType}`).pipe(
      map((response) => response));
   }

  savenotes(taskId: number,payload: any){    
    return this.http.post(`/L1/task/${taskId}/note/create`,payload).pipe(
        map((response) => response)
      );
  }

  updateNotes(postId: number, payload) {
    return this.http.post(`/note/${postId}/edit`, payload).pipe(
      map((response) => response)
    );
  }

  deletenotes(taskId: number, noteId: number, timelineSteps?: string, customStep?: boolean){
    let url = `/L1/task/${taskId}/note/${noteId}/delete?isCustomStep=${customStep}`;
    if(!timelineSteps){ // to delete the non step (genral notes)
      url = `/genNotes/L1/task/${taskId}/note/${noteId}/delete`;
    }
      return this.http.delete(url).pipe
      (map((response) =>{
          return response;
      }))
  }
}
