import { Action } from '@ngrx/store';

import { LoginState } from '../reducers/login.reducer';

export const SET_ATTORNEY_INVITE_BENEFICIARY = '[login] SET_ATTORNEY_INVITE_BENEFICIARY';
export const SET_IS_BACK_TO_LOGIN = '[login] SET_IS_BACK_TO_LOGIN';
export const SET_COMPANY_USER_ID = '[login] SET_COMPANY_USER_ID';
export const SET_MASTER_USER_ALREADY_REGISTERED = '[login] SET_MASTER_USER_ALREADY_REGISTERED';

export class SetAttorneyInviteBeneficiary implements Action {
  readonly type = SET_ATTORNEY_INVITE_BENEFICIARY;

  constructor(public payload: LoginState) { }
}

export class SetIsBackToLogin implements Action {
  readonly type = SET_IS_BACK_TO_LOGIN;

  constructor(public payload: boolean) { }
}

export class SetCompanyUserId implements Action {
  readonly type = SET_COMPANY_USER_ID;

  constructor(public payload: string) { }
}

export class SetMasterUserAlreadyRegistered implements Action {
  readonly type = SET_MASTER_USER_ALREADY_REGISTERED;

  constructor(public payload: boolean) { }
}

export type LoginActions = SetAttorneyInviteBeneficiary | SetIsBackToLogin | SetCompanyUserId | SetMasterUserAlreadyRegistered;
