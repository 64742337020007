<mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav #snav  mode="side" opened>
      <mat-nav-list>
        <a mat-list-item routerLink="['/attorneys/profile']" >Profile</a>
        <a mat-list-item routerLink="['/beneficiaries/self']" >Profile</a>
        <a mat-list-item routerLink="['/petitioner/company']">Petitioner</a>
        <a mat-list-item routerLink="['/user']">User</a>
        <a mat-list-item routerLink="['/roles']">Role</a>
      </mat-nav-list>
    </mat-sidenav>

  </mat-sidenav-container>

