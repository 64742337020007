<div class="fluid-container applicantContainerBox">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-9 step-title p-0">
      <h5 class="mb-0 fontheader">{{stepDetails.stepName}}</h5>
      <p class="mb-0 boldFont">Provide & verify all required data</p>
    </div>
    <div class="col-md-2">
      <lib-step-status-update-component-library [visaType]="primaryData.visatype" [stepDetails]="stepDetails"
        type="EDIT" [isViewMode]="primaryData['isViewMode']" [isCaseRequest]="primaryData['isCaseRequest']">
      </lib-step-status-update-component-library>
    </div>
    
    <div class="col-md-1 pr-0 d-flex justify-content-end">
      <lib-maximize-dialog-library [configData]="{'layoutCode':primaryData?.layoutCode}"></lib-maximize-dialog-library>
      <i (click)="handleClose();" class="close-icon pi pi-times pull-right"></i>
    </div>
  </div>
  <div class="row"> 
    <div class="col-md-12 p-0">
        <lib-assigning-task-library [visaType]="primaryData.visatype" [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)"
        [isViewMode]="primaryData['isViewMode']" [isCaseRequest]="primaryData['isCaseRequest']">
        </lib-assigning-task-library>
    </div>
  </div>
  <p-divider></p-divider>
  <p-tabView styleClass="tabview-custom applicant-view"  [(activeIndex)]="activeIndex" (onChange)="handleTabChange($event)">
    <p-tabPanel *ngFor="let applicant of applicantsList">
      <ng-template pTemplate="header">
        <span>{{applicant.firstName}} {{applicant.lastName}}</span><br>
        <span class="relation"> - {{applicant?.relationshipName}}</span>
      </ng-template>
      <ng-template pTemplate="body">
        <div class="main-body footer-adjust" #mainBody>
          <div class="row mt-2">
            <div class="card card-min no-border">
              <div class="im-card-tab-menu">
                <div class="im-list-group leftSidebar ml-0">
                  <a class="im-list-group-item" *ngFor="let item of tabList;let i=index"
                    (click)="handleLeftPanelSelection(item.code, item.isLastTab)"
                    [ngClass]="{'activeItem': selectedItem === item.code , 'inActiveItem':  selectedItem !== item.code }">
                    <span>{{item.name}}</span></a>
                </div>
              </div>
            </div>
            <div class="col-md pl-0 pr-0 ">
              <div [ngSwitch]="selectedItem">
                <div *ngSwitchCase="'PERSONAL_DETAILS'">
                  <app-sponser-personal-detail [primaryData]="primaryData" [userId]="applicant.id" [type]="type">
                  </app-sponser-personal-detail>
                </div>
                <div *ngSwitchCase="'PARENT_INFO'">
                  <app-family-information [primaryData]="primaryData" [userId]="applicant.id" [type]="type">
                  </app-family-information>
                </div>
                <div *ngSwitchCase="'DOC_INFO'">
                  <vd-family-immigrant-documents [primaryData]="primaryData" [userId]="applicant.id"
                    [isPrimaryApplicant]="applicant.isPrimaryApplicant" [type]="type"></vd-family-immigrant-documents>

                  <!-- <app-required-documents [primaryData]="primaryData" [userId]="applicant.id" [isPrimaryApplicant]="applicant.isPrimaryApplicant" [type]="type"></app-required-documents> -->
                </div>
                <div *ngSwitchCase="'IMM_INFO'">
                  <app-immigration-history [primaryData]="primaryData" [userId]="applicant.id" [type]="type">
                  </app-immigration-history>
                </div>
                <div *ngSwitchCase="'ADDR_HISTORY'">
                  <app-address-history (scrollTop)="onScrollTop()" [primaryData]="primaryData" [userId]="applicant.id" [type]="type">
                  </app-address-history>
                </div>
                <div *ngSwitchCase="'MARITAL_HISTORY'">
                  <app-marital-history (scrollTop)="onScrollTop()" [primaryData]="primaryData" [userId]="applicant.id" [type]="type">
                  </app-marital-history>
                </div>
                <div *ngSwitchCase="'EMP_INFO'">
                  <app-employment-history [primaryData]="primaryData" [userId]="applicant.id" [type]="type"></app-employment-history>
                </div>
                <div *ngSwitchCase="'FAMILY_INFO'"> <!-- it is newly added for DS260 -->
                  <app-family-information (scrollTop)="onScrollTop()" [primaryData]="primaryData" [userId]="applicant.id" [type]="type">
                  </app-family-information>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    </p-tabPanel>
  </p-tabView>

  <p-divider></p-divider>
    <!-- pending UI verification -->
   <div class="row pt-3 mt-n3">
    <lib-custom-questionnaire [stepId]="primaryData.stepId" [taskId]="primaryData.taskId"
    [viewQuestions]="false" [showSaveBtn]="true" [loadedIn]="stepDetails.stepName"
    [isSection]="false"></lib-custom-questionnaire>
   </div>
  <p-divider></p-divider>
  <div class="row pt-3" *ngIf="isLastTab">
    <div class="col-md-6"></div>
    <div class="col-md-6">
      <lib-step-status-update-component-library [visaType]="primaryData.visatype" [stepDetails]="stepDetails"
        [type]="typeOfButton">
      </lib-step-status-update-component-library>
    </div>
  </div>
  <div class="row pt-2" *ngIf="!isLastTab">
    <span style="color: #6c6666; font-size: 14px;">
      <i class="pi pi-info-circle pr-2" style="color: #19A0DA;"></i>This step can be marked complete when you
      reach the last tab, i.e., {{lastTabObj?.name}}
    </span>
  </div>
</div>
