<p>email-kendoeditor works!</p>
<div class="container-fluid">
    <p>Home > Leads > Manage-leads>Email Leads Kendo Editor</p>
    <div class="mat-title titleColor font-weight-bold">Email Client</div>
    <div class="mat-subheading-1 titleColor">
        <span (click)="back()" class="back">
            < Back to Manage Leads</span>
    </div>
    <div class="tab-container pb-2 ">
        <div>
            <form [formGroup]="emailclient">
                <div>
                    <span class="head">Enter multiple recipients</span> <span> (by entering Email ID's
                        seperating them with
                        commas.)</span>
                    <br>
                    <input type="text" formControlName="emailids" /><br>
                    <span class="text-danger"
                        *ngIf="(emailformcontrol.emailids.touched || submitted) && emailformcontrol.emailids.errors?.required">
                        Email Id is required
                    </span>


                </div>
                <hr>
                <div>
                    <label for="emaild">Customise your content</label><br>
                    <span class="subject"> Subject</span>
                    <br>
                    <br>
                    <input type="text" formControlName="subject" />
                    <br>
                    <span class="text-danger"
                        *ngIf="(emailformcontrol.subject.touched || submitted) && emailformcontrol.subject.errors?.required">
                        Subject is required
                    </span>
                </div>
                <br>
                <div class="customize">
                    <label class="bodylabel">Body Content (Kendo Editor,angular) </label>
                    <br>
                    <div class="editor">
                        <kendo-editor #mykendoeditor style="height: 500px;" formControlName="bodycontent">
                            <kendo-toolbar>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                            </kendo-toolbar>
                        </kendo-editor>
                    </div>
                </div>          
            </form>
       </div>
       <hr>
        <div class="row col-6 mt-4 mb-2">


            <div class="col-4"> <button type="submit" class="btn vdCreateBtn mr-3" (click)="save()">SEND NOW</button>
            </div>
            <div class="col-3"> <button type="button" class="btn vdCancelBtn mr-3" (click)="cancel()">CANCEL</button>
            </div>
        </div>
    </div>    
</div>