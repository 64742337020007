import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class RequiredDocumentsService {

  constructor(private http: HttpClient) { }

  getAvailableDocumentsList(visaType, caseType, caseId, type, docType, familyId) {
    const familyIdAppUrl = familyId ?  `?familyId=${familyId}`: '';
    return this.http.get(`/${visaType}/${caseType}/${caseId}/${type}/${docType}${familyIdAppUrl}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  postSyncDocumentsList(visaType, caseType, caseId, type, docType, superTaskId, subTaskId) {
    return this.http.post(`/${visaType}/${caseType}/${caseId}/${type}/category/${docType}/sync`, {})
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response && (response.status === 200 || response.status === 204)) {
            return response;
          }
        })
      );
    }

  getDependentAvailableDocumentList(visaType, caseType, caseId, payload) {
    return this.http.post(`/${visaType}/${caseType}/${caseId}/syncDocuments`, payload).pipe(map((response: ImagilityBaseResponse) => {
      if (response && (response.status === 200 || response.status === 204)) {
        return response;
      }
    })
    );
  }

  getSyncDocuments(visaType, caseType, caseId, payload) {
    return this.http.post(`/${visaType}/${caseType}/${caseId}/documentsSync`, payload).pipe(map((response: ImagilityBaseResponse) => {
      if (response && (response.status === 200 || response.status === 204)) {
        return response;
      }
    })
    );
  }

}
