import { Subject, Subscription } from 'rxjs';
import { Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FileUpload } from '../../../data/models/file-upload';
import { UploadDocumentService } from '../../../services/upload-document.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { ReferenceLibraryService } from '../../../services/referenceLibrary.service';
import { ReferenceLibrary } from '../../../data/models/referenceLibrary.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { takeUntil } from 'rxjs/operators';
import { acceptedFileTypes, acceptedFileTypesText } from 'app-models';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class AppFileUpload implements OnChanges, OnInit, OnDestroy {

  @Input()
  configurations: FileUpload;
  @Output()
  fileUploadSuccess = new EventEmitter();
  observableSubscription$ = new Subject();
  documentTypes = [];
  files: File[] = [];
  listFiles: any = [];
  categoryName = 'BENEDUDOC';
  isMultiple: boolean;
  inputMultipleFlag: boolean;
  inputMultipleFlagState: boolean;
  inputMultipleFlagFed: boolean;
  acceptFileType: string;
  isFileListRequired: boolean;
  uploadedFilesData: FormData; // populated by ngfFormData directive
  displayedColumns: string[] = ['name', 'documentType', 'size'];
  dataSource = [];
  documentTypeRequired: boolean;
  docummentTypeCode: string;
  entityId: any;
  fileCategory = '';
  useCase: string;
  useCaseId: number;
  envPath: any;
  otherName = '';
  showSpecify = false;
  useEntityId = false;
  isEntityBasedDelete = false;
  selectedDocType = '';
  isViewEnabled = true;
  isDownloadEnabled = true;
  isDeleteEnabled = true;
  isUploadEnabled = true; // IM 3926
  petitionOutcomeId: string;
  singleFileTypeName: string;
  buttonOnlyMode: boolean;
  singleButtonUpload: boolean;
  subcriptions: Subscription[] = [];
  familyId: any;
  family: any;
  isFBUser: boolean;
  @Input() containerId: number;
  @Output() filesDataChange = new EventEmitter<FormData>();
  @Output() fileCategoryChange = new EventEmitter<any>();
  @Input() isImmigrationWizardIndividualDetails?: boolean = false;
  selectedCountry = 'India';
  @Input() isWizard = false;

  acceptedTypesText = acceptedFileTypesText;
  constructor(
    private uploadDocumentService: UploadDocumentService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private referenceLibraryService: ReferenceLibraryService
  ) {
    this.files = [];
    this.isMultiple = false;
    this.acceptFileType = acceptedFileTypes;
    this.isFileListRequired = true;
    this.documentTypeRequired = false;
    this.docummentTypeCode = null;
    this.useCase = '';
    this.useCaseId = 0;

    this.isFBUser = 'isFBUser' in sessionStorage ? sessionStorage.getItem('isFBUser') === 'true' : false;
  }

  ngOnInit() {
    this.envPath = environment.docs;
  }

  ngOnChanges(e: SimpleChanges) {
    if (e.configurations && e.configurations.currentValue) {
      this.isMultiple = false;
      this.singleFileTypeName = e.configurations.currentValue.singleFileTypeName ? e.configurations.currentValue.singleFileTypeName : 'Browse';
      this.buttonOnlyMode = e.configurations.currentValue.buttonOnlyMode ? e.configurations.currentValue.buttonOnlyMode : false;
      this.acceptFileType = e.configurations.currentValue.fileType;
      this.isFileListRequired = e.configurations.currentValue.isFileListRequired;
      this.categoryName = e.configurations.currentValue.categoryName;
      this.entityId = e.configurations.currentValue.entityId;
      this.inputMultipleFlag = e.configurations.currentValue.inputMultipleFlag;
      this.inputMultipleFlagState = e.configurations.currentValue.inputMultipleFlagState;
      this.inputMultipleFlagFed = e.configurations.currentValue.inputMultipleFlagFed;
      this.fileCategory = e.configurations.currentValue.fileCategory;
      if (!this.isImmigrationWizardIndividualDetails) {
        this.listFiles = e.configurations.currentValue.listUploadedFiles ?
          e.configurations.currentValue.entityType ?
            e.configurations.currentValue.listUploadedFiles.filter(x => x[e.configurations.currentValue.entityType] == this.entityId) :
            e.configurations.currentValue.listUploadedFiles.filter(x => x.entityId == this.entityId) : [];
      }
      this.documentTypeRequired = e.configurations.currentValue.documentTypeRequired;
      this.docummentTypeCode = e.configurations.currentValue.docummentTypeCode;
      this.useCase = e.configurations.currentValue.useCase;
      this.useCaseId = e.configurations.currentValue.useCaseId;
      this.useEntityId = (e.configurations.currentValue.useEntityId) ? e.configurations.currentValue.useEntityId : false;
      this.isEntityBasedDelete = (e.configurations.currentValue.isEntityBasedDelete) ?
        e.configurations.currentValue.isEntityBasedDelete : false;
      this.isViewEnabled = (e.configurations.currentValue.isViewEnabled === false) ? false : true;
      this.isDownloadEnabled = (e.configurations.currentValue.isDownloadEnabled === false) ? false : true;
      if (!this.isImmigrationWizardIndividualDetails) {
        this.listFiles = this.listFiles.map((_e: any) => {
          _e.fileLocationName = (this.isFBUser && _e.fileLocation) ? _e.fileLocation.split('/').slice(-1)[0] : _e.fileLocation.substring(23);

          return _e;
        });
      }

      this.isDeleteEnabled = (e.configurations.currentValue.isDeleteEnabled === false) ? false : true;
      this.isUploadEnabled = (e.configurations.currentValue.isUploadEnabled === false) ? false : true;
      this.petitionOutcomeId = e.configurations.currentValue.petitionOutcomeId ? e.configurations.currentValue.petitionOutcomeId : null;
      this.singleButtonUpload = e.configurations.currentValue.singleButtonUpload ? e.configurations.currentValue.singleButtonUpload : false;
      this.familyId = e.configurations.currentValue.familyId ? e.configurations.currentValue.familyId : null;
      this.family = e.configurations.currentValue.family ? e.configurations.currentValue.family : false;
      if (this.docummentTypeCode) {
        if (this.documentTypeRequired) {
          this.fileCategory = '';
        }
        // this.fileCategory = '';
        this.getDocumentTypeList();
      }
      // Refresh the To be uploaded files list
      if (this.fileCategory === '') {
        this.files = [];
      }
    }
  }

  getDocumentTypeList() {
    this.subcriptions[this.subcriptions.length] =
      this.referenceLibraryService.getReferenceDataWithoutAuthentication(this.docummentTypeCode)
        .subscribe((response: ReferenceLibrary[]) => {
          if (this.petitionOutcomeId) {
            switch (this.petitionOutcomeId) {
              case 'APPROVED':
                this.documentTypes = response.filter(x => x.code === 'APPROVAL');
                break;
              case 'RFE':
                this.documentTypes = response.filter(x => x.code === 'RFE');
                break;
              case 'NOID':
                this.documentTypes = response.filter(x => x.code === 'NOID');
                break;
              case 'DENIED':
                this.documentTypes = response.filter(x => x.code === 'DENIAL');
                break;
              case 'PETWITHDRAW':
                this.documentTypes = response.filter(x => x.code === 'WITHDRWACK' || x.code === 'WITHDRAW');
                break;
              case 'GET_RECEIPT':
                this.documentTypes = response.filter(x => x.code === 'RECNOTC');
                break;              
              default:
                this.documentTypes = response;
                break;
            }

          } else {
            if(this.docummentTypeCode === 'OCRDOCTYPE'){
              if(this.useCase === 'company'){
                this.documentTypes = response.filter(x => x.code === 'OCRLCA');
              }else if(this.useCase === 'beneficiary'){
                this.documentTypes = response.filter(x => x.code != 'OCRLCA');
              }else{
                this.documentTypes = response;
              }          
            }else{
              this.documentTypes = response;
            }            
          }
          if (this.documentTypes && this.documentTypes.length === 1) {
            this.fileCategory = this.documentTypes[0].code;
            this.selectedDocType = this.documentTypes[0].name;
          } else {
            this.selectedDocType = '';
          }
        });
  }

  uploadFiles() {
    if (this.documentTypeRequired) {
      if (this.fileCategory) {
        if (this.files[this.files.length - 1].size <= 10485760) {
          if (this.categoryName === 'BANKDETAILSDOC' || this.categoryName === 'W4FORMS' || this.categoryName === 'W4FORMSSTATE' || this.categoryName === 'FORM16DETAILSDOC') {
            this.uploadFinancials();
          } else if (this.categoryName !== 'BENGREENCARD') {
            this.uploadDocument();
          }

          else {
            if (this.family && this.familyId == null) {
              console.log('No Beneficiary ID');
            } else {
              this.uploadDocument();
            }
          }
        }
        else {
          this.files = [];
          this.toastr.error('File Size is > 10MB');
        }
      }
      else {
        this.files = [];
        this.toastr.error('Select a document type');
      }
    }
    else {
      if (this.files[this.files.length - 1].size <= 10485760) {
        this.uploadDocument();
      }
      else {
        this.files = [];
        this.toastr.error('File Size is > 10MB');
      }
    }
  }

  uploadDocument() {
    this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericUploadDocument(
      this.useCase, this.useCaseId, this.categoryName, this.entityId, this.fileCategory, this.otherName, this.uploadedFilesData, this.familyId)
      .subscribe(response => {
        if (response.status === 200) {
          this.filesDataChange.emit(this.uploadedFilesData);
          this.fileUploadSuccess.emit();
          this.toastr.success(response.message);
          this.files = [];
          this.fileCategoryChange.emit(this.fileCategory);
          //setting Document Type Name(Other field) empty
          this.setOtherNameEmpty();
          // if the html have dropdown for selecting the catagory only we clear the fileCategory
          if (this.documentTypeRequired && !this.selectedDocType) {
            this.fileCategory = '';
          }
          if (!this.isImmigrationWizardIndividualDetails) {
            if (this.categoryName === 'OCRDOCTYPE') {
              this.inputMultipleFlag = null;
            }

            this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericGetUploadDocument(
              this.useCase, this.useCaseId, this.categoryName, this.entityId, this.useEntityId, this.familyId)
              .subscribe((responseGetUploadDocument: any) => {
                if (responseGetUploadDocument.status === 200) {
                  this.listFiles =
                    responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                      .map(x => {
                        return {
                          ...x,
                          fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                          fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                        };
                      });

                  // Bcz entity Id is sometime alphanumeric
                  // this code was added for only LCA Withdrawn
                  if (this.fileCategory === 'LCAWTDOC') {
                    this.listFiles = this.listFiles.filter(x => x.fileCategory.code === this.fileCategory).map(e => {
                      e.fileLocationName = (this.isFBUser && e.fileLocation) ? e.fileLocation.split('/').slice(-1)[0] : e.fileLocation.substring(23)
                      return e;
                    });
                  }
                  this.changeDetectorRef.detectChanges();

                }
                if (responseGetUploadDocument.status === 204) {
                  this.listFiles = [];
                  this.changeDetectorRef.detectChanges();
                }
              });
          }
        } else {
          if (this.documentTypeRequired && !this.selectedDocType) {
            this.fileCategory = '';
          }
          this.files = [];

        }
      });
  }
  uploadFinancials() {
    if (this.categoryName === 'BANKDETAILSDOC') {
      this.uploadDocumentService.generiBankUploadDocument(
        this.categoryName, this.fileCategory, this.otherName, this.uploadedFilesData, this.configurations.entityId, this.familyId, this.containerId)
        .subscribe(response => this.handleBankUploadResponse(response));
    } else if (this.categoryName === 'FORM16DETAILSDOC') {
      this.uploadDocumentService.genericForm16UploadDocument(
        this.categoryName, this.fileCategory, this.otherName, this.uploadedFilesData, this.configurations.entityId, this.familyId)
        .subscribe(response => this.handleW4UploadResponse(response));
    } else if (this.categoryName === 'W4FORMS' || this.categoryName === 'W4FORMSSTATE') {
      this.uploadDocumentService.genericW4UploadDocument(
        this.categoryName, this.fileCategory, this.otherName, this.uploadedFilesData, this.configurations.entityId, this.familyId)
        .subscribe(response => this.handleW4UploadResponse(response));
    }
  }

  handleBankUploadResponse(response) {
    if (response.status === 200) {
      this.fileUploadSuccess.emit();
      this.toastr.success(response.message);
      this.inputMultipleFlag = null;

      this.files = [];
      // Setting Document Type Name(Other field) empty
      this.setOtherNameEmpty();
      // If the HTML has dropdown for selecting the category only, clear the fileCategory
      if (this.documentTypeRequired && !this.selectedDocType) {
        this.fileCategory = '';
      }

      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericBankGetUploadDocument(
        this.useCase, this.configurations.entityId, this.categoryName, this.configurations.entityId, this.useEntityId, this.familyId, this.containerId)
        .subscribe((responseGetUploadDocument: any) => {
          if (responseGetUploadDocument.status === 200) {
            this.listFiles =
              responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                .map(x => {
                  return {
                    ...x,
                    fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                    fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                  };
                });

            this.changeDetectorRef.detectChanges();
          }
          if (responseGetUploadDocument.status === 204) {
            this.listFiles = [];
            this.changeDetectorRef.detectChanges();
          }
        });
    } else {
      // this.toastr.error(response['message']);
      if (this.documentTypeRequired && !this.selectedDocType) {
        this.fileCategory = '';
      }
      this.files = [];
    }
  }

  handleW4UploadResponse(response) {
    if (response.status === 200) {
      this.fileUploadSuccess.emit();
      this.toastr.success(response.message);
      this.inputMultipleFlagState = null;
      this.inputMultipleFlagFed = null;

      this.files = [];
      // Setting Document Type Name(Other field) empty
      this.setOtherNameEmpty();
      // If the HTML has dropdown for selecting the category only, clear the fileCategory
      if (this.documentTypeRequired && !this.selectedDocType) {
        this.fileCategory = '';
      }
      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericW4GetUploadDocument(
        this.useCase, this.configurations.entityId, this.categoryName, this.configurations.entityId, this.useEntityId, this.familyId)
        .subscribe((responseGetUploadDocument: any) => {
          if (responseGetUploadDocument.status === 200) {
            this.listFiles =
              responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                .map(x => {
                  return {
                    ...x,
                    fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                    fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                  };
                });

            this.changeDetectorRef.detectChanges();
          }
          if (responseGetUploadDocument.status === 204) {
            this.listFiles = [];
            this.changeDetectorRef.detectChanges();
          }
        });
    } else {
      // this.toastr.error(response['message']);
      if (this.documentTypeRequired && !this.selectedDocType) {
        this.fileCategory = '';
      }
      this.files = [];
    }
  }

  triggerFileDeletion() {
    if (this.configurations && this.listFiles.length > 0) {
      this.listFiles.forEach((file, index) => {
        this.deleteFilesWithoutWarning(index, file);
      });
    }
  }

  deleteFilesWithoutWarning(index: number, item) {
    this.uploadDocumentService.genericDeleteUploadDocument(
      this.useCase, this.useCaseId, item.fileCategory.group, item.fileCategory.code,
      item.id, this.entityId, this.useEntityId, this.isEntityBasedDelete)
      .subscribe((response: any) => {
        if (response.status === 200) {
          // this.fileUploadSuccess.emit();
          // this.toastr.success(response.message);
          this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericGetUploadDocument(
            this.useCase, this.useCaseId, this.categoryName, this.entityId, this.useEntityId, this.familyId)
            .subscribe((responseGetUploadDocument: any) => {
              if (responseGetUploadDocument.status === 200) {

                this.listFiles = responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                  .map(x => {
                    return {
                      ...x,
                      fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                      fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                    };
                  });

                this.changeDetectorRef.detectChanges();
              }
              if (responseGetUploadDocument.status === 204) {
                this.listFiles = [];
                this.changeDetectorRef.detectChanges();
                if (this.categoryName === 'OCRDOCTYPE') {
                  this.inputMultipleFlag = true;
                }
              }
            });
        } else {
          this.toastr.error(response.message);
        }
      });

  }
  deleteFiles(index: number, item) {

    const title = 'Confirm';
    const message = 'Do you wish to Delete the File?';
    this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
      if (result) {
        if (this.categoryName !== 'BANKDETAILSDOC' && this.categoryName !== 'W4FORMS' && this.categoryName !== 'W4FORMSSTATE') {
          this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericDeleteUploadDocument(
            this.useCase, this.useCaseId, item.fileCategory.group, item.fileCategory.code,
            item.id, this.entityId, this.useEntityId, this.isEntityBasedDelete)
            .subscribe((response: any) => {
              if (response.status === 200) {
                this.fileUploadSuccess.emit();
                this.toastr.success(response.message);
                if (!this.isImmigrationWizardIndividualDetails) {
                  this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericGetUploadDocument(
                    this.useCase, this.useCaseId, this.categoryName, this.entityId, this.useEntityId, this.familyId)
                    .subscribe((responseGetUploadDocument: any) => {
                      if (responseGetUploadDocument.status === 200) {

                        this.listFiles = responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                          .map(x => {
                            return {
                              ...x,
                              fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                              fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                            };
                          });

                        this.changeDetectorRef.detectChanges();
                      }
                      if (responseGetUploadDocument.status === 204) {
                        this.listFiles = [];
                        this.changeDetectorRef.detectChanges();
                        if (this.categoryName === 'OCRDOCTYPE') {
                          this.inputMultipleFlag = true;
                        }
                      }
                    });
                }
              } else {
                this.toastr.error(response.message);
              }
            });
        }
        if (this.categoryName === 'BANKDETAILSDOC') {
          this.deleteBankDocumentHandler(item);
        } else if (this.categoryName === 'W4FORMS' || this.categoryName === 'W4FORMSSTATE') {
          this.deleteW4DocumentHandler(item);
        }
      }
    });
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');

  }

  trackItem(index, item) {
    return item.id;
  }

  dialogueInitializer(title: string, message: string) {
    // const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['Cancel', 'Delete']
      }
    });
    return dialogRef;
  }

  deleteBankDocumentHandler(item) {
    this.uploadDocumentService.deleteBankFileFromDocumentList(item.id).pipe(takeUntil(this.observableSubscription$)).subscribe((data: any) => {
      this.toastr.success(data.message);
      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericBankGetUploadDocument(
        this.useCase, this.configurations.entityId, this.categoryName, this.configurations.entityId, this.useEntityId, this.familyId, this.containerId)
        .subscribe((responseGetUploadDocument: any) => {
          if (responseGetUploadDocument.status === 200) {
            this.listFiles = responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
              .map(x => {
                return {
                  ...x,
                  fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                  fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                };
              });

            this.changeDetectorRef.detectChanges();
          }
          if (responseGetUploadDocument.status === 204) {
            this.listFiles = [];
            this.inputMultipleFlag = true;
            this.changeDetectorRef.detectChanges();
          }
        });
    });
  }
  deleteW4DocumentHandler(item) {
    this.uploadDocumentService.deleteW4FileFromDocumentList(this.configurations.entityId, this.categoryName, this.fileCategory).pipe(takeUntil(this.observableSubscription$)).subscribe((data: any) => {
      this.toastr.success(data.message);
      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericW4GetUploadDocument(
        this.useCase, this.configurations.entityId, this.categoryName, this.configurations.entityId, this.useEntityId, this.familyId)
        .subscribe((responseGetUploadDocument: any) => {
          if (responseGetUploadDocument.status === 200) {

            this.listFiles = responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
              .map(x => {
                return {
                  ...x,
                  fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                  fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                };
              });

            this.changeDetectorRef.detectChanges();
          }
          if (responseGetUploadDocument.status === 204) {
            this.listFiles = [];
            this.inputMultipleFlagFed = true;
            this.inputMultipleFlagState = true;
            this.changeDetectorRef.detectChanges();
          }
        });
    });
  }
  onFileSelected(e) {
    if (e && e.length > 0) {
      setTimeout(() => {
        this.uploadFiles();
      }, 500);
    }
  }
  setOtherNameEmpty() {
    this.otherName = '';
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    if (this.categoryName === 'OCRDOCTYPE') {
      this.triggerFileDeletion()
    }
  }

}
