
<div *ngIf="!isEdit">

<div class="row p-3">
    
    <h5 class="rowHeading p-3">Personal Information <span class="subHeading">( Fetched from Profile data )</span></h5>
    <div class="col-md-3">
        <label>First Name</label>
        <label class="viewDetails" *ngIf="!isEdit">{{section1Info?.beneficiaryDetails?.firstName}}</label>
        <!-- <input type="text" > -->
    </div>
    <div class="col-md-3">
        <label>Last Name</label>
        <label class="viewDetails">{{section1Info?.beneficiaryDetails?.lastName}}</label>
        <!-- <input type="text" > -->
    </div>
    <div class="col-md-3">
        <label>Middle Name</label>
        <label class="viewDetails">{{section1Info?.beneficiaryDetails?.middleName}}</label>
        <!-- <input type="text" > -->
    </div>
</div>

<div class="row p-3">
<div class="col-md-3">
    <label>Date of Birth</label>
    <label class="viewDetails">{{section1Info?.beneficiaryDetails?.dob | date: 'MM/dd/yyyy'}}</label>
    <!-- <input type="text" > -->
</div>
<div class="col-md-6">
    <label>U.S. Social Security Number</label>
    <label class="viewDetails">{{section1Info?.beneficiaryDetails?.ssn}}</label>
    <!-- <input type="text" > -->
</div>

</div>


<div class="row border-bottom-dashed py-3">
<div class="col-md-3 px-0">
<label>Mobile No.</label>
<label class="viewDetails">{{mobileNumber}}</label>
<!-- <input type="text" > -->
</div>
<div class="col-md-6">
<label>Email ID</label>
<label class="viewDetails">{{primaryEmail}}</label>
<!-- <input type="text" > -->
</div>

</div>

<div class="row p-3">
<h5 class="rowHeading p-3">Address Details</h5>
<div class="col-12">
   <label>Address 1</label>
   <label class="viewDetails">{{currentAddressObj?.addressLine1 || '--'}}</label>
<!-- <textarea row="6" style="width: 100%;"></textarea> -->

</div>
<div class="col-md-12 pt-3">
<label>Address 2</label>
<label class="viewDetails">{{currentAddressObj?.addressLine2 || '--'}}</label>
<!-- <textarea  row="6" style="width: 100%;"></textarea> -->
</div>
</div>

<div class="row p-3">
    <div class="col-md-3">
        <label>Country</label>
        <label class="viewDetails">{{currentAddressObj?.countryCode?.countryName || '--'}}</label>
    </div>
    <div class="col-md-3">
        <label>State</label>
        <label class="viewDetails">{{currentAddressObj?.stateProvinceCode || '--'}}</label>
    </div>
    <div class="col-md-3">
        <label>City</label>
        <label class="viewDetails">{{currentAddressObj?.city || '--'}}</label>
    </div>
</div>
<div class="row py-3 border-bottom-dashed">
    <div class="col-md-3 px-0">
        <label>Locality</label>
        <label class="viewDetails">{{currentAddressObj?.locality || '--'}}</label>
    </div>
    <div class="col-md-3">
        <label>ZIP / Postal code</label>
        <label class="viewDetails">{{currentAddressObj?.postCode || '--'}}</label>
    </div>
</div>

<div class="row p-3 border-bottom-solid">
    <h5 class="rowHeading p-3" style="width: 100%;">Digital Signature</h5>
    
    <div class="col-md-3">
            <label class="d-block">Signature of Employee</label>
            <img  *ngIf='beneSignature' class = "signatureImageBox p-0" [src]="beneSignature">
            <!-- <p-fileUpload  #fileUpload mode="basic"
            customUpload="true" (uploadHandler)="uploadSignature($event, fileUpload)"  [auto]="true"
            class="gc-file-upload" chooseIcon="pi pi-upload" chooseLabel="Upload">
            </p-fileUpload> -->
    </div>
    <div class="col-md-3">
            <label>Date</label>
            <label class="viewDetails">{{today | date: 'MM/dd/yyyy'}}</label>
    </div> 
</div>

</div>

<div *ngIf="isEdit">
    <div class="row p-3">
    
        <h5 class="rowHeading p-3">Personal Information <span class="subHeading">( Fetched from Profile data )</span></h5>
        <div class="col-md-3">
            <label>First Name</label>

            <input type="text" class="im-input col-md" placeholder="Enter First Name">

        </div>
        <div class="col-md-3">
            <label>Last Name</label>
            <input type="text" class="im-input col-md" placeholder="Enter Last Name">
        </div>
        <div class="col-md-3">
            <label>Middle Name</label>
            <input type="text" class="im-input col-md" placeholder="Enter Middle Name">
        </div>
    </div>
    
    <div class="row p-3">
    <div class="col-md-3">
        <label>Date of Birth</label>
       
    </div>
    <div class="col-md-6">
        <label>U.S. Social Security Number</label>
        <input type="text" class="im-input col-md" placeholder="Enter U.S. Social Security Number">
    </div>
    
    </div>
    
    
    <div class="row border-bottom-dashed py-3">
    <div class="col-md-3 px-0">
    <label>Mobile No.</label>
    <input type="text" class="im-input col-md" placeholder="Enter Mobile No">
    </div>

    <div class="col-md-6">
    <label>Email ID</label>
    <input type="text" class="im-input col-md" placeholder="Enter Email ID">
    </div>
    
    </div>
    
    <div class="row p-3">
    <h5 class="rowHeading p-3">Address Details</h5>
    <div class="col-12">
       <label>Address 1</label>
       <input type="text" class="im-input col-md" placeholder="Enter Address 1">
    </div>
    <div class="col-md-12 pt-3">
    <label>Address 2</label>
    <input type="text" class="im-input col-md" placeholder="Enter Address 2">
    </div>
    </div>
    
    <div class="row p-3">
        <div class="col-md-3">
            <label>Country</label>
           <select class="im-input col-md">
           <option *ngFor="let code of listCountry" [value]="code.countryCode">
            {{ code.countryName }}
          </option></select>
        </div>
        <div class="col-md-3">
            <label>State</label>
            <select></select>
        </div>
        <div class="col-md-3">
            <label>City</label>
            <select></select>
        </div>
    </div>
    <div class="row py-3 border-bottom-dashed">
        <div class="col-md-3 px-0">
            <label>Locality</label>
            <input type="text" class="im-input col-md" placeholder="Enter Locality">
        </div>
        <div class="col-md-3">
            <label>ZIP / Postal code</label>
            <input type="text" class="im-input col-md" placeholder="Enter ZIP / Postal code">
        </div>
    </div>
    
    <div class="row p-3 border-bottom-solid">
        <h5 class="rowHeading p-3" style="width: 100%;">Digital Signature</h5>
        
        <div class="col-md-3">
                <label class="d-block">Signature of Employee</label>
                <img  *ngIf='beneSignature' class = "signatureImageBox p-0" [src]="beneSignature">
        </div>
        <div class="col-md-3">
                <label>Date</label>
        </div> 
    </div>
</div>