import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs/internal/Subject';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/internal/operators/first';
import { DirtyFormGuard } from 'dirty-check-store';
import { PrimaryData } from 'app-models';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { VisaExtendedState } from '../../utility/interfaces/visa-extended-state';
import { TaskStep } from 'src/app/data/models/task-step.model';
import { getSubTaskDetails, updateStepStatus, getStepDetails } from 'visa-store';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
@Component({
  selector: 'capture-extraordinary-ability-details',
  templateUrl: './capture-extraordinary-ability-details.component.html',
  styleUrls: ['./capture-extraordinary-ability-details.component.scss']
})
export class CaptureExtraordinaryAbilityDetailsComponent implements OnInit {
  titleSoc: string;
  observableSubscription$ = new Subject();
  formChangesSubscription: any;
  guardSubscription: any;
  inputData: PrimaryData;
  stepDetails: TaskStep;
  abilityTypeNational = true;
  stepStatus: string;
  task: SubTaskDetails;
  tabViewSteps = [];

  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    private store: Store<VisaExtendedState>,
    public dynamicDialogConfig: DynamicDialogConfig,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private guard: DirtyFormGuard,
  ) {
    this.inputData = this.dynamicDialogConfig.data;
  }

  ngOnInit() {

    combineLatest([
      this.store.pipe(select(getSubTaskDetails)),
      this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
    ]).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        this.task = response[0];
        if (this.task.extrAblDocEvdCatg['code'] === "OTHERDOCEVDN") {
          this.abilityTypeNational = true;
          this.tabViewSteps = this.task.extrAblDocEvdList.sort((a, b) => (a['id'] > b['id']) ? 1 : -1);
        }
        else {
          this.abilityTypeNational = false;
        }
        this.stepDetails = response[1];

      });
  }

  handleClose() {
    this.guardSubscription = this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.dynamicDialogConfig.data.visatype
    }));
  }

  ngOnDestroy() {
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close();
    }
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

}
