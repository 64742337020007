import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicService {

  constructor() { }

  getDynamicData() {
    return {
      "status": 200,
      "message": "Fetched Display Questions Successfully",
      "data": {
        "taskTypeId": 1,
        "beneficiaryProfileInfo": {
          "refLibFamilySection": {
            "createdBy": 1,
            "modifiedBy": null,
            "id": 1279,
            "group": "QTPROFILESECT ",
            "refGroupdesc": "Questionnaire Profile Section",
            "code": "QTPROFILEBEN",
            "name": "Questionnaire Profile Section - Beneficiary",
            "description": "Questionnaire Profile Section - Beneficiary"
          },
          "selected": true,
          "qfamilySubSectionList": [
            {
              "referenceLibrarysubSection": {
                "createdBy": 1,
                "modifiedBy": null,
                "id": 1284,
                "group": "QTPROFILESUBSECT ",
                "refGroupdesc": "Questionnaire Profile Sub Section",
                "code": "QTPROFILESSEDUC",
                "name": "Education Details",
                "description": "Education Details"
              },
              "selected": true
            },
            {
              "referenceLibrarysubSection": {
                "createdBy": 1,
                "modifiedBy": null,
                "id": 1286,
                "group": "QTPROFILESUBSECT ",
                "refGroupdesc": "Questionnaire Profile Sub Section",
                "code": "QTPROFILESSIMM",
                "name": "Immigration Details",
                "description": "Immigration Details"
              },
              "selected": true
            },
            {
              "referenceLibrarysubSection": {
                "createdBy": 1,
                "modifiedBy": null,
                "id": 1283,
                "group": "QTPROFILESUBSECT ",
                "refGroupdesc": "Questionnaire Profile Sub Section",
                "code": "QTPROFILESSPERS",
                "name": "Personal Details",
                "description": "Personal Details"
              },
              "selected": true
            },
            {
              "referenceLibrarysubSection": {
                "createdBy": 1,
                "modifiedBy": null,
                "id": 1285,
                "group": "QTPROFILESUBSECT ",
                "refGroupdesc": "Questionnaire Profile Sub Section",
                "code": "QTPROFILESSWRKEXP",
                "name": "Work Experience Details",
                "description": "Work Experience Details"
              },
              "selected": true
            }
          ]
        },
        "additionalPetitionInfo": [
          {
            "id": 671,
            "title": null,
            "stepDescription": "Enter Proposed Job Description",
            "sequenceNo": 40,
            "companyId": 256,
            "taskTypeId": 1,
            "stepType": null,
            "stepName": "Enter Proposed Job Description",
            "feinNo": null,
            "selected": true,
            "isBeneficiaryAction": 1,
            "label": null
          },
          {
            "id": 674,
            "title": null,
            "stepDescription": "Enter Specialty Occupation Details",
            "sequenceNo": 70,
            "companyId": 256,
            "taskTypeId": 1,
            "stepType": null,
            "stepName": "Enter Specialty Occupation Details",
            "feinNo": null,
            "selected": true,
            "isBeneficiaryAction": 1,
            "label": null
          },
          {
            "id": 676,
            "title": null,
            "stepDescription": "Enter Beneficiary Personal Details",
            "sequenceNo": 90,
            "companyId": 256,
            "taskTypeId": 1,
            "stepType": null,
            "stepName": "Enter Beneficiary Personal Details",
            "feinNo": null,
            "selected": true,
            "isBeneficiaryAction": 1,
            "label": null
          },
          {
            "id": 678,
            "title": null,
            "stepDescription": "Enter Beneficiary Education Details",
            "sequenceNo": 110,
            "companyId": 256,
            "taskTypeId": 1,
            "stepType": null,
            "stepName": "Enter Beneficiary Education Details",
            "feinNo": null,
            "selected": true,
            "isBeneficiaryAction": 1,
            "label": null
          },
          {
            "id": 680,
            "title": null,
            "stepDescription": "Enter Beneficiary Experience Details",
            "sequenceNo": 130,
            "companyId": 256,
            "taskTypeId": 1,
            "stepType": null,
            "stepName": "Enter Beneficiary Experience Details",
            "feinNo": null,
            "selected": true,
            "isBeneficiaryAction": 1,
            "label": null
          },
          {
            "id": 682,
            "title": null,
            "stepDescription": "Enter Itinerary of Services",
            "sequenceNo": 150,
            "companyId": 256,
            "taskTypeId": 1,
            "stepType": null,
            "stepName": "Enter Itinerary of Services",
            "feinNo": null,
            "selected": true,
            "isBeneficiaryAction": 1,
            "label": null
          },
          {
            "id": 684,
            "title": null,
            "stepDescription": "Provide Beneficiary's Immigration & Other documents",
            "sequenceNo": 170,
            "companyId": 256,
            "taskTypeId": 1,
            "stepType": null,
            "stepName": "Provide Beneficiary's Immigration & Other documents",
            "feinNo": null,
            "selected": true,
            "isBeneficiaryAction": 1,
            "label": null
          },
          {
            "id": 686,
            "title": null,
            "stepDescription": "Enter Employer Employee Relationship Details",
            "sequenceNo": 190,
            "companyId": 256,
            "taskTypeId": 1,
            "stepType": null,
            "stepName": "Enter Employer Employee Relationship Details",
            "feinNo": null,
            "selected": true,
            "isBeneficiaryAction": 1,
            "label": null
          }
        ],
        "documentCheckList": [
          {
            "label": "Project documents",
            "selected": false,
            "qtitleSheetDetailDto": [
              {
                "id": 1352,
                "titleSheetName": "Project documents",
                "docName": "Marketing analysis",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 157,
                  "group": "PETPROJDOC",
                  "refGroupdesc": "Petition Project Document",
                  "code": "MARKETAYS",
                  "name": "Marketing Analysis",
                  "description": "Marketing Analysis"
                },
                "docSequenceNo": 10,
                "selected": true
              },
              {
                "id": 1353,
                "titleSheetName": "Project documents",
                "docName": "Organization chart",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 203,
                  "group": "PETPROJDOC",
                  "refGroupdesc": "Petition Project Document",
                  "code": "ORGCHART",
                  "name": "Organization Chart",
                  "description": "Organization Chart"
                },
                "docSequenceNo": 20,
                "selected": true
              },
              {
                "id": 1358,
                "titleSheetName": "Project documents",
                "docName": "Other",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 885,
                  "group": "PETPROJDOC",
                  "refGroupdesc": "Petition Project Document",
                  "code": "OTHER",
                  "name": "Other",
                  "description": "Other"
                },
                "docSequenceNo": 70,
                "selected": true
              },
              {
                "id": 1354,
                "titleSheetName": "Project documents",
                "docName": "Project Description",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 154,
                  "group": "PETPROJDOC",
                  "refGroupdesc": "Petition Project Document",
                  "code": "PROJDESC",
                  "name": "Project Description",
                  "description": "Project Description"
                },
                "docSequenceNo": 30,
                "selected": true
              },
              {
                "id": 1355,
                "titleSheetName": "Project documents",
                "docName": "Project Milestones",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 155,
                  "group": "PETPROJDOC",
                  "refGroupdesc": "Petition Project Document",
                  "code": "PROJMILE",
                  "name": "Project Milestones",
                  "description": "Project Milestones"
                },
                "docSequenceNo": 40,
                "selected": true
              },
              {
                "id": 1356,
                "titleSheetName": "Project documents",
                "docName": "Projections",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 158,
                  "group": "PETPROJDOC",
                  "refGroupdesc": "Petition Project Document",
                  "code": "PROJECTION",
                  "name": "Projections",
                  "description": "Projections"
                },
                "docSequenceNo": 50,
                "selected": true
              },
              {
                "id": 1357,
                "titleSheetName": "Project documents",
                "docName": "Technical Documentation",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 156,
                  "group": "PETPROJDOC",
                  "refGroupdesc": "Petition Project Document",
                  "code": "TECHDOC",
                  "name": "Technical Documentation",
                  "description": "Technical Documentation"
                },
                "docSequenceNo": 60,
                "selected": true
              }
            ]
          },
          {
            "label": "Beneficiary Documents",
            "selected": false,
            "qtitleSheetDetailDto": [
              {
                "id": 1329,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Approval notice",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 140,
                  "group": "BENIMMDOC",
                  "refGroupdesc": "Beneficiary Immigration Document",
                  "code": "APPROVAL",
                  "name": "Approval notice",
                  "description": "Approval notice"
                },
                "docSequenceNo": 40,
                "selected": true
              },
              {
                "id": 1335,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Certifications",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 139,
                  "group": "BENLICDOC",
                  "refGroupdesc": "Beneficiary License Document",
                  "code": "PCERT",
                  "name": "Professional Certification",
                  "description": "Professional Certification"
                },
                "docSequenceNo": 100,
                "selected": true
              },
              {
                "id": 1330,
                "titleSheetName": "Beneficiary Documents",
                "docName": "EAD in case the Beneficiary is a F1 visa holder",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 143,
                  "group": "BENIMMDOC",
                  "refGroupdesc": "Beneficiary Immigration Document",
                  "code": "EAD",
                  "name": "EAD",
                  "description": "EAD"
                },
                "docSequenceNo": 50,
                "selected": true
              },
              {
                "id": 1326,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Education Certificate",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 483,
                  "group": "BENEDUDOC",
                  "refGroupdesc": "Beneficiary Education Document",
                  "code": "EDUCERT",
                  "name": "Education Certificate",
                  "description": "Education Certificate"
                },
                "docSequenceNo": 10,
                "selected": true
              },
              {
                "id": 1343,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Experience documents",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 145,
                  "group": "BENWEDOC",
                  "refGroupdesc": "Beneficiary Work Experience Document",
                  "code": "EXPLTR",
                  "name": "Experience Letter",
                  "description": "Experience Letter"
                },
                "docSequenceNo": 180,
                "selected": true
              },
              {
                "id": 1332,
                "titleSheetName": "Beneficiary Documents",
                "docName": "I-20",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 183,
                  "group": "BENIMMDOC",
                  "refGroupdesc": "Beneficiary Immigration Document",
                  "code": "I20",
                  "name": "I-20",
                  "description": "I-20"
                },
                "docSequenceNo": 70,
                "selected": true
              },
              {
                "id": 1333,
                "titleSheetName": "Beneficiary Documents",
                "docName": "I-94",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 144,
                  "group": "BENIMMDOC",
                  "refGroupdesc": "Beneficiary Immigration Document",
                  "code": "I94",
                  "name": "I-94",
                  "description": "I-94"
                },
                "docSequenceNo": 80,
                "selected": true
              },
              {
                "id": 1331,
                "titleSheetName": "Beneficiary Documents",
                "docName": "I140 in case of Non-immigrant worker",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 142,
                  "group": "BENIMMDOC",
                  "refGroupdesc": "Beneficiary Immigration Document",
                  "code": "I140",
                  "name": "I-140",
                  "description": "I-140"
                },
                "docSequenceNo": 60,
                "selected": true
              },
              {
                "id": 1336,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Licenses",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 138,
                  "group": "BENLICDOC",
                  "refGroupdesc": "Beneficiary License Document",
                  "code": "PLICENSE",
                  "name": "Professional License",
                  "description": "Professional License"
                },
                "docSequenceNo": 110,
                "selected": true
              },
              {
                "id": 1327,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Marks sheets",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 135,
                  "group": "BENEDUDOC",
                  "refGroupdesc": "Beneficiary Education Document",
                  "code": "MARKSHEET",
                  "name": "Mark Sheet",
                  "description": "Mark Sheet"
                },
                "docSequenceNo": 20,
                "selected": true
              },
              {
                "id": 1344,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Offer Letter",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 98,
                  "group": "POSOFFRLTR",
                  "refGroupdesc": "Position Offer Letter",
                  "code": "POSOFFRLTR",
                  "name": "Position Offer Letter",
                  "description": "Position Offer Letter"
                },
                "docSequenceNo": 190,
                "selected": true
              },
              {
                "id": 1345,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Other",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 859,
                  "group": "BENOTHDOC",
                  "refGroupdesc": "Beneficiary Other Document",
                  "code": "OTHER",
                  "name": "Other",
                  "description": "Other"
                },
                "docSequenceNo": 200,
                "selected": true
              },
              {
                "id": 1341,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Passport",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 147,
                  "group": "BENPASSDOC",
                  "refGroupdesc": "Beneficiary Passport Document",
                  "code": "PASSCOPY",
                  "name": "Passport Copy",
                  "description": "Passport Copy"
                },
                "docSequenceNo": 160,
                "selected": true
              },
              {
                "id": 1338,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Pay stubs",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 146,
                  "group": "BENOTHDOC",
                  "refGroupdesc": "Beneficiary Other Document",
                  "code": "PAY",
                  "name": "Pay Stubs",
                  "description": "Pay Stubs"
                },
                "docSequenceNo": 130,
                "selected": true
              },
              {
                "id": 1334,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Receipt Notice",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 141,
                  "group": "BENIMMDOC",
                  "refGroupdesc": "Beneficiary Immigration Document",
                  "code": "RECNOTC",
                  "name": "Receipt Notice",
                  "description": "Receipt Notice"
                },
                "docSequenceNo": 90,
                "selected": true
              },
              {
                "id": 1337,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Resume",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 150,
                  "group": "BENOTHDOC",
                  "refGroupdesc": "Beneficiary Other Document",
                  "code": "RESUME",
                  "name": "Beneficiary Resume",
                  "description": "Beneficiary Resume"
                },
                "docSequenceNo": 120,
                "selected": true
              },
              {
                "id": 1339,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Tax Returns",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 149,
                  "group": "BENOTHDOC",
                  "refGroupdesc": "Beneficiary Other Document",
                  "code": "TAXRETURN",
                  "name": "Tax Returns",
                  "description": "Tax Returns"
                },
                "docSequenceNo": 140,
                "selected": true
              },
              {
                "id": 1342,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Training Certificates and Courseware",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 137,
                  "group": "BENTRGDOC",
                  "refGroupdesc": "Beneficiary Training Certificate Document",
                  "code": "TCERT",
                  "name": "Training Certificate",
                  "description": "Training Certificates and Courseware"
                },
                "docSequenceNo": 170,
                "selected": true
              },
              {
                "id": 1328,
                "titleSheetName": "Beneficiary Documents",
                "docName": "Transcripts & Degree Certificate",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 136,
                  "group": "BENEDUDOC",
                  "refGroupdesc": "Beneficiary Education Document",
                  "code": "TRANS",
                  "name": "Transcripts",
                  "description": "Transcripts & Degree Certificate"
                },
                "docSequenceNo": 30,
                "selected": true
              },
              {
                "id": 1340,
                "titleSheetName": "Beneficiary Documents",
                "docName": "W2s",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 148,
                  "group": "BENOTHDOC",
                  "refGroupdesc": "Beneficiary Other Document",
                  "code": "W2",
                  "name": "W2",
                  "description": "Wage and Tax statement"
                },
                "docSequenceNo": 150,
                "selected": true
              }
            ]
          },
          {
            "label": "Client documents",
            "selected": false,
            "qtitleSheetDetailDto": [
              {
                "id": 1360,
                "titleSheetName": "Client documents",
                "docName": "Client Letter",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 48,
                  "group": "PETDOCTYP",
                  "refGroupdesc": "Petition Documents Type",
                  "code": "CLNTLT",
                  "name": "Client Letter",
                  "description": "Client Letter"
                },
                "docSequenceNo": 20,
                "selected": true
              },
              {
                "id": 1361,
                "titleSheetName": "Client documents",
                "docName": "Client Position Offer Letter",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 887,
                  "group": "PETDOCTYP",
                  "refGroupdesc": "Petition Documents Type",
                  "code": "CLNTOFFRLTR",
                  "name": "Client Position Offer Letter",
                  "description": "Client Position Offer Letter"
                },
                "docSequenceNo": 30,
                "selected": true
              },
              {
                "id": 1366,
                "titleSheetName": "Client documents",
                "docName": "Client Support Letter",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 51,
                  "group": "PETDOCTYP",
                  "refGroupdesc": "Petition Documents Type",
                  "code": "SUPLTR",
                  "name": "Client Support Letter",
                  "description": "Support Letter"
                },
                "docSequenceNo": 80,
                "selected": true
              },
              {
                "id": 1364,
                "titleSheetName": "Client documents",
                "docName": "Master Service Agreement",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 49,
                  "group": "PETDOCTYP",
                  "refGroupdesc": "Petition Documents Type",
                  "code": "MSADOC",
                  "name": "Vendor Agreement - MSA",
                  "description": "Vendor Agreement - MSA"
                },
                "docSequenceNo": 60,
                "selected": true
              },
              {
                "id": 1367,
                "titleSheetName": "Client documents",
                "docName": "Other",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 231,
                  "group": "PETDOCTYP",
                  "refGroupdesc": "Petition Documents Type",
                  "code": "OTHER",
                  "name": "Other",
                  "description": "Other"
                },
                "docSequenceNo": 90,
                "selected": true
              },
              {
                "id": 1362,
                "titleSheetName": "Client documents",
                "docName": "Purchase Order",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 209,
                  "group": "PETDOCTYP",
                  "refGroupdesc": "Petition Documents Type",
                  "code": "PO",
                  "name": "Purchase Order",
                  "description": "Purchase Order"
                },
                "docSequenceNo": 40,
                "selected": true
              },
              {
                "id": 1359,
                "titleSheetName": "Client documents",
                "docName": "Staffing Service Agreement",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 886,
                  "group": "PETDOCTYP",
                  "refGroupdesc": "Petition Documents Type",
                  "code": "SSA",
                  "name": "Staffing Service Agreement",
                  "description": "Staffing Service Agreement"
                },
                "docSequenceNo": 10,
                "selected": true
              },
              {
                "id": 1365,
                "titleSheetName": "Client documents",
                "docName": "Vendor letter",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 50,
                  "group": "PETDOCTYP",
                  "refGroupdesc": "Petition Documents Type",
                  "code": "VNDLT",
                  "name": "Vendor Letter",
                  "description": "Vendor Letter"
                },
                "docSequenceNo": 70,
                "selected": true
              },
              {
                "id": 1363,
                "titleSheetName": "Client documents",
                "docName": "Work Order",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 47,
                  "group": "PETDOCTYP",
                  "refGroupdesc": "Petition Documents Type",
                  "code": "SOW",
                  "name": "Work Order",
                  "description": "Work Order"
                },
                "docSequenceNo": 50,
                "selected": true
              }
            ]
          },
          {
            "label": "Standard Documents",
            "selected": false,
            "qtitleSheetDetailDto": [
              {
                "id": 1317,
                "titleSheetName": "Standard Documents",
                "docName": "Cover Letter",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 121,
                  "group": "DOCTYP",
                  "refGroupdesc": "Document Type",
                  "code": "COVLTR",
                  "name": "Cover Letter",
                  "description": "Cover Letter"
                },
                "docSequenceNo": 20,
                "selected": true
              },
              {
                "id": 1316,
                "titleSheetName": "Standard Documents",
                "docName": "G-28",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 165,
                  "group": "PETFORMDOC",
                  "refGroupdesc": "Petition related Forms",
                  "code": "G28",
                  "name": "G-28",
                  "description": "G-28"
                },
                "docSequenceNo": 10,
                "selected": true
              },
              {
                "id": 1318,
                "titleSheetName": "Standard Documents",
                "docName": "I-129",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 166,
                  "group": "PETFORMDOC",
                  "refGroupdesc": "Petition related Forms",
                  "code": "I129",
                  "name": "I-129",
                  "description": "I-129"
                },
                "docSequenceNo": 30,
                "selected": true
              },
              {
                "id": 1319,
                "titleSheetName": "Standard Documents",
                "docName": "I-907",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 427,
                  "group": "PETFORMDOC",
                  "refGroupdesc": "Petition related Forms",
                  "code": "I907",
                  "name": "I907",
                  "description": "I907"
                },
                "docSequenceNo": 40,
                "selected": true
              },
              {
                "id": 1321,
                "titleSheetName": "Standard Documents",
                "docName": "LCA- Labor Condition Application",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 167,
                  "group": "LCADOC",
                  "refGroupdesc": "Petition related Forms",
                  "code": "LCA",
                  "name": "LCA",
                  "description": "LCA"
                },
                "docSequenceNo": 60,
                "selected": true
              },
              {
                "id": 1322,
                "titleSheetName": "Standard Documents",
                "docName": "Selection Receipt",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 488,
                  "group": "RECLOTT",
                  "refGroupdesc": "Receipt Type Lottery",
                  "code": "USCISSEL",
                  "name": "USCIS Selected",
                  "description": "USCIS Selected"
                },
                "docSequenceNo": 70,
                "selected": true
              },
              {
                "id": 1320,
                "titleSheetName": "Standard Documents",
                "docName": "Support Letter",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 120,
                  "group": "DOCTYP",
                  "refGroupdesc": "Document Type",
                  "code": "SUPLTR",
                  "name": "Support Letter",
                  "description": "Support Letter"
                },
                "docSequenceNo": 50,
                "selected": true
              }
            ]
          },
          {
            "label": "Speciality Occupation",
            "selected": false,
            "qtitleSheetDetailDto": [
              {
                "id": 1324,
                "titleSheetName": "Speciality Occupation",
                "docName": "Beneficiary Qualification",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 169,
                  "group": "PETSODOC",
                  "refGroupdesc": "Petition Special Occupation Document",
                  "code": "BENQUAL",
                  "name": "Beneficiary Qualification",
                  "description": "Beneficiary Qualification"
                },
                "docSequenceNo": 20,
                "selected": true
              },
              {
                "id": 1325,
                "titleSheetName": "Speciality Occupation",
                "docName": "Itinerary of Services",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 170,
                  "group": "PETSODOC",
                  "refGroupdesc": "Petition Special Occupation Document",
                  "code": "ITISERVICE",
                  "name": "Itinerary of Services",
                  "description": "Itinerary of Services"
                },
                "docSequenceNo": 30,
                "selected": true
              },
              {
                "id": 1323,
                "titleSheetName": "Speciality Occupation",
                "docName": "List of Duties",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 168,
                  "group": "PETSODOC",
                  "refGroupdesc": "Petition Special Occupation Document",
                  "code": "DUTYLIST",
                  "name": "List of Duties",
                  "description": "List of Duties"
                },
                "docSequenceNo": 10,
                "selected": true
              }
            ]
          },
          {
            "label": "Company/petitioner's documents",
            "selected": false,
            "qtitleSheetDetailDto": [
              {
                "id": 1350,
                "titleSheetName": "Company/petitioner's documents",
                "docName": "Articles of Incorporation",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 740,
                  "group": "COMPGENDOC",
                  "refGroupdesc": "Company General Document",
                  "code": "ARTCLINCRPRTN",
                  "name": "Articles of Incorporation",
                  "description": "Articles of Incorporation"
                },
                "docSequenceNo": 50,
                "selected": true
              },
              {
                "id": 1346,
                "titleSheetName": "Company/petitioner's documents",
                "docName": "Benefit Summary",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 153,
                  "group": "COMPGENDOC",
                  "refGroupdesc": "Company General Document",
                  "code": "BENEFITSUM",
                  "name": "Benefit Summary",
                  "description": "Benefit Summary"
                },
                "docSequenceNo": 10,
                "selected": true
              },
              {
                "id": 1348,
                "titleSheetName": "Company/petitioner's documents",
                "docName": "Business Plan",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 152,
                  "group": "COMPGENDOC",
                  "refGroupdesc": "Company General Document",
                  "code": "PROJPLAN",
                  "name": "Business Plan",
                  "description": "Business Plan"
                },
                "docSequenceNo": 30,
                "selected": true
              },
              {
                "id": 1347,
                "titleSheetName": "Company/petitioner's documents",
                "docName": "Company Profile",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 151,
                  "group": "COMPGENDOC",
                  "refGroupdesc": "Company General Document",
                  "code": "COMPROF",
                  "name": "Company Profile",
                  "description": "Company Profile"
                },
                "docSequenceNo": 20,
                "selected": true
              },
              {
                "id": 1349,
                "titleSheetName": "Company/petitioner's documents",
                "docName": "Financial Statement",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 648,
                  "group": "COMPGENDOC",
                  "refGroupdesc": "Company General Document",
                  "code": "COMPFINANCIAL",
                  "name": "Company Financial Document",
                  "description": "Company Financial Document"
                },
                "docSequenceNo": 40,
                "selected": true
              },
              {
                "id": 1351,
                "titleSheetName": "Company/petitioner's documents",
                "docName": "Other",
                "entityType": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 844,
                  "group": "COMPOTHDOC",
                  "refGroupdesc": "Company Other Document",
                  "code": "OTHER",
                  "name": "Other",
                  "description": "Other"
                },
                "docSequenceNo": 60,
                "selected": true
              }
            ]
          }
        ],
        "petitionerCompanyList": null,
        "attorneyCompanyList": null,
        // "accessTillDate": '2022-10-10T18:30:00.000Z',
        "accessTillDate": null,
        "priority": null,
        "qfamilyOtherDependents": [
          {
            "refLibFamilySection": {
              "createdBy": 1,
              "modifiedBy": null,
              "id": 888,
              "group": "FAMRELTYP",
              "refGroupdesc": "Family Relationship Type",
              "code": "BROTHER",
              "name": "Brother",
              "description": "Brother"
            },
            "selected": false,
            "qfamilySubSectionList": [
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1284,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSEDUC",
                  "name": "Education Details",
                  "description": "Education Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1286,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSIMM",
                  "name": "Immigration Details",
                  "description": "Immigration Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1283,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSPERS",
                  "name": "Personal Details",
                  "description": "Personal Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1285,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSWRKEXP",
                  "name": "Work Experience Details",
                  "description": "Work Experience Details"
                },
                "selected": true
              }
            ]
          },
          {
            "refLibFamilySection": {
              "createdBy": 1,
              "modifiedBy": null,
              "id": 15,
              "group": "FAMRELTYP",
              "refGroupdesc": "Family Relationship Type",
              "code": "FATHER",
              "name": "Father",
              "description": "Father"
            },
            "selected": false,
            "qfamilySubSectionList": [
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1284,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSEDUC",
                  "name": "Education Details",
                  "description": "Education Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1286,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSIMM",
                  "name": "Immigration Details",
                  "description": "Immigration Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1283,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSPERS",
                  "name": "Personal Details",
                  "description": "Personal Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1285,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSWRKEXP",
                  "name": "Work Experience Details",
                  "description": "Work Experience Details"
                },
                "selected": true
              }
            ]
          },
          {
            "refLibFamilySection": {
              "createdBy": 1,
              "modifiedBy": null,
              "id": 1110,
              "group": "FAMRELTYP",
              "refGroupdesc": "Family Relationship Type",
              "code": "FIANCE",
              "name": "Fiance",
              "description": "Fiance"
            },
            "selected": false,
            "qfamilySubSectionList": [
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1284,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSEDUC",
                  "name": "Education Details",
                  "description": "Education Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1286,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSIMM",
                  "name": "Immigration Details",
                  "description": "Immigration Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1283,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSPERS",
                  "name": "Personal Details",
                  "description": "Personal Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1285,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSWRKEXP",
                  "name": "Work Experience Details",
                  "description": "Work Experience Details"
                },
                "selected": true
              }
            ]
          },
          {
            "refLibFamilySection": {
              "createdBy": 1,
              "modifiedBy": null,
              "id": 16,
              "group": "FAMRELTYP",
              "refGroupdesc": "Family Relationship Type",
              "code": "MOTHER",
              "name": "Mother",
              "description": "Mother"
            },
            "selected": false,
            "qfamilySubSectionList": [
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1284,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSEDUC",
                  "name": "Education Details",
                  "description": "Education Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1286,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSIMM",
                  "name": "Immigration Details",
                  "description": "Immigration Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1283,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSPERS",
                  "name": "Personal Details",
                  "description": "Personal Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1285,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSWRKEXP",
                  "name": "Work Experience Details",
                  "description": "Work Experience Details"
                },
                "selected": true
              }
            ]
          },
          {
            "refLibFamilySection": {
              "createdBy": 1,
              "modifiedBy": null,
              "id": 889,
              "group": "FAMRELTYP",
              "refGroupdesc": "Family Relationship Type",
              "code": "SISTER",
              "name": "Sister",
              "description": "Sister"
            },
            "selected": false,
            "qfamilySubSectionList": [
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1284,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSEDUC",
                  "name": "Education Details",
                  "description": "Education Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1286,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSIMM",
                  "name": "Immigration Details",
                  "description": "Immigration Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1283,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSPERS",
                  "name": "Personal Details",
                  "description": "Personal Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1285,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSWRKEXP",
                  "name": "Work Experience Details",
                  "description": "Work Experience Details"
                },
                "selected": true
              }
            ]
          }
        ],
        "qfamilyDependents": [
          {
            "refLibFamilySection": {
              "createdBy": 1,
              "modifiedBy": null,
              "id": 13,
              "group": "FAMRELTYP",
              "refGroupdesc": "Family Relationship Type",
              "code": "CHILD",
              "name": "Child",
              "description": "Child"
            },
            "selected": false,
            "qfamilySubSectionList": [
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1284,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSEDUC",
                  "name": "Education Details",
                  "description": "Education Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1286,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSIMM",
                  "name": "Immigration Details",
                  "description": "Immigration Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1283,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSPERS",
                  "name": "Personal Details",
                  "description": "Personal Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1285,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSWRKEXP",
                  "name": "Work Experience Details",
                  "description": "Work Experience Details"
                },
                "selected": true
              }
            ]
          },
          {
            "refLibFamilySection": {
              "createdBy": 1,
              "modifiedBy": null,
              "id": 14,
              "group": "FAMRELTYP",
              "refGroupdesc": "Family Relationship Type",
              "code": "SPOUSE",
              "name": "Spouse",
              "description": "Spouse"
            },
            "selected": false,
            "qfamilySubSectionList": [
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1284,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSEDUC",
                  "name": "Education Details",
                  "description": "Education Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1286,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSIMM",
                  "name": "Immigration Details",
                  "description": "Immigration Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1283,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSPERS",
                  "name": "Personal Details",
                  "description": "Personal Details"
                },
                "selected": true
              },
              {
                "referenceLibrarysubSection": {
                  "createdBy": 1,
                  "modifiedBy": null,
                  "id": 1285,
                  "group": "QTPROFILESUBSECT ",
                  "refGroupdesc": "Questionnaire Profile Sub Section",
                  "code": "QTPROFILESSWRKEXP",
                  "name": "Work Experience Details",
                  "description": "Work Experience Details"
                },
                "selected": true
              }
            ]
          }
        ]
      },
      "errors": null,
      "isCredentialsExpired": false
    };

  }
}
