<div class="container">
    <div class="row pad-top-caption">
        <div class="col-md-3"></div>
        <div class="col-md-6 content">
            <h4 class="caption">Internal Error occured !</h4>
            <div class="card img-fluid" style="width:500px">
              <img class="card-img-top" src="../../../../assets/weblogo.png" alt="Card image" style="width:100%">
              <div class="content-text">
                    <h4 class="card-title">A log of this incident has been sent to Imagility</h4>
                    <div class="flex-row">
                       <p class="card-text">Meanwhile you can go to previous url and retry..</p>
                       <a href="#" (click)= "redirectToTheUrl()" class="btn btn-primary">try Again</a>
                    </div>
              </div>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div> <!--row--->
</div><!--container--->

 
