import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-inline',
  templateUrl: './inline.component.html',
  styleUrls: ['./inline.component.scss']
})
export class InlineComponent {
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() isEditable: boolean = true;

  isEditMode = false;
  originalValue: string;
  @Input() configuration: {
    fontSize?: string,
    needClear?: boolean,
    inlineEditMode?: string,
    containerHeight?: string
  };

  edit(): void {
    if (this.isEditable) {
      this.originalValue = this.value;
      this.isEditMode = true;
    }
  }

  save(): void {
    this.isEditMode = false;
    this.valueChange.emit(this.value);
  }

  clear(): void {
    this.value = '';
  }

  cancel(): void {
    this.value = this.originalValue;
    this.isEditMode = false;
  }

}
