import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ImagilityBaseResponse } from '../data/models/response.model';

import { pluck, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RfeService {

constructor(private httpClient: HttpClient) { }


getRFEDetails(taskId) {
  return this.httpClient.get(`/rfe/task/${taskId}/rfeNotice`).pipe(
    pluck('data'), map((data: Array<any>) => {
      return data;
    }));
  }

 saveRFEDetails(taskId,receiptNo,noticeDate,dueDate,payload){
  return this.httpClient.post(`/rfe/task/${taskId}/rfeNotice?dueDate=${dueDate}&noticeDate=${noticeDate}&receiptNo=${receiptNo}`,payload).pipe(
    map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    })
);
 }

 deleteRFEDocument(taskId,rfeDocumentId){
  return this.httpClient.delete(`/rfe/task/${taskId}/rfeDocument/${rfeDocumentId}`).pipe(
    map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    })
);
 }
 
 updateRfeNotice(rfeNoticeId,actionCode,payload){
  return this.httpClient.post(`/rfe/rfeNotice/${rfeNoticeId}/actionCode/${actionCode}`,payload).pipe(
    map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    }));
}

getRFENotificationMessage(docType,rfeNoticeId){
  return this.httpClient.get(`/rfe/notification/type/${docType}/entity/${rfeNoticeId}`).pipe(
    pluck('data'), map((data: Array<any>) => {
      return data;
    }));
}

  getRfeAttorneySteps(taskId,taskStepId){
    return this.httpClient.get(`/task/${taskId}/taskStep/${taskStepId}/subSteps`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  getRfeAttorneyCatagoryList(rfeNoticeId){
    return this.httpClient.get(`/rfe/attorney/rfeNotice/${rfeNoticeId}/defaultCategories`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  getRfeSelectedCatagoryList(rfeNoticeId){
    return this.httpClient.get(`/rfe/attorney/rfeNotice/${rfeNoticeId}/categories`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  saveRfeSelectedCatagoryList(rfeNoticeId,payload){
    return this.httpClient.post(`/rfe/attorney/rfeNotice/${rfeNoticeId}/categories`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
          return response; // send  full data
      })
  );
  }

  getSupportingDocuments(taskId){
    return this.httpClient.get(`/rfe/task/${taskId}/supportDocuments`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  } 

  getOrganizeDocuments(taskId){
    return this.httpClient.get(`/rfe/task/${taskId}/organizeDocuments`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }
  

  getDocumentType(categoryId){
    return this.httpClient.get(`/rfe/task/1/rfeCategory/${categoryId}/getDocumentTypes`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  saveUploadedDocument(taskId,rfeCategoryId,documentTypeName,entityTypeId,masterTitleSheetId,rfeTitleSheetDetailId,payload){
    return this.httpClient.post(`/rfe/task/${taskId}/rfeCategory/${rfeCategoryId}/uploadDocument?documentType=${documentTypeName}&entityTypeId=${entityTypeId}&masterTitleSheetDetailId=${masterTitleSheetId}&rfeTitleSheetDetailId=${rfeTitleSheetDetailId}`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
          return response; // send  full data
      })
  );
  }

  addDocumentType(taskId,rfeCategoryId,payload){
    return this.httpClient.post(`/rfe/task/${taskId}/rfeCategory/${rfeCategoryId}/addDocumentType`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
          return response; // send  full data
      })
  );
  }

  sendNotification(rfeNoticeId,payload){
    return this.httpClient.post(`/rfe/rfeNotice/${rfeNoticeId}/doc/notifcation`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
          return response; // send  full data
      })
  );
  }

  sendIndividualNotification(rfeNoticeId,payload){
    return this.httpClient.post(`/rfe/rfeNotice/${rfeNoticeId}/res/notifcation`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
          return response; // send  full data
      })
  );
  }

  getResponseDetails(rfeNoticeId){
    return this.httpClient.get(`/rfe/notification/rfeNotice/${rfeNoticeId}/response`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  getSupportingDocumentForAttorney(rfeNoticeId){
    return this.httpClient.get(`/rfe/notification/rfeNotice/doc/${rfeNoticeId}/attorney`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  getResponseForAttorney(rfeNoticeId){
    return this.httpClient.get(`/rfe/notification/rfeNotice/doc/${rfeNoticeId}/attorney`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  getRfeResponseBlock(companyId){
    return this.httpClient.get(`/rfe/company/${companyId}/`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  saveRfeResponseBlock(companyId,payload){
    return this.httpClient.post(`/rfe/company/${companyId}/`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    })
    );
  }

  deleteRfeResponseBlock(companyId,rfeResponseBlockId){
    return this.httpClient.delete(`/rfe/company/${companyId}/?rfeResponseBlockId=${rfeResponseBlockId}`).pipe(
      map((data) => {
        return data;
      })
    )
  }

  getRfeResponseTemplate(rfeNoticeId,isDefaultTemplate){
    return this.httpClient.get(`/rfe/rfeNotice/${rfeNoticeId}/${isDefaultTemplate}/responseTemplate`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  saveRfeResponseTemplate(rfeNoticeId,payload){
    return this.httpClient.post(`/rfe/rfeNotice/${rfeNoticeId}/responseTemplate`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    })
    );
  }

  getRfePlaceholder(rfeNoticeId){
    return this.httpClient.get(`/rfe/rfeNotice/${rfeNoticeId}/placeHolders`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }
  getPetitonData(rfeNoticeId){
    return this.httpClient.get(`/rfe/rfeNotice/${rfeNoticeId}/petitionData`).pipe(
      pluck('data'), map((data: any) => {
        return data;
      }));
  }

  generatePdf(rfeId,draftFlag){
    return this.httpClient.post(`/rfe/${rfeId}/${draftFlag}/generate/letter`,[]).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    }));
  }

  viewGeneratePdf(rfeId,draftFlag){
    return this.httpClient.get(`/rfe/${rfeId}/${draftFlag}/generate/letter`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  updateReviewer(rfeId,reviewerId){
    return this.httpClient.post(`/rfe/task/${rfeId}/reviewer?reviewerId=${reviewerId}`,[]).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    }));
  }

  notifyReviewer(taskId){
    return this.httpClient.post(`/rfe/task/${taskId}/reviewNotify?isReviewerNotify=true`,[]).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    }));
  }

  notifyPreparer(taskId){
    return this.httpClient.post(`/rfe/task/${taskId}/reviewNotify?isPreparerNotify=true`,[]).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    }));
  }

  getGeneratedPdf(rfeId,draftFlag){
    return this.httpClient.get(`/rfe/${rfeId}/${draftFlag}/generate/letter`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));
  }

  getRfeNotification(rfeId,type,userId){
    return this.httpClient.get(`/rfe/notification/rfeNotice/${rfeId}/${type}/${userId}`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));

  }

  sendCommentFromReviewer(rfeId,comments){
    return this.httpClient.post(`/rfe/task/${rfeId}/reviewerComment?comment=${comments}`,[]).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    }));
  }

  getRfeNotificationForResponse(rfeId,type,userId){
    return this.httpClient.get(`/rfe/notification/rfeNotice/${rfeId}/res/${type}/${userId}`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));

  }

  updateNotificationIds(payload){
    return this.httpClient.post(`/rfe/notification/action/update`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
          return response; // send  full data
      }));
  }

  

  saveRfeNoticationResponse(rfeId,payload){
    return this.httpClient.post(`/rfe/notification/rfeNotice/${rfeId}/response`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
          return response; // send  full data
      }));
   }


  renameTitleSheet(taskId,titleSheetId,newName){
    return this.httpClient.post(`/rfe/task/${taskId}/titleSheet/${titleSheetId}/rename?newName=${newName}`,[]).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    }));
  }

  saveOrganizedDocuments(taskId,payload){
    return this.httpClient.post(`/rfe/task/${taskId}/organizeDocuments`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    }));
  }

  getRfeNotificationResponse(rfeId){
    return this.httpClient.get(`/rfe/notification/rfeNotice/${rfeId}/response`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      }));

  }



}
