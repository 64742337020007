<ng-container *ngIf="totalRecordCount > 0">
    <table mat-table [dataSource]="dataSource" class="im-card-no-border-table table im-card-table-noborder-spacing">

        <!-- Beneficiary Name Column -->
        <ng-container matColumnDef="beneficiaryName">
            <th mat-header-cell *matHeaderCellDef> Beneficiary Name </th>
            <td mat-cell *matCellDef="let element"> {{element.beneficiaryName}} </td>
        </ng-container>

        <!-- Request Date Column -->
        <ng-container matColumnDef="createDt">
            <th mat-header-cell *matHeaderCellDef> Request Date </th>
            <td mat-cell *matCellDef="let element"> {{element.createDt | date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <!-- Requested By Column -->
        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> Requested By </th>
            <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
        </ng-container>

         <!-- VISA Type Column -->
         <ng-container matColumnDef="visaType">
            <th mat-header-cell *matHeaderCellDef> VISA Type </th>
            <td mat-cell *matCellDef="let element"> {{element.visaType}} </td>
        </ng-container>

         <!-- Petition Type Column -->
         <ng-container matColumnDef="petitionType">
            <th mat-header-cell *matHeaderCellDef> Petition Type </th>
            <td mat-cell *matCellDef="let element"> {{element.petitionType}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> 
                 <span [ngClass]="element.status.description"> {{element.status.description | titlecase}} </span>
            </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder" [matMenuTriggerFor]="menu">
                    <mat-icon>settings</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </mat-icon>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="viewRequest(element);">View Request</button>
                    <button mat-menu-item (click)="openDialog(element, 'resend')">Resend</button>
                    <button mat-menu-item (click)="openDialog(element, 'delete')">Delete</button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-container>

<div class="no-data" *ngIf="totalRecordCount === 0">
    <h6>No Data Found!</h6>
  </div>

<p-confirmDialog id="data-log-modal"[style]="{width: '50vw'}"></p-confirmDialog>

<p-dialog id="view-request-modal" *ngIf="viewRequestModal" header="Requested data and documents" [(visible)]="viewRequestModal" [style]="{width: '90vw'}">    
    <app-request-data-documents [isBeneficiaryQuestionnaire]="true" [beneficiaryQuestionnaireEdit]="viewDataLogData" [viewDataLog]="true"></app-request-data-documents>
</p-dialog>