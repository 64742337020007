import { ImagilityBaseResponse } from 'app-models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorAndPublicationService {

  constructor(private http:HttpClient) {

   }

   getAuthorshipPublicationDetails(petitionId:number){
    return this.http.get(`/extraordinaryAbility/authorship/petition/${petitionId}/list`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else{
          return [];
        }
      })
    );
   }

   saveAuthorshipPublicationDetails(petitionId,payload){
    return this.http.post(`/extraordinaryAbility/authorship/petition/${petitionId}`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else{
          return [];
        }
      })
    );
   }

   deleteAuthorshipPublicationDetails(id){
    return this.http.delete(`/extraordinaryAbility/authorship/${id}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }

  saveAdditionalInfo(subtask,payload){
    return this.http.post(`/${subtask.type}/${subtask.caseType}/${subtask.caseId}/additionalInfo`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getAdditionalInfo(subtask,infoType:string){
    return this.http.get(`/${subtask.type}/${subtask.caseType}/${subtask.caseId}/additionalInfo/${infoType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }else {
          return [];
        }
      })
    );
  }

}
