<div class="fluid-container">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-9 step-title">
      <h5 class="mb-0">Enter Processing Information</h5>
      <p class="mb-0" style="font-size:14px">Let us know the following details related to the application</p>
    </div>
    <div class="col-md-2 editBtn">
      <lib-step-status-update-component-library [visaType]="primaryData.caseType" [stepDetails]="stepDetails"
        type="EDIT" [isViewMode]="primaryData['isViewMode']" [isCaseRequest]="primaryData['isCaseRequest']">
      </lib-step-status-update-component-library>
    </div>
    <div class="col-md-1">
      <span (click)="handleClose();" class="close-icon pi pi-times pull-right"></span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <lib-assigning-task-library [visaType]="primaryData.visatype" [stepDetails]="stepDetails" 
      (updateStatus)="updateStepStatus($event)" [isViewMode]="primaryData['isViewMode']" [isCaseRequest]="primaryData['isCaseRequest']">
      </lib-assigning-task-library>
    </div>
  </div>
  <p-divider class="header-divider"></p-divider>
  <lib-last-update-library [inputDetails]="stepDetails" [primaryData]="primaryData" [sectionCode]="sectionCode">
  </lib-last-update-library>
  <form [formGroup]="enterProcessingInformationForm" (ngSubmit)="onSubmit()" class="p-form">
    <div class="main-body footer-adjust">
      <div class="container-fluid mt-2 mb-4">
        <div class="row mb-3">
          <div class="col-12">
            <h6 class="sub-header">Filling Category / Application Type</h6>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-4">
            <label class="form-label-bold">Category</label>
            <label class="form-value">{{ primaryData.immigrationCategoryName }}</label>
          </div>
          <div class="col-4">
            <label class="form-label-bold">Immigration classification</label>
            <label class="form-value">{{ primaryData.immigrationClassificationName }}</label>
          </div>
          <div class="col-4">
            <label class="form-label-bold">Application Type</label>
            <label class="form-value">Petition for Alien Relative</label>
          </div>
        </div>
      </div>
      <p-divider type="dashed" class="d-block"></p-divider>
      <div class="container-fluid mt-2 mb-4">
        <div class="row mb-3"
          *ngIf="primaryData.immigrationClassification && primaryData.immigrationClassification === 'F4'">
          <div class="form-group col-8">
            <label class="form-label required float-left">Beneficiary is your brother/sister, is related by
              adoption</label>
            <p-inputSwitch formControlName="isAdopted" class="float-right"></p-inputSwitch>
          </div>
        </div>
        <div class="row mb-3"
          *ngIf="primaryData.immigrationClassification && ['IR2', 'IR5', 'F1', 'F2A', 'F2B', 'F3'].includes(primaryData.immigrationClassification)">
          <div class="form-group col-12">
            <!-- <label class="form-label required">You are filing this petition for Child, Please select one of the
              applicable from below</label> -->
            <label class="form-label required">If you are filing this petition for your child or parent, select the one
              that describes your relationship</label>
            <div class="mb-1" *ngFor="let childType of childTypeList">
              <p-radioButton formControlName="childTypeCode" name="childTypeCodeName" [value]="childType.code"
                styleClass="form-value-radiobutton" [label]="childType.name">
              </p-radioButton>
            </div>
            <small
              *ngIf="!enterProcessingInformationForm.valid && enterProcessingInformationForm.get('childTypeCode').touched && enterProcessingInformationForm.errors"
              class="p-error">
              <span *ngIf="enterProcessingInformationForm.errors['childTypeCodeFieldIsRequired']">Required</span>
            </small>
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-8">
            <label class="form-label required float-left">Did you gain lawful permanent residence or citizenship by
              adoption?</label>
            <p-inputSwitch formControlName="lawFulPermanentRsdOrCitznAdop" class="float-right"></p-inputSwitch>
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-12">
            <label class="form-label required">Has anyone else ever filed a petition for the beneficiary?</label>
            <div class="row">
              <div class="col-2">
                <p-radioButton formControlName="hasPetFileBen" name="hasPetFileBenName" [value]="1"
                  inputId="hasPetFileBen_1" styleClass="form-value-radiobutton" label="Yes">
                </p-radioButton>
              </div>
              <div class="col-2">
                <p-radioButton formControlName="hasPetFileBen" name="hasPetFileBenName" [value]="0"
                  inputId="hasPetFileBen_0" styleClass="form-value-radiobutton" label="No">
                </p-radioButton>
              </div>
              <div class="col-2">
                <p-radioButton formControlName="hasPetFileBen" name="hasPetFileBenName" [value]="2"
                  inputId="hasPetFileBen_2" styleClass="form-value-radiobutton" label="Unknown">
                </p-radioButton>
              </div>
            </div>
            <small
              *ngIf="!enterProcessingInformationForm.get('hasPetFileBen').valid && enterProcessingInformationForm.get('hasPetFileBen').touched && enterProcessingInformationForm.get('hasPetFileBen').errors"
              class="p-error">
              <span *ngIf="enterProcessingInformationForm.get('hasPetFileBen').errors['required']">Required</span>
            </small>
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-12">
            <label class="form-label required">Have you EVER previously filed a petition for this beneficiary or any
              other Alien?</label>
            <div class="row">
              <div class="col-2">
                <p-radioButton formControlName="hasPetFileBenOrAlien" name="hasPetFileBenOrAlienName" [value]="1"
                  inputId="hasPetFileBenOrAlien_1" (onClick)="onPrevPetitionFiled()" styleClass="form-value-radiobutton"
                  label="Yes">
                </p-radioButton>
              </div>
              <div class="col-2">
                <p-radioButton formControlName="hasPetFileBenOrAlien" name="hasPetFileBenOrAlienName" [value]="0"
                  inputId="hasPetFileBenOrAlien_0" (onClick)="onPrevPetitionFiled()" styleClass="form-value-radiobutton"
                  label="No">
                </p-radioButton>
              </div>
            </div>
            <small
              *ngIf="!enterProcessingInformationForm.get('hasPetFileBenOrAlien').valid && enterProcessingInformationForm.get('hasPetFileBenOrAlien').touched && enterProcessingInformationForm.get('hasPetFileBenOrAlien').errors"
              class="p-error">
              <span
                *ngIf="enterProcessingInformationForm.get('hasPetFileBenOrAlien').errors['required']">Required</span>
            </small>
          </div>
        </div>
        <div class="row mb-3"
          *ngIf="enterProcessingInformationForm.get('hasPetFileBenOrAlien').value && enterProcessingInformationForm.get('hasPetFileBenOrAlien').value === 1">
          <div class="col-12">
            <label class="form-label required">Provide the name, place, date of filing and the result.</label>
            <div class="row mb-3">
              <div class="form-group col-3">
                <label class="form-sub-label required">Title</label>
                <p-dropdown appendTo="body" placeholder="Select" class="p-form-control" [options]="titleList"
                  optionValue="code" optionLabel="name" formControlName="title">
                </p-dropdown>
                <small
                  *ngIf="enterProcessingInformationForm.get('title').touched && enterProcessingInformationForm.errors && enterProcessingInformationForm.errors['titleFieldIsRequired']"
                  class="p-error">
                  <span>Required</span>
                </small>
              </div>
              <div class="form-group col-3">
                <label class="form-sub-label required">First Name</label>
                <input type="text" pInputText class="p-form-control" placeholder="Enter" formControlName="firstName" />
                <small
                  *ngIf="enterProcessingInformationForm.get('firstName').touched && enterProcessingInformationForm.errors && enterProcessingInformationForm.errors['firstNameFieldIsRequired']"
                  class="p-error">
                  <span>Required</span>
                </small>
              </div>
              <div class="form-group col-3">
                <label class="form-sub-label required">Last Name</label>
                <input type="text" pInputText class="p-form-control" placeholder="Enter" formControlName="lastName" />
                <small
                  *ngIf="enterProcessingInformationForm.get('lastName').touched && enterProcessingInformationForm.errors && enterProcessingInformationForm.errors['lastNameFieldIsRequired']"
                  class="p-error">
                  <span>Required</span>
                </small>
              </div>
              <div class="form-group col-3">
                <label class="form-sub-label">Middle Name</label>
                <input type="text" pInputText class="p-form-control" placeholder="Enter" formControlName="middleName" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="form-group col-3">
                <label class="form-sub-label required">City</label>
                <input type="text" pInputText class="p-form-control" placeholder="Enter" formControlName="city" />
                <small
                  *ngIf="enterProcessingInformationForm.get('city').touched && enterProcessingInformationForm.errors && enterProcessingInformationForm.errors['cityFieldIsRequired']"
                  class="p-error">
                  <span>Required</span>
                </small>
              </div>
              <div class="form-group col-3">
                <label class="form-sub-label required">State</label>
                <p-dropdown appendTo="body" placeholder="Select" class="p-form-control" [options]="stateList"
                  optionValue="stateProvinceCode" optionLabel="stateProvinceName" formControlName="stateProvinceCode">
                </p-dropdown>
                <small
                  *ngIf="enterProcessingInformationForm.get('stateProvinceCode').touched && enterProcessingInformationForm.errors && enterProcessingInformationForm.errors['stateProvinceCodeFieldIsRequired']"
                  class="p-error">
                  <span>Required</span>
                </small>
              </div>
              <div class="form-group col-3">
                <label class="form-sub-label required">Date filed</label>
                <p-calendar appendTo="body" styleClass="p-form-datepicker" dateFormat="mm/dd/yy"
                  placeholder="MM/DD/YYYY" [showIcon]="true" [maxDate]="currentDate"
                  formControlName="filingDate" [monthNavigator]="true" [yearNavigator]="true"
                  yearRange="{{fromYear}}:{{toYear}}">
                </p-calendar>
                <small
                  *ngIf="enterProcessingInformationForm.get('filingDate').touched && enterProcessingInformationForm.errors"
                  class="p-error">
                  <span
                    *ngIf="enterProcessingInformationForm.errors['filingDateFieldIsRequired']">Required</span></small>
              </div>
            </div>
            <div class="row mb-3">
              <div class="form-group col-3">
                <label class="form-sub-label required">Result</label>
                <p-dropdown appendTo="body" placeholder="Select" class="p-form-control" [options]="resultList"
                  optionValue="code" optionLabel="name" formControlName="resultCode">
                </p-dropdown>
                <small
                  *ngIf="enterProcessingInformationForm.get('resultCode').touched && enterProcessingInformationForm.errors && enterProcessingInformationForm.errors['resultFieldIsRequired']"
                  class="p-error">
                  <span>Required</span>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p-divider></p-divider>
    <!-- pending UI verification -->
   <div class="row pt-3 mt-n3">
    <lib-custom-questionnaire [stepId]="primaryData.stepId" [taskId]="primaryData.taskId"
    [viewQuestions]="false" [showSaveBtn]="false" [loadedIn]="stepDetails.stepName"
    [isSection]="false"></lib-custom-questionnaire>
   </div>
    <p-divider type="dashed" class="d-block"></p-divider>
    <div class="row">
      <div class="col-md-2">
        <button pButton type="submit" label="Save" title="Save" class="save-btn" icon="pi pi-save"
          [ngClass]="enterProcessingInformationForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="enterProcessingInformationForm.invalid  || disabledControls"></button>
      </div>
      <div class="col-md-2">
        <button pButton type="button" label="Cancel" icon="pi pi-times-circle" class="p-button-secondary cancel-btn button-width-icon-left
            d-flex alogn-items-center" [disabled]="disabledControls" (click)="handleCancel()">
        </button>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-6 completeStep">
        <lib-step-status-update-component-library [visaType]="primaryData.caseType" [stepDetails]="stepDetails"
          [type]="typeOfButton"></lib-step-status-update-component-library>
      </div>
    </div>
  </form>
</div>
