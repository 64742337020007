import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EditorResizableOptions } from '@progress/kendo-angular-editor';
import { ToastrService } from 'ngx-toastr';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'app-email-kendoeditor',
  templateUrl: './email-kendoeditor.component.html',
  styleUrls: ['./email-kendoeditor.component.scss']
})
export class EmailKendoeditorComponent implements OnInit {
  @ViewChild("mykendoeditor", { static: false }) mykendoeditor: any;
  schsend: boolean = false;
  emailclient: FormGroup
  companyId: string;
  saveData: any = [];
  submitted: boolean = false;
  constructor(public fb: FormBuilder, private apiservice: EmailService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,) { }

  public resizable: boolean | EditorResizableOptions = {
    minWidth: 300,
    minHeight: 150,
    maxWidth: 1000,
    maxHeight: 450,
  };
  public onChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.resizable = isChecked
      ? {
          minWidth: 300,
          minHeight: 150,
          maxWidth: 600,
          maxHeight: 450,
        }
      : false;
  }

  ngOnInit(): void {
    this.initform();
    this.companyId = sessionStorage.getItem('companyId');
  }

  initform() {
    this.emailclient = this.fb.group({
      emailids: ['', Validators.required],
      subject: ['', Validators.required],
      bodycontent: ['']
    })
  }

  get emailformcontrol() {
    return this.emailclient.controls;
  }

  sendnow(evt) {
  }

  schedulesend(evt) {
    // this.schsend = true
  }
  save() {
    this.submitted = true;
    //mykendoeditor
    if (this.emailclient.valid) {
      let payload = {
        //"body": this.convertToPlain(this.emailclient.controls['bodycontent'].value),
        //"body":this.mykendoeditor.getSource(),
        "body":this.mykendoeditor.value,
        "emailSendOption": "SENDNOW",
        "emailType": "MRKTNG",
        "mktLeadId": 47,
        "status": "SENT",
        "subject": this.emailclient.controls['subject'].value,
        "toMail": this.emailclient.controls['emailids'].value
      }

      // let req = JSON.stringify(payload)
      // console.log(req)
      this.apiservice.sendEmailtoclient(payload, this.companyId).subscribe((data) => {
        this.toastr.success('', "Mail Sent Successfully");
        setTimeout(() => {
          this.router.navigate(['../../marketing'], { relativeTo: this.route, state: { redirectUrl: '', redirectPage: '' } });
        }, 1000)
        console.log(data)
      })
    }
  }

  convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }
  cancel() {
    this.emailclient.reset();
    this.router.navigate(['../../marketing'], { relativeTo: this.route, state: { redirectUrl: '', redirectPage: '' } });
  }

  back() {
    this.router.navigate(['../../marketing'], { relativeTo: this.route, state: { redirectUrl: '', redirectPage: '' } });
  }

}
