import { Component, EventEmitter, Output, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UpdateEmploymentService } from './../../update-employment/update-employment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagilityBaseResponse, SubTaskDetails } from 'app-models';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { select, Store } from '@ngrx/store';
import { getSubTaskDetails } from 'visa-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { VisaExtendedState } from '../../l1-visa/utility/interfaces/visa-extended-state';
import { MigrationConfirmationModalComponent } from '../../beneficiary/migration-confirmation-modal/migration-confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiServicesService } from '../../beneficiary/services/api-services.service';

@Component({
  selector: 'app-onboard-employment',
  templateUrl: './onboard-employment.component.html',
  styleUrls: ['./onboard-employment.component.scss']
})
export class OnboardEmploymentComponent implements OnChanges, OnInit, OnDestroy {
  beneficiary: {
    employmentStartDate: Date,
    employmentEndDate: Date,
    positionDetails: string,
    comments: string,
    isBenNotifyLcaLinkPet: boolean,
    initiateI9Form: boolean,
    id: number
  } = {
      employmentStartDate: null,
      employmentEndDate: null,
      positionDetails: '',
      comments: null,
      isBenNotifyLcaLinkPet: true,
      initiateI9Form: false,
      id: 0
    };
  employmentDetails: any;
  // @Input() employmentDetails: any;
  @Output() formSubmitted = new EventEmitter();
  toYear: string;
  fromYear: string;
  minDateForStartDate: Date = null;
  maxDateForEndDate: Date = null;
  isRoutedFromBene: boolean;
  taskId = 0;
  beneficiaryEmployeeId = 0;
  companyId = 0;
  i9FormId = 0;
  taskDetailsSubscription$ = new Subject();
  taskDetails: any;
  index = 0;
  i9FormTrue: boolean;
  constructor(
    private petitionerService: PetitionerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    protected pbStore: Store<VisaExtendedState>,
    private updateEmploymentService: UpdateEmploymentService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private apiService: ApiServicesService,
  ) {
    this.toYear = moment().add(7, 'M').format('YYYY');
    this.fromYear = moment().add(7, 'M').subtract(5, 'years').format('YYYY');
    this.i9FormTrue = false;
    const url = this.activatedRoute.snapshot['_routerState']['url'] || '';
    this.index = url.includes('on-board') ? 0 : 1;
    this.isRoutedFromBene = url.includes('beneficiaries') ? true : false;
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.taskDetailsSubscription$))
      .subscribe(params => {
        this.taskId = params.taskId || 0;
        this.beneficiaryEmployeeId = params.beneficiaryEmployeeId || 0;
        this.companyId = params.companyId || 0;
        this.i9FormId = params.i9FormId || 0;
      });

  }

  ngOnInit() {
    this.getEmploymentDetails();
  }

  getEmploymentDetails() {
    if (!this.isRoutedFromBene) {
      this.petitionerService.taskViewTypeBSubject.next(4);
      this.pbStore.pipe(select(getSubTaskDetails))
        .pipe(takeUntil(this.taskDetailsSubscription$))
        .subscribe((taskDetails: SubTaskDetails) => {
          // this.pbStore.select(getSubTaskDetails).subscribe((taskDetails) => {
          if (taskDetails.id) {            
            this.taskDetails = taskDetails;
            // if(!this.taskDetails.isOutsideImagility)
            //  {
            this.updateEmploymentService
              .getOnboardingEmployment(
                0,
                this.taskDetails.id,
                this.taskDetails.companyId
              ).pipe(takeUntil(this.taskDetailsSubscription$))
              .subscribe((employmentDetails) => {
                console.log(employmentDetails);
                // if (employmentDetails) {
                // this.taskUtilityService.setTaskDetails(this.taskDetails);
                this.employmentDetails = {
                  ...employmentDetails,
                  taskId: this.taskDetails.id,
                  companyId: this.taskDetails.companyId,
                  visaType: employmentDetails.visaType
                };
                this.i9FormId = employmentDetails.i9FormId;
                this.i9FormTrue = employmentDetails.initiateI9Form;
                this.mapEmploymentData();
                // }
              });

          }
        });
      // this.activatedRoute.data.subscribe((data) => {
      //   this.taskDetails = data.taskDetails;
      // });
    }
    else {
      this.updateEmploymentService.getOnboardingEmploymentFromBenList(this.beneficiaryEmployeeId, 0, this.companyId)
        .pipe(takeUntil(this.taskDetailsSubscription$))
        .subscribe((response: ImagilityBaseResponse) => {
          console.log(response);
          if (response.status === 200) {
            this.employmentDetails = {
              ...response.data,
              beneficiaryId: response.data.beneficiaryId,
              taskId: this.taskId,
              companyId: this.companyId,
              visaType: response.data.visaType
            };
            this.i9FormId = response.data.i9FormId;
            this.i9FormTrue = response.data.initiateI9Form;
            this.mapEmploymentData();
          }
        });
    }
  }
  mapEmploymentData() {
    if (this.employmentDetails) {
      this.beneficiary.employmentStartDate = this.employmentDetails.employmentStartDate ?
        moment(this.employmentDetails.employmentStartDate, 'YYYY-MM-DD').toDate() : null;
      this.beneficiary.employmentEndDate = this.employmentDetails.employmentEndDate ?
        moment(this.employmentDetails.employmentEndDate, 'YYYY-MM-DD').toDate() : null;
      this.beneficiary.comments = this.employmentDetails.comments;
      this.beneficiary.isBenNotifyLcaLinkPet = this.employmentDetails.isBenNotifyLcaLinkPet;
      this.beneficiary.initiateI9Form = this.employmentDetails.initiateI9Form;
      if (this.employmentDetails.isOutsideImagility || this.taskDetails.subTaskTypeCode === 'DS160') {
        this.beneficiary.positionDetails = this.employmentDetails.positionDetails;
      }
    }
    this.setMaxDateForEndDate();
    this.setMinDateForStartDate();
  }

  ngOnChanges() {
    this.beneficiary.employmentStartDate = this.employmentDetails.employmentStartDate ?
      moment(this.employmentDetails.employmentStartDate, 'YYYY-MM-DD').toDate() : null;
    this.beneficiary.employmentEndDate = this.employmentDetails.employmentEndDate ?
      moment(this.employmentDetails.employmentEndDate, 'YYYY-MM-DD').toDate() : null;
    this.beneficiary.comments = this.employmentDetails.comments;
    this.beneficiary.isBenNotifyLcaLinkPet = this.employmentDetails.isBenNotifyLcaLinkPet;
    this.beneficiary.initiateI9Form = this.employmentDetails.initiateI9Form;
    if (this.employmentDetails.isOutsideImagility || this.taskDetails.subTaskTypeCode === 'DS160') {
      this.beneficiary.positionDetails = this.employmentDetails.positionDetails;
    }
    this.setMaxDateForEndDate();
    this.setMinDateForStartDate();
  }

  setMaxDateForEndDate() {
    if (this.beneficiary.employmentStartDate) {
      const maxDate = new Date(this.beneficiary.employmentStartDate);
      maxDate.setDate(maxDate.getDate() - 1);
      this.maxDateForEndDate = new Date(maxDate);
    } else {
      this.maxDateForEndDate = null;
    }
  }

  setMinDateForStartDate() {
    if (this.beneficiary.employmentEndDate) {
      const minDate = new Date(this.beneficiary.employmentEndDate);
      minDate.setDate(minDate.getDate() + 1);
      this.minDateForStartDate = new Date(minDate);
    } else {
      this.minDateForStartDate = null;
    }
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const payload: any = {};
      payload.beneficiaryId = this.employmentDetails.beneficiaryId;
      payload.comments = form.value.comments;
      payload.employmentEndDate = form.value.employmentEndDate ? moment(form.value.employmentEndDate).format('YYYY-MM-DD') : null;
      payload.employmentStartDate = moment(form.value.employmentStartDate).format('YYYY-MM-DD');
      payload.taskId = this.employmentDetails.taskId;
      payload.companyId = this.employmentDetails.companyId;
      payload.isBenNotifyLcaLinkPet = this.beneficiary.isBenNotifyLcaLinkPet;
      if (this.employmentDetails.isOutsideImagility || this.taskDetails.subTaskTypeCode === 'DS160') {
        payload.positionDetails = form.value.positionDetails;
      }
      payload.initiateI9Form = this.beneficiary.initiateI9Form;
      this.updateEmploymentService.updateEmployment(payload)
        .pipe(takeUntil(this.taskDetailsSubscription$))
        .subscribe(response => {
          this.toastr.success(response.message);
          console.log(response);
          const queryParamsObj = {
            beneficiaryEmployeeId: this.employmentDetails.beneficiaryId || 0,
            companyId: this.employmentDetails.companyId || 0,
            taskId: this.employmentDetails.taskId || 0,
            i9FormId: this.i9FormId || response?.data?.i9FormId || 0
          };
          // this.router.navigateByUrl(`./I-9?${queryParamsObj}`);
          if (this.beneficiary.initiateI9Form) {
            this.router.navigate(['../I-9'], { queryParams: queryParamsObj, relativeTo: this.activatedRoute });
          } else {
            if (!this.isRoutedFromBene) {
              this.router.navigate(['./../../details'], { relativeTo: this.activatedRoute });
            } else {
              this.router.navigate(['../../../beneficiaries/list'], { relativeTo: this.activatedRoute });
            }
          }
        });
    }
  }
  onCancel() {
    if (!this.isRoutedFromBene) {
      this.router.navigate(['./../../details'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../../../beneficiaries/list'], { relativeTo: this.activatedRoute });
    }
  }


  migrateBeneficiary() {
    const title = 'Onboard Individuals';
    const migrationData = [Number(this.beneficiaryEmployeeId)];
    this.dialogueInitializer(title, migrationData).afterClosed().subscribe(result => {
      if (result) {
        this.apiService.migrateOrOnboardEmployees(this.companyId, migrationData)
        .pipe(takeUntil(this.taskDetailsSubscription$))
        .subscribe((result: any) => {
          if (result) {
            this.toastr.success(result.message, 'Success');
          }
        })
      }
    });
  }
  
  dialogueInitializer(title: string, migrationData: any) {
    const dialogRef = this.dialog.open(MigrationConfirmationModalComponent, {
      width: '600px',
      data: {
        update: { title, migrationData },
        buttons: ['I will do it later', 'Onboard']
      }
    });
    return dialogRef;
  }

  ngOnDestroy(): void {
    this.taskDetailsSubscription$.next();
    this.taskDetailsSubscription$.complete();
  }

}
