<div class="fluid-container">
    <div class="row pt-4 d-flex align-items-center">
        <div class="col-md-9 step-title p-0">
            <div class="w-100 d-flex">
                <h5 class="mb-0">Educational Details</h5>
                <a *ngIf="!questionnaire && primaryData?.caseType !== 'I140'" href="javascript:void(0);" (click)="updateEducation()" class="ml-3 pt-1">Update Education</a>
            </div>
            <div class="w-100 pt-1">
                <p class="mb-0">Data fetched from the Beneficiary's profile. To update navigate to
                Beneficiary's profile section</p>
            </div>
        </div>
        <div class="col-md-2">
            <gc-status-update [stepDetails]="stepDetails" type="EDIT"></gc-status-update>
        </div>
        <div class="col-md-1 pr-0 d-flex justify-content-end">
            <lib-maximize-dialog-library [configData]="{'layoutCode':stepDetails?.stepActionList[0].layoutCode}"></lib-maximize-dialog-library>
            <i (click)="handleClose();" class="close-icon pi pi-times"></i>
        </div>
    </div>
    <div class="row"> 
        <div class="col-md-12 p-0">
            <lib-assigning-task-library  [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)">
            </lib-assigning-task-library>
        </div>
    </div>
    <p-divider></p-divider>
    <div class="main-body footer-adjust" *ngIf="isEditMode === false">
        <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
                <div class="btn-group justify-content-end">
                    <button pButton (click)="syncToProfile()" type="button"
                      class="p-button-rounded p-button-text pull-right syncBtn" pTooltip="Sync With Profile"
                      icon="pi pi-arrow-right-arrow-left">SYNC</button>
                    <button pButton (click)="mergeEducation()" type="button"
                      class="p-button-rounded p-button-text pull-right syncBtn" pTooltip="Merge Both Profile & Petition Data" icon="pi pi-sync
                    ">MERGE</button>
                    <button pButton (click)="resetEducation()" type="button"
                      class="p-button-rounded p-button-text pull-right syncBtn" pTooltip="Reset Petition Data"
                      icon="pi pi-undo">RESET</button>
                    <button button pButton class="p-button-outlined btnData" pTooltip="Edit"
                      (click)="onEditEducation()"><mat-icon class="mr-1 icn">edit</mat-icon>Edit</button>
                </div>
            </div>
        </div>
        <div class="row pt-3">
            <div class="row col-md-4 p-0 pb-2 d-flex align-items-center profile-title">
                <div class="col-md-8 p-0">
                    <h6>Added Education Details</h6>
                </div>
            </div>

            <p-table [columns]="educationCols" *ngIf="educationList && educationList.length > 0; else noEducation"
                class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
                styleClass="p-datatable-gridlines" [value]="educationList" dataKey="id">
                <ng-template pTemplate="header" let-columns>
                    <tr class="text-uppercase">
                        <th style="width: 3rem"> </th>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
                    <tr>
                        <td class="expandIcon">
                            <button type="button" pButton pRipple [pRowToggler]="rowData"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                            </button>
                        </td>
                        <td *ngFor="let col of columns">
                            <span>
                                {{rowData[col.field] ? rowData[col.field]: ''}}
                            </span>
                            <span *ngIf="col.field === 'degree'">
                                {{rowData['degreeType']['name']}}
                            </span>
                            <span class="table-btn" *ngIf="col.field === 'action'">
                                <button type="button" pButton icon="pi pi-trash" (click)="deleteEducationList(rowData)"
                                    class="p-button-text p-button-rounded " [disabled]="disabledControls"
                                    pTooltip="Delete"></button>
                                <button type="button" pButton icon="pi pi-refresh" pTooltip="Sync"
                                    (click)="syncSingleEduaction(rowData)" class="p-button-text p-button-rounded "
                                    [disabled]="disabledControls"></button>
                            </span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-subeducationlist>
                    <tr>
                        <td colspan="8">
                            <div class="p-p-3 sub-task-type">
                                <p-table class="sub-task-table profile-table" [columns]='educationSubCols'
                                    [value]="[subeducationlist]" dataKey="id">
                                    <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns">
                            <span>
                                {{rowData[col.field]}}
                            </span>
                            <span *ngIf="col.field === 'countryCode'">
                                {{rowData['country']['countryName']}}
                            </span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No Data</td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="courseList pt-4" *ngIf="subeducationlist.courses && subeducationlist.courses.length >0">
                <h6>List of Courses</h6>
                <p-card class="courses-card">
                    <div class="row courses courses-title d-flex align-items-center"
                        *ngFor="let item of subeducationlist.courses; let i = index">
                        <div class="col-md-11">
                            <span class="duty-list">{{i+1}}</span> {{ item.courseName }}
                        </div>
                        <div class="col-md-1 pull-right">
                            <button pButton type="button" icon="pi pi-trash" pTooltip="Delete"
                                [disabled]="disabledControls" (click)="deleteCoursesList(subeducationlist, item)"
                                class="p-button-text p-button-rounded "></button>
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="courseList pt-4" *ngIf="subeducationlist.documents && subeducationlist.documents.length >0">
                <h6>List of Documents</h6>
                <p-card class="courses-card">
                    <div class="row document-header">                        
                        <div class="col-md-5">
                            <h6>Document Type</h6>
                        </div>
                        <div class="col-md-5">
                            <h6>Document Name </h6>
                        </div>
                        <div class="col-md-2">
                            <h6>Action</h6>
                        </div>
                    </div>
                    <div class="row courses courses-title d-flex align-items-center"
                        *ngFor="let item of subeducationlist.documents; let i = index">
                        <div class="col-md-5">
                            <span class="duty-list">{{i+1}}</span>
                            <span>
                                {{ item?.fileCategory.code === 'OTHER' && item.isPresent ? item.fileCtaegoryOtherName : item?.fileCategory.name }}
                            </span>
                        </div>
                        <div class="col-md-5">
                            <span *ngIf="item.isPresent">
                                {{ item.fileName}}
                            </span>
                            <span *ngIf="!item.isPresent" class="redColor d-flex align-items-center">
                                <i class="pi pi-exclamation-triangle pr-2"></i> Document Missing
                            </span>
                        </div>
                        <div class="col-md-2 pull-right d-flex align-items-center" *ngIf="item?.isPresent">
                            <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                            [disabled]="!item.fileLocation" (click)="openDoc(item)"
                            class="p-button-text p-button-rounded"></button>
                            <button type="button" *ngIf="userType !== 'Beneficiary'" pButton icon="pi pi-directions-alt"
                                pTooltip="Send Back" (click)="handleSendBack(item)"
                                class="p-button-text p-button-rounded" [disabled]="disabledControls"></button>
                            <button pButton type="button" icon="pi pi-trash" pTooltip="Delete"
                                [disabled]="disabledControls" (click)="deleteDocumentsList(subeducationlist, item)"
                                class="p-button-text p-button-rounded "></button>
                        </div>
                        <div class="col-md-2 pull-right" *ngIf="!item?.isPresent">
                            <button type="button" *ngIf="userType !== 'Beneficiary'" pTooltip="Notify" pButton
                                icon="pi pi-bell" (click)="handleNotification(item)"
                                class="p-button-text p-button-rounded " [disabled]="disabledControls"></button>
                            <!-- <button type="button" *ngIf="userType !== 'Beneficiary'" pTooltip="Not Applicable" pButton icon="pi pi-ban"
                                class="p-button-text p-button-rounded " [disabled]="disabledControls"></button> -->
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
        </td>
        </tr>
        </ng-template>
        </p-table>
        <ng-template #noEducation>
            <p class="pb-3" style="border-bottom: 1px dashed lightgray;">If no education details are available. Starting
                education details need to be filled first before Sync education Data.</p>
        </ng-template>

    </div>
    <div class="row pt-5">
        <div class="row col-md-12 p-0 pb-2 d-flex align-items-center profile-title">
            <div class="col-md-8 p-0">
                <h6>Added Training Details</h6>
            </div>
            <div class="col-md-4 p-0">
                <button pButton (click)="mergeTraining()" type="button"
              class="p-button-rounded p-button-text pull-right syncBtn" pTooltip="Merge Both Profile & Petition Data" icon="pi pi-sync
            ">MERGE</button>
            <button pButton (click)="resetTraining()" type="button"
              class="p-button-rounded p-button-text pull-right syncBtn" pTooltip="Reset Petition Data"
              icon="pi pi-undo">RESET</button>
            </div>
        </div>
        <p-table [columns]="trainingCols" *ngIf="trainingList && trainingList.length > 0; else noTraining"
            class="profile-table pa-table im-card-no-border-table im-card-table-noborder-spacing table"
            styleClass="p-datatable-gridlines" [value]="trainingList" dataKey="id" (onRowExpand)="onRowExpand($event)">
            <ng-template pTemplate="header" let-columns>
                <tr class="text-uppercase">
                    <th style="width: 3rem"> </th>
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
                <tr>
                    <td class="expandIcon">
                        <button type="button" pButton pRipple [pRowToggler]="rowData"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                        </button>
                    </td>
                    <td> {{rowData['name']}} </td>
                    <td class="text-truncate"> {{rowData['subjects']}} </td>
                    <td> {{rowData['institution']}} </td>
                    <td> {{rowData['startDate'] | I140DateFormat}} </td>
                    <td> {{rowData['endDate'] | I140DateFormat}} </td>
                    <td class="table-btn">
                        <button type="button" pTooltip="Delete" pButton icon="pi pi-trash"
                            (click)="deleteTrainingList(rowData)" class="p-button-text p-button-rounded "
                            [disabled]="disabledControls"></button>
                        <button type="button" pTooltip="Sync" pButton icon="pi pi-refresh"
                            (click)="handleTrainingSync(rowData)" class="p-button-text p-button-rounded "
                            [disabled]="disabledControls"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-subeducationlist>
                <tr>
                    <td colspan="7">
                        <div class="p-p-3 sub-task-type">
                            <div class="courseList pt-2"
                                *ngIf="subeducationlist.subjects && subeducationlist.subjects.length >0">
                                <h6>Topic of Study</h6>
                                <p-card class="courses-card">
                                    <div class="row courses courses-title d-flex align-items-center">
                                        <div class="col-md-12">
                                            {{ subeducationlist.subjects }}
                                        </div>
                                    </div>
                                </p-card>
                            </div>
                            <div class="courseList pt-4"
                                *ngIf="subeducationlist.documents && subeducationlist.documents.length >0">
                                <h6>List of Documents</h6>
                                <p-card class="courses-card">
                                    <div class="row document-header">
                                        <div class="col-md-5">
                                            <h6>Document Type</h6>
                                        </div>
                                        <div class="col-md-5">
                                            <h6>Document Name </h6>
                                        </div>
                                        <div class="col-md-2">
                                            <h6>Action</h6>
                                        </div>
                                    </div>
                                    <div class="row courses courses-title d-flex align-items-center"
                                        *ngFor="let item of subeducationlist.documents; let i = index">
                                        <div class="col-md-5">
                                            <span class="duty-list">{{i+1}}</span>
                                            <span>
                                                {{ item?.fileCategory.name }}
                                            </span>
                                        </div>
                                        <div class="col-md-5">
                                            <span *ngIf="item.isPresent">
                                                {{ item.fileName}}
                                            </span>
                                            <span *ngIf="!item.isPresent" class="redColor d-flex align-items-center">
                                                <i class="pi pi-exclamation-triangle pr-2"></i> Document Missing
                                            </span>
                                        </div>

                                        <div class="col-md-2 pull-right d-flex align-items-center"
                                            *ngIf="item?.isPresent">
                                            <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                                [disabled]="!item.fileLocation" (click)="openDoc(item)"
                                                class="p-button-text p-button-rounded"></button>
                                            <button type="button" *ngIf="userType !== 'Beneficiary'" pButton
                                                icon="pi pi-directions-alt" pTooltip="Send Back"
                                                (click)="handleSendBack(item)" class="p-button-text p-button-rounded"
                                                [disabled]="disabledControls"></button>
                                            <button pButton type="button" icon="pi pi-trash"
                                                [disabled]="disabledControls"
                                                (click)="deleteTrainingDocumentsList(subeducationlist, item)"
                                                class="p-button-text p-button-rounded "></button>
                                        </div>
                                        <div class="col-md-2 pull-right" *ngIf="!item?.isPresent">
                                            <button type="button" *ngIf="userType !== 'Beneficiary'" pButton
                                                icon="pi pi-bell" pTooltip="Notify" (click)="handleNotification(item)"
                                                class="p-button-text p-button-rounded"
                                                [disabled]="disabledControls"></button>
                                            <!-- <button type="button" pButton icon="pi pi-ban" pTooltip="Not Applicable"
                                                class="p-button-text p-button-rounded"
                                                [disabled]="disabledControls"></button> -->
                                        </div>
                                    </div>
                                </p-card>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #noTraining>
        <p class="pl-3 pb-3" style="border-bottom: 1px dashed lightgray;">If no training details are available. Starting
            training details need to be filled first before Sync training Data.</p>
    </ng-template>
    <div class="row pt-5 pb-3">
        <div class="row col-md-12 p-0 pb-2 d-flex align-items-center profile-title">
            <div class="col-md-6 p-0">
                <h6>Added Licenses & Certifications Details</h6>
            </div>
            <div class="col-md-6 p-0">
                <button pButton (click)="mergeLicense()" type="button"
              class="p-button-rounded p-button-text pull-right syncBtn" pTooltip="Merge Both Profile & Petition Data" icon="pi pi-sync
                ">MERGE</button>
                <button pButton (click)="resetLicense()" type="button"
                class="p-button-rounded p-button-text pull-right syncBtn" pTooltip="Reset Petition Data"
                icon="pi pi-undo">RESET</button>
            </div>
        </div>
        <p-table [columns]="linceseCols" *ngIf="licensesList && licensesList.length > 0; else noLicense"
            class="profile-table pa-table im-card-no-border-table im-card-table-noborder-spacing table"
            styleClass="p-datatable-gridlines" [value]="licensesList" dataKey="id" (onRowExpand)="onRowExpand($event)">
            <ng-template pTemplate="header" let-columns>
                <tr class="text-uppercase">
                    <th style="width: 3rem"> </th>
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
                <tr>
                    <td class="expandIcon">
                        <button type="button" pButton pRipple [pRowToggler]="rowData"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                        </button>
                    </td>
                    <td> {{rowData['name']}} </td>
                    <td class="text-truncate"> {{rowData['subjects']}} </td>
                    <td> {{rowData['institution']}} </td>
                    <td> {{rowData['startDate'] | I140DateFormat}} </td>
                    <td> {{rowData['endDate'] | I140DateFormat}} </td>
                    <td class="table-btn">
                        <button type="button" pButton icon="pi pi-trash" pTooltip="Delete"
                            (click)="deleteLicensesList(rowData)" class="p-button-text p-button-rounded "
                            [disabled]="disabledControls"></button>
                        <button type="button" pButton icon="pi pi-refresh" pTooltip="Sync"
                            (click)="handleLicensesSync(rowData)" class="p-button-text p-button-rounded "
                            [disabled]="disabledControls"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-subeducationlist>
                <tr>
                    <td colspan="7">
                        <div class="p-p-3 sub-task-type">
                            <div class="courseList pt-2"
                                *ngIf="subeducationlist.subjects && subeducationlist.subjects.length >0">
                                <h6>Topic of Study</h6>
                                <p-card class="courses-card">
                                    <div class="row courses courses-title d-flex align-items-center">
                                        <div class="col-md-12">
                                            {{ subeducationlist.subjects }}
                                        </div>
                                    </div>
                                </p-card>
                            </div>
                            <div class="courseList pt-4"
                                *ngIf="subeducationlist.documents && subeducationlist.documents.length >0">
                                <h6>List of Documents</h6>
                                <p-card class="courses-card">
                                    <div class="row document-header">
                                        <div class="col-md-5">
                                            <h6>Document Type</h6>
                                        </div>
                                        <div class="col-md-5">
                                            <h6>Document Name</h6>
                                        </div>
                                        <div class="col-md-2">
                                            <h6>Action</h6>
                                        </div>
                                    </div>
                                    <div class="row courses courses-title d-flex align-items-center"
                                        *ngFor="let item of subeducationlist.documents; let i = index">
                                        <div class="col-md-5">
                                            <span class="duty-list">{{i+1}}</span>
                                            <span>
                                                {{ item?.fileCategory.name }}
                                            </span>
                                        </div>
                                        <div class="col-md-5">
                                            <span *ngIf="item.isPresent">
                                                {{ item.fileName}}
                                            </span>                                           
                                            <span *ngIf="!item.isPresent" class="redColor d-flex align-items-center">
                                                <i class="pi pi-exclamation-triangle pr-2"></i> Document Missing
                                            </span>
                                        </div>
                                        <div class="col-md-2 pull-right d-flex align-items-center"
                                            *ngIf="item?.isPresent">
                                            <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                            [disabled]="!item.fileLocation" (click)="openDoc(item)"
                                            class="p-button-text p-button-rounded"></button>
                                            <button type="button" pButton icon="pi pi-directions-alt"
                                                *ngIf="userType !== 'Beneficiary'" pTooltip="Send Back"
                                                (click)="handleSendBack(item)" class="p-button-text p-button-rounded"
                                                [disabled]="disabledControls"></button>
                                            <button pButton type="button" icon="pi pi-trash" pTooltip="Delete"
                                                [disabled]="disabledControls"
                                                (click)="deleteLicensesDocumentsList(subeducationlist, item)"
                                                class="p-button-text p-button-rounded"></button>
                                        </div>
                                        <div class="col-md-2 pull-right" *ngIf="!item?.isPresent">
                                            <button type="button" *ngIf="userType !== 'Beneficiary'" pButton
                                                icon="pi pi-bell" pTooltip="Notify" (click)="handleNotification(item)"
                                                class="p-button-text p-button-rounded"
                                                [disabled]="disabledControls"></button>
                                            <!-- <button type="button" pButton icon="pi pi-ban" pTooltip="Not Applicable"
                                                (click)="handleNotification(item)"
                                                class="p-button-text p-button-rounded"
                                                [disabled]="disabledControls"></button> -->
                                        </div>
                                    </div>
                                </p-card>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #noLicense>
        <p class="pl-3 pb-3">If no license details are available. Starting license details need to be filled first
            before
            Sync license Data.</p>
    </ng-template>
    <div class="row pt-3">
        <div class="col mb-2">
          <lib-custom-questionnaire [stepId]="stepId" [taskId]="stepDetails.taskId" [viewQuestions]="false"
        [showSaveBtn]="true" [loadedIn]="stepDetails.stepName" [isSection]="false">
        </lib-custom-questionnaire>
        </div>
      </div>
</div>

<div class="education-details-wrapper" *ngIf="isEditMode === true">
    
    <!-- <education-details-library [primaryData]="primaryData" (onDialogClose)="handleDialogClose()"
    style="width:100%">
    </education-details-library> -->

    <lib-edit-complete-education-detail-library [editEducationData]="primaryData" (onEditClose)="onEditClose()"></lib-edit-complete-education-detail-library>
</div>

<p-divider></p-divider>

<div class="row">
    <div class="col-md-6">
        <!-- <button pButton type="button" label="VERIFY" class="save-btn" (click)="handleVerify()"></button> -->
    </div>
    <div class="col-md-6">
        <gc-status-update [stepDetails]="stepDetails" [type]="typeOfButton"></gc-status-update>
    </div>
</div>
</div>