
  <div class="row m-2">
    <form [formGroup]="preparerForm" class="col-12 preparer-form" #f="ngForm">
      <div class="row">
        <label class="mt-2 main-label"> Preparer / Translator's Personal Details</label>
      </div>
      <div class="row mt-2">        
        <div class="col-3">
          <p class="label-cls required">First Name</p>
          <input type="text" [readonly]="!isEdit" class="im-input" [ngClass]="{
              'im-input-error':
                (preparerForm.get('firstName').touched || submitted) &&
                preparerForm.get('firstName').errors?.required
            }" pattern="^[a-zA-Z ]*$" formControlName="firstName" placeholder="Enter your First Name" />
          <span class="invalid-message" *ngIf="
              (preparerForm.get('firstName') || submitted) &&
              preparerForm.get('firstName').errors?.required
            ">
            {{ getGlobalErrorMessages("REQUIRED") }}
          </span>
          <span class="invalid-message" *ngIf="
              (preparerForm.get('firstName').touched || submitted) &&
              preparerForm.get('firstName').errors?.pattern
            ">
            {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
          </span>
        </div>
        <div class="col-3">
          <p class="label-cls required">Last Name</p>
          <input type="text" [readonly]="!isEdit" class="im-input" [ngClass]="{
              'im-input-error':
                (preparerForm.get('lastName').touched || submitted) &&
                (preparerForm.get('lastName').errors?.required)
            }" pattern="^[a-zA-Z ]*$" formControlName="lastName" placeholder="Enter your Last Name" />
          <span class="invalid-message" *ngIf="
              (preparerForm.get('lastName').touched || submitted) &&
              (preparerForm.get('lastName').errors?.required)
            ">
            {{ getGlobalErrorMessages("REQUIRED") }}
          </span>
          <span class="invalid-message" *ngIf="
              (preparerForm.get('lastName').touched || submitted) &&
              (preparerForm.get('lastName').errors?.pattern)
            ">
            {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
          </span>
        </div>
        <div class="col-3">
          <p class="label-cls">Middle Name</p>
          <input type="text" [readonly]="!isEdit" class="im-input" [ngClass]="{
              'im-input-error':
                (preparerForm.get('middleName').touched || submitted) &&
                preparerForm.get('middleName').errors?.pattern
            }" pattern="^[a-zA-Z ]*$" formControlName="middleName" placeholder="Enter your Middle Name" />
          <span class="invalid-message" *ngIf="
              (preparerForm.get('middleName').touched || submitted) &&
              preparerForm.get('middleName').errors?.pattern
            ">
            {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
          </span>
        </div>        
      </div>  
      <div class="row border-bottom-dashed mt-4">
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <label class="mt-2 main-label">Signature of Preparer or Translator</label>
        </div>        
      </div>    
      <div class="row mt-2">
          <div class="col-3 signature-upload">  
            <p></p>          
            <button pButton pRipple type="button" label="Upload Now!" icon="pi pi-upload" [disabled]="!isEdit" class="p-button-outlined" (click)="selectSignatureImage()"></button>  
            
          </div>
          <div class="col-3">
            <p class="label-cls">Date</p>
            <p-calendar  [disabled]="!isEdit" formControlName="preparerSignDate"
             [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="2021:2030"  dateFormat="mm-dd-yy"
            inputId="expiryPick" required></p-calendar>
        <span class="invalid-message"
            *ngIf="(preparerForm.get('preparerSignDate').touched || preparerForm.get('preparerSignDate').dirty) && preparerForm.get('preparerSignDate').errors?.required">
            {{ getGlobalErrorMessages("REQUIRED") }}</span>
          </div>
      </div>
      <div class="row mt-2" *ngIf="signatureImage">
        <div class="col-6">
          <img class="signatureImageBox" [src]="signatureImage">
         </div>
      </div>
      <div class="row border-bottom-dashed mt-4">
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <label class="mt-2 main-label">Address Details</label>
        </div>
      </div>
      <!-- Current Address -->
      <div formArrayName="prepAddress">
        <div [formGroupName]="0">
          <div class="row mt-2">
            <div class="col-9">
              <p class="label-cls required">Address 1</p>
              <input type="text" [readonly]="!isEdit" class="im-input col-md" [ngClass]="{
                  'im-input-error':
                    (submitted ||
                    preparerForm
                        .get('prepAddress')
                        ['controls']['0'].get('addressLine1').touched) &&
                        preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('addressLine1').errors?.required
                }" formControlName="addressLine1" placeholder="Address 1" maxLength="99" />
              <span class="invalid-message" *ngIf="
                  (submitted ||
                  preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('addressLine1').touched) &&
                      preparerForm
                    .get('prepAddress')
                    ['controls']['0'].get('addressLine1').errors?.required
                ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
            </div>
          </div>
           <div class="row mt-2">
            <div class="col-9">
              <p class="label-cls">Address 2</p>
              <input type="text" [readonly]="!isEdit" class="im-input col-md" [ngClass]="{
                  'im-input-error':
                    submitted &&
                    preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('addressLine2').errors?.required
                }" formControlName="addressLine2" placeholder="Address 2" maxLength="99" />
              <span class="invalid-message" *ngIf="
                  submitted &&
                  preparerForm
                    .get('prepAddress')
                    ['controls']['0'].get('addressLine2').errors?.required
                ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
            </div>
          </div>
          <div class="row mt-2">            
            <div class="col-3" formGroupName="countryCode">
              <p class="label-cls required">Country</p>
              <select class="im-input col-md" [attr.disabled]="!isEdit? true:null" formControlName="countryCode" (change)="
                  handleCountryChange($event.target.value)
                ">
                <option *ngFor="let code of listCountry" [value]="code.countryCode">
                  {{ code.countryName }}
                </option>
              </select>             
              <span class="invalid-message" *ngIf="
                  (submitted ||
                  preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('countryCode').touched) &&
                      preparerForm
                    .get('prepAddress')
                    ['controls']['0'].get('countryCode')
                    .get('countryCode').errors?.required
                ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
            </div>
            <div class="col-3" *ngIf="listStates.length > 0">
              <p class="label-cls required">State</p>
              <select class="im-input col-md" [attr.disabled]="!isEdit? true:null" formControlName="stateProvinceCode" placeholder="Select State"
                (change)="handleStateChange($event.target.value, 0)">
                <option *ngFor="let item of listStates" [value]="item.stateProvinceCode">
                  {{ item.stateProvinceName }}
                </option>
              </select>
              <span class="invalid-message" *ngIf="
                  (submitted ||
                  preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('stateProvinceCode').touched) &&
                      preparerForm
                    .get('prepAddress')
                    ['controls'][0].get('stateProvinceCode').errors?.required
                ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
            </div>
            <div class="col-3" *ngIf="listStates.length <= 0">
              <p class="label-cls">State</p>
              <input class="im-input" [readonly]="!isEdit" [ngClass]="{
                  'im-input-error':
                    (submitted ||
                    preparerForm
                        .get('prepAddress')
                        ['controls']['0'].get('stateProvinceName').touched) &&
                        preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('stateProvinceName').errors?.required
                }" formControlName="stateProvinceName" type="text" placeholder="State" />
              <span class="invalid-message" *ngIf="
                  (submitted ||
                  preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('stateProvinceName').touched) &&
                      preparerForm
                    .get('prepAddress')
                    ['controls']['0'].get('stateProvinceName').errors?.required
                ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
            </div>
            <div class="col-3">
              <p class="label-cls required">City</p>
              <input class="im-input" [readonly]="!isEdit" [ngClass]="{
                  'im-input-error':
                    (submitted ||
                    preparerForm
                        .get('prepAddress')
                        ['controls']['0'].get('city').touched) &&
                        preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('city').errors?.required
                }" formControlName="city" type="text" placeholder="City" />
              <span class="invalid-message" *ngIf="
                  (submitted ||
                  preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('city').touched) &&
                      preparerForm.get('prepAddress')['controls']['0'].get('city')
                    .errors?.required
                ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <p class="label-cls">Locality</p>
              <input class="im-input" [readonly]="!isEdit" formControlName="locality" type="text" placeholder="Area" />
            </div>            
            <div class="col-2">
              <p class="label-cls required">Zip / Postal Code</p>
              <input class="im-input" [readonly]="!isEdit" [ngClass]="{
                  'im-input-error':
                    ((submitted ||
                    preparerForm
                        .get('prepAddress')
                        ['controls']['0'].get('postCode').touched) &&
                        preparerForm
                        .get('prepAddress')
                        ['controls']['0'].get('postCode').errors?.pattern) ||
                    (submitted &&
                    preparerForm
                        .get('prepAddress')
                        ['controls']['0'].get('postCode').errors?.required)
                }" formControlName="postCode" type="text" placeholder="Zip Code" maxLength="10" />
              <span class="invalid-message" *ngIf="
                  (submitted ||
                  preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('postCode').touched) &&
                  preparerForm
                    .get('prepAddress')
                    ['controls']['0'].get('postCode').errors?.required
                ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
              <span class="invalid-message" *ngIf="
                  (submitted ||
                  preparerForm
                      .get('prepAddress')
                      ['controls']['0'].get('postCode').touched) &&
                      preparerForm
                    .get('prepAddress')
                    ['controls']['0'].get('postCode').errors?.pattern
                ">
                {{ getGlobalErrorMessages("ZIPCODE_ONLY") }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row border-bottom-dashed mt-4">
      </div>
  </form>
  </div>