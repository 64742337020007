import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState, I9Model } from 'src/app/data/models/app-state';


export const selectI9SectionOneState = createFeatureSelector('i9');

export const selectI9SectionOne = createSelector(
    selectI9SectionOneState,
  (state: I9Model) => state
);
export const selectBeneDetails = createSelector(
  selectI9SectionOneState,
(state: I9Model) => state.beneficiaryDetails
);
