import { NgModule } from '@angular/core';
import { DeleteDialogLibraryComponent } from './delete-dialog-library.component';
import { PrimengLibraryModule } from 'primeng-library';

@NgModule({
  declarations: [DeleteDialogLibraryComponent],
  imports: [
    PrimengLibraryModule
  ],
  providers: [],
  exports: [DeleteDialogLibraryComponent]
})
export class DeleteDialogLibraryModule { }
