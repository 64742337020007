import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { GCI140Service } from '../../../../services/gci140.service';
import { first } from 'rxjs/internal/operators/first';
import { FileUploadConfiguration, I140FileUploadConfiguration, ImagilityBaseResponse, SubTaskDetails, TaskSummaryOverview, PrimaryData } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/internal/operators/map';
import moment from 'moment';
import * as errorUtils from 'error-message-utility';
import { getSubTaskDetails, updateStepStatusToInitialState, loadSubTaskDetails } from 'visa-store';
import { DirtyFormGuard, MarkDirtyFormAction, MarkCleanFormAction } from 'dirty-check-store';
import { ReferenceLibraryService } from 'reference-library';
import { UpdateDecisionService } from './update-decision.service';
import { VisaExtendedState } from '../../utility/interfaces/visa-extended-state';
import { mergeMap, take } from 'rxjs/operators';
import { PetitionerService } from 'src/app/services/petitioner.service';
import * as Task from './../../../superTask/task-state/actions/task.actions';


@Component({
  selector: 'i140-update-decision',
  templateUrl: './update-decision.component.html',
  styleUrls: ['./update-decision.component.scss']
})
export class UpdateDecisionComponent implements OnInit, OnDestroy {

  @ViewChild('updateDecisionForm', { static: true }) updateDecisionForm: NgForm;
  // fileUploadConfigurations: I140FileUploadConfiguration;
  fileUploadConfigurations: FileUploadConfiguration;

  listResponses: [];
  receiptRefList: [];

  updateDecision: {
    beneficiaryConfirmationNumber: string;
    decissionCode: string;
    outcomeDate: Date;
    validityEndDate: Date;
    validityStartDate: Date;
    outcomeReason: string;
    rfeResponseDueDate: Date;
    isNotifyBeneficiary: boolean;
    isNotifyPetitioner: boolean;
    receiptDate: Date,
  };

  disabledControls: boolean;
  observableSubscription$ = new Subject();
  receiptNumber: string;
  noticeMaxDate: Date;
  copyUpdateDecisionDetails: {
    beneficiaryConfirmationNumber: string;
    decissionCode: string;
    outcomeDate: Date;
    validityEndDate: Date;
    validityStartDate: Date;
    outcomeReason: string;
    rfeResponseDueDate: Date;
    isNotifyBeneficiary: boolean;
    isNotifyPetitioner: boolean;
    receiptDate: Date;  // IM-8484 issue fix added receipt date
  };
  userType: string;
  minRFEResponseDueDate: Date;
  getReceiptDate: Date;
  primaryData: PrimaryData;
  currentDate = new Date();
  isDs160: boolean = false;
  constructor(
    private store: Store<VisaExtendedState>,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private gcI140Service: GCI140Service,
    private guard: DirtyFormGuard,
    private toastr: ToastrService,
    private referenceLibraryService: ReferenceLibraryService,
    private updateDecisionService: UpdateDecisionService,
    private petitionerService: PetitionerService
  ) {
    this.primaryData = this.dynamicDialogConfig.data;
    this.disabledControls = false;
    this.updateDecision = {
      decissionCode: '',
      isNotifyBeneficiary: false,
      isNotifyPetitioner: false,
      outcomeDate: new Date(),
      validityEndDate: new Date(),
      validityStartDate: new Date(),
      outcomeReason: '',
      beneficiaryConfirmationNumber: '',
      rfeResponseDueDate: new Date(),
      receiptDate: new Date()
    };

    this.receiptNumber = '';
    this.noticeMaxDate = new Date();
    this.userType = sessionStorage.getItem('userTypeRole');
  }

  ngOnInit(): void {
    // Get step list with details
    this.store.select(getSubTaskDetails).pipe(takeUntil(this.observableSubscription$)).subscribe((data: SubTaskDetails) => {
      this.receiptNumber = data.receiptNumber;
      this.getReceiptDate = new Date(data.receiptDate);
      this.isDs160 = data?.subTaskTypeCode === 'DS160';
    });
    this.getI140UpdateDecision();
    this.getResponseList();
    this.getReceiptType();

    this.updateDecisionForm.form.valueChanges.pipe(
      map(() => this.updateDecisionForm.dirty),
    ).pipe(takeUntil(this.observableSubscription$)).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });

    this.setFileUploadUSConfigurations();
  }


  setFileUploadUSConfigurations() {
    const docTypeRefGroupCode = 'RECPETDCS';

    this.fileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: false,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getDocTypeApiUrl: `/${this.dynamicDialogConfig.data.visatype}/petitionType/${this.dynamicDialogConfig.data.petitionType}/group/${docTypeRefGroupCode}/documentTypes`,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${docTypeRefGroupCode}/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${docTypeRefGroupCode}/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: this.updateDecision.decissionCode
    });
  }
  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  setMinRFEResponseDueDate() {
    this.minRFEResponseDueDate = new Date(this.updateDecision.outcomeDate);
    this.minRFEResponseDueDate.setDate(this.minRFEResponseDueDate.getDate() + 1);
  }

  getI140UpdateDecision() {
    this.updateDecisionService.getUPdateDecisionDetails(
      this.dynamicDialogConfig.data.visatype, this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId)
      .pipe(take(1))
      .subscribe((response: ImagilityBaseResponse) => {
        this.copyUpdateDecisionDetails = {
          decissionCode: response.data.decissionCode,
          isNotifyBeneficiary: response.data.isNotifyBeneficiary,
          isNotifyPetitioner: response.data.isNotifyPetitioner,
          outcomeDate: response.data.outcomeDate ? new Date(response.data.outcomeDate) : null,
          validityEndDate: response.data.validityEndDate ? new Date(response.data.validityEndDate) : null,
          validityStartDate: response.data.validityStartDate ? new Date(response.data.validityStartDate) : null,
          outcomeReason: response.data.outcomeReason,
          beneficiaryConfirmationNumber: response.data.beneficiaryConfirmationNumber,
          rfeResponseDueDate: response.data.rfeResponseDueDate ? new Date(response.data.rfeResponseDueDate) : null,
          receiptDate: response.data.receiptDate ? new Date(response.data.receiptDate) : null

        };
        this.updateDecision = {
          decissionCode: response.data.decissionCode,
          isNotifyBeneficiary: response.data.isNotifyBeneficiary,
          isNotifyPetitioner: response.data.isNotifyPetitioner,
          outcomeDate: response.data.outcomeDate ? new Date(response.data.outcomeDate) : null,
          outcomeReason: response.data.outcomeReason,
          validityEndDate: response.data.validityEndDate ? new Date(response.data.validityEndDate) : null,
          validityStartDate: response.data.validityStartDate ? new Date(response.data.validityStartDate) : null,
          beneficiaryConfirmationNumber: response.data.beneficiaryConfirmationNumber,
          rfeResponseDueDate: response.data.rfeResponseDueDate ? new Date(response.data.rfeResponseDueDate) : null,
          receiptDate: response.data.receiptDate ? new Date(response.data.receiptDate) : null
        };

        // this.fileUploadConfigurations = {
        //   disableControls: this.disabledControls,
        //   isDocumentTypeRequired: false,
        //   isDocumentUploadRequired: true,
        //   isViewEnabled: true,
        //   isDownloadEnabled: true,
        //   isDeleteEnabled: true,
        //   getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/RECPETDCS/`,
        //   postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/RECPETDCS/fileCategory/`,
        //   deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
        //   documentTypeDropdownCode: this.updateDecision.decissionCode
        // };
        this.handleUSCISChange();
      });
  }

  getResponseList() {
    this.updateDecisionService.getStatsReponses(this.dynamicDialogConfig.data.caseType)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        this.listResponses = response.data.filter(x => x.code !== 'FILED');
      });
  }

  getReceiptType() {
    this.referenceLibraryService.getGroupReferenceData('RECPETDCS')
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: []) => {
        this.receiptRefList = response;
      });
  }

  handleUSCISChange() {
    if (this.updateDecision.decissionCode === this.copyUpdateDecisionDetails.decissionCode) {
      this.updateDecision = Object.assign({}, this.copyUpdateDecisionDetails);
    }
    else {
      this.updateDecision = {
        decissionCode: this.updateDecision.decissionCode,
        isNotifyBeneficiary: false,
        isNotifyPetitioner: false,
        outcomeDate: null,
        validityEndDate: null,
        validityStartDate: null,
        outcomeReason: null,
        beneficiaryConfirmationNumber: this.updateDecision.beneficiaryConfirmationNumber,
        rfeResponseDueDate: null,
        receiptDate: this.updateDecision.receiptDate

      };
    }
    this.setFileUploadUSConfigurations();
    switch (this.updateDecision.decissionCode) {
      case 'APPROVED':
        this.fileUploadConfigurations.documentTypeDropdownCode = 'APPROVAL';
        break;
      case 'DENIED':
        this.fileUploadConfigurations.documentTypeDropdownCode = 'DENIAL';
        break;
      case 'WITHDRAWN':
        this.fileUploadConfigurations.documentTypeDropdownCode = 'WITHDRAW';
        break;
      case 'RFEISS':
        this.fileUploadConfigurations.documentTypeDropdownCode = 'RFE';
        break;
    }
  }

  postDecisionDetails() {
    const payload = {
      decisionName: '',
      decissionCode: this.updateDecision.decissionCode,
      isBeneficiaryNotify: this.updateDecision.isNotifyBeneficiary ? 1 : 0,
      isPetitionerNotify: this.updateDecision.isNotifyPetitioner ? 1 : 0,
      outcomeDate: this.updateDecision.outcomeDate ?
        moment(this.updateDecision.outcomeDate).format('YYYY-MM-DD') : null,
      validityEndDate: this.updateDecision.validityEndDate ?
        moment(this.updateDecision.validityEndDate).format('YYYY-MM-DD') : null,
      validityStartDate: this.updateDecision.validityStartDate ?
        moment(this.updateDecision.validityStartDate).format('YYYY-MM-DD') : null,
      outcomeReason: this.updateDecision.outcomeReason,
      rfeResponseDueDate: this.updateDecision.rfeResponseDueDate ?
        moment(this.updateDecision.rfeResponseDueDate).format('YYYY-MM-DD') : null,
      noticeNo: this.receiptNumber ? this.receiptNumber : null,
      receiptDate: this.updateDecision.receiptDate ?
        moment(this.updateDecision.receiptDate).format('YYYY-MM-DD') : null,

    };
    this.updateDecisionService.postUPdateDecisionDetails(
      this.dynamicDialogConfig.data.visatype, this.dynamicDialogConfig.data.caseType, this.dynamicDialogConfig.data.caseId, payload)
      .pipe(
        takeUntil(this.observableSubscription$),
        mergeMap((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message);
          this.updateDecisionForm.resetForm();
          this.getI140UpdateDecision();
          return this.petitionerService.getSuperTask(this.dynamicDialogConfig.data.superTaskId);
        })
      ).subscribe((taskSummaryOverview: TaskSummaryOverview) => {
        this.store.dispatch(new Task.SetTaskSummaryOverview(taskSummaryOverview));
        this.store.dispatch(loadSubTaskDetails({
          taskId: parseInt(this.dynamicDialogConfig.data.subTaskId.toString()),
          visaTypeCode: this.dynamicDialogConfig.data.getTaskType
        }));
      }
      );
  }

  handleClose() {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }

}
