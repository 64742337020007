import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { I140FileUploadConfiguration, ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RequiredDocumentsService } from './required-documents.service';
import { environment } from 'environment-configurations';
import { FileUploadLibraryService } from 'file-upload-library';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DeleteDialogConfigurations, NoteDialogConfigurations } from 'dialog-configuration-library';
import { select, Store } from '@ngrx/store';
import { getStepDetails } from 'visa-store';
import { DEPENDENT_VISAS, BENEFICIARY } from 'app-models';
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import { AppServicesLibraryService } from 'app-services-library';

@Component({
  selector: 'app-required-documents',
  templateUrl: './required-documents.component.html',
  styleUrls: ['./required-documents.component.scss']
})
export class RequiredDocumentsComponent implements OnDestroy, OnChanges, OnInit {
  @Input()
  primaryData: PrimaryData;
  requiredDocFileUploadConfigurations: I140FileUploadConfiguration;
  availableDocFileUploadConfigurations: I140FileUploadConfiguration = {
    disableControls: false,
    isDocumentTypeRequired: true,
    isDocumentUploadRequired: true,
    isViewEnabled: true,
    isDownloadEnabled: true,
    isDeleteEnabled: true,
    postApiUrl: '',
    getApiUrl: '',
    deleteApiUrl: '',
    documentTypeDropdownCode: ''
  };
  disabledControls: boolean;
  availableDocList = [];
  observableSubscription$ = new Subject();
  @Input() userId: number;
  stepDetails: TaskStep;
  @Input() type: string;
  @Input() isPrimaryApplicant: boolean;
  isDependetVisa: boolean;
  userType: string;
  notifyDestinationUser: string;

  constructor(
    public store: Store<any>,
    private apiService: RequiredDocumentsService,
    public uploadService: FileUploadLibraryService,
    public dialogService: DialogService,
    public toasterService: ToastrService,
    private appServicesLibraryService: AppServicesLibraryService) {
    this.notifyDestinationUser = BENEFICIARY;
  }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem('userTypeRole');
    // Get Specific step details by passing step code
    this.isDependetVisa = DEPENDENT_VISAS.includes(this.primaryData.visatype);
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        if (data && data.id) {
          this.stepDetails = data;
          this.toggleControlsStatus();
        }
      });
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
    //   this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
    if (this.primaryData && this.userId) {
      this.setFileUploadConfigurations();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.primaryData && this.userId) {
      this.setFileUploadConfigurations();
      if (DEPENDENT_VISAS.includes(this.primaryData.visatype)) {
        this.getDependentAvailableDocumentsList();
      } else {
        this.getAvailableDocumentsList((this.primaryData.familyId === this.userId || this.type === "family") ? 'fimmigration' : 'immigration');
      }
    }
  }

  setFileUploadConfigurations() {
    let docTypeRefGroupCode;
    if (this.primaryData.immigrationClassification === "ASYLRELATIVE") {
      docTypeRefGroupCode = 'BENOTHDOCASYLEE';
    } else {
      docTypeRefGroupCode = (this.primaryData.familyId === this.userId || this.type === "family" || ["EB2ADVDEG", "WVRINADMI601", "WVRUNLAWPRSNCI601A", "EB2EXCPABLTY", "EB2NATINTWVR", "EB3PROF", "EB3SKLWRKR", "EB3UNSKLWRKR", "DACAI821D", "EB4IMMJUVNL", "EB4IMMRELGWRKR", "EB4IMMOTH", "WIDOWERUSI360", "AMERASIANI360", "VAWASPOUSEI360", "VAWACHILDI360", "VAWAPARENTI360", "AFFDVTSUPTEXMPT864W", "TEMPPROTSTATI821", "RELFFORMERSEC191", "SUSPDPRT881","EB1OUTSTND", "EB1ALIEN", "EB1ALIENSELF","APPLN400","APPLTOREPLRESICARD","ASYLAFFIRM"].includes(this.primaryData.immigrationClassification) || ['DS160'].includes(this.primaryData.caseType) || ['U1', 'UD'].includes(this.primaryData.visatype)) ? 'BENOTHDOC' : 'SPONSOROTHDOC';
    }


    let requiredDocFileUploadConfigurations: I140FileUploadConfiguration = {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getDocTypeApiUrl: `/${this.primaryData.visatype}/task/${this.primaryData.subTaskId}/group/${docTypeRefGroupCode}/documentTypes`,
      getApiUrl: `/${this.primaryData.visatype}/${this.primaryData.caseType}/${this.primaryData.caseId}/document/category/${docTypeRefGroupCode}/`,
      postApiUrl: `/${this.primaryData.visatype}/${this.primaryData.caseType}/${this.primaryData.caseId}/document/category/${docTypeRefGroupCode}/fileCategory/`,
      deleteApiUrl: `/${this.primaryData.visatype}/${this.primaryData.caseType}/${this.primaryData.caseId}/document/`,
      documentTypeDropdownCode: docTypeRefGroupCode

    };

    if (this.primaryData.familyId === this.userId || this.type === "family") {
      requiredDocFileUploadConfigurations = {
        ...requiredDocFileUploadConfigurations,
        familyId: this.type === "family" ? this.userId : this.primaryData.familyId
      };
    }

    this.requiredDocFileUploadConfigurations = Object.assign({}, requiredDocFileUploadConfigurations);
  }

  getAvailableDocumentsList(type) {
    let beneficiaryFamilyId = this.type === "family" ? this.userId : this.primaryData.familyId;
    const visaType = this.primaryData.caseType === 'I130' ? 'GC' : this.primaryData.visatype ? this.primaryData.visatype : this.primaryData.immigrationCategory;
    const docTypeCode = this.primaryData.caseType === 'I130' ? 'BENPASSDOC' : 'BENIMMDOC';
    this.apiService.getAvailableDocumentsList(
      visaType, this.primaryData.caseType, this.primaryData.caseId, type, docTypeCode, beneficiaryFamilyId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        this.availableDocList = response.data ? response.data : [];
      });
  }

  getDependentAvailableDocumentsList() {
    let payload;
    if (this.userId === this.primaryData.beneficiaryId) {
      payload = {
        "beneficiaryId": this.primaryData.beneficiaryId,
        "familyId": null,
        "groupCodes": [
          "BENIMMDOC", "BENWEDOC", "BENPASSDOC", "BENLICDOC"
        ]
      }

    } else {
      payload = {
        "beneficiaryId": null,
        "familyId": this.userId,
        "groupCodes": [
          "BENIMMDOC", "BENWEDOC", "BENPASSDOC", "BENLICDOC"
        ],
        "primary": this.isPrimaryApplicant ? this.isPrimaryApplicant : (['K1', 'K3'].includes(this.primaryData.visatype) ? true : false)
      }
    }

    this.apiService.getDependentAvailableDocumentList(
      this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId, payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        this.availableDocList = response.data ? response.data : [];
      });
  }

  syncDocuments() {
    let payload;
    if (this.userId === this.primaryData.beneficiaryId) {
      payload = {
        "beneficiaryId": this.primaryData.beneficiaryId,
        "familyId": null,
        "groupCodes": [
          "BENIMMDOC", "BENWEDOC", "BENPASSDOC", "BENLICDOC"
        ]
      }

    } else {
      payload = {
        "beneficiaryId": null,
        "familyId": this.userId,
        "groupCodes": [
          "BENIMMDOC", "BENWEDOC", "BENPASSDOC", "BENLICDOC"
        ],
        "primary": this.isPrimaryApplicant ? this.isPrimaryApplicant : (['K1', 'K3'].includes(this.primaryData.visatype) ? true : false)
      }
    }
    this.apiService.getSyncDocuments(
      this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId, payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        this.toasterService.success(response.message);
        if (response.status == 200) {
          if (DEPENDENT_VISAS.includes(this.primaryData.visatype)) {
            this.getDependentAvailableDocumentsList();
          } else {
            this.getAvailableDocumentsList((this.primaryData.familyId === this.userId || this.type === "family") ? 'fimmigration' : 'immigration');
          }

        }
        // this.availableDocList = response.data ? response.data : [];
      });
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  download(file) {
    this.uploadService.downloadDocuments(environment.docs + file.fileLocation.substring(23), file.fileLocation.substring(23));
  }
  deleteDocumentTypeLis(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        const api = this.primaryData.caseType && this.primaryData.caseType === 'I130' ?
          `/GC/${this.primaryData.caseType}/${this.primaryData.caseId}/document/${input.id}`
          : `${this.requiredDocFileUploadConfigurations.deleteApiUrl}${input.id}`;
        this.uploadService.deleteUploadDocument(api)
          .pipe(takeUntil(this.observableSubscription$))
          .subscribe((responseUploadedDocs: ImagilityBaseResponse) => {
            this.toasterService.success(responseUploadedDocs.message);
            if (DEPENDENT_VISAS.includes(this.primaryData.visatype)) {
              this.getDependentAvailableDocumentsList();
            } else {
              this.getAvailableDocumentsList((this.primaryData.familyId === this.userId || this.type === "family") ? 'fimmigration' : 'immigration');
            }
          });
      }
    });
  }

  handleSync() {
    if (DEPENDENT_VISAS.includes(this.primaryData.visatype)) {
      this.syncDocuments();
    } else {
      const type = this.primaryData.familyId === this.userId ? 'fimmigration' : 'immigration';
      const visaType = this.primaryData.caseType === 'I130' ? 'GC' : this.primaryData.visatype ? this.primaryData.visatype : this.primaryData.immigrationCategory;
      const docTypeCode = this.primaryData.caseType === 'I130' ? 'BENPASSDOC' : 'BENIMMDOC';
      this.apiService.postSyncDocumentsList(
        visaType, this.primaryData.caseType, this.primaryData.caseId, type, docTypeCode,
        this.primaryData.superTaskId, this.primaryData.subTaskId)
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((response: ImagilityBaseResponse) => {
          if (response) {
            this.toasterService.success(response.message);
            this.getAvailableDocumentsList(type);
          }
        });
    }
  }

  handleNotification(item) {
    NoteDialogConfigurations.data = {
      title: `Missing Document - ${item.fileCategory.name} `,
      payload: {
        // beneficiaryId: this.primaryData.sponsorId ? this.primaryData.sponsorId : null,
        beneficiaryId: this.primaryData.sponsorId ? this.primaryData.sponsorId : this.primaryData.beneficiaryId ? this.primaryData.beneficiaryId : null,
        companyId: this.stepDetails.attorneyId ? this.stepDetails.attorneyId : null,
        employeeId: null,
        notificationType: 'DOCMISS',
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.primaryData.caseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group,
          superTaskId: this.primaryData.superTaskId
        }),
        channelType: '',
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: '',
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();

  }

}

