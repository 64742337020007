import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VdJsonViewerService {

  constructor(
    private httpClient: HttpClient,
    public toastr: ToastrService
  ) { }

  getUploadedFilesJSON(taskTypeJsonId: number) {
    return this.httpClient.get(
      `/vd/pdfToWorkflow/get/formAutomation/jsonViewerFormsList/${taskTypeJsonId}`
    ).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status === 200 && response.data) {
          const data = (JSON.parse(response.data));
          const jsonViewerFormsListResponse = data.jsonViewerFormsListResponse && data.jsonViewerFormsListResponse.length > 0 ?
            data.jsonViewerFormsListResponse : null;
          return jsonViewerFormsListResponse;
        } else {
          return null;
        }
      })
    );
  }

  saveEditedJSON(payload: any) {
    return this.httpClient.post(
      `/vd/pdfToWorkflow/update/pdfToJsonViewer`,
      payload
    ).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.toastr.success(response.message);
          return true;
        } else {
          this.toastr.error(response.message);
          return false;
        }
      })
    );
  }

  extractPartNumber(inputString) {
    const match = inputString.match(/^part\s([A-Z0-9]+)/i);
    return match ? match[1] : null;
  }

  preJSONViewerEngine(rawJSON: any) {
    const resultJSON = [];
    rawJSON.forEach((obj: any) => {
      const tempJSON = {
        fileName: obj.fileName,
        elementList: [],
        id: obj.id,
        taskTypeId: obj.taskTypeJsonId
      };
      const elementJSONObj = (JSON.parse(obj.elementsJSONResponseListAsString) as any[]);
      const isJSONConvertedObj = elementJSONObj.find(x => x.isJSONConverted);
      if (isJSONConvertedObj) {
        elementJSONObj.splice(elementJSONObj.findIndex(x => x.isJSONConverted), 1);
        tempJSON.elementList.push(...elementJSONObj);
      } else {
        const checkboxHandled = [];
        const filteredValidElementArr = elementJSONObj
          .filter(x => x.wholepartString && x.getFullyQualifiedName && String(x.getFullyQualifiedName).trim() !== '');
        const elementsJSONResponseList = filteredValidElementArr
          .map((x, eleIndex) => {
            x.getFullyQualifiedName = String(x.getFullyQualifiedName).trim();
            x.wholepartString = String(x.wholepartString).replace(/\s+/g, ' ').trim();
            const stringX = String(x.wholepartString).toLowerCase();
            const partIndex = stringX.indexOf('part');
            x.partCompare = true;
            x.defineAs = 'element';
            x.isSelected = false;
            x.speedDialClicked = false;
            x.deleted = false;
            x.dropDownValuesList =
              x.dropDownValuesList ?
                JSON.parse(x.dropDownValuesList) :
                x.getFieldType === 'Dropdown' ? [] : null;
            x.group = null;
            x.groupElementsPlacement = null;
            x.groupClosed = false;
            x.fileName = obj.fileName;
            x.properties = null;
            x.index = eleIndex;
            if (partIndex !== 0) {
              const regex = /^form.+\.#subform.+\.P(\d+)_.+$/;
              if (x.getFullyQualifiedName.match(regex)) {
                const fullPartIndex = x.wholepartString.indexOf(`Part ${RegExp.$1}`);
                if (fullPartIndex > 0) {
                  x.wholepartString = String(x.wholepartString).substring(fullPartIndex);
                } else {
                  x.wholepartString = `Part ${RegExp.$1}. ${x.wholepartString}`;
                  x.partCompare = false;
                }
              } else {
                if (stringX.indexOf('“part') === 0) {
                  x.wholepartString = String(x.wholepartString).substring(1);
                } else {
                  x.wholepartString = `Part 0. ${x.wholepartString}`;
                }
              }
            }
            if (x.wholepartString.indexOf(`Part .`) !== -1) {
              if (eleIndex > 0 && filteredValidElementArr[eleIndex - 1].partNumber) {
                x.wholepartString = x.wholepartString.replace('Part .', `Part ${filteredValidElementArr[eleIndex - 1].partNumber}.`)
              } else {
                x.wholepartString = x.wholepartString.replace('Part .', `Part 0.`)
              }
            }
            x.partNumber = this.extractPartNumber(x.wholepartString);
            // x.partNumber = Number(x.wholepartString.match(/\d+/)[0]);
            // group Checkbox - start'
            if (x.getFieldType === 'Checkbox') {
              const regex = /\[\d+\]$/g;
              let checkboxId = String(x.getFullyQualifiedName).replace(regex, '');
              // let checkboxId = String(x.getFullyQualifiedName).substring(0, x.getFullyQualifiedName.length - 3);
              if (checkboxId.endsWith('_Male')) {
                checkboxId = checkboxId.substring(0, checkboxId.length - 5);
              } else if (checkboxId.endsWith('_Female')) {
                checkboxId = checkboxId.substring(0, checkboxId.length - 7);
              } else if (checkboxId.endsWith('_yes')) {
                checkboxId = checkboxId.substring(0, checkboxId.length - 4);
              } else if (checkboxId.endsWith('_no')) {
                checkboxId = checkboxId.substring(0, checkboxId.length - 3);
              }
              if (checkboxHandled.indexOf(checkboxId) === -1) {
                checkboxHandled.push(checkboxId);
                const checkboxList = filteredValidElementArr
                  .filter((y) => y.getFieldType === 'Checkbox')
                  .filter((y) => {
                    y.getFullyQualifiedName = String(y.getFullyQualifiedName).trim();
                    let compareCheckboxId = String(y.getFullyQualifiedName).replace(regex, '');
                    // let compareCheckboxId = String(y.getFullyQualifiedName).substring(0, y.getFullyQualifiedName.length - 3);
                    if (compareCheckboxId.endsWith('_Male')) {
                      compareCheckboxId = compareCheckboxId.substring(0, compareCheckboxId.length - 5);
                    } else if (compareCheckboxId.endsWith('_Female')) {
                      compareCheckboxId = compareCheckboxId.substring(0, compareCheckboxId.length - 7);
                    } else if (compareCheckboxId.endsWith('_yes')) {
                      compareCheckboxId = compareCheckboxId.substring(0, compareCheckboxId.length - 4);
                    } else if (compareCheckboxId.endsWith('_no')) {
                      compareCheckboxId = compareCheckboxId.substring(0, compareCheckboxId.length - 3);
                    }
                    return compareCheckboxId === checkboxId;
                  });
                const properties = checkboxList.map(y => {
                  return {
                    name: y.wholepartString,
                    isChecked: false,
                    data_field_name: y.getFullyQualifiedName
                  }
                });
                if (checkboxList.length > 1) {
                  const uuid = this.uuid();
                  x.getFullyQualifiedName = uuid;
                  x.elementId = uuid;
                }
                x.properties = properties;
              } else {
                x.deleted = true;
              }
            }
            // group Checkbox - end
            return x;
          }).filter(x => !x.deleted);

        elementsJSONResponseList.sort((a, b) => {
          return a.partNumber - b.partNumber;
        });
        const postPartElementList = [];
        Array.from(new Set(elementsJSONResponseList
          .map(x => x.partNumber)))
          .forEach((x) => {
            const partArray = elementsJSONResponseList.filter(y => y.partNumber === x);
            const partElement = this.getPartElement(partArray, x);
            postPartElementList.push(...[partElement, ...partArray].map(y => {
              return this.setFieldType(y);
            }));
          });
        // postPartElementList
        //   .filter(x =>
        //     // ['Checkbox', 'Dropdown'].includes(x.getFieldType)
        //     ['Dropdown'].includes(x.getFieldType)
        //   )
        //   .forEach(x => {
        //     console.log(x);
        //   });
        tempJSON.elementList.push(...postPartElementList);
      }
      resultJSON.push(tempJSON);
    });
    return resultJSON;
  }

  uuid() {
    const val1 = Date.now().toString(36);
    const val2 = Math.random().toString(36).substring(2);
    return val1 + val2;
  }

  getPartElement(partArray: any[], partNumber: number) {
    const partElement = {
      getFieldType: 'partTitle',
      getFullyQualifiedName: this.uuid(),
      partNumber,
      wholepartString: '',
      defineAs: 'part',
      isSelected: false,
      speedDialClicked: false,
      dropDownValuesList: null,
      deleted: false,
      group: null,
      groupElementsPlacement: null,
      groupClosed: false
    };
    if (partArray.length === 1) {
      partElement.wholepartString = partArray[0].wholepartString.match(/^PART\s[a-zA-Z0-9]+\..*?(\.\s|\.?$)/i)[0].replace(/[ (]+$/, '');

    } else {
      const comparablePartArray = partArray.filter(x => x.partCompare);
      if (comparablePartArray?.length) {
        const wholePartString = String(comparablePartArray[0].wholepartString);
        for (let index = 0; index < wholePartString.length; index++) {
          const sameStringArray = comparablePartArray
            .filter(x => String(x.wholepartString).substring(0, index).toLowerCase() === wholePartString.substring(0, index).toLowerCase());
          if (sameStringArray.length < comparablePartArray.length) {
            let finalString = wholePartString.substring(0, index - 1).replace(/[ (]+$/, '');
            let sensibleFinalString;
            if (finalString.substring(finalString.length - 1) !== '.') {
              // sensibleFinalString = finalString.match(/.*(\.)(?!.*\1)/g)?.[0];
              // if (sensibleFinalString === `Part ${partNumber}.`) {
              //   const possibleFinalStringArray = comparablePartArray
              //     .map(x => String(x.wholepartString).match(/^PART\s[a-zA-Z0-9]+\..*?(\.\s|\.?$)/i))
              //     .filter(matchedString => matchedString !== null)
              //     .map(matchedString => matchedString[0]);
              //   const uniquePossibleFinalStringArray = Array.from(new Set(possibleFinalStringArray)).map(x => {
              //     return {
              //       name: x,
              //       count: possibleFinalStringArray.filter(y => y === x).length
              //     }
              //   });
              //   partElement.wholepartString = uniquePossibleFinalStringArray.find(y => y.count === Math.max(...(uniquePossibleFinalStringArray.map(x => x.count)))).name;
              // } else {
              //   partElement.wholepartString = sensibleFinalString;
              // }
              sensibleFinalString = finalString.match(/.*(\.)(?!.*\1)/g);

              if (sensibleFinalString) {
                sensibleFinalString = sensibleFinalString[0];
              } else {
                sensibleFinalString = finalString;
              }

              if (sensibleFinalString === `Part ${partNumber}.`) {
                const possibleFinalStringArray = comparablePartArray
                  .map(x => String(x.wholepartString).match(/^PART\s[a-zA-Z0-9]+\..*?(\.\s|\.?$)/i))
                  .filter(matchedString => matchedString !== null)
                  .map(matchedString => matchedString[0]);
                const uniquePossibleFinalStringArray = Array.from(new Set(possibleFinalStringArray)).map(x => {
                  return {
                    name: x,
                    count: possibleFinalStringArray.filter(y => y === x).length
                  }
                });
                partElement.wholepartString = uniquePossibleFinalStringArray.find(y => y.count === Math.max(...(uniquePossibleFinalStringArray.map(x => x.count)))).name.replace(/[ (]+$/, '');
              } else if (sensibleFinalString === `Part ${partNumber}`) {
                partElement.wholepartString = `Part ${partNumber}.`;
              } else {
                partElement.wholepartString = sensibleFinalString;
              }
            } else {
              if (finalString === `Part ${partNumber}.`) {
                const possibleFinalStringArray =
                  comparablePartArray
                    .map(x => {
                      let returnPossibleFinalString = null;
                      const wholepartStringBreakArray = String(x.wholepartString).match(/^PART\s[a-zA-Z0-9]+\..*?(\.\s|\.?$)/i);
                      if (wholepartStringBreakArray && wholepartStringBreakArray.length > 0) {
                        returnPossibleFinalString = String(x.wholepartString).match(/^PART\s[a-zA-Z0-9]+\..*?(\.\s|\.?$)/i)[0].replace(/[ (]+$/, '');
                      }
                      return returnPossibleFinalString;
                    });
                const uniquePossibleFinalStringArray = Array.from(new Set(possibleFinalStringArray)).filter(x => x).map(x => {
                  return {
                    name: x,
                    count: possibleFinalStringArray.filter(y => y === x).length
                  }
                });
                partElement.wholepartString = uniquePossibleFinalStringArray.find(y => y.count === Math.max(...(uniquePossibleFinalStringArray.map(x => x.count)))).name;
              } else {
                partElement.wholepartString = finalString;
              }
            }
            partArray.forEach(x => {
              x.wholepartString = String(x.wholepartString)
                .replace(partElement.wholepartString, '')
                .trim();
              if (String(x.wholepartString).indexOf(`Part ${partNumber}. `) === 0) {
                x.wholepartString = String(x.wholepartString)
                  .replace(`Part ${partNumber}. `, '')
                  .trim();
              }
              if (x.getFieldType === 'Checkbox') {
                x.properties.forEach(y => {
                  y.name = String(y.name)
                    .replace(partElement.wholepartString, '')
                    .trim();
                  if (String(y.name).indexOf(`Part ${partNumber}. `) === 0) {
                    y.name = String(y.name)
                      .replace(`Part ${partNumber}. `, '')
                      .trim();
                  }
                });
              }
            });
            break;
          }
        }
      } else {
        partElement.wholepartString = `Part ${partNumber}.`;
      }
    }
    return partElement;
  }

  setFieldType(jsonObj: any) {
    let returnObj: any;
    let properties: any[];
    switch (jsonObj.getFieldType) {
      case 'Textbox':
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          defaultProperties: {
            align: null,
            checkboxLabel: null,
            configurable: false,
            controlName: null,
            controlType: null,
            disabled: false,
            editMode: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            groupName: null,
            mandatory: false,
            maxLength: null,
            minLength: null,
            placeholder: null,
            properties: null,
            questionCheck: false,
            questionText: null,
            readonly: false,
            styleResponse: {
              color: "#000",
              width: "95%",
              border: "0px",
              position: "absolute",
              background: "none"
            },
            subText: null,
            valuesResonse: {
              checkbox_val: false,
              textFieldVal: ""
            },
            fieldType: jsonObj.getFieldType,
            numberOfRow: null,
            numberOfColumn: null,
            data_field_name: jsonObj.getFullyQualifiedName,
            style: {
              color: "#000",
              border: "0px",
              position: "absolute",
              background: "none",
              width: '100%'
            },
            layoutCode: 'CustomTextFieldComponent',
            label: jsonObj.wholepartString,
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            coordinateX: 0,
            coordinateY: 0
          }
        };
        break;
      case 'Dropdown':
        properties = jsonObj.properties && jsonObj.properties.length > 0 ?
          JSON.parse(JSON.stringify(jsonObj.properties)).map(x => typeof x === 'string' ? x : x && x.name ? x.name : null) : [];
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          properties: JSON.parse(JSON.stringify(properties)),
          defaultProperties: {
            checkboxLabel: null,
            configurable: false,
            disabled: false,
            editMode: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            groupName: null,
            mandatory: false,
            maxLength: null,
            minLength: null,
            placeholder: null,
            questionCheck: false,
            questionText: null,
            readonly: false,
            styleResponse: { color: "#000", width: "95%", border: "0px", position: "absolute", background: "none" },
            subText: null,
            valuesResonse: { checkbox_val: false, textFieldVal: "" },
            fieldType: jsonObj.getFieldType,
            numberOfRow: null,
            numberOfColumn: null,
            data_field_name: jsonObj.getFullyQualifiedName,
            controlElementType: 'DROPDOWN',
            style: {
              color: "#000",
              border: "0px",
              position: "absolute",
              background: "none",
              width: '100%'
            },
            isConfigurable: false,
            isChecked: false,
            isDisabled: false,
            properties: {
              inputArray: JSON.parse(JSON.stringify(properties)),
              isDataFromApi: false,
              isMultiselect: false,
              showDataSelection: true,
              showMultiSelectOption: true,
              refercenceCodeForApiCall: ''
            },
            value: '',
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            label: jsonObj.wholepartString,
            layoutCode: 'CustomDropdownComponent',
            coordinateX: 0,
            coordinateY: 0,
            controlName: 'Dropdown',
            controlType: 'CUSTOM',
            align: 'left'
          }
        };
        break;
      case 'Datepicker':
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: 'CALENDAR',
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          defaultProperties: {
            fieldType: jsonObj.getFieldType,
            numberOfRow: null,
            numberOfColumn: null,
            data_field_name: jsonObj.getFullyQualifiedName,
            label: jsonObj.wholepartString,
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            controlElementType: 'CALENDAR',
            layoutCode: 'CustomCalenderComponent',
            editMode: false,
            isChecked: false,
            isConfigurable: false,
            isDisabled: false,
            mandatory: false,
            style: {
              color: '#000',
              width: '100%',
              border: '0px',
              position: 'absolute',
              background: 'none'
            },
            value: null,
            coordinateX: 0,
            coordinateY: 0,
            align: null,
            checkboxLabel: null,
            configurable: false,
            controlName: "Date Picker",
            controlType: "CUSTOM",
            disabled: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            groupName: null,
            maxLength: null,
            minLength: null,
            placeholder: null,
            properties: null,
            questionCheck: false,
            questionText: null,
            readonly: false,
            styleResponse: {
              color: '#000',
              width: '100%',
              border: '0px',
              position: 'absolute',
              background: 'none'
            },
            subText: null,
            valuesResonse: null
          }
        };
        break;
      case 'DateTimePicker':
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          defaultProperties: {
            align: null,
            checkboxLabel: null,
            configurable: false,
            controlName: null,
            controlType: null,
            disabled: false,
            editMode: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            groupName: null,
            mandatory: false,
            maxLength: null,
            minLength: null,
            placeholder: null,
            properties: null,
            questionCheck: false,
            questionText: null,
            readonly: false,
            styleResponse: {
              color: "#000",
              width: "95%",
              border: "0px",
              position: "absolute",
              background: "none"
            },
            subText: null,
            valuesResonse: {
              checkbox_val: false,
              textFieldVal: ""
            },
            fieldType: jsonObj.getFieldType,
            numberOfRow: null,
            numberOfColumn: null,
            data_field_name: jsonObj.getFullyQualifiedName,
            style: {
              color: "#000",
              border: "0px",
              position: "absolute",
              background: "none",
              width: '100%'
            },
            layoutCode: 'CustomDateTimePickerComponent',
            label: jsonObj.wholepartString,
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            coordinateX: 0,
            coordinateY: 0
          }
        };
        break;
      case 'Checkbox':
        properties = jsonObj.properties && jsonObj.properties.length > 0 ?
          JSON.parse(JSON.stringify(jsonObj.properties)).map(x => {
            return {
              name: typeof x === 'string' ? x : x && x.name ? x.name : null,
              isChecked: false,
              data_field_name: x.data_field_name ? x.data_field_name : this.uuid()
            };
          }) : [];
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          alignControl: 'left',
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          properties: JSON.parse(JSON.stringify(properties)),
          defaultProperties: {
            fieldType: jsonObj.getFieldType,
            numberOfRow: null,
            numberOfColumn: null,
            data_field_name: jsonObj.getFullyQualifiedName,
            alignControl: 'left',
            controlElementType: 'CHECKBOX',
            style: {
              color: "#000",
              border: "0px",
              position: "absolute",
              background: "none",
              width: '100%'
            },
            isConfigurable: false,
            isChecked: false,
            isDisabled: false,
            properties: {
              inputArray: JSON.parse(JSON.stringify(properties)),
              isDataFromApi: false,
              refercenceCodeForApiCall: ''
            },
            value: JSON.parse(JSON.stringify(properties)),
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            label: jsonObj.wholepartString,
            layoutCode: 'CustomCheckboxComponent',
            coordinateX: 0,
            coordinateY: 0,
            align: null,
            checkboxLabel: null,
            configurable: false,
            controlName: null,
            controlType: null,
            disabled: false,
            editMode: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            groupName: null,
            mandatory: false,
            maxLength: null,
            minLength: null,
            placeholder: null,
            questionCheck: false,
            questionText: null,
            readonly: false,
            styleResponse: { color: "#000", width: "95%", border: "0px", position: "absolute", background: "none" },
            subText: null,
            valuesResonse: { checkbox_val: false, textFieldVal: "" }
          }
        };
        break;
      case 'Radiobutton':
        properties = jsonObj.properties && jsonObj.properties.length > 0 ?
          JSON.parse(JSON.stringify(jsonObj.properties)).map(x => {
            return {
              name: typeof x === 'string' ? x : x && x.name ? x.name : null,
              data_field_name: x.data_field_name ? x.data_field_name : this.uuid()
            };
          }) : [];
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          alignControl: 'left',
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          properties: JSON.parse(JSON.stringify(properties)),
          groupName: this.uuid(),
          radioValue: null,
          defaultProperties: {
            fieldType: jsonObj.getFieldType,
            numberOfRow: null,
            numberOfColumn: null,
            data_field_name: jsonObj.getFullyQualifiedName,
            alignControl: 'left',
            style: {
              color: "#000",
              border: "0px",
              position: "absolute",
              background: "none",
              width: '100%'
            },
            isConfigurable: false,
            isChecked: false,
            isDisabled: false,
            properties: {
              inputArray: JSON.parse(JSON.stringify(properties)),
              isDataFromApi: false,
              refercenceCodeForApiCall: '',
              showDataSelection: true,
              showMultiSelectOption: false
            },
            value: JSON.parse(JSON.stringify(properties)),
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            label: jsonObj.wholepartString,
            coordinateX: 0,
            coordinateY: 0,
            align: null,
            checkboxLabel: null,
            configurable: false,
            disabled: false,
            editMode: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            mandatory: false,
            maxLength: null,
            minLength: null,
            placeholder: null,
            questionCheck: false,
            questionText: null,
            readonly: false,
            styleResponse: { color: "#000", width: "95%", border: "0px", position: "absolute", background: "none" },
            subText: null,
            valuesResonse: { checkbox_val: false, textFieldVal: "" },
            controlName: "Radio",
            layoutCode: "CustomRadioButtonComponent",
            controlType: "CUSTOM",
            controlElementType: "RADIOBTN",
            groupName: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`
          }
        };
        break;
      case 'CustomRadiobutton':
        properties = jsonObj.properties && jsonObj.properties.length > 0 ?
          JSON.parse(JSON.stringify(jsonObj.properties)).map(x => {
            return {
              name: typeof x === 'string' ? x : x && x.name ? x.name : null,
              data_field_name: x.data_field_name ? x.data_field_name : this.uuid()
            };
          }) : [];
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          alignControl: 'left',
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          properties: JSON.parse(JSON.stringify(properties)),
          groupName: this.uuid(),
          radioValue: null,
          defaultProperties: {
            fieldType: jsonObj.getFieldType,
            numberOfRow: null,
            numberOfColumn: null,
            data_field_name: jsonObj.getFullyQualifiedName,
            alignControl: 'left',
            style: {
              color: "#000",
              border: "0px",
              position: "absolute",
              background: "none",
              width: '100%'
            },
            isConfigurable: false,
            isChecked: false,
            isDisabled: false,
            properties: {
              inputArray: JSON.parse(JSON.stringify(properties)),
              isDataFromApi: false,
              refercenceCodeForApiCall: '',
              showDataSelection: true,
              showMultiSelectOption: false
            },
            value: JSON.parse(JSON.stringify(properties)),
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            label: jsonObj.wholepartString,
            coordinateX: 0,
            coordinateY: 0,
            align: null,
            checkboxLabel: null,
            configurable: false,
            disabled: false,
            editMode: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            mandatory: false,
            maxLength: null,
            minLength: null,
            placeholder: null,
            questionCheck: false,
            questionText: null,
            readonly: false,
            styleResponse: { color: "#000", width: "95%", border: "0px", position: "absolute", background: "none" },
            subText: null,
            valuesResonse: { checkbox_val: false, textFieldVal: "" },
            controlName: "CustRadio",
            layoutCode: "CustomFormRadioButtonComponent",
            controlType: "CUSTOM",
            controlElementType: "CUSTRADIOBTN",
            groupName: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`
          }
        };
        break;

      case 'partTitle':
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          defaultProperties: {
            data_field_name: jsonObj.getFullyQualifiedName,
            numberOfRow: null,
            numberOfColumn: null,
            fieldType: jsonObj.getFieldType,
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            coordinateX: 0,
            coordinateY: 0,
            align: "left",
            checkboxLabel: null,
            controlElementType: "GROUP",
            controlName: "VdLabelComponent",
            controlType: "CUSTOM",
            editMode: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            groupName: null,
            isChecked: false,
            isConfigurable: false,
            isDisabled: false,
            label: jsonObj.wholepartString,
            layoutCode: "VdLabelComponent",
            mandatory: false,
            maxLength: "10",
            minLength: "5",
            placeholder: "",
            properties: { title: jsonObj.wholepartString },
            questionCheck: false,
            questionText: null,
            readonly: false,
            style: { color: "#000000", width: "100%", fontWeight: "700", fontSize: "20px" },
            styleResponse: { color: "#000000", width: "100%", fontWeight: "700", fontSize: "20px" },
            subText: "sub Text",
            value: ""
          }
        }
        break;
      case 'subHeadingTitle':
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          defaultProperties: {
            data_field_name: jsonObj.getFullyQualifiedName,
            numberOfRow: null,
            numberOfColumn: null,
            fieldType: jsonObj.getFieldType,
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            coordinateX: 0,
            coordinateY: 0,
            align: "left",
            checkboxLabel: null,
            controlElementType: "GROUP",
            controlName: "VdLabelComponent",
            controlType: "CUSTOM",
            editMode: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            groupName: null,
            isChecked: false,
            isConfigurable: false,
            isDisabled: false,
            label: jsonObj.wholepartString,
            layoutCode: "VdLabelComponent",
            mandatory: false,
            maxLength: "10",
            minLength: "5",
            placeholder: "",
            properties: { title: jsonObj.wholepartString },
            questionCheck: false,
            questionText: null,
            readonly: false,
            style: { color: "#000000", width: "100%", fontWeight: "600", fontSize: "18px" },
            styleResponse: { color: "#000000", width: "100%", fontWeight: "600", fontSize: "18px" },
            subText: "sub Text",
            value: ""
          }
        };
        break;
      case 'elementHeading':
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          defaultProperties: {
            data_field_name: jsonObj.getFullyQualifiedName,
            numberOfRow: null,
            numberOfColumn: null,
            fieldType: jsonObj.getFieldType,
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            coordinateX: 0,
            coordinateY: 0,
            align: "left",
            checkboxLabel: null,
            controlElementType: "GROUP",
            controlName: "VdLabelComponent",
            controlType: "CUSTOM",
            editMode: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            groupName: null,
            isChecked: false,
            isConfigurable: false,
            isDisabled: false,
            label: jsonObj.wholepartString,
            layoutCode: "VdLabelComponent",
            mandatory: false,
            maxLength: "10",
            minLength: "5",
            placeholder: "",
            properties: { title: jsonObj.wholepartString },
            questionCheck: false,
            questionText: null,
            readonly: false,
            style: { color: "#000000", width: "100%", fontWeight: "normal" },
            styleResponse: { color: "#000000", width: "100%", fontWeight: "normal" },
            subText: "sub Text",
            value: ""
          }
        };
        break;
      case 'Questionnaire':
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          defaultProperties: {
            data_field_name: jsonObj.getFullyQualifiedName,
            numberOfRow: null,
            numberOfColumn: null,
            fieldType: jsonObj.getFieldType,
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            coordinateX: 0,
            coordinateY: 0,
            align: "left",
            checkboxLabel: null,
            editMode: false,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            groupElementProperty: null,
            groupName: null,
            isChecked: false,
            isConfigurable: false,
            isDisabled: false,
            label: jsonObj.wholepartString,
            mandatory: false,
            questionCheck: false,
            questionText: null,
            readonly: false,
            style: { color: "#000000", width: "100%", fontWeight: "normal" },
            styleResponse: { color: "#000000", width: "100%", fontWeight: "normal" },
            subText: null,
            controlName: "CustomQuestionsGroupComponent",
            layoutCode: "CustomQuestionsGroupComponent",
            controlType: "CUSTOM",
            controlElementType: "GROUP",
            minLength: null,
            maxLength: null,
            placeholder: null,
            value: null,
            properties: null
          }
        };
        break;
      case 'switchWithExp':
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          textAreaLabel: jsonObj.textAreaLabel && String(jsonObj.textAreaLabel).trim() !== '' && String(jsonObj.textAreaLabel).length > 0 ? jsonObj.textAreaLabel : 'Enter the text area label here',
          defaultProperties: {
            data_field_name: jsonObj.getFullyQualifiedName,
            numberOfRow: null,
            numberOfColumn: null,
            fieldType: jsonObj.getFieldType,
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            coordinateX: 0,
            coordinateY: 0,
            align: "left",
            checkboxLabel: null,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            isChecked: false,
            isDisabled: false,
            label: jsonObj.wholepartString,
            mandatory: false,
            questionText: null,
            readonly: false,
            style: { color: "#000000", width: "100%", border: "0px", background: "none", fontWeight: "normal" },
            styleResponse: { color: "#000000", width: "100%", border: "0px", background: "none", fontWeight: "normal" },
            subText: null,
            controlType: "CUSTOM",
            controlElementType: "GROUP",
            placeholder: null,
            value: null,
            properties: null,
            editMode: true,
            groupName: jsonObj.defaultProperties && jsonObj.defaultProperties.groupName ? jsonObj.defaultProperties.groupName : `SWITCH_WITH_EXPLANATION_${this.uuid()}`,
            maxLength: '10',
            minLength: '5',
            layoutCode: "VdSwitchGroupComponent",
            controlName: "VdSwitchGroupComponent",
            questionCheck: true,
            isConfigurable: true,
            groupElementProperty: {
              labelStyle: {
                width: "100%"
              },
              questionLabel: jsonObj.wholepartString,
              textAreaLabel: jsonObj.textAreaLabel && String(jsonObj.textAreaLabel).trim() !== '' && String(jsonObj.textAreaLabel).length > 0 ? jsonObj.textAreaLabel : 'Enter the text area label here',
              textAreaStyle: {
                width: "100%"
              },
              whenTextAreaShown: "on"
            }
          }
        };
        break;
      case 'textarea':
        returnObj = {
          ...jsonObj,
          elementTitle: jsonObj.wholepartString,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: jsonObj.getFullyQualifiedName,
          elementId: jsonObj.getFullyQualifiedName,
          disabled: false,
          defaultProperties: {
            data_field_name: jsonObj.getFullyQualifiedName,
            numberOfRow: null,
            numberOfColumn: null,
            fieldType: jsonObj.getFieldType,
            name: `${jsonObj.fileName}_${jsonObj.getFullyQualifiedName}`,
            coordinateX: 0,
            coordinateY: 0,
            align: "left",
            checkboxLabel: null,
            fieldConfiguration: null,
            gridProperties: null,
            gridValues: null,
            isChecked: false,
            isDisabled: false,
            label: jsonObj.wholepartString,
            mandatory: false,
            questionText: null,
            readonly: false,
            subText: null,
            controlType: "CUSTOM",
            placeholder: null,
            value: null,
            editMode: true,
            groupName: jsonObj.defaultProperties && jsonObj.defaultProperties.groupName ? jsonObj.defaultProperties.groupName : `TEXT_AREA_${this.uuid()}`,
            maxLength: null,
            minLength: null,
            style: {
              width: "100%"
            },
            styleResponse: { width: "100%" },
            layoutCode: "VdTextAreaComponent",
            properties: {
              textArealabel: jsonObj.wholepartString
            },
            controlName: "VdTextAreaComponent",
            questionCheck: false,
            isConfigurable: false,
            controlElementType: "TEXTAREA",
            groupElementProperty: null
          }
        };
        break;
      case 'countryState':
        const uuid = this.uuid();
        returnObj = {
          ...jsonObj,
          elementTitle: null,
          fieldType: jsonObj.getFieldType,
          checked: false,
          numberOfRow: null,
          numberOfColumn: null,
          data_field_name: uuid,
          elementId: uuid,
          disabled: false,
          defaultProperties: {
            data_field_name: uuid,
            numberOfRow: null,
            numberOfColumn: null,
            fieldType: jsonObj.getFieldType,
            coordinateX: 0,
            coordinateY: 0,
            checkboxLabel: null,
            fieldConfiguration: null,
            isChecked: false,
            isDisabled: false,
            label: null,
            mandatory: false,
            questionText: null,
            readonly: false,
            subText: null,
            placeholder: null,
            value: null,
            editMode: true,
            maxLength: '10',
            minLength: '5',
            style: {
              width: "100%"
            },
            styleResponse: { width: "100%" },
            questionCheck: false,
            isConfigurable: false,
            name: jsonObj.defaultProperties && jsonObj.defaultProperties.name ? jsonObj.defaultProperties.name : `VD_COUNTRY_STATE_${uuid}`,
            align: "top",
            hover: false,
            groupName: jsonObj.defaultProperties && jsonObj.defaultProperties.groupName ? jsonObj.defaultProperties.groupName : `VD_COUNTRY_STATE_${uuid}`,
            gridValues: [],
            layoutCode: "VdCountryStateComponent",
            properties: {
              showDataSelection: false,
              isDocumentTypeDropdown: false
            },
            inputArray: [
              { name: 'country', data_field_name: jsonObj.properties && jsonObj.properties.inputArray && jsonObj.properties.inputArray.length >= 2 && jsonObj.properties.inputArray[0].data_field_name ? jsonObj.properties.inputArray[0].data_field_name : this.uuid() },
              { name: 'state', data_field_name: jsonObj.properties && jsonObj.properties.inputArray && jsonObj.properties.inputArray.length >= 2 && jsonObj.properties.inputArray[1].data_field_name ? jsonObj.properties.inputArray[1].data_field_name : this.uuid() }
            ],
            controlName: "VdCountryStateComponent",
            controlType: "STANDARD",
            isRowColumn: false,
            gridProperties: [
              {
                columnDef: "colDef",
                columnName: "Column Name",
                columnType: "text",
                columnOption: "",
                hideFromList: false
              }
            ],
            controlElementType: "GROUP",
            groupElementProperty: {
              stateStyle: {
                width: "100%"
              },
              countryStyle: {
                width: "100%"
              }
            }
          }
        };
        break;
      // case 'countryState':
      //   {
      //     "group": null,
      //     "index": 16,
      //     "deleted": false,
      //     "defineAs": "element",
      //     "fileName": "i983",
      //     "isMainEdit": false,
      //     "isSelected": false,
      //     "partNumber": 0,
      //     "properties": null,
      //     "groupClosed": false,
      //     "partCompare": true,
      //     "getFieldType": "Sig",
      //     "wholepartString": "Signature of Student",
      //     "speedDialClicked": false,
      //     "dropDownValuesList": null,
      //     "getFullyQualifiedName": "Signature of Student",
      //     "groupElementsPlacement": null
      // }
      //   const uuid = this.uuid();
      //   returnObj = {
      //     ...jsonObj,
      //     elementTitle: null,
      //     fieldType: jsonObj.getFieldType,
      //     checked: false,
      //     numberOfRow: null,
      //     numberOfColumn: null,
      //     data_field_name: uuid,
      //     elementId: uuid,
      //     disabled: false,
      //     defaultProperties: {
      //       data_field_name: uuid,
      //       numberOfRow: null,
      //       numberOfColumn: null,
      //       fieldType: jsonObj.getFieldType,
      //       coordinateX: 0,
      //       coordinateY: 0,
      //       checkboxLabel: null,
      //       fieldConfiguration: null,
      //       isChecked: false,
      //       isDisabled: false,
      //       label: null,
      //       mandatory: false,
      //       questionText: null,
      //       readonly: false,
      //       subText: null,
      //       placeholder: null,
      //       value: null,
      //       editMode: true,
      //       maxLength: '10',
      //       minLength: '5',
      //       style: {
      //         width: "100%"
      //       },
      //       styleResponse: { width: "100%" },
      //       questionCheck: false,
      //       isConfigurable: false,
      //       name: jsonObj.defaultProperties && jsonObj.defaultProperties.name ? jsonObj.defaultProperties.name : `VD_COUNTRY_STATE_${uuid}`,
      //       align: "top",
      //       hover: false,
      //       groupName: jsonObj.defaultProperties && jsonObj.defaultProperties.groupName ? jsonObj.defaultProperties.groupName : `VD_COUNTRY_STATE_${uuid}`,
      //       gridValues: [],
      //       layoutCode: "VdCountryStateComponent",
      //       properties: {
      //         showDataSelection: false,
      //         isDocumentTypeDropdown: false
      //       },
      //       inputArray: [
      //         { name: 'country', data_field_name: jsonObj.properties && jsonObj.properties.inputArray && jsonObj.properties.inputArray.length >= 2 && jsonObj.properties.inputArray[0].data_field_name ? jsonObj.properties.inputArray[0].data_field_name : this.uuid() },
      //         { name: 'state', data_field_name: jsonObj.properties && jsonObj.properties.inputArray && jsonObj.properties.inputArray.length >= 2 && jsonObj.properties.inputArray[1].data_field_name ? jsonObj.properties.inputArray[1].data_field_name : this.uuid() }
      //       ],
      //       controlName: "VdCountryStateComponent",
      //       controlType: "STANDARD",
      //       isRowColumn: false,
      //       gridProperties: [
      //         {
      //           columnDef: "colDef",
      //           columnName: "Column Name",
      //           columnType: "text",
      //           columnOption: "",
      //           hideFromList: false
      //         }
      //       ],
      //       controlElementType: "GROUP",
      //       groupElementProperty: {
      //         stateStyle: {
      //           width: "100%"
      //         },
      //         countryStyle: {
      //           width: "100%"
      //         }
      //       }
      //     }
      //   };
      //   break;
      default:
        returnObj = {
          ...jsonObj
        };
        console.log(jsonObj.getFieldType);
        break;
    };
    return returnObj;
  }

}
