<div class="bene-quest-container" #mainBody>
    <div class="bene-quest-card" *ngIf="tabList"> 
        <!-- {{isCaseRequestStatus}}
        isViewMode :: {{isViewMode}} -->
        <div class="d-flex flex-column px-2">
            <div class="d-flex align-items-center justify-content-between">
                <button *ngIf="isCaseRequestStatus" class="btn btn-link align-self-start d-flex align-items-center px-0" type="button"
                    (click)="goBack()"><i class="fa fa-chevron-left"></i>Back</button>
                <button *ngIf="!isCaseRequestStatus" class="btn btn-link align-self-start d-flex align-items-center px-0" type="button"
                (click)="goBackCase()"><i class="fa fa-chevron-left"></i>Home</button>  
                <!-- <button [hidden]="!isCaseRequestStatus" *ngIf="!isShowSingleDiv || !hideSpecificDiv" class="btn btn-link align-self-start d-flex align-items-center px-0" type="button" (click)="goNextCase()">Next<i class="fa fa-chevron-right"></i></button>  -->
                <div *ngIf="isBenQuestionStatus;else other_content">
                </div>
                <ng-template #other_content>
                    <button [hidden]="!isCaseRequestStatus" class="btn btn-link align-self-start d-flex align-items-center px-0" type="button" (click)="goNextCase()">Next<i class="fa fa-chevron-right"></i></button> 
                </ng-template>

                <div class="d-flex align-items-center justify-content-between" *ngIf="isCaseRequestStatus && tabList?.attorneyJson?.accessTillDate">
                    <span [ngClass]="{'text-danger': isExpired}" *ngIf="isExpired">Expired</span>
                    <mat-icon [ngClass]="{'text-danger': isExpired}">date_range</mat-icon>
                    <span class="title-portion pr-2" [ngClass]="{'text-danger': isExpired}">Access Till Date </span><br>
                    <span class="access-date"
                        [ngClass]="{'text-danger': isExpired}"><b>{{tabList.attorneyJson.accessTillDate |
                            date}}</b></span>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between py-4"> 
                <h4 class="mb-0" *ngIf="route?.snapshot?.queryParams?.prop !== 'additional'">Profile Information -
                    {{tabList.beneficiary.fName}} {{tabList.beneficiary.lName}}</h4>
                <h4 class="mb-0" *ngIf="route?.snapshot?.queryParams?.prop === 'additional'">Petitioner Information -
                    {{tabList.beneficiary.fName}} {{tabList.beneficiary.lName}}</h4>

                <ng-container *ngIf="!isExternalEmployeeId">  
                <div
                    *ngIf="route?.snapshot?.queryParams?.prop !== 'additional' && route?.snapshot?.queryParams?.prop !== 'CHILD' && route?.snapshot?.queryParams?.prop !== 'BROTHER' && route?.snapshot?.queryParams?.prop !== 'SISTER' && isCaseRequestStatus">
                    <p class="mb-0">Progress (By Sections) <span>{{completedSteps}} of {{dynamicValue?.length}}</span>
                    </p>
                    <div class="progress" style="height: 10px;">
                        <div class="progress-bar bg-success progress-bar-animated"
                            [ngClass]="{'progress-bar-striped':completedSteps !== dynamicValue?.length}"
                            role="progressbar" aria-label="Animated striped example" aria-valuenow="0" aria-valuemin="0"
                            aria-valuemax="100" [style]="'width: '+(completedSteps / dynamicValue?.length)*100+'%'">
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="route?.snapshot?.queryParams?.prop === 'CHILD' || route?.snapshot?.queryParams?.prop === 'BROTHER' || route?.snapshot?.queryParams?.prop === 'SISTER' && isCaseRequestStatus">
                    <p class="mb-0">Progress (By Sections) <span>{{completedSteps}} of
                            {{((extraFamMemDynamicValue?.length+1)*dynamicValue?.length)}}</span>
                    </p>
                    <div class="progress" style="height: 10px;">
                        <div class="progress-bar bg-success progress-bar-animated"
                            [ngClass]="{'progress-bar-striped':completedSteps !== dynamicValue?.length}"
                            role="progressbar" aria-label="Animated striped example" aria-valuenow="0" aria-valuemin="0"
                            aria-valuemax="100"
                            [style]="'width: '+(completedSteps / ((extraFamMemDynamicValue?.length+1)*dynamicValue?.length))*100+'%'">
                        </div>
                    </div>
                </div>
                <div *ngIf="route?.snapshot?.queryParams?.prop === 'additional' && isCaseRequestStatus">
                    <p class="mb-0">Progress (By Sections) <span>{{additionalCompletedSteps}} of
                            {{additionalPetitionInfo?.length}}</span></p>
                    <div class="progress" style="height: 10px;">
                        <div class="progress-bar bg-success progress-bar-animated"
                            [ngClass]="{'progress-bar-striped':additionalCompletedSteps !== dynamicValue?.length}"
                            role="progressbar" aria-label="Animated striped example" aria-valuenow="0" aria-valuemin="0"
                            aria-valuemax="100"
                            [style]="'width: '+(additionalCompletedSteps / additionalPetitionInfo?.length)*100+'%'">
                        </div>
                    </div>
                </div>
                </ng-container>

                
            <!-- <ng-container *ngIf="isExternalEmployeeId">
                <div>
                <button class="btn btn-primary btn-sm">Download</button> &nbsp;
                <button class="btn btn-add im-btn-outline-primary btn-sm">Next</button>
                </div>
            </ng-container> -->
            </div>
        </div>

        <!-- <div class="main-body" #mainBody> -->
        <div class="row border-top border-secondary">
            <div class="border-right border-secondary" *ngIf="route?.snapshot?.queryParams?.prop !== 'additional'">
                <div class="im-card-tab-menu">
                    <div class="im-list-group leftSidebar"
                        *ngIf="route?.snapshot?.queryParams?.prop !== 'CHILD' && route?.snapshot?.queryParams?.prop !== 'BROTHER' && route?.snapshot?.queryParams?.prop !== 'SISTER'">
                        <div *ngFor="let item of dynamicValue; let i = index"
                            [ngClass]="{'div-disabled': selectedDetailAbout?.showDetailFor !== 'self' &&  disableBtn &&  item.referenceLibrarysubSection.code !== 'QTPROFILESSPERS'}">
                            <div class="list-group-item" *ngIf="item.selected"
                                (click)="handleLeftPanelSelection(item, item.referenceLibrarysubSection.isLastTab, beneficiaryFamilyInfoGrouped[route?.snapshot?.queryParams?.prop]&&beneficiaryFamilyInfoGrouped[route?.snapshot?.queryParams?.prop].length>0?beneficiaryFamilyInfoGrouped[route?.snapshot?.queryParams?.prop][0]:null, i, null)"
                                [ngClass]="{'active-menu-item': selectedItem === item.referenceLibrarysubSection.code , 'inActiveItem':  selectedItem !== item.referenceLibrarysubSection.code, 'completed-menu-item': item.markComplete === true}">
                                <span>{{item.referenceLibrarysubSection.name}} <i
                                        class="pi pi-check-circle ml-2 text-success" *ngIf="item.markComplete"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="im-list-group leftSidebar"
                        *ngIf="beneficiaryFamilyInfoGrouped && route?.snapshot?.queryParams?.prop === 'CHILD' || beneficiaryFamilyInfoGrouped && route?.snapshot?.queryParams?.prop === 'SISTER' || beneficiaryFamilyInfoGrouped && route?.snapshot?.queryParams?.prop === 'BROTHER'">
                        <p-accordion (onOpen)="onTabOpen($event)">
                            <ng-container
                                *ngFor="let famInfo of beneficiaryFamilyInfoGrouped[route?.snapshot?.queryParams?.prop]; let j = index">
                                <p-accordionTab [header]="route?.snapshot?.queryParams?.prop + ' ' +  (j+1)" *ngIf="j===0"
                                    [selected]="selectedChild == j">
                                    <h4 class="childheading"></h4>
                                    <div *ngFor="let item of dynamicValue; let i = index"
                                        [ngClass]="{'div-disabled': selectedDetailAbout?.showDetailFor !== 'self' &&  disableBtn &&  item.referenceLibrarysubSection.code !== 'QTPROFILESSPERS'}">
                                        <div class="list-group-item" *ngIf="item.selected"
                                            (click)="handleLeftPanelSelection(item, item.referenceLibrarysubSection.isLastTab,famInfo, i, j)"
                                            [ngClass]="{'active-menu-item': (selectedItem === item.referenceLibrarysubSection.code && selectedChild == j), 'inActiveItem':  selectedItem !== item.referenceLibrarysubSection.code, 'completed-menu-item': item.markComplete }">
                                            <span>{{item.referenceLibrarysubSection.name}} <i
                                                    class="pi pi-check-circle ml-2 text-success"
                                                    *ngIf="item.markComplete"></i></span>
                                        </div>
                                    </div>
                                </p-accordionTab>
                            </ng-container>
                            <ng-container *ngFor="let famInfo of extraFamMemDynamicValue; let x = index">
                                <p-accordionTab
                                    *ngIf="famInfo.refLibFamilySection.code === route?.snapshot?.queryParams?.prop"
                                    [selected]="selectedChild == x+1">
                                    <ng-template pTemplate="header">
                                        {{route?.snapshot?.queryParams?.prop + ' ' + (2+x)}}
                                        <i class="pi pi-times ml-2 text-danger" *ngIf="famInfo.tempId>0"
                                            (click)="removeEtraFammember(famInfo?.tempId)"></i>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <ng-container *ngFor="let item of famInfo.qfamilySubSectionList let g = index">
                                            <div [ngClass]="{'div-disabled': famInfo.tempId>0 &&  item.referenceLibrarysubSection.code
                                        !== 'QTPROFILESSPERS'}">
                                                <div class="list-group-item" *ngIf="item.selected"
                                                    (click)="handleLeftPanelSelection(item, item.referenceLibrarysubSection.isLastTab,beneficiaryFamilyInfoGrouped[route?.snapshot?.queryParams?.prop][x+1], g, x+1)"
                                                    [ngClass]="{'active-menu-item': (famInfo.qfamilySubSectionList[g].referenceLibrarysubSection.code === item.referenceLibrarysubSection.code && selectedSectionIndex == g), 'inActiveItem':  selectedItem !== item.referenceLibrarysubSection.code, 'completed-menu-item': item.markComplete }">
                                                    <span>{{item.referenceLibrarysubSection.name}} <i
                                                            class="pi pi-check-circle ml-2 text-success"
                                                            *ngIf="item.markComplete"></i></span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </p-accordionTab>
                            </ng-container>
                        </p-accordion>
                        <button
                            [disabled]="extraFamMemDynamicValue[extraFamMemDynamicValue.length-1]?.tempId == extraFamMemDynamicValue.length"
                            *ngIf="isEditMode"
                            class="btn btn-success-outline btn-sm my-1 d-flex align-items-center justify-content-end pr-2"
                            (click)="onAddButton(route?.snapshot?.queryParams?.prop)">
                            <i class="pi pi-plus mr-1 text-success"></i> Add
                            {{route?.snapshot?.queryParams?.prop==='CHILD'?'Child':route?.snapshot?.queryParams?.prop==='BROTHER'?'Brother':'Sister'}}
                        </button>
                    </div>
                    <!-- <div class="im-list-group leftSidebar"
                        *ngIf="beneficiaryFamilyInfoGrouped && route?.snapshot?.queryParams?.prop === 'SISTER'">
                        <p-accordion (onOpen)="onTabOpen($event)">
                            <p-accordionTab [header]="route?.snapshot?.queryParams?.prop + ' ' +  (j+1) "
                                *ngFor="let famInfo of beneficiaryFamilyInfoGrouped[route?.snapshot?.queryParams?.prop]; let j = index"
                                [selected]="selectedChild == j">
                                <h4 class="childheading"></h4>
                                <div *ngFor="let item of dynamicValue; let i = index"
                                    [ngClass]="{'div-disabled': disableBtn &&  item.referenceLibrarysubSection.code !== 'QTPROFILESSPERS'}">
                                    <div class="list-group-item" *ngIf="item.selected"
                                        (click)="handleLeftPanelSelection(item, item.referenceLibrarysubSection.isLastTab,famInfo, i, j)"
                                        [ngClass]="{'active-menu-item': (selectedItem === item.referenceLibrarysubSection.code && selectedChild == j), 'inActiveItem':  selectedItem !== item.referenceLibrarysubSection.code, 'completed-menu-item': item.markComplete}">
                                        <span>{{item.referenceLibrarysubSection.name}}</span>
                                    </div>
                                </div>
                            </p-accordionTab>
                        </p-accordion>
                        <button [disabled]="!isEditMode" class="addDependentBtn last"
                            (click)="onAddButton(route?.snapshot?.queryParams?.prop)">Add
                            Sister</button>
                    </div>
                    <div class="im-list-group leftSidebar"
                        *ngIf="beneficiaryFamilyInfoGrouped && route?.snapshot?.queryParams?.prop === 'BROTHER'">
                        <p-accordion (onOpen)="onTabOpen($event)">
                            <p-accordionTab [header]="route?.snapshot?.queryParams?.prop + ' ' +  (j+1) "
                                *ngFor="let famInfo of beneficiaryFamilyInfoGrouped[route?.snapshot?.queryParams?.prop]; let j = index"
                                [selected]="selectedChild == j">
                                <h4 class="childheading"></h4>
                                <div *ngFor="let item of dynamicValue; let i = index"
                                    [ngClass]="{'div-disabled': disableBtn &&  item.referenceLibrarysubSection.code !== 'QTPROFILESSPERS'}">
                                    <div class="list-group-item" *ngIf="item.selected"
                                        (click)="handleLeftPanelSelection(item, item.referenceLibrarysubSection.isLastTab,famInfo, i, j)"
                                        [ngClass]="{'active-menu-item': (selectedItem === item.referenceLibrarysubSection.code && selectedChild == j), 'inActiveItem':  selectedItem !== item.referenceLibrarysubSection.code, 'completed-menu-item': item.markComplete }">
                                        <span>{{item.referenceLibrarysubSection.name}}</span>
                                    </div>
                                </div>
                            </p-accordionTab>
                        </p-accordion>
                        <button [disabled]="!isEditMode" class="addDependentBtn last"
                            (click)="onAddButton(route?.snapshot?.queryParams?.prop)">Add
                            Brother</button>
                    </div> -->
                </div>
            </div>

            <ng-container *ngIf="route?.snapshot?.queryParams?.prop === 'additional'" class="additionalMenu">
                <div *ngFor="let petitionStep of additionalPetitionInfo; let i = index"
                    class="additionalMenu col-sm-8 mx-0 my-4">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                        <h1>{{i+1}}</h1>
                        <div class="list-group-item w-100 d-flex align-items-center justify-content-between"
                            (click)="handleStepClick(petitionStep)"
                            [ngClass]="{'completed-menu-item':petitionStep.stepStatusDetails?.stepStatusCode === 'SUBMIT', 'active-menu-item':petitionStep.stepStatusDetails?.stepStatusCode === 'INPROGRESS', 'disableBeneficiaryAction':  petitionStep.isBeneficiaryEditable === 0}" *ngIf="isCaseRequestStatus">
                            <span>{{petitionStep.stepName}} </span>
                            <!-- hi normal step -->
                            <i class="pi pi-check-circle ml-2 text-success"
                                *ngIf="petitionStep.stepStatusDetails?.stepStatusCode === 'SUBMIT'"></i>
                        </div>
                        <div class="list-group-item w-100 d-flex align-items-center justify-content-between"
                            (click)="handleStepClick(petitionStep)"
                            [ngClass]="{'completed-menu-item':petitionStep.stepStatusDetails?.stepStatusCode === 'COMPLETE', 'active-menu-item':petitionStep.stepStatusDetails?.stepStatusCode === 'INPROGRESS', 'disableBeneficiaryAction':  petitionStep.isPetitionerEditable === 0}" [hidden]="petitionStep.isPetitionerVisible === 0" *ngIf="!isCaseRequestStatus"> 
                            <span>{{petitionStep.stepName}}</span>
                            <!-- hi Case Request Step -->
                            <!-- <i class="pi pi-check-circle ml-2 text-success"
                                *ngIf="petitionStep.stepStatusDetails?.stepStatusCode === 'COMPLETE'"></i> -->
                        </div>
                    </div>
                </div>
                <!-- <div *ngFor="let petitionStep of selectedSubTask.customStepOverviewList; let i = index"
                    class="additionalMenu col-sm-8 mx-0 my-4">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                        <h1>{{i+1}}</h1>
                        <div class="list-group-item w-100 d-flex align-items-center justify-content-between"
                            (click)="handleStepClick(petitionStep)"
                            [ngClass]="{'completed-menu-item':petitionStep.stepStatusDetails?.stepStatusCode === 'SUBMIT', 'active-menu-item':petitionStep.stepStatusDetails?.stepStatusCode === 'INPROGRESS', 'disableBeneficiaryAction':  petitionStep.isBeneficiaryEditable === 0}">
                            <span>{{petitionStep.stepName}}</span>
                            <i class="pi pi-check-circle ml-2 text-success"
                                *ngIf="petitionStep.stepStatusDetails?.stepStatusCode === 'SUBMIT'"></i>
                        </div>
                    </div>
                </div> -->
                <!-- <app-additional-information></app-additional-information> -->
            </ng-container>

            <div class="col px-3" *ngIf="route?.snapshot?.queryParams?.prop !== 'additional'">
                <div [ngSwitch]="selectedItem">
                    <div *ngSwitchCase="'QTPROFILESSPERS'">
                        <ng-container *ngIf="!isExternalEmployeeId">
                        <div *ngIf="selectedDetailAbout?.showDetailFor !== 'self' && !hasFamily">
                            <marquee style="padding: 20px 0px 0px 15px;font-weight: 600;color: crimson;"
                                class="personal-css">Personal details need to be completed & saved, to continue with the
                                addition of Education, Experience & Immigration details</marquee>
                        </div>
                       </ng-container>
                        <h5 class="tab-header">Personal Details</h5>
                        <!-- {{selectedDetailAbout.showDetailFor}} -->
                        <!-- <app-personal-details (profileEditStatus)="onEditClicked($event)" (scrollTop)="onScrollTop()"
                            [detailsAbout]="selectedDetailAbout" [editMode]="isEditMode"
                            (hasFamily)="checkSub($event)" [customQuestionsDisplay]="customQuestionnaieDisplay"></app-personal-details> -->
                            <!-- <app-personal-details (profileEditStatus)="onEditClicked($event)" (scrollTop)="onScrollTop()"
                            [detailsAbout]="selectedDetailAbout" [editMode]="isEditMode" 
                            (hasFamily)="checkSub($event)" [customQuestionsDisplay]="customQuestionnaieDisplay"></app-personal-details> -->
                            <app-personal-details (profileEditStatus)="onEditClicked($event)" (scrollTop)="onScrollTop()"
                            [detailsAbout]="selectedDetailAbout" [editMode]="isEditMode"  [isViewMode]="isViewMode"
                            (hasFamily)="checkSub($event)" [customQuestionsDisplay]="customQuestionnaieDisplay" [caseRequestShowDivs]="!isCaseRequestStatus ? selectedSectionData: null" [benQuestShowDivs]="isBenQuestionElemStatus ? selectedSectionData: null"></app-personal-details> 
                            <mat-card *ngIf="(isEditMode && familyId)  || selectedDetailAbout?.showDetailFor == 'self' ">
                                <ng-container *ngIf="!isExternalEmployeeId">
                                <div class="mt-2 mb-2 mr-2 ml-2 row" *ngIf="!isCaseRequestStatus ? (currentItemDisplay.includes('PASPORTDTLS') ? true: false) : true">
                                    <p class="lbl-text-2">Add the Photocopy of your passport</p>
                                    <app-file-upload style="width: 100%;" [configurations]="fileUploadConfigurations" >
                                    </app-file-upload>
                                </div>
                                </ng-container>
                            </mat-card>
                     
                    </div>
                    <div *ngSwitchCase="'QTPROFILESSEDUC'">
                        <p-tabView class="ques-tabs" >
                            <p-tabPanel header="Education">
                                <h5 class="tab-header">Education Details</h5>
                                <app-add-degree [mode]="'list'" [showFileUploadSection]="showFileUploadSection" [isViewMode]="isViewMode" [caseRequestShowDivs]="!isCaseRequestStatus ? selectedSectionData: null" [benQuestShowDivs]="isBenQuestionElemStatus ? selectedSectionData: null"
                                    (scrollTop)="onScrollTop()"></app-add-degree>
                            </p-tabPanel>
                            <p-tabPanel header="Trainings">
                                <h5 class="tab-header">Trainings</h5>
                                <app-beneficiary-training [mode]="'list'"  [isViewMode]="isViewMode"
                                    [showLifeStoryTimeLineSection]="showFileUploadSection"
                                    (scrollTop)="onScrollTop()" [caseRequestShowDivs]="!isCaseRequestStatus ? selectedSectionData: null" [benQuestShowDivs]="isBenQuestionElemStatus ? selectedSectionData: null"></app-beneficiary-training>
                            </p-tabPanel>
                            <p-tabPanel header="Licenses or Certifications">
                                <h5 class="tab-header">Licenses or Certifications</h5>
                                <app-beneficiary-license [mode]="'list'" [isViewMode]="isViewMode"
                                    [showLifeStoryTimeLineSection]="showFileUploadSection"
                                    (scrollTop)="onScrollTop()" [caseRequestShowDivs]="!isCaseRequestStatus ? selectedSectionData: null" [benQuestShowDivs]="isBenQuestionElemStatus ? selectedSectionData: null"></app-beneficiary-license>
                            </p-tabPanel>
                        </p-tabView>
                        <!-- <h5 class="tab-header">Education Details</h5>
                        <app-add-degree [mode]="'list'" [showFileUploadSection]="showFileUploadSection"
                            (scrollTop)="onScrollTop()"></app-add-degree> -->
                    </div>
                    <div *ngSwitchCase="'QTPROFILESSWRKEXP'" > 
                        <h5 class="tab-header">Work Experience Details</h5>
                        <app-work-experience [mode]="'list'"  [isViewMode]="isViewMode"
                            [showLifeStoryTimeLineSection]="showLifeStoryTimeLineSection" (scrollTop)="onScrollTop()" [caseRequestShowDivs]="!isCaseRequestStatus ? selectedSectionData: null" [benQuestShowDivs]="isBenQuestionElemStatus ? selectedSectionData: null">
                        </app-work-experience>
                    </div>
                    <div *ngSwitchCase="'QTPROFILESSIMM'">
                        <h5 class="tab-header">Immigration Details</h5>
                        <beneficiary-immigration [mode]="'list'" [isViewMode]="isViewMode"
                            [showLifeStoryTimeLineSection]="showLifeStoryTimeLineSection"
                            [caseRequestShowDivs]="!isCaseRequestStatus ? selectedSectionData: null" (scrollTop)="onScrollTop()" [benQuestShowDivs]="isBenQuestionElemStatus ? selectedSectionData: null">
                        </beneficiary-immigration>
                    </div>
                    <div *ngSwitchCase="'DOCUMENTS_CHECKLIST'" >
                        <h5 class="tab-header">Document Checklist</h5>
                        <app-document-list (removeElement)="deleteDocumentHandler($event)" (scrollTop)="onScrollTop()" [isViewMode]="isViewMode">
                        </app-document-list>
                    </div>
                </div>
                
                <div *ngIf="showFileUploadSection">
                    <p-accordion>
                        <div *ngIf="selectedDetailAbout?.showDetailFor == 'self'">
                        <div *ngFor="let ques of customQuestionnaieDisplay?.customSectionList">
                            <div class="customSections" *ngFor="let item of ques?.stakeHoldersSelected">
                                <p-accordionTab *ngIf="(item?.key == 'QTPROFILEBEN') && item?.selected == true" [header]="ques.name" [selected]="false" [hidden]="ques?.isBenHidden">
                                    <app-custom-questions 
                                    [isNewPortion]="true" 
                                    [viewQuestions]="false" 
                                    [loadedIn]="ques?.name" 
                                    [beneficiaryId]="beneficiaryId" 
                                    [isSection]="true"
                                    [familyId]="fileUploadConfigurations?.familyId"
                                    (refreshList)="onRefreshPage($event)"
                                    ></app-custom-questions>
                                </p-accordionTab>
                            </div>
                        </div>
                        </div>
                        <div *ngIf="selectedDetailAbout?.showDetailFor !== 'self' && fileUploadConfigurations?.familyId">
                            <div *ngFor="let ques of customQuestionnaieDisplay?.customSectionList">
                                <div class="customSections" *ngFor="let item of ques?.stakeHoldersSelected">
                                    <p-accordionTab *ngIf="(selectedDetailAbout?.dependentType.code == item?.key) && item?.selected == true" [header]="ques.name" [selected]="false" [hidden]="ques?.isBenHidden">
                                        <app-custom-questions 
                                        [isNewPortion]="true" 
                                        [viewQuestions]="false" 
                                        [loadedIn]="ques?.name" 
                                        [beneficiaryId]="beneficiaryId" 
                                        [isSection]="true"
                                        [familyId]="fileUploadConfigurations?.familyId"
                                        (refreshList)="onRefreshPage($event)"
                                        ></app-custom-questions>
                                    </p-accordionTab>
                                </div>
                            </div>
                        </div>
                    </p-accordion>
                </div>
                <div class="mt-3" *ngIf="(!showFileUploadSection && selectedDetailAbout?.showDetailFor == 'self')">
                    <mat-card>
                      <mat-card-header class="customHeader">
                        <mat-card-title>
                          <p>Personal details need to be completed & saved, to continue with the addition of
                            custom section details</p>
                        </mat-card-title>
                      </mat-card-header>
                    </mat-card>
                </div>
                <div class="mt-3" *ngIf="(!fileUploadConfigurations?.familyId && selectedDetailAbout?.showDetailFor !== 'self')">
                <mat-card>
                    <mat-card-header class="customHeader">
                    <mat-card-title>
                        <p>Personal details need to be completed & saved, to continue with the addition of
                        custom section details</p>
                    </mat-card-title>
                    </mat-card-header>
                </mat-card>
                </div>
            </div> 

        </div>

        <p-divider></p-divider>
        <div class="d-flex align-items-center justify-content-end px-4" *ngIf="!isExternalEmployeeId && !isCaseRequestStatus">
            <button *ngIf="!isCaseRequestStatus" type="button" class="btn btn-primary" (click)="goBackCase()">Close</button> 
        </div>
        <div class="d-flex align-items-center justify-content-end px-4" *ngIf="!isExternalEmployeeId && isCaseRequestStatus">
            <button *ngIf="isCaseRequestStatus" type="button" class="btn btn-light" [routerLink]="['/beneficiary-profile']"
                routerLinkActive="beneficiary-profile">
                Cancel
            </button>
            <button *ngIf="!isCaseRequestStatus" type="button" class="btn btn-light" (click)="goBackCase()">Cancel</button> 
            <button
                *ngIf="!isExpired && route?.snapshot?.queryParams?.prop !== 'additional' && !selectedSectionData?.markComplete"
                type="button" (click)="markcomplete()"
                class="ml-2 btn btn-success d-flex align-items-center justify-content-between">
                Mark Complete
            </button>
            <span *ngIf="route?.snapshot?.queryParams?.prop !== 'additional' && selectedSectionData?.markComplete"
                class="ml-2 text-success d-flex align-items-center justify-content-between stepCompleted"
                (click)="markcomplete()">
                Marked as Completed <i class="pi pi-check ml-2"></i>
            </span>
            <button
                *ngIf="!isExpired && route?.snapshot?.queryParams?.prop === 'additional' && !tabList.attorneyJson.additionalPetitionInfoCompleted"
                type="button" (click)="markcomplete()"
                class="ml-2 btn btn-success d-flex align-items-center justify-content-between">
                Mark Complete
            </button>
            <span
                *ngIf="route?.snapshot?.queryParams?.prop === 'additional' && tabList.attorneyJson.additionalPetitionInfoCompleted"
                class="ml-2 text-success d-flex align-items-center justify-content-between stepCompleted"
                (click)="markcomplete()">
                Marked as Completed <i class="pi pi-check ml-2"></i>
            </span>
        </div>
    </div>
</div>