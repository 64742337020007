import { Component, Input, Output, EventEmitter, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeNode } from 'app-models';

@Component({
  selector: 'app-tree-select',
  templateUrl: './TreeSelect.component.html',
  styleUrls: ['./TreeSelect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeSelectComponent),
      multi: true
    }
  ]
})
export class TreeSelectComponent implements ControlValueAccessor, OnChanges {
  @Input() treeData: TreeNode[] = [];
  @Input() disabled: boolean = false;
  // @Output() selectionChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  selectedValue: number[];
  selectedLabel: any;
  //dropdownVisible = false;
  onChange: any = () => { };
  onTouched: any = () => { };
  // valueLabelMap: { [key: string]: any } = {};

  constructor() { }

  writeValue(value: number[]): void {
    this.selectedValue = value;
    this.waitForTreeData();
  }

  waitForTreeData() {
    if (this.treeData?.length > 0 && this.selectedValue) {
      this.setSelectedValue(this.treeData);
      // this.selectedLabel = this.getLabelByValue(this.selectedValue);
      // if (!this.selectedLabel) {
      //   this.selectedValue = null;
      // }
    }
  }

  setSelectedValue(treeData: TreeNode[]) {
    treeData.forEach(node => {
      if (node.children) {
        node.selected = false;
        this.setSelectedValue(node.children);
      } else {
        const selected = this.selectedValue.includes(node.value);
        node.selected = selected;
        if (selected) {
          node.disabled = false;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.treeData && this.treeData?.length > 0) {
      // this.valueLabelMap = {};
      // this.createValueLabelMap(this.treeData);
      this.waitForTreeData();
    }
  }

  // private createValueLabelMap(nodes: TreeNode[]): void {
  //   nodes.forEach(node => {
  //     this.valueLabelMap[node.value] = node.label;
  //     if (node.children) {
  //       this.createValueLabelMap(node.children);
  //     }
  //   });
  // }

  // private getLabelByValue(value: any): string | undefined {
  //   return this.valueLabelMap[value];
  // }

  // private getLabelByValue(value: any) {
  //   let returnValue = null;
  //   const findLabel = (nodes: TreeNode[]) => {
  //     for (const node of (nodes || [])) {
  //       if (node.value === value) {
  //         returnValue = node.label;
  //         break;
  //       }
  //       if (node.children && node.children.length > 0) {
  //         findLabel(node.children);
  //         if (returnValue) {
  //           break;
  //         }
  //       }
  //     }
  //   };
  //   findLabel(this.treeData);
  //   return returnValue;
  // }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    // if (isDisabled) {
    // //  this.dropdownVisible = false;
    //   this.treeData.forEach((node: TreeNode) => {
    //     this.onCollapseNode(node);
    //   });
    // }
  }

  getSelectedValues(nodes: TreeNode[]): any[] {
    let selectedValues: any[] = [];
    for (const node of nodes) {
      if (node.children?.length) {
        selectedValues = selectedValues.concat(this.getSelectedValues(node.children));
      } else if (node.selected) {
        selectedValues.push(node.value);
      }
    }
    return selectedValues;
  };

  onNodeSelect(node: TreeNode) {
    // event.stopPropagation();
    if (!node.disabled) {
      const selectedValues = this.getSelectedValues(this.treeData);
      this.onChange(selectedValues); // Emit selected values
      // this.selectionChange.emit(selectedValues);
    }
    // if (!node.disabled) {
    //   if (!node.children) {
    //     this.selectedValue = node.value;
    //     this.selectedLabel = node.label;
    //     this.onChange(this.selectedValue);
    //     this.selectionChange.emit(node.customObj);
    //    // this.dropdownVisible = false;
    //   }
    //   // else {
    //   //   node.showChildren = !node.showChildren;
    //   //   if (!node.showChildren) {
    //   //     this.onCollapseNode(node);
    //   //   }
    //   //   // this.checkViewPort();
    //   // }
    // }
  }

  // toggleDropdown() {
  //   if (!this.disabled) {
  //     this.dropdownVisible = !this.dropdownVisible;
  //     if (!this.dropdownVisible) {
  //       this.treeData.forEach((node: TreeNode) => {
  //         this.onCollapseNode(node);
  //       });
  //     }
  //   }
  //   // this.checkViewPort();
  // }

  // onCollapseNode(node: TreeNode) {
  //   node.showChildren = false;
  //   if (node.children && node.children.length > 0) {
  //     node.children.forEach((child: TreeNode) => {
  //       this.onCollapseNode(child);
  //     });
  //   }
  // }

  // @HostListener('document:click', ['$event.target'])
  // onClickOutside(target: any) {
  //   const clickedInside = this.elementRef.nativeElement.contains(target);
  //   if (!clickedInside) {
  //    this.dropdownVisible = false;
  //     if (this.treeData) {
  //       this.treeData.forEach((node: TreeNode) => {
  //         this.onCollapseNode(node);
  //       });
  //     }
  //   }
  // }

  // checkViewPort() {
  //   if (this.dropdownVisible) {
  //     // Check if the dropdown is partially or completely outside the viewport
  //     const dropdownElement = this.elementRef.nativeElement.querySelector('.dropdown-list');
  //     const dropdownRect = dropdownElement.getBoundingClientRect();
  //     const viewportHeight = window.innerHeight;

  //     if (dropdownRect.bottom > viewportHeight) {
  //       // Dropdown is partially or completely below the viewport, adjust its position
  //       const maxHeight = viewportHeight - dropdownRect.top - 10; // Leave some margin
  //       dropdownElement.style.maxHeight = maxHeight + 'px';
  //     }
  //   }
  // }


}
