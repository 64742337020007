const urls = {
  dev5: 'http://18.215.237.95',
  qa9: 'https://qa9.imagility.co',
  dev9: 'http://50.19.45.219',
  qa7: 'http://34.235.141.147',
  uat4: 'http://44.210.134.224',
  dev3url: 'http://3.81.207.168',
  dev3: 'http://34.228.186.101',
  prod: 'https://pl.imagility.co',
  dev8: 'http://35.172.253.220',
  qa3: 'http://34.202.145.234',
  uat2: 'http://52.204.59.234',
  dev2: 'http://52.54.81.36',
  qa4: 'http://44.199.163.55',
  qa6: 'http://18.215.173.111',
  qa6_https: 'https://qa6.imagility.co',
  qa6_5600: 'http://18.208.188.1',
  dev2_5659: 'http://35.175.248.1',
  staging: 'https://stag.mytestsdummy.co.in',
  dev1: 'http://3.88.240.163',
  dev7: 'http://18.215.173.111',
  host: 'window.location.host',
  origin: 'window.location.origin',
  alternativeSatging: 'https://qa9.mytestsdummy.co.in',
  qa8: 'http://34.195.44.137',
  uat1: 'http://35.171.5.20',
  staging_fb: 'http://3.238.244.166',
  dev11: 'http://3.239.125.215'
  // Add more URLs as needed
};

const selectedUrl = 'dev1';  

// Change this to select the desired URL

const port = '5651';  // efiling

export const environment = {
  production: false,
  baseUrl: `${urls[selectedUrl]}:${port}/api/v1`,
  docs: `${urls[selectedUrl]}:${port}/docs/`,
  appUrl: urls[selectedUrl],
  affiniPayUrl: 'https://secure.affinipay.com/oauth/authorize',
  affiniPayClientId: 'f8cae1757af41b4b99f3a0c7619daa8080359ad322227d90df3f2961b4e7a58b',
  secretKey: '7e5da343be9b93e0e31fe670ec1638c1bbc9b648baab484fbfb338562ce5a1fa',
  gmailCompany: [239, 240]
};


