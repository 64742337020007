import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as RolesRouteMap from 'src/app/data/constants/roles';
import { Router } from '@angular/router';
import * as errorUtils from 'src/app/modules/utility/global-utils';
import { Store } from '@ngrx/store';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { ToastrService } from 'ngx-toastr';
import { concatMap, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { ConfirmationService, FilterService } from "primeng/api";
import { AttorneyService } from 'src/app/services/attorney.service';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import { USER_PERMISSIONS } from 'permission-library';
import { LookupService } from 'src/app/services/lookup-data.service';
import { ReferenceLibrary, TaskSummaryOverview, DEPENDENT_VISAS, ServiceCenterDetails, ImagilityBaseResponse } from 'app-models';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';
import * as Task from 'src/app/modules/superTask/task-state/actions/task.actions';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { CreateTaskService } from '../create-task/create-task.service';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { standardImmClassification, standardSubtasks, standardVisas } from 'src/app/data/constants/standard-visas';
import { CommonQuetionsService } from 'common-quetions';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddClientCompanyComponent, AddClientCompanyService } from 'add-client-company';
import { AddPrimaryContactComponent, AddPrimaryContactService } from 'add-primary-contact';
import { AddBeneficiaryOrMemberComponent, AddBeneficiaryOrMemberService } from 'add-beneficiary-or-member';
import { APIDocumentChecklist } from 'base-document-checklist-library';
import { TaskAllocationService } from "src/app/services/task-allocation.service";
import { CustomQuestionnaireService } from "src/app/modules/custom-questionaire/services/custom-questionnaire.service";
import { UserRoleService } from 'src/app/services/user-role.service';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { CountryApiService } from 'src/app/modules/petitioner/services/country.service';
import * as moment from 'moment';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { ImmigrationWizardService } from 'src/app/modules/immigration-wizard/services/immigration-wizard.service';
import { AttorneyProfileService } from 'src/app/services/attorney-profile.service';
import { WizardConfirmationDialogComponent } from '../wizard-confirmation-dialog/wizard-confirmation-dialog.component';
@Component({
  selector: 'app-create-task-with-wizard',
  templateUrl: './create-task-with-wizard.component.html',
  styleUrls: ['./create-task-with-wizard.component.scss']
})
export class CreateTaskWithWizardComponent implements OnInit {

  @Input() caseRequestCreateTask: any;
  @Output() afterCreateTask = new EventEmitter<any>();
  isCaseRequestCreateTask: boolean = true;
  isCreateTask: boolean = false;
  dynamicDialogRef: DynamicDialogRef;
  autoCompletePlaceholder: string = 'Enter/Select';
  emailPattern: any = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$';
  newTaskForm: FormGroup;
  destroy$ = new Subject<void>();
  formChangesSubscriptions: any[] = [];
  primaryCompanyId: number | null;
  userType = '';
  visaTypeArray = ['L1A', 'L1B', 'E1', 'E2', 'E2C', 'TN1', 'TN2', 'H1-B'];
  OVisaTypeArray = ['O1A', 'O1B'];
  dependentVisaArray = DEPENDENT_VISAS;
  companyListAssociatedWithAttorney: any[] = [];
  filteredCompanies: any[] = [];
  petitionerUserList: any[] = [];
  filteredPetitionerUsers: any[] = [];
  beneficiaryList: any[] = [];
  filteredBeneficiaryList: any[] = [];
  taskTypeList: ReferenceLibrary[];
  subTaskType: ReferenceLibrary[];
  visaTypes: ReferenceLibrary[] = [];
  petitionTypes: ReferenceLibrary[] = [];
  immigrationStatus: any[] = [];
  petitionerCompanyList: any[] = [];
  duplicatePetitionerList = {
    petitionType: [],
    company: [],
    beneficiaryList: [],
    selectedPetitionType: [],
    visaType: '',
    selectedBeneficiaryId: 0
  }
  isDuplicatedData: boolean = false;
  reSuperTask: any;
  permSuperTask: any;
  i140SuperTask: any;
  i485SuperTask: any;
  ds160SuperTask: any;
  immigrantType = '';
  selectedSubTaskTypeName: string;
  isImmigrant: boolean = false;
  isLottery = true;
  fields: {
    disableIsOutsideImagility: boolean;
  } = {
      disableIsOutsideImagility: false
    };
  immCategoryCode: any;
  immClassificationCode: any;
  isExtension: boolean = false;
  visaTemplates = [];
  visaId = 0;
  visaTemplateJson = null;
  taskJson = null;
  stepJson = null;
  selectedSubTask = '';
  selectedTaskType: any;
  dependentList: any[] = [];
  filteredDependentList: any[] = [];
  isTaskForIndvdul: boolean = false;
  taskFor: string = 'company';
  dynamicModalData: any = {};
  selectedBeneficiary: any = {};
  selectedBeneficiaryName: any;
  attorneyCompanyId: any;
  attorneyList: any[] = [];
  selectedBenDetails: any;
  caseTaskTypeId: any;
  selectedAttorneyCid: any;
  isCaseRequestChange: boolean = false;
  disabledControls: boolean;
  listStates = [];
  listCountry = [];
  listCounty = [];
  maxDate: Date;

  stepTaskTimelineList = [];
  customStepTaskTimelineList = [];
  foreignFields: any = {};
  foreignCompanyList: any[] = [];
  companyRelationList: ReferenceLibrary[] = [];
  employeeList = [];
  isVDTemplate: boolean;
  templateType: string;
  treatyCountryList: any[] = [];
  isNotOutsideImagility: boolean = true;
  lcaList: any[] = [];
  filingOption = [{
    code: 'PAPER',
    name: 'Paper Filing',
    inputId: 'option1'
  }
  // {
  //   code: 'EFILE',
  //   name: 'E-Filing',
  //   inputId: 'option2'
  // }
];
  serviceCenterList: any[] = [];
  wizardTaskId: any;
  reviewUsersList: any;
  users: any;
  selectedPetetionPreprarer: any;
  selectedSignatory: any;
  selectedDesignation: any;
  filteredAttorneysList: any[] = [];
  employeeDetails: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    protected store: Store<any>,
    private toast: ToastrService,
    private attorneyService: AttorneyService,
    private apiService: ApiServicesService,
    private lookupService: LookupService,
    private referenceLibraryService: ReferenceLibraryService,
    private petitionerService: PetitionerService,
    private createTaskService: CreateTaskService,
    private dashboardWidgetsService: DashboardWidgetsService,
    private taskListApiService: CommonQuetionsService,
    private countryService: CountryApiService,
    public dialogService: DialogService,
    private addClientCompanyService: AddClientCompanyService,
    private addPrimaryContactService: AddPrimaryContactService,
    private addBenMemberService: AddBeneficiaryOrMemberService,
    private apiDocService: APIDocumentChecklist,
    private _taskAllocationService: TaskAllocationService,
    private customQuestionsService: CustomQuestionnaireService,
    private userRoleService: UserRoleService,
    private cacheCountryService: CacheCountryService,
    private wizardDialogRef: DynamicDialogRef,
    private stepStatusUpdatePayloadService: StepStatusUpdatePayloadService,
    public immigrationWizardService: ImmigrationWizardService,
    private confirmationService: ConfirmationService,
    private attorneyProfileService: AttorneyProfileService,
  ) {
    this.newTaskForm = this.fb.group({
      id: [null],
      beneficiary: [null, Validators.required],
      beneficiaryId: [null, Validators.required],
      company: [null, this.companyRequired.bind(this)],
      attorney:[null],
      attorneyId: [null],
      companyId: [null],
      petitionType: [null],
      beneficiaryConfirmationNo: [null],
      isLottery: [true],
      visaType: [null],
      immCategoryCode: [null],
      immClassificationCode: [null],
      immSpecialistId: [null, Validators.required],
      taskTypeCode: ["PETITION", Validators.required],
      superTaskTypeCode: ['NONIMMPET', Validators.required],
      authorizedSignatory: [null],
      authorizedSignatoryId: [null, Validators.required],
      templateType: [null],
      formType: ['I129FORM'],
      countryCode: ['USA'],
      language: ['ENG'],
      subTaskType: [null, Validators.required],
      isOutsideImagility: [true],
      fName: [null, Validators.required],
      lName: [null, Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      // dob: [null, Validators.required],
      isSelfSponsor: [false],
      primaryApplicant: [null, this.applicantRequired.bind(this)],
      preparerAttorneyId: [null],
      positionTitle: [null, Validators.required],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      stateProvinceCode: [null, Validators.required],
      county: [null, Validators.required],
      isLCAFiled: [true],
      lcaId: [null],
      filingType: ['PAPER', Validators.required],
      processingType: [0],
      serviceCenterId: [null],
      includeAttorney: [true],
      selectedDesignation: [null]
    }, { validators: this.dateRangeValidator });

    this.maxDate = new Date();

  }



  ngOnChanges(event: SimpleChanges) {
  }

  dateRangeValidator(group: FormGroup) {
    const startDate = group.get('startDate').value;
    const endDate = group.get('endDate').value;
    return endDate >= startDate ? null : { dateRange: true };
  }

  onAttorneyChange(event) {
    this.newTaskForm.get('lawFirmSelected').patchValue(null);
    this.selectedAttorneyCid = event.value;
    const payload = {
      pageNumber: 0,
      recordsPerPage: 500,
      searchText: ""
    };
    this.userRoleService.getUsersList(event.value, payload).subscribe((user: any) => {
      this.employeeList = user['employees'];
      this.employeeList.forEach((data: any) => {
        data.name = data.firstName + " " + data.lastName
      });
      if (this.employeeList.length == 1) {
        this.newTaskForm.get('lawFirmSelected').patchValue(this.employeeList[0].id);
      }
    });
  }

  ngOnInit(): void {
    this.onChangeCountry('USA');
    this.userType = sessionStorage.getItem('userTypeRole');
    this.primaryCompanyId = this.getCompanyId();
    const observables = [this.referenceLibraryService.getReferenceData('IMMTYP'),this.referenceLibraryService.getReferenceData('SUPTSKTYP')];
    if( this.userType !== 'Attorney') {
        observables.push(this.attorneyService.getAttorneysList(this.primaryCompanyId,{}));
    }
    combineLatest(observables)
      .pipe(take(1))
      .subscribe((response: any[]) => {
        this.immigrationStatus = response[0];
        this.immigrantType = 'VISA';
        this.taskTypeList = response[1];
        this.attorneyList = response[2]?.data?.attorneyDetailList;
        this.getVisaType();
        if (this.userType === 'Petitioner') {
          // this.getBeneficiaryList(this.primaryCompanyId);
          this.getLCAs();
        }
      });
    this.formChangesSubscriptions[0] = this.newTaskForm.valueChanges
      .subscribe(() => {
        if (this.newTaskForm.dirty) {
          this.store.dispatch(new MarkDirtyFormAction({
            dirty: true
          }));
        } else {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        }
      });

    this.addClientCompanyService.saveClientCompanySubject.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res && res.saved) {
        if (res.data) {
          const immCategoryCode = this.newTaskForm.get('immCategoryCode').value;
          if (immCategoryCode) {
            this.getCompanies(null, true, null, true, res.data);
          } else {
            const visaType = this.newTaskForm.get('visaType').value;
            this.getCompanies(null, false, visaType, true, res.data);
          }
          // this.onCompanyChange({petitionerId: res.data?.companyId});
          // this.onPeitionerUserChange({ id: res.data?.id });
        }
      }
    });
    this.addPrimaryContactService.savePrimaryContactSubject.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res && res.saved) {
        if (res.data) {
          this.getPetitionerUsers(this.newTaskForm.get('companyId').value, true, res.data);
        }
      }
    });
    this.addBenMemberService.saveBenMemberSubject.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res && res.saved) {
        if (res.isAddingMember) {
          this.getDependents(this.newTaskForm.get('beneficiaryId').value, true, res.data[0]);
        } else {
          const cId = this.foreignFields?.showForeignCompany ? this.newTaskForm.get('foreignCompanyId').value : this.getCompanyId();
          this.getBeneficiaryList(cId, '', true, res.data);
        }
      }
    });
    this.populateDefaultValues();

    if(this.getCompanyId() && this.userType !== 'Attorney'){
      this.immigrationWizardService.getReviewersUser(this.getCompanyId(), USER_PERMISSIONS.PETITION_REVIEWER).subscribe(res => {
        this.reviewUsersList = res;
      });
  
      this.immigrationWizardService.getUsersOfCompany(this.getCompanyId()).subscribe(res => {
        this.users = res;
      });
    }
    
  }

  poplulateServicesList(){
    if(!this.serviceCenterList.length){
      this.onChangeProcessingType(this.newTaskForm.get('processingType').value || 0);
    }
  }

  onSuperTaskTypeChange(event: any) {
    if (event?.value) {
      this.newTaskForm.patchValue({
        visaType: null,
        petitionType: null,
        immCategoryCode: null,
        immClassificationCode: null,
        subTaskType: null,
        templateType: null
      });
      this.visaTypes = [];
      this.visaTemplates = [];
      this.setNewTaskFormValue();
      this.getVisaType();
    }
  }

  onVisaTypeChange(value: any) {
    if (value) {
      this.newTaskForm.patchValue({
        petitionType: null,
        immCategoryCode: null,
        immClassificationCode: null,
        subTaskType: null,
        templateType: null
      });
      this.setNewTaskFormValue();
      this.getPetitionType(value);
    }
  }

  getLCAs() {
    let userTypeVal = (this.userType === 'Attorney') ? 'attorney' : 'company';
    this.petitionerService.getLcaCodesWizard(this.primaryCompanyId).subscribe(data => {
      console.log(data)
      this.lcaList = data;
    });
  }

  onChangeProcessingType(processingType: number) {
    this.serviceCenterList = [];
    // this.task.serviceCenterDetails.id = null;
    this.getServiceCenter(processingType);
  }

  onChangeLottery(val) {
    this.isLottery = val;
  }

  getServiceCenter(processingType: number) {
    const isPremium = processingType !== 0;
    const request = this.lookupService.getServiceCenters(
      isPremium,
      this.newTaskForm.get('visaType').value,
      this.newTaskForm.get('petitionType').value);
    request
      .subscribe((data: ServiceCenterDetails[]) => {
        this.serviceCenterList = data;
      });
  }

  onPetitionTypeChange(event: any) { 
    if (event?.value) {
      this.newTaskForm.patchValue({
        immCategoryCode: null,
        immClassificationCode: null,
        subTaskType: null,
        templateType: null
      });
      this.setNewTaskFormValue();
      this.getSubTaskType();
      if (this.petitionerCompanyList.length > 0 && this.userType === 'Petitioner') {
        let currentCompanyData: any = this.petitionerCompanyList.find(item => item.id == this.getCompanyId());
        this.onCompanyChange(currentCompanyData);
      }
    }
  }

  onSubTaskTypeChange(event: any) {
    if (event?.value) {
      this.newTaskForm.patchValue({
        templateType: null
      });
      this.visaTemplateJson = null;
      this.newTaskForm.get('taskTypeCode').setValue(event.value);
      this.setNewTaskFormValue();
    }
  }

  fetchDetailsForAttorney(formControlName: string) {
    if (this.newTaskForm.get(formControlName).value) {
      this.selectedBeneficiary = this.beneficiaryList
        .find(ele => ele.beneficiaryEmployeeId === this.newTaskForm.get(formControlName).value);
      this.selectedBeneficiaryName = this.beneficiaryList
        .filter(ele => ele.beneficiaryEmployeeId === this.newTaskForm.get(formControlName).value)[0].beneficiaryEmployeeFName;
    }
  }

  handleSwitchChange() {
    // if (this.petitionTaskComponent) {
    //   this.petitionTaskComponent.getQuestionListForCreateTask();
    // }
    if (this.newTaskForm.get('superTaskTypeCode').value) {
      if (!this.newTaskForm.get('isOutsideImagility').value && this.newTaskForm.get('superTaskTypeCode').value === 'NONIMMPET') {
        this.newTaskForm.patchValue({
          taskTypeCode: 'PETITION'
        });
        this.selectedSubTaskTypeName = this.subTaskType.find(x => x.code === 'PETITION').name;
        this.onChangeFilterDropDown();
      }
    }
  }

  populateDefaultValues(resetForm: boolean = false, resetvalue: any = {}) {
    if (resetForm) {
      resetvalue = {
        ...resetvalue,
        superTaskTypeCode: 'NONIMMPET',
        formType: 'I129FORM',
        countryCode: 'USA',
        language: 'ENG',
        taskTypeCode: null
      }
      this.newTaskForm.reset(resetvalue);
      this.onSuperTaskTypeChange({ value: 'NONIMMPET' });
    }
    //  const creatingTaskFor = this.newTaskForm.get('creatingTaskFor').value;
    //  if(creatingTaskFor === 'individual'){
    //   this.getBeneficiaryList(this.primaryCompanyId);
    //  }
  }

  setNewTaskFormValue() {
    if (this.newTaskForm) {
      //this.newTaskForm.patchValue(this.newTaskForm.value);
      let a = this.newTaskForm.value;   //visaType
      if (a.petitionType === 'EXTNSN' || a.petitionType === 'AMEND') {
        this.duplicatePetitionerList.selectedPetitionType = a.petitionType;
        this.isExtension = true;
        sessionStorage.setItem('isDuplicated', 'true');
      } else {
        this.isExtension = false;
        sessionStorage.removeItem('isDuplicated');
      }
      if (a.visaType) {
        this.duplicatePetitionerList.visaType = a.visaType;
      }
    }
  }

  getVisaType() {
    this.newTaskForm.patchValue({
      visaType: null,
      petitionType: null,
      taskTypeCode: null,
      immCategoryCode: null,
      immClassificationCode: null,
      company: null,
      companyId: null,
      beneficiaryId: null,
      beneficiary: null,
      authorizedSignatoryId: null,
      authorizedSignatory: null,
      isOutsideImagility: true
    });
    this.selectedSubTaskTypeName = null;
    this.petitionTypes = [];
    this.petitionerCompanyList = [];
    this.companyListAssociatedWithAttorney = [];
    if (this.newTaskForm.get('superTaskTypeCode').value === 'NONIMMPET') {
      this.isImmigrant = false;
      this.referenceLibraryService.getReferenceData('VISATYP')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.petitionTypes = [];
          this.visaTypes = response;
          let visaTypeToSet = this.visaTypes.find(item => item.code === 'H1-B').code;
          this.newTaskForm.get('visaType').setValue(visaTypeToSet);
          this.newTaskForm.updateValueAndValidity();
          this.onVisaTypeChange(visaTypeToSet);
        });
    }
  }

  getPetitionType(value) {
    const visaTypeSelected = value || null;
    this.newTaskForm.patchValue({
      petitionType: null,
      company: null,
      companyId: null,
      beneficiary: null,
      beneficiaryId: null,
      taskTypeCode: null,
      isOutsideImagility: true
    });
    this.selectedSubTaskTypeName = null;
    this.getCompanies(null, false, visaTypeSelected);
  }

  getSubTaskType() {
    this.newTaskForm.patchValue({
      taskTypeCode: null,
    });
    this.selectedSubTaskTypeName = null;
    if (this.newTaskForm.get('superTaskTypeCode').value && this.newTaskForm.get('petitionType').value && this.newTaskForm.get('visaType').value) {
      this.dashboardWidgetsService.getSubTasksType(this.newTaskForm.get('superTaskTypeCode').value, this.newTaskForm.get('petitionType').value, this.newTaskForm.get('visaType').value)
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          if (response) {
            if (!(this.userType === 'Attorney' && this.newTaskForm.get('visaType').value === 'H1-B')) {
              response = response.filter(ele => ele.code !== 'RFE');
            }
            this.subTaskType = response;
            this.subTaskType = [...new Map(this.subTaskType.map(item => [item['code'], item])).values()];
            if (!this.newTaskForm.get('isOutsideImagility').value && !this.ds160SuperTask && !this.reSuperTask) {
              this.newTaskForm.patchValue({
                taskTypeCode: 'PETITION'
              });
              this.selectedSubTaskTypeName = this.subTaskType.find(x => x.code === 'PETITION').name;
              this.onChangeSubTaskType();
            } else if (this.subTaskType && this.subTaskType.length > 0) {
              this.newTaskForm.patchValue({
                taskTypeCode: this.subTaskType[0].code
              });
              this.selectedSubTaskTypeName = this.subTaskType[0].name;
              this.onSubTaskTypeChange({ value: this.subTaskType[0].code });
              this.onChangeSubTaskType();
            }
          }
        });
    }
  }



  onChangeSubTaskType() {
    this.onChangeFilterDropDown();
  }

  onChangeFilterDropDown() {
    const task: any = {};
    task.visaType = this.newTaskForm.get('visaType').value;
    task.petitionType = this.newTaskForm.get('petitionType').value;
    task.taskTypeCode = this.newTaskForm.get('taskTypeCode').value;
    if (task.visaType && task.petitionType && task.taskTypeCode) {
      this.petitionerService.detectTaskFilterChanges(task, this.immigrantType);
    }
  }

  getCompanies(search: any = null, isImmigrant: boolean = false, visaTypeSelected: any, updateNew: boolean = false, newCompanyData: any = {}) {
    const reqJson = {
      searchText: search ? search : '',
      searchCriteria: '',
      sortBy: '',
      sortOrder: '',
      pageNumber: 0,
      recordsPerPage: 0
    };
    if (!isImmigrant) {
      let companyListReq;
      switch (visaTypeSelected) {
        case 'L1A':
        case 'L1B':
          this.newTaskForm.addControl('companyRelationCode', new FormControl(null, Validators.required));
          this.newTaskForm.addControl('foreignCompanyId', new FormControl(null, Validators.required));
          this.newTaskForm.addControl('treatyCountry', new FormControl(null));
          this.foreignFields = {
            showForeignCompany: true,
            showRelationshipOfUSCompanyWithForeignCompany: true,
            showTreatyCountry: false
          };
          this.fields.disableIsOutsideImagility = false;
          const reqArray = [
            this.createTaskService.getPetitionList(visaTypeSelected)
          ];
          if (this.userType === 'Petitioner') {
            companyListReq = this.petitionerService.getPetitionerClientList(this.primaryCompanyId, 'countryCode');
            reqArray.push(companyListReq);
          } else if (this.userType === 'Attorney') {
            companyListReq = this.attorneyService.getClientsList(this.primaryCompanyId, reqJson);
            reqArray.push(companyListReq);
          }
          reqArray.push(this.referenceLibraryService.getReferenceData('USFORGNCOMPREL'));
          combineLatest(reqArray)
            .pipe(take(1))
            .subscribe((response: any[]) => {
              this.companyRelationList = response[2];
              this.petitionTypes = response[0].map(x => {
                return {
                  code: x.code,
                  name: x.name,
                  id: x.id,
                  desc: x.description,
                  group: x.group
                };
              });
              // this.duplicateDataPetitionTypes = this.petitionTypes;
              this.duplicatePetitionerList.petitionType = this.petitionTypes;
              if (this.userType === 'Petitioner') {
                this.petitionerCompanyList = response[1].map((c) => { return { petitionerName: c.companyName, petitionerId: c.id, ...c }; });
                // this.duplicateDataPetitionerCompanyList = this.petitionerCompanyList;
                if (updateNew && newCompanyData) {
                  this.newTaskForm.patchValue({
                    companyId: newCompanyData.companyId,
                    company: this.petitionerCompanyList.find((c) => c.petitionerId === newCompanyData.companyId)
                  });
                  this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                }
                this.duplicatePetitionerList.company = this.petitionerCompanyList;
              } else if (this.userType === 'Attorney') {
                if (response[1] && response[1].petetionerList) {
                  this.companyListAssociatedWithAttorney = response[1].petetionerList;
                  this.duplicatePetitionerList.company = this.companyListAssociatedWithAttorney;
                  if (updateNew && newCompanyData) {
                    this.newTaskForm.patchValue({
                      companyId: newCompanyData.companyId,
                      company: this.companyListAssociatedWithAttorney.find((c) => c.petitionerId === newCompanyData.companyId)
                    });
                    this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                  }
                }
              }
              this.onChangeFilterDropDown();
            });
          break;
        case 'E1':
        case 'E2':
        case 'E2C':
          this.newTaskForm.addControl('companyRelationCode', new FormControl(null, Validators.required));
          this.newTaskForm.addControl('foreignCompanyId', new FormControl(null, Validators.required));
          this.newTaskForm.addControl('treatyCountry', new FormControl(null));
          this.foreignFields = {
            showForeignCompany: true,
            showRelationshipOfUSCompanyWithForeignCompany: true,
            showTreatyCountry: true
          };
          this.fields.disableIsOutsideImagility = false;
          const reqArr = [
            this.createTaskService.getPetitionList(visaTypeSelected)
          ];
          if (this.userType === 'Petitioner') {
            companyListReq = this.petitionerService.getPetitionerClientList(this.primaryCompanyId, 'countryCode');
            reqArr.push(companyListReq);
          } else if (this.userType === 'Attorney') {
            companyListReq = this.attorneyService.getClientsList(this.primaryCompanyId, reqJson);
            reqArr.push(companyListReq);
          }
          reqArr.push(this.referenceLibraryService.getReferenceData('USFORGNCOMPREL'));
          combineLatest(reqArr)
            .pipe(take(1))
            .subscribe((response: any[]) => {
              this.companyRelationList = response[2];
              this.petitionTypes = response[0].map(x => {
                return {
                  code: x.code,
                  name: x.name,
                  id: x.id,
                  desc: x.description,
                  group: x.group
                };
              });
              // this.duplicateDataPetitionTypes = this.petitionTypes;
              this.duplicatePetitionerList.petitionType = this.petitionTypes;
              if (this.userType === 'Petitioner') {
                this.petitionerCompanyList = response[1].map((c) => { return { petitionerName: c.companyName, petitionerId: c.id, ...c }; });
                // this.duplicateDataPetitionerCompanyList = this.petitionerCompanyList;
                if (updateNew && newCompanyData) {
                  this.newTaskForm.patchValue({
                    companyId: newCompanyData.companyId,
                    company: this.petitionerCompanyList.find((c) => c.petitionerId === newCompanyData.companyId)
                  });
                  this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                }
                this.duplicatePetitionerList.company = this.petitionerCompanyList;
              } else if (this.userType === 'Attorney') {
                if (response[1] && response[1].petetionerList) {
                  this.companyListAssociatedWithAttorney = response[1].petetionerList;
                  this.duplicatePetitionerList.company = this.companyListAssociatedWithAttorney;
                  if (updateNew && newCompanyData) {
                    this.newTaskForm.patchValue({
                      companyId: newCompanyData.companyId,
                      company: this.companyListAssociatedWithAttorney.find((c) => c.petitionerId === newCompanyData.companyId)
                    });
                    this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                  }
                }
              }
              this.onChangeFilterDropDown();
            });
          break;
        case 'H1-B':
          this.newTaskForm.removeControl('companyRelationCode');
          this.newTaskForm.removeControl('foreignCompanyId');
          this.newTaskForm.removeControl('treatyCountry');
          this.foreignFields = {
            showForeignCompany: false,
            showRelationshipOfUSCompanyWithForeignCompany: false,
            showTreatyCountry: false
          };
          let obser=[];
          obser.push(this.createTaskService.getPetitionList(visaTypeSelected));
          if (this.userType === 'Petitioner') {
            obser.push(this.petitionerService.getPetitionerClientList(this.primaryCompanyId));
          } else if (this.userType === 'Attorney' && sessionStorage.getItem('companyId')) {
            obser.push(this.attorneyService.getClientsList(parseInt(sessionStorage.getItem('companyId')), reqJson));
          }
          combineLatest(obser)
            .pipe(take(1))
            .subscribe((response: any[]) => {
              this.petitionTypes = response[0].map(x => {
                return {
                  code: x.code,
                  name: x.name,
                  id: x.id,
                  desc: x.description,
                  group: x.group
                };
              });
              // this.duplicateDataPetitionTypes = this.petitionTypes;
              this.duplicatePetitionerList.petitionType = this.petitionTypes;

              if (this.userType === 'Petitioner') {
                this.petitionerCompanyList = response[1].map((c) => { return { petitionerName: c.companyName, petitionerId: c.id, ...c }; });
                // this.duplicateDataPetitionerCompanyList = this.petitionerCompanyList;
                this.duplicatePetitionerList.company = this.petitionerCompanyList;
                if (updateNew && newCompanyData) {
                  this.newTaskForm.patchValue({
                    companyId: newCompanyData.companyId,
                    company: this.petitionerCompanyList.find((c) => c.petitionerId === newCompanyData.companyId)
                  });
                  this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                  this.getBeneficiaryList(newCompanyData.companyId);
                }
              } else if (this.userType === 'Attorney') {
                if (response[1] && response[1].petetionerList) {
                  this.companyListAssociatedWithAttorney = response[1].petetionerList;
                  this.duplicatePetitionerList.company = this.companyListAssociatedWithAttorney;
                  if (updateNew && newCompanyData) {
                    this.newTaskForm.patchValue({
                      companyId: newCompanyData.companyId,
                      company: this.companyListAssociatedWithAttorney.find((c) => c.petitionerId === newCompanyData.companyId)
                    });
                    this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                    this.getBeneficiaryList(newCompanyData.companyId);
                  }
                }
              }

              if (this.ds160SuperTask) {
                this.newTaskForm.get('petitionType').patchValue(this.ds160SuperTask.taskTypeDetails.petitionType);
                this.onPetitionTypeChange({ value: this.ds160SuperTask.taskTypeDetails.petitionType });
              } else {
                this.onChangeFilterDropDown();
              }
            });
          break;
        default:
          this.newTaskForm.removeControl('companyRelationCode');
          this.newTaskForm.removeControl('foreignCompanyId');
          this.newTaskForm.removeControl('treatyCountry');
          this.foreignFields = {
            showForeignCompany: false,
            showRelationshipOfUSCompanyWithForeignCompany: false,
            showTreatyCountry: false
          };
          this.fields.disableIsOutsideImagility = false;
          const req = [
            this.createTaskService.getPetitionList(visaTypeSelected)
          ];
          if (this.userType === 'Petitioner') {
            companyListReq = this.petitionerService.getPetitionerClientList(this.primaryCompanyId);
            req.push(companyListReq);
          } else if (this.userType === 'Attorney') {
            companyListReq = this.attorneyService.getClientsList(this.primaryCompanyId, reqJson);
            req.push(companyListReq);
          }
          combineLatest(req)
            .pipe(take(1))
            .subscribe((response: any[]) => {
              this.petitionTypes = response[0].map(x => {
                return {
                  code: x.code,
                  name: x.name,
                  id: x.id,
                  desc: x.description,
                  group: x.group
                };
              });
              // this.duplicateDataPetitionTypes = this.petitionTypes;
              this.duplicatePetitionerList.petitionType = this.petitionTypes;
              const indiVidualSposorOpt = {
                petitionerId: 0,
                petitionerName: 'Individual/Sponsor',
                contactPersonFirstName: '--',
                contactPersonLastName: '',
                contactPersonMiddleName: '',
              };
              if (this.userType === 'Petitioner') {
                if (response && response[1]) {
                  if (this.dependentVisaArray.includes(this.newTaskForm.get('visaType').value) || ['U1', 'UD'].includes(this.newTaskForm.get('visaType').value)) {
                    this.petitionerCompanyList = [indiVidualSposorOpt, ...response[1]];
                    this.newTaskForm.patchValue({
                      company: this.petitionerCompanyList[0]
                    });
                    this.onCompanyChange(this.petitionerCompanyList[0]);
                  } else {
                    this.petitionerCompanyList = response[1].map((c) => { return { petitionerName: c.companyName, petitionerId: c.id, ...c }; });
                  }
                }
                // this.duplicateDataPetitionerCompanyList = this.petitionerCompanyList;
                if (updateNew && newCompanyData) {
                  this.newTaskForm.patchValue({
                    companyId: newCompanyData.companyId,
                    company: this.petitionerCompanyList.find((c) => c.petitionerId === newCompanyData.companyId)
                  });
                  this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                  this.getBeneficiaryList(newCompanyData.companyId);
                }
                this.duplicatePetitionerList.company = this.petitionerCompanyList;
              } else if (this.userType === 'Attorney') {
                if (response[1] && response[1].petetionerList) {
                  if (this.dependentVisaArray.includes(this.newTaskForm.get('visaType').value) || ['U1', 'UD'].includes(this.newTaskForm.get('visaType').value)) {
                    this.companyListAssociatedWithAttorney = [indiVidualSposorOpt, ...response[1].petetionerList];
                    this.newTaskForm.patchValue({
                      company: this.companyListAssociatedWithAttorney[0]
                    });
                    this.onCompanyChange(this.companyListAssociatedWithAttorney[0]);
                  } else {
                    this.companyListAssociatedWithAttorney = response[1].petetionerList;
                  }
                  this.duplicatePetitionerList.company = this.companyListAssociatedWithAttorney;
                  if (updateNew && newCompanyData) {
                    this.newTaskForm.patchValue({
                      companyId: newCompanyData.companyId,
                      company: this.companyListAssociatedWithAttorney.find((c) => c.petitionerId === newCompanyData.companyId)
                    });
                    this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                    this.getBeneficiaryList(newCompanyData.companyId);
                  }
                }
              }
              this.onChangeFilterDropDown();
            });
          break;
      }
    } else {
      let companyListReq;
      if (this.userType === 'Petitioner') {
        companyListReq = this.petitionerService.getPetitionerClientList(this.primaryCompanyId);
      } else if (this.userType === 'Attorney') {
        companyListReq = this.attorneyService.getClientsList(this.primaryCompanyId, reqJson);
      }
      companyListReq.pipe(take(1))
        .subscribe((response: any) => {
          const immCategoryCode = this.newTaskForm.get('immCategoryCode').value;
          const individualSposor = {
            petitionerId: 0,
            petitionerName: 'Individual/Sponsor',
            contactPersonFirstName: '--',
            contactPersonLastName: '',
            contactPersonMiddleName: '',
          };
          this.duplicatePetitionerList.petitionType = this.petitionTypes;
          if (this.userType === 'Petitioner') {
            this.petitionerCompanyList = response.map((c) => { return { petitionerName: c.companyName, petitionerId: c.id, ...c }; });
            if (immCategoryCode !== 'GCEB') {
              this.petitionerCompanyList = [individualSposor, ...this.petitionerCompanyList];
              this.newTaskForm.patchValue({
                company: this.petitionerCompanyList[0]
              });
              this.onCompanyChange(this.petitionerCompanyList[0]);
            }
            this.duplicatePetitionerList.company = this.petitionerCompanyList;
            if (updateNew && newCompanyData) {
              this.newTaskForm.patchValue({
                companyId: newCompanyData.companyId,
                company: this.petitionerCompanyList.find((c) => c.petitionerId === newCompanyData.companyId)
              });
              this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
              this.getBeneficiaryList(newCompanyData.companyId);
            }
          } else if (this.userType === 'Attorney') {
            if (response && response.petetionerList) {
              if ((this.isImmigrant && this.visaTemplateJson) || (this.newTaskForm.value.immCategoryCode !== 'GCEB')) {
                this.companyListAssociatedWithAttorney = [individualSposor, ...response.petetionerList];
                if (immCategoryCode !== 'GCEB') {
                  this.newTaskForm.patchValue({
                    company: this.companyListAssociatedWithAttorney[0]
                  });
                  this.onCompanyChange(this.companyListAssociatedWithAttorney[0]);
                }
              } else {
                this.companyListAssociatedWithAttorney = response.petetionerList;
              }
              this.duplicatePetitionerList.company = this.companyListAssociatedWithAttorney;
              if (updateNew && newCompanyData) {
                this.newTaskForm.patchValue({
                  companyId: newCompanyData.companyId,
                  company: this.companyListAssociatedWithAttorney.find((c) => c.petitionerId === newCompanyData.companyId)
                });
                this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                this.getBeneficiaryList(newCompanyData.companyId);
              }

            }
          }
        });
    }

  }
  filteredAttorneys(event: any){
    let query = event.query;
    let filtered = [];
    let arrayTobeFiltered = this.attorneyList;

    const keyToFilter = 'companyName';
    for (let i = 0; i < arrayTobeFiltered.length; i++) {
      let item = arrayTobeFiltered[i];
      if (item[keyToFilter].toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(item);
      }
    }
    this.filteredAttorneysList = filtered;
  }

  filterCompanies(event: any) {
    let query = event.query;
    let filtered = [];
    let arrayTobeFiltered = (this.userType === 'Petitioner') ? this.petitionerCompanyList : this.companyListAssociatedWithAttorney;
    if (this.isImmigrant && this.visaTemplateJson && this.userType === 'Attorney') {
      arrayTobeFiltered = [{
        petitionerId: 0,
        petitionerName: 'Individual/Sponsor',
        contactPersonFirstName: '--',
        contactPersonLastName: '',
        contactPersonMiddleName: '',
      }, ...arrayTobeFiltered];
    }

    const keyToFilter = (this.userType === 'Petitioner') ? 'companyName' : 'petitionerName';
    for (let i = 0; i < arrayTobeFiltered.length; i++) {
      let item = arrayTobeFiltered[i];
      if (item[keyToFilter].toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(item);
      }
    }
    this.filteredCompanies = filtered;
  }

  companyRequired(control: FormControl): { [s: string]: boolean } {
    if (this.newTaskForm &&
      (!this.newTaskForm.value.immCategoryCode ||
        (this.newTaskForm.value.immCategoryCode &&
          this.newTaskForm.value.immCategoryCode !== 'GCFLY' && this.newTaskForm.value.immClassificationCode !== 'AFFDVTSUPT864')) &&
      !control.value) {
      return { companyIsRequired: true };
    }
    return null;
  }

  applicantRequired(control: FormControl): { [s: string]: boolean } {
    if (this.newTaskForm && !this.newTaskForm.value.isSelfSponsor &&
      ((this.newTaskForm.value.superTaskTypeCode === 'NONIMMPET' && this.dependentVisaArray.includes(this.newTaskForm.value.visaType)) ||
        (this.newTaskForm.value.immCategoryCode &&
          (this.newTaskForm.value.immCategoryCode === 'GCFLY' || this.newTaskForm.value.immClassificationCode === 'AFFDVTSUPT864'))) && !control.value) {
      return { applicantIsRequired: true };
    }
    return null;
  }

  getBeneficiaryList(companyId, searchText: string = '', updateNew: boolean = false, newBenData: any = {}) {
    if (!companyId) return;
    const beneficiaryParamPayload = {
      pageNumber: 1,
      recordsPerPage: 100,
      searchCriteria: 'beneficiary',
      searchText: searchText,
      sortBy: 'companyName',
      sortOrder: 'asc',
      status: ['ACTIVE']
    };
    this.apiService.getBeneficiariesByCompanyId(companyId, beneficiaryParamPayload)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Array<any>) => {
        if (data) {
          this.beneficiaryList = data.map((ben) => { ben['fullName'] = `${ben.beneficiaryEmployeeFName}${ben.beneficiaryEmployeeMName ? ' ' + ben.beneficiaryEmployeeMName : ''} ${ben.beneficiaryEmployeeLName}`; return ben; });
          if (!this.isCaseRequestCreateTask) {
            if (this.selectedBenDetails) {
              let selectedBenId = parseInt(this.selectedBenDetails.beneficiaryEmployeeId)
              const reqBen = this.beneficiaryList.find((ben) => ben.beneficiaryEmployeeId === selectedBenId);
              this.newTaskForm.patchValue({
                beneficiary: reqBen
              });
              this.onBenChange(reqBen);
            }
          }
          if (updateNew && newBenData) {
            const newBen = this.beneficiaryList.find((ben) => ben.beneficiaryEmployeeId === newBenData?.id);
            this.newTaskForm.patchValue({
              beneficiary: newBen
            });
            this.onBenChange(newBen);
          }
        }
      });
  }

  filterBeneficiaries(event) {
    let query = event.query;
    let filtered = [];

    for (let i = 0; i < this.beneficiaryList.length; i++) {
      let item = this.beneficiaryList[i];
      if (item.fullName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(item);
      }
    }

    this.filteredBeneficiaryList = filtered;
  }

  getPetitionerUsers(compayId: number, updateNew: boolean = false, newContactData: any = {}) {
    if (compayId) {
      this.lookupService.getReviewersUser(compayId, USER_PERMISSIONS.PETITION_REVIEWER).subscribe((data: Array<any>) => {
        this.petitionerUserList = data.map((emp) => { emp['fullName'] = `${emp.firstName}${emp.middleName ? ' ' + emp.middleName : ''} ${emp.lastName}`; return emp; });
        if (updateNew && newContactData) {
          const newContact = this.petitionerUserList.find((pu) => pu.id === newContactData?.id);
          this.newTaskForm.patchValue({
            authorizedSignatory: newContact
          });
          this.onPeitionerUserChange(newContact);
        }
      });
    }
  }

  filterPetitionerUsers(event: any) {
    let query = event.query;
    let filtered = [];

    for (let i = 0; i < this.petitionerUserList.length; i++) {
      let item = this.petitionerUserList[i];
      if (item.fullName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(item);
      }
    }

    this.filteredPetitionerUsers = filtered;
  }

  onCompanyChange(selectedItem: any) {
    let comapnyId = selectedItem.id ? selectedItem.id : selectedItem.petitionerId;

    comapnyId = comapnyId ? comapnyId : this.getCompanyId();

    if(comapnyId && this.userType === 'Attorney'){
      this.immigrationWizardService.getReviewersUser(comapnyId, USER_PERMISSIONS.PETITION_REVIEWER).subscribe(res => {
        this.reviewUsersList = res;
      });
  
      this.immigrationWizardService.getUsersOfCompany(comapnyId).subscribe(res => {
        this.users = res;
      });
    }


    this.newTaskForm.patchValue({
      companyId: comapnyId
    });
    // this.newTaskForm.patchValue({
    //   beneficiaryId: null,
    //   beneficiary: null,
    //   authorizedSignatoryId: null,
    //   authorizedSignatory: null
    // });
    if (!(0 === comapnyId)) {
      this.getPetitionerUsers(this.newTaskForm.value.companyId);
    }
    const visaTypeSelected = this.newTaskForm.get('visaType').value;
    switch (visaTypeSelected) {
      case 'L1A':
      case 'L1B':
        this.petitionerService.getPetitionerClientList(this.newTaskForm.get('companyId').value, 'notInCountry')
          .pipe(take(1))
          .subscribe((response: any[]) => {
            this.foreignCompanyList = response;
          });
        break;
      case 'E1':
      case 'E2':
      case 'E2C':
        combineLatest([
          this.petitionerService.getForeignCompanyListForEVisa(visaTypeSelected, this.newTaskForm.get('companyId').value),
          this.cacheCountryService.getCountry()
        ])
          .pipe(take(1))
          .subscribe((response: any[]) => {
            this.foreignCompanyList = response[0];
            if (response[1]?.length > 0) {
              this.treatyCountryList = response[1]?.map((c) => {
                c['labelWithCode'] = `${c.countryName} - ${c.phoneCode}`;
                return c;
              });
            }
          });
        // this.petitionerService.getForeignCompanyListForEVisa(visaTypeSelected, this.newTaskForm.get('companyId').value)
        //   .pipe(take(1))
        //   .subscribe((response: any[]) => {
        //     this.foreignCompanyList = response;
        //   });
        break;
      default:
        this.getBeneficiaryList(this.newTaskForm.value.companyId);
        if(this.userType === 'Attorney') {
          this.primaryCompanyId = comapnyId;
          this.getLCAs()
        }
        break;
    }

    if (this.isCaseRequestChange) {
      this.newTaskForm.patchValue({
        company: selectedItem,
        companyId: selectedItem.id
      });
      this.isCaseRequestChange = false;
    }

  }

  onForeignCompanyChange(event: any) {
    const foreignCompanyId = this.newTaskForm.get('foreignCompanyId').value;
    this.getBeneficiaryList(foreignCompanyId);
    if (['E1', 'E2', 'E2C'].includes(this.newTaskForm.get('visaType').value)) {
      this.newTaskForm.patchValue({
        treatyCountry: this.foreignCompanyList.find(x => x.id === foreignCompanyId).countryName
      });
    }
  }

  onPeitionerUserChange(selectedItem: any) {
    this.newTaskForm.patchValue({
      authorizedSignatoryId: selectedItem.id
    });
  }

  onBenChange(selectedItem: any) {
    if (!selectedItem) return false;
    this.newTaskForm.patchValue({
      beneficiaryId: selectedItem.id ? selectedItem.id : selectedItem.beneficiaryEmployeeId,
      primaryApplicant: null
    });
    this.newTaskForm.get('primaryApplicant').updateValueAndValidity();
    if (!!(this.newTaskForm.get('immCategoryCode').value && (this.newTaskForm.get('immCategoryCode').value === 'GCFLY' || this.newTaskForm.get('immClassificationCode').value === 'AFFDVTSUPT864')) || (this.newTaskForm.value.superTaskTypeCode === 'NONIMMPET' && this.dependentVisaArray.includes(this.newTaskForm.value.visaType)) && !this.newTaskForm.value.isSelfSponsor) {
      this.getDependents(this.newTaskForm.value.beneficiaryId);
    }
  }

  // onChangeCreatingTaskFor(value: string){
  //   console.log(value, this.taskFor);
  //   this.isTaskForIndvdul = (value === 'individual');
  //   if(value !== this.taskFor ){
  //     this.taskFor = value;
  //     this.populateDefaultValues(true, {creatingTaskFor: value});
  //   }
  // }

  getDependents(benificiaryId: number, updateNew: boolean = false, newApplicantData: any = {}) {
    this.createTaskService.getDependentList(benificiaryId).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.dependentList = response.map(x => {
        return {
          ...x,
          displayName: `${x.firstName} ${x.lastName} - ${x.relationshipName}`,
          isChecked: false,
          isPrimaryApplicant: false,
          email: x?.emailContacts?.length ? x.emailContacts[0].email : null
        };
      });
      if (updateNew && newApplicantData) {
        const newApplicant = this.dependentList.find((dep) => dep.id && dep.id === newApplicantData.id);
        this.newTaskForm.patchValue({
          primaryApplicant: newApplicant
        });
        this.onDependentChange(newApplicant);
      }
    });
  }

  filterDependents(event: any) {
    let query = event.query;
    let filtered = [];

    for (let i = 0; i < this.dependentList.length; i++) {
      let item = this.dependentList[i];
      if (item.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(item);
      }
    }

    this.filteredDependentList = filtered;
  }

  onDependentChange(selectedItem: any) {
    let primApplicantValue = this.newTaskForm.get('primaryApplicant').value;
    this.newTaskForm.get('primaryApplicant').setValue({ ...primApplicantValue, isPrimaryApplicant: true, primaryApplicant: true });
  }

  handleSelfPetitionerChange(event: any) {
    this.newTaskForm.patchValue({
      company: null,
      companyId: null
    });
    // if(event.checked){
    //   this.getCompanies();
    // }
  }


  onChangeOutsideImagility(checked: boolean){
    if(checked) {
      this.newTaskForm.get('fName').setValidators([Validators.required]);
      this.newTaskForm.get('email').setValidators([Validators.required, Validators.pattern(this.emailPattern)]);
      this.newTaskForm.get('lName').setValidators([Validators.required]);
      this.newTaskForm.get('fName').updateValueAndValidity();
      this.newTaskForm.get('email').updateValueAndValidity();
      this.newTaskForm.get('lName').updateValueAndValidity();
    } else {
      this.newTaskForm.get('fName').clearValidators();
      this.newTaskForm.get('email').clearValidators();
      this.newTaskForm.get('lName').clearValidators();
      this.newTaskForm.get('fName').updateValueAndValidity();
      this.newTaskForm.get('email').updateValueAndValidity();
      this.newTaskForm.get('lName').updateValueAndValidity();
    }
    this.newTaskForm.patchValue({
      isOutsideImagility: checked,
    });
  }

  onChangeLcaFiled(checked: boolean) {
    this.newTaskForm.patchValue({
      isLCAFiled: checked,
    });
  }

  onChangeAttorney(checked: boolean) {
    this.newTaskForm.patchValue({
      includeAttorney: checked,
    });
  }

  onAttroneyChange(selectedItem: any){
    this.newTaskForm.patchValue({
      attorneyId: selectedItem?.companyId
    });
  }

  backToDashboard() {
    this.router.navigate([`../../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}`]);
  }

  backToBeneficiaries() {
    this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[localStorage.getItem('userTypeRole')]}/beneficiaries/list`]);
  }

  saveIndividual() {
    let payload: any;
    let req;
    payload = {
      id: 0,
      companyId: this.getCompanyId(),
      fName: this.newTaskForm.controls['fName'].value,
      mName: null,
      lName: this.newTaskForm.controls['lName'].value,
      emailContacts: [
        {
          email: this.newTaskForm.controls['email'].value,
          id: 0,
          type: {
            code: "PRIM",
            id: 0,
          },
        },
      ],
      relationShipType: null,
      email: this.newTaskForm.controls['email'].value,
      firstName: this.newTaskForm.controls['fName'].value,
      middleName: null,
      lastName: this.newTaskForm.controls['lName'].value,
    };

    req = this.addBenMemberService.createBeneficiary(payload);
    req.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.newTaskForm.get('fName').patchValue('');
        this.newTaskForm.get('email').patchValue('');
        this.newTaskForm.get('lName').patchValue('');
        this.newTaskForm.get('fName').clearValidators();
        this.newTaskForm.get('email').clearValidators();
        this.newTaskForm.get('lName').clearValidators();
        this.newTaskForm.get('fName').updateValueAndValidity();
        this.newTaskForm.get('email').updateValueAndValidity();
        this.newTaskForm.get('lName').updateValueAndValidity();
        this.newTaskForm.get('isOutsideImagility').patchValue(true);
        this.toast.success(response.message);
        this.addBenMemberService.saveBenMemberSubject.next({ saved: true, data: response.data });
        this.store.dispatch(new MarkCleanFormAction({ dirty: false }));

      }
    });
  }

  validateLCA(){
    const cItem = this.lcaList.find(x=>x.caseNumber==this.newTaskForm.get('lcaId').value)
    let selectedStartDate = moment(this.newTaskForm.get('startDate').value).format('YYYY-MM-DD');
    let selectedEndDate = moment(this.newTaskForm.get('endDate').value).format('YYYY-MM-DD');
    if((this.newTaskForm.get('startDate').value && this.newTaskForm.get('endDate').value) && (selectedEndDate < selectedStartDate)){
      this.toast.error("End date should be greater than or equal to start date");
      this.newTaskForm.get('endDate').setValue(null);
    }
    
    if(this.newTaskForm.get('startDate').value && this.newTaskForm.get('endDate').value && this.newTaskForm.get('lcaId').value){
      let lcaStartDate = moment(cItem?.employmentStartDate).format('YYYY-MM-DD');
      let lcaEndDate = moment(cItem?.employmentEndDate).format('YYYY-MM-DD');
      if(lcaStartDate && lcaEndDate && selectedStartDate && selectedEndDate){
        if((selectedStartDate >= lcaStartDate) && (selectedEndDate <= lcaEndDate)){
          // valid lca
        } else {
          this.toast.error("Start date & end date range should be in-between the selected LCA's date range!");
          if(!(selectedStartDate >= lcaStartDate)){
            this.newTaskForm.get('startDate').setValue(null);
          }
          if(!(selectedEndDate <= lcaEndDate)){
            this.newTaskForm.get('endDate').setValue(null);
          }
        }
      }
    }
  }

  onSubmit() {
    this.saveTask1(false);
  }

  getCompanyId(){
    let companyId=null;
    if (this.userType === 'Attorney') {
      companyId =  this.newTaskForm.value.companyId;
    }
    else if (this.userType === 'Petitioner') {
      companyId =  !!(sessionStorage.getItem('companyId')) ? JSON.parse(sessionStorage.getItem('companyId')) : null
    }
    return companyId;
  }

  loadConfirmPopup(){
    const dialogConfig = {
      width: '30%',
      closeOnEscape: false,
      closable: false,
      showHeader: false,
      contentStyle: { "z-index": "1001" },
      styleClass: 'wizardConfimPetition',
    };
    const wizardRef = this.dialogService.open(WizardConfirmationDialogComponent, {
      ...dialogConfig,
    });
    wizardRef.onClose
    .pipe().subscribe((response: any) => {
      console.log('response', response);
      if (response) {
        this.saveTask1(true);
      }
    })
  }

  saveTask1(data) {
    let formValue = this.newTaskForm.value;
    let payload =
    { stateProvinceCode: formValue.stateProvinceCode,
      county: formValue.county,
      lcaCaseId: formValue.lcaId,
      receiptNumber: formValue.beneficiaryConfirmationNo,
      saveTaskDTO: {
        attorneyId: this.userType === 'Attorney' ? JSON.parse(sessionStorage.getItem('companyId')) : (formValue?.includeAttorney) ? formValue?.attorneyId : '',
        immSpecialistId: formValue.immSpecialistId,
        isOutsideImagility: 0,
        preparerAttorneyId: null,
        superTaskTypeCode: formValue.superTaskTypeCode,
        taskTypeCode: formValue.taskTypeCode,
        authorizedSignatoryId: formValue.authorizedSignatoryId,
        beneficiaryId: formValue.beneficiary?.beneficiaryEmployeeId,
        companyId: this.getCompanyId(),
        immigrantTypeCode: "VISA",
        petitionTypeCode: formValue.petitionType,
        questionAndResponseDTO: [],
        visaTypeCode: formValue.visaType,
        visaType: formValue.visaType,
        companyRelationCode: null,
        foreignCompanyId: null,
        treatyCountryCode: null,
        id: 0,
        superTaskId: 0,
        stepList: [],
        customStepList: [],
        serviceCenterId: formValue.serviceCenterId,
        taskSourceCode: "WIZARD",
        //skipValidation: false,
      }
    }
    // if(data){
    //   payload.saveTaskDTO.skipValidation = true;
    // }
    this.petitionerService.addTaskFromWizard(payload, formValue.visaType).pipe(
      concatMap((addTaskFromWizardRes: any) => {
        // addTaskFromWizardRes.skipValidation = true;
        // addTaskFromWizardRes.status = 200;
        // if(addTaskFromWizardRes && addTaskFromWizardRes.skipValidation && addTaskFromWizardRes.status==200){
        //   console.log('errorrrrrr0000000', addTaskFromWizardRes);
        //   this.loadConfirmPopup();
        // } else {
          this.wizardTaskId = addTaskFromWizardRes.data?.taskId;
          let payload2 =
          {
            id: 0,
            jobTitle: formValue.positionTitle,
            duties: [],
            wagesFrom: null,
            wageTypeCode: null,
            wagesPerYear: null,
            wageTypeName: null,
            thisFulltime: null,
            workingHoursPerWeek: null,
            startDate: moment(formValue.startDate).format('YYYY-MM-DD'),
            endDate: moment(formValue.endDate).format('YYYY-MM-DD'),
            workLocationId: null,
            offerSignedByName: null,
            educationRequired: null,
            dotCode: null,
            experienceRequired: null,
            fieldOfStudy: [],
            technicalSkills: [],
            clientPhoneCountryCode: null,
            jobDutyForceDelete: null,
            jobRole: null,
            otherJobPosition: null,
            selectedCategory: null,
            selectedEducationId: null,
            thisPastJob: null
          }
          return this.petitionerService.getPositionDetails(payload2, formValue.visaType, addTaskFromWizardRes.data?.petitionId).pipe(
            map((getPositionDetailsRes) => ({addTaskFromWizardRes, getPositionDetailsRes}))
          )
        //}
      }
    ),
    concatMap(({addTaskFromWizardRes, getPositionDetailsRes}) => {
      return this.immigrationWizardService.getWizardTaskDetails$().pipe(
        map((getWizardTaskDetailsRes) => ({addTaskFromWizardRes, getPositionDetailsRes, getWizardTaskDetailsRes}))
      )
    })
    ).subscribe(
      ({addTaskFromWizardRes, getPositionDetailsRes}) => {
        sessionStorage.setItem('wizardTID', this.wizardTaskId);
        this.toast.success(getPositionDetailsRes.message);
        this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/immigration-wizard/upload/individual-details`]);
        this.closePopup();
        sessionStorage.removeItem('savedLcaId');
      }
    )
  }

  redirectToRequestDataDocument() {
    if (this.userType === 'Attorney') {
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/attorney/request-data`, { createTask: true }]);
    } else if (this.userType === 'Petitioner') {
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/beneficiaries/request-data`, { createTask: true }]);
    }
  }


  getAddComponent(componentName: string): any {
    if (componentName) {
      switch (componentName) {
        case 'addClientCompany':
          this.dynamicModalData = { attorneyId: this.userType === 'Attorney' ? this.primaryCompanyId : null };
          return AddClientCompanyComponent;
        case 'addPrimaryContact':
          this.dynamicModalData = { companyId: this.newTaskForm.value.companyId };
          return AddPrimaryContactComponent;
        case 'addBeneficiary':
        case 'addMember':
          const isSponsor = (!!(this.newTaskForm.get('immCategoryCode').value && this.newTaskForm.get('immCategoryCode').value === 'GCFLY') || this.dependentVisaArray.includes(this.newTaskForm.get('visaType').value) || this.newTaskForm.get('immClassificationCode').value === 'AFFDVTSUPT864') && !this.newTaskForm.get('isSelfSponsor').value && componentName === 'addBeneficiary' ? true : false;
          this.dynamicModalData = { companyId: this.foreignFields?.showForeignCompany ? this.newTaskForm.value.foreignCompanyId : (this.newTaskForm.value.companyId ? this.newTaskForm.value.companyId : this.primaryCompanyId), isAddingMember: false, isAddingSponsor: isSponsor };
          if (componentName === 'addMember') {
            this.dynamicModalData = { ...this.dynamicModalData, isAddingMember: true, beneficiaryId: this.newTaskForm.value.beneficiaryId };
          }
          return AddBeneficiaryOrMemberComponent;
        default:
          return '';
      }
    }
  }

  handleAddClick(addFlag: string = '') {
    this.dynamicDialogRef = this.dialogService.open(this.getAddComponent(addFlag), {
      width: '60%',
      contentStyle: { 'z-index': '1000', 'padding-bottom': '15px', 'overflow-y': 'hidden !important' },
      styleClass: 'create-task-new-dialog',
      closable: false,
      showHeader: false,
      closeOnEscape: false,
      modal: true,
      dismissableMask: true,
      data: this.dynamicModalData
    });
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  onChangeCountry(countryCode: string) {
    this.countryService.getStates(countryCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe((states: []) => {
        this.listStates = states;
      });
  }

  closePopup() {
    this.wizardDialogRef.close();
  }

  onChangeState(stateCode: string) {
    this.countryService.getCounties(stateCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe((county: []) => {
        this.listCounty = county;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.formChangesSubscriptions) {
      this.formChangesSubscriptions.forEach(item => { item.unsubscribe(); });
    }
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));

  }

  redirectToEFiling(label){
    if(label=='E-Filing'){
      window.open(window.location.origin + '/efilling/', '_blank');
    }
    // window.open('http://34.235.141.147/efilling/' + '/efilling/', '_blank');
  }


  onPetitionerPreparerSelected() {
    const id = this.newTaskForm.get('immSpecialistId').value;
    this.selectedPetetionPreprarer = this.users.find(ele => ele.id === id);
  }

  userSelected() {
    const id = this.newTaskForm.get('authorizedSignatoryId').value;
    this.selectedSignatory = this.reviewUsersList.find(ele => ele.id === id);
    this.newTaskForm.patchValue({
      selectedDesignation: this.selectedSignatory.designation,

    });
    this.selectedDesignation = this.selectedSignatory.designation;
  }

  get isimmSpecialistIdIsInvalid() {
    return this.newTaskForm.get('immSpecialistId').invalid;
  }
  get isauthorizedSignatoryIdIsInvalid() {
      return this.newTaskForm.get('authorizedSignatoryId').invalid;
  }
  get ispetitionTypeIsInvalid() {
      return this.newTaskForm.get('petitionType').invalid;
  }
  get isbeneficiaryConfirmationNoIsInvalid() {
      return this.newTaskForm.get('beneficiaryConfirmationNo').invalid;
  }
  get isisOutsideImagilityIsInvalid() {
      return this.newTaskForm.get('isOutsideImagility').invalid;
  }
  get isbeneficiaryIsInvalid() {
      return this.newTaskForm.get('beneficiary').invalid;
  }
  get isbeneficiaryIdIsInvalid() {
      return this.newTaskForm.get('beneficiaryId').invalid;
  }
  get ispositionTitleIsInvalid() {
      return this.newTaskForm.get('positionTitle').invalid;
  }
  get isstartDateIsInvalid() {
      return this.newTaskForm.get('startDate').invalid;
  }
  get isendDateIsInvalid() {
      return this.newTaskForm.get('endDate').invalid;
  }
  get isstateProvinceCodeIsInvalid() {
      return this.newTaskForm.get('stateProvinceCode').invalid;
  }
  get iscountyIsInvalid() {
      return this.newTaskForm.get('county').invalid;
  }
  get isserviceCenterIdIsInvalid() {
      return this.newTaskForm.get('serviceCenterId').invalid;
  }
}

