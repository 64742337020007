<div [ngStyle]="{'height': configuration.containerHeight ? configuration.containerHeight : 'auto'}">
  <div class="inline-edit" *ngIf="!isEditMode">
    <div class="value-display" (click)="edit()"
      [ngStyle]="{'font-size': configuration.fontSize ? configuration.fontSize : '14px' }">{{ value }}</div>
    <!-- <div class="edit-controls">
    <button pButton type="button" icon="pi pi-pencil" title="Edit" (click)="edit()"></button>
  </div> -->
  </div>
  <div class="inline-edit-mode"
    [ngStyle]="{'display': configuration.inlineEditMode ? configuration.inlineEditMode : 'flex' }" *ngIf="isEditMode">
    <input type="text" [(ngModel)]="value"
      [ngClass]="{'w-100': configuration.inlineEditMode && configuration.inlineEditMode === 'block'}">
    <div class="edit-controls"
      [ngClass]="{'m-top': configuration.inlineEditMode && configuration.inlineEditMode === 'block', 'm-left': !(configuration.inlineEditMode && configuration.inlineEditMode === 'block')}">
      <button pButton type="button" icon="pi pi-check" title="Save" class="save-btn" (click)="save()"></button>
      <button pButton type="button" icon="pi pi-trash" title="Clear" class="clear-btn" (click)="clear()"
        *ngIf="configuration.needClear"></button>
      <button pButton type="button" icon="pi pi-times" title="cancel" class="cancel-btn" (click)="cancel()"></button>
    </div>
  </div>
</div>
