<div class="fluid-container">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-9 step-title">
      <!-- <h5 class="mb-0">Petitioner Details & Documents (Foreign Company)</h5>
        <p class="mb-0">Upload supporting petitioner documents</p> -->
      <div class="page-header">
        <h2>Petitioner Details & Documents (Foreign Company)</h2>
        <p>Upload supporting petitioner documents.</p>
      </div>
    </div>
    <div class="col-md-2 editBtn">
      <gc-status-update [stepDetails]="stepDetails"
        [visaType]="inputData.caseType === 'I130' ? inputData.caseType : inputData.visatype" type="EDIT">
      </gc-status-update>
    </div>
    <div class="col-md-1 pr-0 d-flex justify-content-end">
      <lib-maximize-dialog-library [configData]="{'layoutCode':inputData?.layoutCode}"></lib-maximize-dialog-library>
      <i (click)="handleClose();" class="close-icon pi pi-times"></i>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <!-- <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)">
      </lib-assigning-task-library> -->
      <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)"
      [isViewMode]="inputData['isViewMode']" [isCaseRequest]="inputData['isCaseRequest']">
      </lib-assigning-task-library>

    </div>
  </div>
  <p-divider></p-divider>
  <div class="main-body footer-adjust">
    <div class="row col-md-12 pt-4 pb-3">
      <h6 class="heading p-text-bold">Petitioner Details (Foreign Company)</h6>
    </div>
    <div class="row pb-3">
      <div class="col">
        <label class="label-cls">Employer Name</label>
        <div class="readOnly-label">{{PetitionerDetailsForeignCompany?.companyName}}</div>
      </div>
    </div>
    <div class="row  pb-3">
      <div class="col-md-4">
        <label class="label-cls">Address Line 1</label>
        <div class="readOnly-label">
          {{PetitionerDetailsForeignCompany?.address[PetitionerDetailsForeignCompany.address.length -1]?.addressLine1}}
        </div>
      </div>
      <div class="col-md-4">
        <label class="label-cls">Address Line 2</label>
        <div class="readOnly-label">
          {{PetitionerDetailsForeignCompany?.address[PetitionerDetailsForeignCompany.address.length -1]?.addressLine2}}
        </div>
      </div>
      <div class="col-md-4">
        <label class="label-cls">Country</label>
        <div class="readOnly-label">
          {{PetitionerDetailsForeignCompany?.address[PetitionerDetailsForeignCompany.address.length -1]?.countryName}}
        </div>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-md-4">
        <label class="label-cls">State</label>
        <!-- <div class="readOnly-label" *ngIf="!PetitionerDetailsForeignCompany?.address[PetitionerDetailsForeignCompany.address.length -1]?.stateProvinceName === null">{{PetitionerDetailsForeignCompany?.address[PetitionerDetailsForeignCompany.address.length -1]?.stateProvinceName}}</div> -->
        <div class="readOnly-label">
          {{PetitionerDetailsForeignCompany?.address[PetitionerDetailsForeignCompany.address.length -1]?.state ?
          PetitionerDetailsForeignCompany?.address[PetitionerDetailsForeignCompany.address.length -1]?.state :
          PetitionerDetailsForeignCompany?.address[PetitionerDetailsForeignCompany.address.length
          -1]?.stateProvinceName}}</div>
      </div>
      <div class="col-md-4">
        <label class="label-cls">City</label>
        <div class="readOnly-label">
          {{PetitionerDetailsForeignCompany?.address[PetitionerDetailsForeignCompany.address.length -1]?.city}}</div>
      </div>
      <div class="col-md-4">
        <label class="label-cls">ZIP / Postal Code</label>
        <div class="readOnly-label">
          {{PetitionerDetailsForeignCompany?.address[PetitionerDetailsForeignCompany.address.length -1]?.postCode}}
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="row col-md-12 p-0 pt-2 d-flex align-items-center">
      <div class="col-md-6">
        <h6 class="heading p-text-bold">Petitioner Documents (Foreign Company)</h6>
        <!-- <p class="mb-0">Default documents from company profile</p> -->
      </div>
      <div class="col-md-6 sync-btn">
        <button pButton type="button" label="" class="p-button-outlined btn pull-right pl-0 pr-0"
          [disabled]="disabledControls" (click)="syncPetitionerDocuments()" pTooltip="Syn Profile">
          <img src="/assets/images/sync-icon.svg" class="mr-2 pl-2" />
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="courseList col-md-12 p-0">
          <p-card class="courses-card">
            <div class="row document-header">
              <div class="col-md-5">
                <h6 class="text-uppercase">Document Type</h6>
              </div>
              <div class="col-md-5">
                <h6 class="text-uppercase">Document Name</h6>
              </div>
              <div class="col-md-2">
                <h6 class="text-uppercase">Action</h6>
              </div>
            </div>
            <div class="row document  courses-title d-flex align-items-center"
              *ngFor="let item of petitionerDetailsForeignDocuments; let i = index">
              <div class="col-md-5">
                <span>
                  {{ item?.fileCategory.code === 'OTHER' && item.isPresent ? item.fileCtaegoryOtherName : item?.fileCategory.name }}
                </span>
              </div>
              <div class="col-md-5">
                <span *ngIf="item.isPresent">
                  {{ item.fileName}}
                </span>
                <span *ngIf="!item.isPresent" class="redColor d-flex align-items-center">
                  <i class="pi pi-exclamation-triangle pr-2"></i> Document Missing
                </span>
              </div>
              <div class="col-md-2 pull-right d-flex align-items-center text-center" *ngIf="item?.isPresent">
                <div class="mr-2 padding0">
                  <button pButton type="button" pTooltip="View" [disabled]="!item.fileLocation" (click)="openDoc(item)"
                    class="p-button-text p-button-rounded">
                    <mat-icon class="mr-1 icn" matTooltip="View">visibility</mat-icon>
                  </button>
                </div>
                <div class="mr-2 padding0">
                  <button *ngIf="userType === 'Attorney'" pButton pRipple type="button" style=" color: #75777A;"
                    icon="pi pi-directions-alt icons-arrow" class="p-button-rounded p-button-text" pTooltip="Send Back"
                    [disabled]="disabledControls" (click)="handleSendBack(item)"></button>
                </div>
                <div class="mr-2 padding0">
                  <button pButton type="button" pTooltip="Delete" (click)="deleteImmigrationDocuments(item)"
                    [disabled]="disabledControls" class="p-button-text p-button-rounded">

                    <mat-icon class="mr-1 icn" matTooltip="Delete">delete</mat-icon>
                  </button>
                </div>
              </div>
              <div class="col-md-2" *ngIf="!item?.isPresent">
                <button *ngIf="userType !== 'Beneficiary'" pButton pRipple pTooltip="Notify" type="button"
                  icon="pi pi-bell" [disabled]="disabledControls || userType === 'Petitioner'" style=" color: #75777A;"
                  (click)="handleNotification(item)" class="p-button-text p-button-rounded"></button>

                <!-- <button *ngIf="userType !== 'Beneficiary'" pButton pRipple pTooltip="Not Applicable"
                                  type="button" icon="pi pi-ban" [disabled]="disabledControls"
                                  class="p-button-text p-button-rounded"></button> -->
              </div>
            </div>
          </p-card>
        </div>
      </div>
      <!-- <div class="col-md-12 p-0 ">
                <i140-file-upload style="width: 100%;" [fileUploadConfigurations]="fileUploadConfigurations">
                </i140-file-upload>
            </div> -->
    </div>
    <p-divider></p-divider>
    <div class="row col-md-12 p-0 pt-2 d-flex align-items-center">
      <div class="col-md-6">
        <!-- <p class="mb-0">Upload any additional required documents</p> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 p-0 ">
        <file-upload-library style="width: 100%;" [fileUploadConfigurations]="otherDocumentsFileUploadConfigurations">
        </file-upload-library>
      </div>
    </div>
  </div>
  <p-divider class="mt-2"></p-divider>
  <div class="row pt-3">
    <div class="col-md-6">
    </div>
    <div class="col-md-6">
      <gc-status-update [stepDetails]="stepDetails"
        [visaType]="inputData.caseType === 'I130' ? inputData.caseType : inputData.visatype" [type]="typeOfButton">
      </gc-status-update>
    </div>
  </div>
</div>
