import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import * as I9SectionOneSelector  from 'src/app/app-state/selectors/i9-section-one.selector';
import { AppState } from 'src/app/data/models/app-state';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';


@Component({
  selector: 'app-beneficiary-details-readonly',
  templateUrl: './beneficiary-details-readonly.component.html',
  styleUrls: ['./beneficiary-details-readonly.component.scss']
})
export class BeneficiaryDetailsReadonlyComponent implements OnInit, OnChanges {
  @Input() section1Info;
  @Input() beneSignature;
  @Input() isEdit: boolean; 
  beneSelfDetailsForm: FormGroup;
  submitted: boolean = false;
  currentAddressObj: any = null;
  primaryEmail: string;
  mobileNumber: string;
  listCountry: [];
  listStates: [];
  listForeignCountry = [];
  today = moment().toLocaleString();
  constructor(private store: Store<AppState>,
    private cacheCountryService: CacheCountryService,) {

    this.cacheCountryService.getCachedCountryList().subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries;
        this.listForeignCountry = this.listCountry;//this.listCountry.filter((item) => item['countryCode'] !== 'USA');
      }
    });
   }

  ngOnInit(){
    this.store.pipe(select(I9SectionOneSelector.selectI9SectionOne)).subscribe( state =>{
      if(state){
        this.section1Info = state;
        let beneData = this.section1Info.beneficiaryDetails || null;
        if(beneData){
          this.currentAddressObj = (beneData?.benAddress && beneData?.benAddress.length>0) ? beneData.benAddress.filter(x => x.type.code === 'CURR')[0]: null;
          this.primaryEmail = (beneData?.benEmail && beneData?.benEmail.length>0) ? beneData.benEmail.filter(x => x.type.code === 'PRIM')[0].email : '';
          this.mobileNumber = (beneData?.benPhone && beneData?.benPhone.length>0) ? beneData.benPhone.filter(x => x.type.code === 'MOBL')[0].phoneNo : '';
        }      
      }
    });
  }
  ngOnChanges(e: SimpleChanges) {
  //  if(e.section1Info?.currentValue){
  //    let beneData = e.section1Info.currentValue.beneficiaryDetails || null;
  //    if(beneData){
  //     this.currentAddressObj = (beneData?.benAddress && beneData?.benAddress.length>0) ? beneData.benAddress.filter(x => x.type.code === 'CURR')[0]: null;
  //     this.primaryEmail = (beneData?.benEmail && beneData?.benEmail.length>0) ? beneData.benEmail.filter(x => x.type.code === 'PRIM')[0].email : '';
  //     this.mobileNumber = (beneData?.benPhone && beneData?.benPhone.length>0) ? beneData.benPhone.filter(x => x.type.code === 'MOBL')[0].phoneNo : '';
  //    }      
  //  }
   if(e.beneSignature?.currentValue){
     this.beneSignature = `data:image/jpeg;base64,${e.beneSignature.currentValue}`;
   }
  }
  // edit(){
  //   this.isEdit=!this.isEdit;
  // }
}
