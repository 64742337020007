export class WorkDetails {
    constructor(obj: Partial<WorkDetails>) {
        Object.assign(this, obj);
    }
    id: number;
    companyName: string;
    designation: string;
    isCurrentRole: boolean;
    startDate: string;
    endDate: string;
    currency: string;
    salary: number;
    stateProvinceCode: string;
    stateProvinceName: string;
    city: string;
    country: string;
    employmentType: string;
    countryCode: string;
    mobileNo: string;
    mobileCountryCode: {
        countryCode: string;
    };
    officeCountryCode: {
        countryCode: string;
    };
    officeNo: string;
    addressLine1: string;
    addressLine2: string;
    duty: string;
    subDutyDescription: string;
    jobDuties: JobDuties[];
    skillName: string;
    tools: Tool[];
    employmentTypeValue?: string;
    zipCode: string;
    workExpDetailId: string;
    clients: Client[];
    documentList: Document[];
}

export interface JobDuties {
    dutyId: number;
    sequenceNo: number;
    duty: string;
    subDuties: SubDuties[]
}

export interface SubDuties {
    sequenceNo: number;
    subDutyId: number;
    subDutyDescription: string;
}

export interface Tool {
    id: number;
    skillName: string;
}

export interface Client {
    id: number;
    clientName: string;
    startDate: string;  
    endDate: string;
}

export interface Document {
    fileType: string;
    fileName: string;
}