import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImagilityBaseResponse, TaskStep } from 'app-models';
import { stepStatus } from 'src/app/data/constants/step-status';
import { Store } from '@ngrx/store';
import { updateStepStatus } from 'visa-store';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { VisaExtendedState } from '../../utility/interfaces/visa-extended-state';
import { TaskNotesService } from 'task-notes-lib';
import { layout_code } from "../../utility/constants/layout-code";
import { MarkCleanFormAction, selectFormDirty, ConfirmationModalComponent } from 'dirty-check-store';
import { map, take } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'gc-status-update',
  templateUrl: './status-update.component.html',
  styleUrls: ['./status-update.component.scss']
})
export class StatusUpdateComponent implements OnInit {

  @Input()
  stepDetails: TaskStep;

  @Input()
  type: string;

  @Input()
  visaType: string;

  @Input() isViewMode: boolean;
  @Input() isCaseRequest : boolean;

  @Output()
  onStatusToggle: EventEmitter<boolean> = new EventEmitter();

  @Output()
  onDiscardUnsavedChanges: EventEmitter<boolean> = new EventEmitter();

  guardSubscription: any;
  tootlTipTextMarkComplete: string;
  tootlTipTextMarkSubmit: string;
  tootlTipTextEdit: string;

  userType: string;
  stepLayoutCodes: any[] = [layout_code.H1_B_POSITION_DETAIL,layout_code.H1B_POSITION_DETAIL,layout_code.POSITION_DETAIL,layout_code.BEN_H1_B_POSITION_DETAIL,layout_code.PAST_POSITION_DETAIL ,layout_code.BEN_PAST_POSITION_DETAIL,layout_code.H1B_POSITION_DETAIL];
  beneQuestIsCaseRequest: string;
  constructor(
    private store: Store<VisaExtendedState>,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private taskNotesService: TaskNotesService,
    private dialog: MatDialog, 
    private ngxService: NgxUiLoaderService
  ) {
    this.tootlTipTextMarkComplete = '';
    this.tootlTipTextEdit = '';
    this.tootlTipTextMarkSubmit = '';
  }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem("userTypeRole");
    this.beneQuestIsCaseRequest = 'beneQuestIsCaseRequest' in sessionStorage ? sessionStorage.getItem('beneQuestIsCaseRequest') : 'false';
    this.tootlTipTextMarkComplete = `To update the '${this.stepDetails.stepName}' step status to COMPLETE in Timeline, Please click on "Mark Complete" Button`;
    this.tootlTipTextEdit = `To update the '${this.stepDetails.stepName}' step status to IN-PROGRESS in Timeline, Please click on "Edit" Button`;
    this.tootlTipTextMarkSubmit = `To update the '${this.stepDetails.stepName}' step status to SUBMIT in Timeline, Please click on "Mark Submit" Button`;
  }

  get stepStatus() {
    return stepStatus;
  }

  updateStepStatus(status) {
    if(this.stepDetails['stepActionList'] && this.stepLayoutCodes.includes(this.stepDetails['stepActionList'][0]['layoutCode'])){
      this.store.select(selectFormDirty).pipe(
        take(1)).subscribe(formState => {
          if (!formState.dirty) {
            this.continueToStepUpdate(status);
          } else {
            this.ngxService.stopAll();
            return this.dialog.open(ConfirmationModalComponent, {
              width: '400px',
              data: {
                update: {
                  title: 'Unsaved Changes!',
                  message: 'If you leave before saving, all changes made on the page will be lost. Do you wish to continue?'
                },
                buttons: ['No', 'Yes'],
                disableClose: true
              }
            }).beforeClosed().pipe(take(1)).subscribe((confirmed: boolean) =>{
                if(confirmed){
                  this.continueToStepUpdate(status);
                  this.onDiscardUnsavedChanges.emit(true);
                }
            });
          }
        });
    }else{
      this.continueToStepUpdate(status);
    }
  }

  continueToStepUpdate(status){
    this.store.dispatch(new MarkCleanFormAction({
      dirty: false
    }));
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.visaType,
      stepId: this.stepDetails.id
    }));
  }

  updateNoteAvailability(e) {    
    this.stepDetails.notesAvailable = e?.notesAvailable || false;  
    this.stepDetails.notes.noteText = e.noteText || '';
  }  

}
