import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  LoginActions,
  SET_ATTORNEY_INVITE_BENEFICIARY,
  SET_COMPANY_USER_ID,
  SET_IS_BACK_TO_LOGIN,
  SET_MASTER_USER_ALREADY_REGISTERED
} from '../actions/login.actions';
import * as fromRoot from '../../../../app.reducer';

export interface LoginState {
  linkRequestId: string;
  initiated: string;
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
  beneficiaryMidName: string;
  contactEmail: string;
  backToLogin?: boolean;
  type?: string;
  beneficiaryType?: string;
  companyUserId?: string;
  beneficiaryId?: number;
  masterUserRegistered?: boolean;
}

export interface State extends fromRoot.State {
  login: LoginState;
}

const InitialTask: LoginState = {
  linkRequestId: null,
  initiated: null,
  beneficiaryFirstName: null,
  beneficiaryLastName: null,
  beneficiaryMidName: null,
  contactEmail: null,
  backToLogin: false,
  type: null,
  beneficiaryType: null,
  companyUserId: null,
  beneficiaryId: null,
  masterUserRegistered: false
};

export function loginReducer(state = InitialTask, action: LoginActions) {
  switch (action.type) {
    case SET_ATTORNEY_INVITE_BENEFICIARY:
      return {
        ...state,
        ...action.payload
      };
    case SET_IS_BACK_TO_LOGIN:
      return {
        ...state,
        backToLogin: action.payload
      };
    case SET_COMPANY_USER_ID:
      return {
        ...state,
        companyUserId: action.payload
      };
    case SET_MASTER_USER_ALREADY_REGISTERED:
      return {
        ...state,
        masterUserRegistered: action.payload
      };
    default:
      return state;
  }
}

export const getTaskState = createFeatureSelector<LoginState>('login');
export const getLoginState = createSelector(getTaskState, (state: LoginState) =>
  state ? state : null);
export const getIsBackToLogin = createSelector(getTaskState, (state: LoginState) =>
  state ? state.backToLogin : false);
export const getIsTypeNotCreate = createSelector(getTaskState, (state: LoginState) =>
  state && state.type ? String(state.type).toLowerCase() !== 'created' : true);
