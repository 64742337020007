<div mat-dialog-title class="d-flex align-items-center justify-content-between">
  <h4 class="mb-0">Snooze</h4>
  <i class="fa fa-close pointer" (click)="cancelreminder()"></i>
</div>
<mat-dialog-content class="mat-typography snooze-details">
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Scheduled</a>
    <ng-template ngbNavContent>
      <p-table [value]="leadSnoozes" [scrollable]="true" scrollHeight="300px" styleClass="p-datatable-gridlines">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 0.4rem">#</th>
            <th style="width: 160px">Snooze Date</th>
            <th style="width: 120px">Snooze Time</th>
            <th>Snooze Message</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-snooze let-i="rowIndex">
          <tr>
            <td style="width: 0.4rem">{{i+1}}</td>
            <td style="width: 160px">{{snooze.reminderDate}}</td>
            <td style="width: 120px">{{snooze.reminderTime}}</td>
            <td>{{snooze.reminderDesc}}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>Add New</a>
    <ng-template ngbNavContent>
      <div>
        <form [formGroup]="addSnoozeForm">
          <div class="row mt-2">
            <div class="col-3">
              <label for="first-name" class="label-cls required">Snooze Date & Time</label>
            </div>
            <!-- <p-calendar formControlName="reminderDate" name="reminderDate" ngDefaultControl styleClass="p-form-datepicker" dateFormat="mm/dd/yy"
              placeholder="MM/DD/YYYY" [showIcon]="true" [minDate]="minimumDate" [monthNavigator]="true"
              [yearNavigator]="true" yearRange="2000:2099">
            </p-calendar> -->
            <div class="col-9">
              <p-calendar formControlName="reminderDate" placeholder="Select date & time" [minDate]="minimumDate" [(ngModel)]="snoozeDate" [showTime]="true" hourFormat="12" [showIcon]="true" inputId="icon" class="p-form-datepicker"></p-calendar>

              <small *ngIf="addSnoozeForm.get('reminderDate').touched && addSnoozeForm.get('reminderDate').errors" class="p-error px-3">
                <span *ngIf="addSnoozeForm.get('reminderDate').errors['required']">Required</span>
              </small>
            </div>
            </div>
            <!-- <div class="row mt-2">
            <div class="col-3">
              <label for="first-name" class="label-cls required">Time</label>
              </div>
              <div class="col-9">
              <p-calendar [(ngModel)]="snoozeTime" formControlName="reminderTime" [timeOnly]="true" hourFormat="24" dateFormat="HH:mm"></p-calendar>
              <small *ngIf="addSnoozeForm.get('reminderTime').touched && addSnoozeForm.get('reminderTime').errors" class="p-error px-3">
                <span *ngIf="addSnoozeForm.get('reminderTime').errors['required']">Required</span>
              </small>

            </div>

          </div> -->
          <div class="row mt-2 ml-2">
            <label class='required' for="">Description/Agenda</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="reminderDesc" placeholder="Enter Description/Agenda"></textarea>
            <small *ngIf="addSnoozeForm.get('reminderDesc').touched && addSnoozeForm.get('reminderDesc').errors"
              class="p-error">
              <span *ngIf="addSnoozeForm.get('reminderDesc').errors['required']">Required</span>
            </small>
          </div>
          <div class="row mt-4 mb-4">
            <button type="button" [disabled]="!addSnoozeForm.valid" class="btn btn-primary" (click)="save()">Save</button>
            <button type="button" class="btn btn-light ml-2" (click)="cancelreminder()">Cancel</button>
          </div>

        </form>
      </div>
    </ng-template>
  </li>
</ul>
</mat-dialog-content>

<div [ngbNavOutlet]="nav" class="mt-2"></div>

<mat-dialog-actions class="d-flex align-items-center justify-content-end" *ngIf="active==1">
  <button class="mt-2 btn btn-primary" (click)="cancelreminder()">Close</button>
</mat-dialog-actions>

