import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { EMPTY, Subject, of } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-userId-validation',
  templateUrl: './userId-validation.component.html',
  styleUrls: ['./userId-validation.component.scss']
})
export class UserIdValidationComponent implements OnChanges, OnDestroy {
  @Input() public userId;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter();
  @Input() checkUserIdAvailability = false;
  @Input() isSystemGenerated = false;
  userAvailable = false;
  reset$ = new Subject<void>();
  userIdValid: boolean;
  observableSubscription$ = new Subject();

  constructor(private loginService: LoginService) {
    this.reset$.pipe(
      takeUntil(this.observableSubscription$),
      startWith(undefined as void),
      switchMap(() => {
        if (this.userId) {
          return this.isSystemGenerated ? of({status: 200, data: {available: true}}) : this.loginService.checkUserIdAvailability(this.userId);
        } else {
          this.userAvailable = false;
          this.isValid.emit(false);
          return EMPTY;
        }
      })
    )
      .subscribe((response: ImagilityBaseResponse) => {
        if (response.status === 200 && response.data) {
          this.userAvailable = response.data.available;
          this.isValid.emit(true);
        } else {
          this.userAvailable = false;
          this.isValid.emit(false);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userId) {
      this.userIdAvailable();
    }
  }

  userIdAvailable() {
    this.validateInput('totalCount');
    this.validateInput('aA1');
    this.validateInput('number');
    this.validateInput('specChar');
    if (this.checkUserIdAvailability && this.userIdValid) {
      this.reset$.next();
    }
  }

  validateInput(type): boolean {
    let regex: RegExp;

    if (type === 'totalCount') {
      regex = /^.{5,12}$/;
    }
    else if (type === 'aA1') {
      regex = /^(?=.*?[A-Za-z])/g;

    }
    else if (type === 'number') {
      regex = /^(?=.*\d)/g;
    }
    else if (type === 'specChar') {
      regex = /^[a-zA-Z0-9\\_\\-\\.]*$/;
    }
    if (this.userId.match(regex)) {
      this.isControlValid();
      return true;
    } else {
      this.userIdValid = false;
      this.isValid.emit(false);
      return false;
    }

  }

  isControlValid() {
    if (!this.userId || (this.userId && (this.userId.length < 5 || this.userId.length > 12))) {
      this.userIdValid = false;
      this.isValid.emit(false);
    }
    else {
      if (this.userId.match('.*[a-zA-Z]+.*')) {
        if (this.userId.match('.*[0-9]+.*')) {
          const regex1 = '^[a-zA-Z0-9\\_\\-\\.]*$';
          this.userIdValid = this.userId.match(regex1) ? true : false;
          this.isValid.emit(this.userId.match(regex1) ? true : false);
        }
        else {
          this.userIdValid = false;
          this.isValid.emit(false);
        }
      }
    }
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }



}
